<template>
  <OverallSettings v-bind="$props">
    <div class="c-cta-banner" v-if="fields">
      <div class="c-cta-banner__content" :class="`align-${align}`">
        <SiteButton class="c-cta-banner__btn" v-for="btn in fields.buttons" :key="btn.id" v-bind="btn" />
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { computed } from 'vue';
export default {
  name: 'CTABanner',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const computes = {
      align: computed(() => (props.fields.align?.fields.phrase?.value ? props.fields.align.fields.phrase.value : 'left'))
    };
    return {
      ...computes
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
.c-cta-banner {
  @include component-overall-settings;
  @include component-themes;
  @include grid-container;
  &__content {
    @include grid-block(2, 10);
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 0;
    &.align- {
      &left {
        justify-content: flex-start;
        text-align: start;
      }
      &center {
        justify-content: center;
        text-align: center;
      }
      &right {
        justify-content: flex-end;
        text-align: end;
      }
    }
  }

  @include tablet-landscape {
    &__content {
      @include grid-block(3, 20);
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 40px;
    }
  }
}
.c-uk-cards {
  $cd: '.c-uk-cards';
  &_column {
    .c-cta-banner {
      display: block;
      &__content {
        width: 100%;
      }
    }
  }
}
</style>

<template>
  <OverallSettings v-bind="$props">
    <div class="c-banner-video" v-if="fields">
      <AdaptiveBackImage class="c-banner-video__backgroud" :pc-image="fields.backgroudPC" :mobile-image="fields.backgroudMobile">
        <div class="c-banner-video__content" :class="contentClasses" v-grid-layout="fields.gridLayout">
          <div class="c-banner-video__text-group" v-for="item in fields.textGroup" :key="item.id">
            <Icon v-if="!isMobile()" :svg="item.fields.icon?.value.svgCode" class="c-banner-video__text-group-icon" :class="`__c-${item.fields.iconColor?.fields.phrase.value ?? 'black'}`" />
            <JssRichText v-if="!isMobile()" class="c-banner-video__text-group-title" :field="item.fields.title" tag="span" />
            <div class="c-banner-video__text-group-body">
              <JssRichText class="c-banner-video__text-group-body-item" :field="item.fields.body" tag="div" />
            </div>
            <div class="c-banner-video__text-group-body-line" :class="`__bc-${fields.lineColor?.fields.phrase.value ?? 'black'}`"></div>
          </div>
        </div>
      </AdaptiveBackImage>
      <div class="c-banner-video__video">
        <VideoPlayer :options="videoOptions" v-if="fields.video?.value" />
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { computed } from 'vue';
import { isMobile } from '@/utils/dom-utils';
export default {
  name: 'BannerVideo',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const computes = {
      contentClasses: computed(() => [`align-${props.fields.align?.fields.phrase.value ?? 'left'}`]),
      videoOptions: computed(() => methods.buildVideoOps(props.fields.video))
    };
    const methods = {
      isMobile: isMobile,
      buildVideoOps(video) {
        return {
          controls: true,
          autoplay: false,
          muted: true,
          loop: true,
          disableFullscreen: props.fields.videoDisablefullscreen?.value ?? false,
          disableUnmute: props.fields.videoDisableUnmute?.value ?? false,
          sources: [
            {
              type: 'video/mp4',
              src: video.value
            }
          ]
        };
      }
    };
    return {
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
@import '../styles/rte/color.scss';
@import '../styles/rte/backgroundColor.scss';
@import '../styles/rte/fontSize.scss';
.c-banner-video {
  position: relative;
  &__backgroud {
    @include grid-block(1, 12);
    height: 100vh;
    .e-background-image__content {
      @include grid-container;
      height: 100%;
    }
  }
  &__content {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: $space-120;
    &.align {
      &-left {
        > div:first-child {
          text-align: end;
        }
      }
      &-center {
        text-align: center;
        .c-banner-video__text-group-body-line {
          margin: 0 auto;
        }
      }
      &-right {
        > div:last-child {
          text-align: end;
        }
        .c-banner-video__text-group-body-line {
          float: right;
        }
      }
    }
  }
  &__text-group {
    &:last-child {
      margin-bottom: $space-60;
      .c-banner-video__text-group-body-line {
        display: block !important;
      }
    }
    &:last-child {
      margin-bottom: $space-60;
    }
    &-icon {
      margin-bottom: $space-10;
    }
    &-body {
      padding-bottom: $space-40;
    }
    &-body-line {
      display: none !important;
      height: 5px;
      width: grid-width(8);
      padding: 0 !important;
    }
  }
  &__video {
    width: grid-width(24);
    height: grid-width(math.div(24 * 9, 16));
    margin-top: $space-60;
    padding: 0;
    .e-video-player {
      height: 100%;
    }
  }
  @include tablet-landscape {
    padding-bottom: $space-120;
    &__backgroud {
      height: grid-width(24);
    }
    &__content {
      padding-top: $space-80;
      justify-content: flex-start;
      &.align {
        &-left {
          > div:first-child {
            text-align: start;
          }
        }
        &-right {
          > div:first-child {
            text-align: end;
          }
          > div:last-child {
            text-align: end;
          }
        }
      }
    }
    &__text-group {
      &:first-child {
        margin-bottom: $space-40;
        .c-banner-video__text-group-body-line {
          display: block !important;
          height: 2px;
          width: grid-width(2);
          padding: 0;
        }
      }
      &:last-child {
        .c-banner-video__text-group-body-line {
          display: none !important;
        }
      }
    }
    &__video {
      width: grid-width(10);
      height: grid-width(math.div(10 * 9, 16));
      position: absolute;
      left: grid-width(2);
      bottom: grid-width(1);
    }
  }
}
html.rtl {
  .c-banner-video {
    &__content {
      &.align {
        &-right {
          .c-banner-video__text-group-body-line {
            float: left;
          }
        }
      }
    }
  }
}
</style>

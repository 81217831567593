<template>
  <overall-settings v-bind="$props">
    <form-layout
      :fields="layoutFields"
      :class="['c-contact-us-form__layout', { submitted, 'show-image-on-mobile': deviceState.deviceType === 'mobile' && fields.showImageOnMobile?.value }]"
      ref="layoutRef"
    >
      <div class="c-contact-us-form" v-if="!submitted">
        <heading class="c-contact-us-form__title" :field="fields.title" :type="fields.titleType" />
        <jss-rich-text class="c-contact-us-form__subtitle" :field="fields.subtitle" />
        <dynamic-form :form="fields.form" :data="formConfig" ref="formRef" @change="onFormChange" />
        <site-button class="c-contact-us-form__submit-btn" :disabled="submitDisabled" v-bind="fields.submitButton" @click="onSubmit" />
      </div>
      <template v-slot:image-content>
        <div class="c-contact-us-form__success" v-if="submitted">
          <heading class="c-contact-us-form__success-title" :field="fields.successTitle" rich />
          <jss-rich-text class="c-contact-us-form__success-subtitle" :field="fields.successSubtitle" rich />
          <site-button class="c-contact-us-form__success-btn" v-bind="fields.successButton" />
        </div>
      </template>
    </form-layout>
  </overall-settings>
</template>

<script>
/**
 * @typedef ContactUsFormFields
 * @property {{
 *   fields: AdaptiveImageFields
 * }} image
 * @property {SimpleField} title
 * @property {GlobalSettingEntry} titleType
 * @property {CheckField} reverse
 * @property {CheckField} fullWidthImage
 * @property {SimpleField} subtitle
 * @property {Form} form
 * @property {ButtonField} submitButton
 * @property {CheckField} showImageOnMobile
 * @property {SimpleField} successTitle
 * @property {SimpleField} successSubtitle
 * @property {ButtonField} successButton
 * @property {{
 *   fields: AdaptiveImageFields
 * }} successImage
 * @property {CheckField} successFullWidthImage
 * */
import { computed, inject, nextTick, onMounted, reactive, toRefs } from 'vue';
import api from '@/api';
import { formatDate } from '@/utils/date-utils';
import { debounce } from 'lodash';
import { getGlobalConfigs, scrollToTop } from '@/utils/site-utils';
import { gtmFormView, gtmFormStart, gtmFormSubmit } from '@/utils/gtm-utils';
import onScrollToView from '@/hooks/onScrollToView';
import useDevice from '@/hooks/useDevice';

export default {
  name: 'ContactUsForm',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    const loading = inject('loading');
    const toast = inject('toast');
    const { deviceState } = useDevice();
    const { formType } = props.page.fields;
    const state = reactive({
      /**@type FormLayout*/
      layoutRef: null,
      /**@type DynamicForm*/
      formRef: null,
      siteKey: null,
      formConfig: {
        vehicleModel: {
          visible: false
        },
        vehicleModelForOrder: {
          visible: false
        },
        receiptNumber: {
          visible: false
        }
      },
      submitDisabled: true,
      submitted: false,
      formChanged: false
    });
    const computes = {
      layoutFields: computed(() => ({
        ...props.fields,
        image: state.submitted ? props.fields.successImage : props.fields.image,
        title: state.submitted ? props.fields.successTitle : props.fields.title,
        reverse: state.submitted ? props.fields.successReverse : props.fields.reverse,
        fullWidthImage: state.submitted ? props.fields.successFullWidthImage : props.fields.fullWidthImage
      }))
    };
    const methods = {
      onSubmit: debounce(async (e) => {
        loading.show();
        const [valid, formData] = await state.formRef.validate();
        if (valid) {
          const consents = state.formRef.getVerifiedConsents();
          const leadTypes = state.formRef.getLeadTypes();
          const [res, ex] = await api.crm.contactus(null, {
            inquiryType: formData.inquiryType?.code ?? null,
            vehicleModel: formData.vehicleModel?.code ?? formData.vehicleModelForOrder?.code ?? null,
            description: formData.description,
            isLotusCustomer: formData.isLotusCustomer?.code === 'Yes',
            typeOfCustomer: formData.typeOfCustomer?.code ?? null,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: `${formData.phone?.area.code} ${formData.phone?.number}`,
            receiptNumber: formData.receiptNumber,
            countryRegion: formData.phone?.area.data.alpha2Code ?? null,
            storeCode: '',
            channel: 'Official Website',
            processData: formData.processData,
            formScId: formData.formScId,
            gRecaptchaToken: formData.gRecaptchaToken ?? null,
            termsAndConditions: consents.map((x) => ({
              revisionNumber: x,
              title: window.document.title,
              formUrl: window.location.href,
              effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
            }))
          });
          const formDetails = {
            selected_model: formData?.vehicleModel?.code ?? formData?.vehicleModelForOrder?.code ?? null,
            selected_location_name: null,
            selected_location_type: null,
            selected_location_date: null,
            selected_location_time: null,
            customer_type: formData?.typeOfCustomer?.code ?? null,
            dealer_name: null
          };
          const leadsDetails = {};
          if (leadTypes?.length) {
            leadTypes.forEach((l) => {
              leadsDetails[l.leadType] = res ?? null;
            });
          }
          gtmFormSubmit(
            formType?.value,
            props.fields.form.id,
            {
              email: formData.email,
              mobileAreaCode: formData.phone.area.code,
              phone: formData.phone.number
            },
            formDetails,
            leadsDetails
          );
          if (ex) {
            await toast.showEx(ex);
            loading.hide();
            return;
          }
          state.submitted = true;
          await nextTick();
          scrollToTop();
        }
        loading.hide();
      }, 100),
      onFormChange(key, value) {
        if (key === 'inquiryType') {
          state.formConfig.vehicleModel.visible = /I have a question about a Lotus model/gi.test(value?.code);
          state.formConfig.vehicleModelForOrder.visible = /(I have a question about a reservation)|(I need)/gi.test(value?.code);
          state.formConfig.receiptNumber.visible = /I have a question about a reservation/gi.test(value?.code);
        }
        state.submitDisabled = !state.formRef.hasAllMandatoryCheckboxChecked();
        if (!state.formChanged) {
          state.formChanged = true;
          gtmFormStart(formType?.value, props.fields.form.id);
        }
      }
    };
    onScrollToView({
      getContainer() {
        return state.layoutRef.rootEl;
      },
      callback() {
        gtmFormView(formType?.value, props.fields.form.id);
      }
    });
    onMounted(() => {
      const [gRecaptchaKey] = getGlobalConfigs(props.page, 'gRecaptchaKey');
      state.submitDisabled = !state.formRef.hasAllMandatoryCheckboxChecked();
      state.siteKey = gRecaptchaKey?.value;
    });
    return {
      ...toRefs(state),
      deviceState,
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-contact-us-form {
  $l: '.s-form-layout';
  padding-bottom: $space-40;
  &__layout {
    &.submitted {
      #{$l}__image-wrapper {
        z-index: 1;
      }
      #{$l}__main {
        display: none;
      }
    }
    &.show-image-on-mobile {
      #{$l}__side-image {
        display: block;
        @include grid-block(1, 12, 1);
        height: 100vw;
      }
      #{$l}__image-wrapper {
        height: 100vw;
      }
      #{$l}__image {
        height: 100vw;
      }
      #{$l}__main {
        @include grid-block(2, 10, 2);
      }
    }
  }
  &__title {
    @include h5;
    margin-bottom: $space-24;
  }
  &__subtitle {
    margin-bottom: $space-40;
  }
  &__submit-btn {
    width: 100%;
  }
  &__success-title {
    @include h5;
    margin-left: grid-width(2);
    padding-top: $space-56;
    margin-bottom: $space-24;
  }
  &__success-subtitle {
    margin-left: grid-width(2);
    padding-top: $space-16;
    margin-bottom: $space-24;
  }
  &__success-btn {
    @include h5;
    margin-left: grid-width(2);
  }
  @include tablet-landscape {
    &__submit-btn {
      width: auto;
      min-width: 100px;
    }
  }
}
</style>

<template>
  <OverallSettings v-bind="$props.fields.consent">
    <div class="c-consent-detail" v-if="fields">
      <JssRichText class="c-consent-detail__title" :field="fields.consent?.fields.title" />
      <div class="c-consent-detail__side" v-if="selectedOption && selectedOption.data">
        <!-- <div class="c-consent-detail__side-version">{{ `${selectedOption.data.agreementName} ${selectedOption.data.agreementVersion}` }}</div>
        <div class="c-consent-detail__side-time">{{ formatDate(selectedOption.data.updateTime) }}</div> -->
        <dropdown :options="options" :selectedOption="selectedOption" @change="onChange" />
      </div>
      <div class="c-consent-detail__main" v-if="selectedOption && selectedOption.data">
        <div v-html="$formatString(selectedOption.data.agreementContext, { lastdayofmonth: $getLastDayOfCurrentMonth() })" />
      </div>
    </div>
  </OverallSettings>
</template>

<script>
import { inject, onMounted, computed, reactive, toRefs } from 'vue';
import { formatDate } from '@/utils/date-utils';
import api from '@/api';
/**
 * @typedef ConsentDetailFields
 * @property {Consent} consent
 * */
export default {
  name: 'ConsentDetail',
  props: {
    /**@type ConsentDetailFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const toast = inject('toast');
    const loading = inject('loading');
    const state = reactive({
      options: [],
      selectedOption: {}
    });
    const computes = {
      agreementNo: computed(() => props.fields.consent?.fields.revisionNumber?.value)
    };
    const methods = {
      formatDate,
      onChange(e, option) {
        state.selectedOption = option;
      }
    };
    onMounted(async () => {
      loading.show();
      const [res, ex] = await api.consent.get(null, {
        agreementNo: computes.agreementNo.value
      });
      if (ex) {
        await toast.showEx(ex);
        loading.hide();
        return;
      } else {
        const [res1, ex1] = await api.consent.versions(null, {
          agreementNo: res.id,
          pageNum: 1,
          pageSize: 99999
        });
        if (ex1) {
          await toast.showEx(ex);
          loading.hide();
          return;
        } else {
          state.options = res1.records.map((r) => ({
            data: r,
            text: r.agreementVersion,
            code: r.id
          }));
          state.selectedOption = state.options[0];
          loading.hide();
        }
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-consent-detail {
  @include grid-container;
  &__title {
    @include grid-block(2, 10, 1);
    margin-bottom: 40px;
  }
  &__side {
    @include grid-block(2, 10, 2);
  }
  &__main {
    @include grid-block(2, 10, 3);
    &-title {
      margin-bottom: 10px;
    }
  }
  &__title {
    margin-bottom: 20px;
  }
  &__desc {
    margin-bottom: 10px;
  }
  @include tablet-landscape {
    &__title {
      @include grid-block(2, 20, 1);
      margin-bottom: 40px;
    }
    &__side {
      @include grid-block(2, 6, 2);
    }
    &__main {
      @include grid-block(10, 12, 2);
      &-title {
        margin-bottom: 20px;
      }
    }
    &__desc {
      margin-bottom: 20px;
    }
  }
}
</style>

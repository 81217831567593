import axios from 'axios';
import config from '../temp/config';
import { isAbsoluteUri, getLangFromUrl } from '@/utils/uri-utils';
import { deepCopy, isFn, isNullOrEmpty } from '@/utils/obj-utils';
import { merge } from 'lodash';
import { APP_CONST, REQUEST_TYPES } from '@/utils/constants';
import Md5 from 'crypto-js/md5';
import { webStorage } from '@/utils/web-storage';
import { S_LANG, S_LOGIN_INFO, S_LOGIN_URL } from '@/utils/web-storage-keys';
import { equalString } from '@/utils/string-utils';
import { canUseDOM } from '@/utils/dom-utils';
const service = axios.create();
const requestPromises = {};

/**@description Sitecore自定义接口*/
export const r = (path, loginRequired = false, hooks = null) => {
  return handleRequest(path, loginRequired, hooks, REQUEST_TYPES.web);
};
/**@description 官网BFF接口*/
export const bff = (path, loginRequired = true, hooks = null) => {
  return handleRequest(path, loginRequired, hooks, REQUEST_TYPES.bff);
};
/**@description Sitecore自定义接口*/
export const cms = (path, loginRequired = false, hooks = null) => {
  return handleRequest(path, loginRequired, hooks, REQUEST_TYPES.cms);
};
/**@description CRM接口,走BFF*/
export const crm = (path, loginRequired = false, hooks = null) => {
  return handleRequest(path, loginRequired, hooks, REQUEST_TYPES.crm);
};
/**@description MHP接口*/
export const mhp = (path, loginRequired = false, hooks = null) => {
  return handleRequest(path, loginRequired, hooks, REQUEST_TYPES.mhp);
};
/**@description Lotus接口*/
export const lts = (path, loginRequired = true, hooks = null) => {
  return handleRequest(path, loginRequired, hooks, REQUEST_TYPES.lts);
};
/**@description 价格中心接口*/
export const ltsP = (path, loginRequired = false, hooks = null) => {
  const [method, url] = path.split(' ');
  return lts(`${method} api/price/${url}`, loginRequired, hooks);
};
/**@description 谷歌地图接口*/
export const gm = (path) => {
  return handleRequest(path, false, null, REQUEST_TYPES.gm);
};
/**@description Code Waver接口*/
export const cw = (path, hooks = null) => {
  return handleRequest(path, false, hooks, REQUEST_TYPES.cw);
};
export const handleRequest = (path, loginRequired = false, hooks, requestType) => {
  return (params = null, data = null, opts = {}) => {
    // const extraOpts = merge({}, _optsHandler ? _optsHandler(path, params, data) : null, opts);
    const options = getRequestOptions(path, loginRequired, params, data, opts, hooks, requestType);
    return processRequest(options, requestType);
  };
};
export const getRequestOptions = (path, loginRequired, params, data, opts, hooks, requestType) => {
  let [method, url] = path.split(' ');
  const loginInfo = webStorage.get(S_LOGIN_INFO);
  const lang = canUseDOM() ? getLangFromUrl() : APP_CONST.lang;
  if (!params) params = {};
  if (requestType === REQUEST_TYPES.cw) {
    merge(params, {
      workflowId: config.app.cw.workflowId,
      'api-version': '2016-06-01',
      sp: '/triggers/manual/run',
      sv: '1.0',
      sig: config.app.cw.sig
    });
  }
  if (isFn(hooks?.beforeBuildOptions)) {
    hooks.beforeBuildOptions(method, url, params, data);
  }
  url = url.replace(/\{.+?\}/gi, (match) => {
    const matchKey = match.replace(/\{|\}/gi, '');
    let result = match;
    if (params && params[matchKey]) {
      result = params[matchKey];
      delete params[matchKey];
    }
    if (data && data[matchKey]) {
      result = data[matchKey];
      delete data[matchKey];
    }
    return result;
  });
  if (/^GET$/i.test(method)) {
    params = merge(params, {
      __t: `${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`
    });
  }
  if (!isAbsoluteUri(url)) {
    const apiInfo = config.app[requestType];
    url = `${apiInfo?.apiHost ?? `${config.sitecoreApiHost}/`}${url}`;
  }
  const options = {
    method,
    url,
    params,
    data
  };
  if (requestType !== REQUEST_TYPES.gm) {
    merge(options, {
      headers: {
        lang
      }
    });
  }
  if (requestType === REQUEST_TYPES.lts) {
    merge(options, {
      headers: {
        'app-channel': 'official-website'
      }
    });
  }
  if (requestType === REQUEST_TYPES.cw) {
    merge(options, {
      headers: {
        'Lotus-api-key': config.app.cw.apiKey
      }
    });
  }
  const { token } = loginInfo ?? {};
  if (loginRequired) {
    if (!isNullOrEmpty(token)) {
      merge(options, {
        headers: {
          authorization: token,
          token
        }
      });
    }
  }
  merge(options, opts);
  if (isFn(hooks?.afterBuildOptions)) {
    hooks.afterBuildOptions(options);
  }
  return options;
};
export const processRequest = (options, requestType) => {
  const requestKey = getRequestKey(options);
  if (!requestPromises[requestKey]) {
    requestPromises[requestKey] = new Promise((resolve) => {
      service
        .request(options)
        .then((res) => {
          if (res.status === 200) {
            resolve(handleResponse[requestType](res.data));
          } else {
            resolve([null, res]);
          }
        })
        .catch((ex) => {
          resolve([null, ex]);
        })
        .finally(() => {
          delete requestPromises[requestKey];
        });
    });
  }
  return requestPromises[requestKey];
};
export const handleResponse = {
  [REQUEST_TYPES.web](res) {
    if ([200, '200'].includes(res.code)) {
      return [res.result ?? res.data ?? null, null];
    } else {
      return [null, res];
    }
  },
  [REQUEST_TYPES.cms](res) {
    if ([200, '200'].includes(res.code)) {
      return [res.result, null];
    } else {
      return [null, res];
    }
  },
  [REQUEST_TYPES.bff](res) {
    if ([200, '200'].includes(res.code)) {
      if (!res.result || res.result.code === 10000000) {
        return [res.result?.data ?? null, null];
      } else {
        // setTimeout(() => {
        //   if (res?.result?.code === 60000001) {
        //     const loginUrl = webStorage.get(S_LOGIN_URL);
        //     webStorage.clear(S_LOGIN_INFO);
        //     // const { pathname } = window.location;
        //     // if(//gi.test(pathname)) {
        //     //   window.location = window.location;
        //     // }
        //     if (loginUrl) {
        //       window.location = appendQuery(loginUrl, {
        //         redirect_url: window.location.href
        //       });
        //       return [null, null];
        //     }
        //   }
        // }, 10);
        return [null, res.result];
      }
    } else {
      return [null, res];
    }
  },
  [REQUEST_TYPES.crm](res) {
    if ([200, '200'].includes(res.code)) {
      if (res.result?.success || [200, '200'].includes(res.result?.code)) {
        return [res.result?.data ?? res.result ?? null, null];
      } else {
        return [null, res.result];
      }
    } else {
      return [null, res];
    }
  },
  [REQUEST_TYPES.mhp](res) {
    return [res, null];
  },
  [REQUEST_TYPES.lts](res) {
    if ([10000000, '10000000'].includes(res.code)) {
      return [res.data, null];
    } else {
      return [null, res];
    }
  },
  [REQUEST_TYPES.gm](res) {
    if (['SUCCESS', 'OK'].includes(res.status)) {
      return [res, null];
    } else {
      return [null, res];
    }
  },
  [REQUEST_TYPES.cw](res) {
    return [res, null];
  }
};
const getRequestKey = (options) => {
  const body = deepCopy(options);
  if (body.params.__t) {
    delete body.params.__t;
  }
  try {
    return Md5(
      JSON.stringify(body, (key, value) => {
        if (['computed', 'effect'].includes(key)) return null;
        return value;
      })
    ).toString();
  } catch (ex) {
    return null;
  }
};

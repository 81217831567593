<template>
  <OverallSettings v-bind="$props">
    <div class="c-description-block" v-if="fields">
      <div class="c-description-block__content" ref="wrapperEl">
        <div class="c-description-block__des" ref="desEl">
          <AnimatedContent type="fade-in">
            <JssRichText class="c-description-block__des-text" :field="fields.des" />
          </AnimatedContent>
        </div>
        <div class="c-description-block__backgroud-group" ref="">
          <div class="c-description-block__backgroud" v-if="fields.backgroundVideo?.value" ref="videoEl">
            <VideoPlayer :options="videoOptions" v-if="video">
              <div class="c-description-block__details" ref="detailsEl">
                <div
                  class="c-description-block__details-des-group"
                  @click="$deviceComputes.isMobileOrTablet.value && fields.hasDes?.value && toggleDes(1)"
                  @mouseover="!$deviceComputes.isMobileOrTablet.value && fields.hasDes?.value && toggleDes(1)"
                  @mouseout="!$deviceComputes.isMobileOrTablet.value && fields.hasDes?.value && toggleDes(1)"
                >
                  <div class="c-description-block__details-des" :style="showDesStyle" v-if="fields.hasDes?.value">
                    <JssRichText :field="fields.desWindow" />
                  </div>
                  <AnimatedContent type="fade-in-top-left" ease="cubic-bezier(0.055, 0.215, 0.000, 1.000)">
                    <Icon :svg="fields.icon?.value.svgCode" class="c-description-block__des-group-icon" :class="`__c-${fields.iconColor?.fields.phrase.value}`" />
                  </AnimatedContent>
                  <AnimatedContent type="skew-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)">
                    <div class="c-description-block__details-des-group-title">
                      <JssRichText :field="fields.title" />
                      <Icon
                        :svg="fields.desIcon?.value.svgCode"
                        :class="`__c-${showDesIndex > 0 ? fields.showDesIconColor?.fields.phrase?.value : fields.closeDesIconColor?.fields.phrase?.value}`"
                        v-if="fields.hasDes?.value && fields.title?.value"
                      />
                    </div>
                  </AnimatedContent>
                  <AnimatedContent type="bottom-fade-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)">
                    <JssRichText class="c-description-block__details-des-group-body" :field="fields.body" />
                  </AnimatedContent>
                </div>
                <div class="c-description-block__details-specifications-group">
                  <div class="c-description-block__details-des" :style="showDesStyle" v-if="!fields.hasDes?.value">
                    <JssRichText :field="fields.desWindow" />
                  </div>
                  <AnimatedContent
                    class="c-description-block__details-specifications-group-item"
                    :class="item.fields.layout?.fields.phrase?.value"
                    v-for="(item, index) in fields.specifications"
                    :key="item.id"
                    type="bottom-fade-in"
                    :delay="400 + 400 * index"
                    offset="100%"
                    @click="$deviceComputes.isMobileOrTablet.value && toggleDes(index)"
                    @mouseover="!$deviceComputes.isMobileOrTablet.value && toggleDes(index)"
                    @mouseout="!$deviceComputes.isMobileOrTablet.value && toggleDes(index)"
                  >
                    <div class="c-description-block__details-specifications-group-item-left" v-if="numbers?.length">
                      <div
                        class="c-description-block__details-specifications-group-item-number"
                        :class="`__c-${item.fields.numberColor?.fields.phrase?.value ?? 'black'}`"
                        :style="getNumberWidth(numbers[index])"
                      >
                        {{ numbers[index] }}
                      </div>
                      <div class="c-description-block__details-specifications-group-item-unit" v-if="item.fields.layout?.fields.phrase?.value === 'vertical'">
                        <JssRichText :field="item.fields.unit" />
                      </div>
                    </div>
                    <div class="c-description-block__details-specifications-group-item-right">
                      <div class="c-description-block__details-specifications-group-item-unit" v-if="item.fields.layout?.fields.phrase?.value === 'horizontal'">
                        <JssRichText :field="item.fields.unit" />
                      </div>
                      <div class="c-description-block__details-specifications-group-item-des">
                        <JssRichText :field="item.fields.des" />
                        <Icon
                          :svg="fields.desIcon?.value.svgCode"
                          :class="`__c-${showDesIndex == index + 1 ? fields.showDesIconColor?.fields.phrase?.value : fields.closeDesIconColor?.fields.phrase?.value}`"
                          v-if="item.fields.hasDes?.value"
                        />
                      </div>
                    </div>
                  </AnimatedContent>
                </div>
              </div>
            </VideoPlayer>
            <div class="c-description-block__slogan-top c-description-block__slogan-top-in" ref="inSloganTopEl">
              <JssRichText :field="fields.topSloganIn" />
            </div>
            <div class="c-description-block__slogan-bottom c-description-block__slogan-bottom-in" ref="inSloganBottomEl">
              <JssRichText :field="fields.bottomSloganIn" />
            </div>
          </div>
          <div class="c-description-block__backgroud" v-else ref="imageEl">
            <BackgroundImage :image="image">
              <div class="c-description-block__details" ref="detailsEl">
                <div
                  class="c-description-block__details-des-group"
                  @click="$deviceComputes.isMobileOrTablet.value && fields.hasDes?.value && toggleDes(1)"
                  @mouseover="!$deviceComputes.isMobileOrTablet.value && fields.hasDes?.value && toggleDes(1)"
                  @mouseout="!$deviceComputes.isMobileOrTablet.value && fields.hasDes?.value && toggleDes(1)"
                >
                  <div class="c-description-block__details-des" :style="showDesStyle" v-if="fields.hasDes?.value">
                    <JssRichText :field="fields.desWindow" />
                  </div>
                  <AnimatedContent type="fade-in-top-left" ease="cubic-bezier(0.055, 0.215, 0.000, 1.000)">
                    <Icon :svg="fields.icon?.value.svgCode" class="c-description-block__des-group-icon" :class="`__c-${fields.iconColor?.fields.phrase.value}`" />
                  </AnimatedContent>
                  <AnimatedContent type="skew-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)">
                    <div class="c-description-block__details-des-group-title">
                      <JssRichText :field="fields.title" />
                      <Icon
                        :svg="fields.desIcon?.value.svgCode"
                        :class="`__c-${showDesIndex > 0 ? fields.showDesIconColor?.fields.phrase?.value : fields.closeDesIconColor?.fields.phrase?.value}`"
                        v-if="fields.hasDes?.value && fields.title?.value"
                      />
                    </div>
                  </AnimatedContent>
                  <AnimatedContent type="bottom-fade-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)">
                    <JssRichText class="c-description-block__details-des-group-body" :field="fields.body" />
                  </AnimatedContent>
                </div>
                <div class="c-description-block__details-specifications-group">
                  <div class="c-description-block__details-des" :style="showDesStyle" v-if="!fields.hasDes?.value">
                    <JssRichText :field="fields.desWindow" />
                  </div>
                  <AnimatedContent
                    class="c-description-block__details-specifications-group-item"
                    :class="item.fields.layout?.fields.phrase?.value"
                    v-for="(item, index) in fields.specifications"
                    :key="item.id"
                    type="bottom-fade-in"
                    :delay="400 + 400 * index"
                    offset="100%"
                    @click="$deviceComputes.isMobileOrTablet.value && toggleDes(index)"
                    @mouseover="!$deviceComputes.isMobileOrTablet.value && toggleDes(index)"
                    @mouseout="!$deviceComputes.isMobileOrTablet.value && toggleDes(index)"
                  >
                    <div class="c-description-block__details-specifications-group-item-left" v-if="numbers?.length">
                      <div
                        class="c-description-block__details-specifications-group-item-number"
                        :class="`__c-${item.fields.numberColor?.fields.phrase?.value ?? 'black'}`"
                        :style="getNumberWidth(numbers[index])"
                      >
                        {{ numbers[index] }}
                      </div>
                      <div class="c-description-block__details-specifications-group-item-unit" v-if="item.fields.layout?.fields.phrase?.value === 'vertical'">
                        <JssRichText :field="item.fields.unit" />
                      </div>
                    </div>
                    <div class="c-description-block__details-specifications-group-item-right">
                      <div class="c-description-block__details-specifications-group-item-unit" v-if="item.fields.layout?.fields.phrase?.value === 'horizontal'">
                        <JssRichText :field="item.fields.unit" />
                      </div>
                      <div class="c-description-block__details-specifications-group-item-des">
                        <JssRichText :field="item.fields.des" />
                        <Icon
                          :svg="fields.desIcon.value.svgCode"
                          :class="`__c-${showDesIndex == index + 1 ? fields.showDesIconColor?.fields.phrase?.value : fields.closeDesIconColor?.fields.phrase?.value}`"
                          v-if="item.fields.hasDes?.value"
                        />
                      </div>
                    </div>
                  </AnimatedContent>
                </div>
              </div>
            </BackgroundImage>
            <div class="c-description-block__slogan-top c-description-block__slogan-top-in" ref="inSloganTopEl">
              <JssRichText :field="fields.topSloganIn" />
            </div>
            <div class="c-description-block__slogan-bottom c-description-block__slogan-bottom-in" ref="inSloganBottomEl">
              <JssRichText :field="fields.bottomSloganIn" />
            </div>
          </div>
          <div class="c-description-block__slogan-top c-description-block__slogan-top-out" ref="outSloganTopEl">
            <JssRichText :field="fields.topSloganOut" />
          </div>
          <div class="c-description-block__slogan-bottom c-description-block__slogan-bottom-out" ref="outSloganBottomEl">
            <JssRichText :field="fields.bottomSloganOut" />
          </div>
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { computed, inject, onMounted, onUnmounted, reactive, toRefs, watch } from 'vue';
import { appVisible, loadScrollMagic, getGlobalConfigs, getAdaptiveValueField, getAdaptiveImageField } from '@/utils/site-utils';
import { isMobile, largeThanTablet, largeThanTabletLandscape } from '@/utils/dom-utils';
import gsap from 'gsap';
import { useRoute } from 'vue-router';
export default {
  name: 'DescriptionBlock',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    const { deviceState } = inject('device-common');
    const route = useRoute();
    const [rtl] = getGlobalConfigs(props.page, 'rtl');
    if (!appVisible(route, props.fields)) return;
    let ScrollMagic, controller, scene1, tl1, scene2, tl2;
    const state = reactive({
      wrapperEl: null,
      desEl: null,
      videoEl: null,
      imageEl: null,
      detailsEl: null,
      inSloganTopEl: null,
      inSloganBottomEl: null,
      outSloganTopEl: null,
      outSloganBottomEl: null,
      showDesIndex: 0,
      showDesStyle: null,
      numbers: []
    });
    const computes = {
      image: computed(
        () =>
          getAdaptiveImageField(deviceState, props.fields.adaptiveMedia?.fields.desktopImage, props.fields.adaptiveMedia?.fields.mobileImage, props.fields.adaptiveMedia?.fields.tabletImage) ??
          props.fields?.backgroundImage
      ),
      video: computed(
        () =>
          getAdaptiveValueField(
            deviceState,
            props.fields.adaptiveMedia?.fields.desktopVideo,
            props.fields.adaptiveMedia?.fields.mobileVideo,
            props.fields.adaptiveMedia?.fields.tabletVideo,
            null,
            (x) => x?.value
          ) ?? props.fields?.video
      ),
      videoOptions: computed(() => methods.buildVideoOps(computes.video.value))
    };
    const methods = {
      buildVideoOps(video) {
        return {
          controls: false,
          autoplay: true,
          muted: true,
          loop: true,
          disableFullscreen: props.fields.videoDisablefullscreen?.value ?? false,
          disableUnmute: props.fields.videoDisableUnmute?.value ?? false,
          sources: [
            {
              type: 'video/mp4',
              src: video.value
            }
          ]
        };
      },
      countNumber: (i) => {
        let v = props.fields.specifications[i].fields.number?.value + '';
        let tmp = state.numbers[i].split('');
        for (let j = 0; j <= 100; j++) {
          setTimeout(() => {
            if (j < 100) {
              for (let k = 0; k < tmp.length; k++) {
                if (tmp[k] !== '.') {
                  tmp[k] = Math.floor(Math.random() * 10);
                }
              }
              state.numbers[i] = tmp.join('');
            } else {
              state.numbers[i] = v;
            }
          }, j * 10);
        }
      },
      onscroll: async () => {
        const offset = state.detailsEl.getBoundingClientRect();
        const top = isMobile() ? 40 : 60;
        if (offset.top > top) {
          document.removeEventListener('scroll', methods.onscroll, true);
          for (let i = 0; i < props.fields.specifications.length; i++) {
            setTimeout(() => {
              methods.countNumber(i);
            }, 400 * i);
          }
        }
      },
      toggleDes: (i) => {
        state.showDesIndex = state.showDesIndex ? 0 : i + 1;
      },
      scroll: () => {
        ScrollMagic = loadScrollMagic();
        controller = new ScrollMagic.Controller();
        const desOffset = state.desEl.getBoundingClientRect();
        const backgroundOffset = state.videoEl ? state.videoEl.getBoundingClientRect() : state.imageEl.getBoundingClientRect();
        const inSloganTopOffset = state.inSloganTopEl.getBoundingClientRect();
        const inSloganBottomOffset = state.inSloganBottomEl.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        tl1 = gsap.timeline();
        if (isMobile()) {
          if (rtl?.value) {
            tl1
              .to(state.desEl, { y: -desOffset.height }, 'first')
              .fromTo(state.inSloganTopEl, { right: -backgroundOffset.width / 10, y: windowHeight }, { right: -backgroundOffset.width / 10, y: -windowHeight * 2 }, 'first')
              .fromTo(state.inSloganBottomEl, { left: -backgroundOffset.width / 10, y: windowHeight + 400 }, { left: -backgroundOffset.width / 10, y: -windowHeight * 2 + 400 }, 'first')
              .fromTo(state.outSloganTopEl, { right: 0, y: windowHeight }, { right: 0, y: -windowHeight * 2 }, 'first')
              .fromTo(state.outSloganBottomEl, { left: 0, y: windowHeight + 400 }, { left: 0, y: -windowHeight * 2 + 400 }, 'first');
          } else {
            tl1
              .to(state.desEl, { y: -desOffset.height }, 'first')
              .fromTo(state.inSloganTopEl, { left: -backgroundOffset.width / 10, y: windowHeight }, { left: -backgroundOffset.width / 10, y: -windowHeight * 2 }, 'first')
              .fromTo(state.inSloganBottomEl, { right: -backgroundOffset.width / 10, y: windowHeight + 400 }, { right: -backgroundOffset.width / 10, y: -windowHeight * 2 + 400 }, 'first')
              .fromTo(state.outSloganTopEl, { left: 0, y: windowHeight }, { left: 0, y: -windowHeight * 2 }, 'first')
              .fromTo(state.outSloganBottomEl, { right: 0, y: windowHeight + 400 }, { right: 0, y: -windowHeight * 2 + 400 }, 'first');
          }
          scene1 = new ScrollMagic.Scene({
            triggerElement: state.wrapperEl,
            offset: desOffset.height / 2,
            triggerHook: 0,
            duration: windowHeight * 1.5
          })
            .setTween(tl1)
            .setPin(state.wrapperEl)
            .addTo(controller);
        } else {
          let offset = (backgroundOffset.height - desOffset.height) / 3;
          if (largeThanTablet()) {
            offset = (backgroundOffset.height - desOffset.height) / 2;
          }
          tl1
            .to(state.desEl, { y: -desOffset.height }, 'first')
            .fromTo(
              state.inSloganTopEl,
              { left: -backgroundOffset.width / (14 / 5) - 4, x: '35.7%', y: windowHeight * 1.74 + backgroundOffset.height * 0.36 + inSloganTopOffset.height / 4 },
              { left: -backgroundOffset.width / (14 / 5) - 4, x: '35.7%', y: -windowHeight * 2 * 1.74 + backgroundOffset.height * 0.36 + inSloganTopOffset.height / 4 },
              'first'
            )
            .fromTo(
              state.inSloganBottomEl,
              { right: -backgroundOffset.width / (14 / 5) - 4, x: '-35.7%', y: windowHeight * 1.73 + backgroundOffset.height * 0.36 + inSloganBottomOffset.height / 4 + 400 * 1.724 },
              { right: -backgroundOffset.width / (14 / 5) - 4, x: '-35.7%', y: -windowHeight * 2 * 1.73 + backgroundOffset.height * 0.36 + inSloganBottomOffset.height / 4 + 400 * 1.724 },
              'first'
            )
            .fromTo(state.outSloganTopEl, { left: 0, y: windowHeight }, { left: 0, y: -windowHeight * 2 }, 'first')
            .fromTo(state.outSloganBottomEl, { right: 0, y: windowHeight + 400 }, { right: 0, y: -windowHeight * 2 + 400 }, 'first');
          scene1 = new ScrollMagic.Scene({
            triggerElement: state.wrapperEl,
            offset: offset,
            triggerHook: 0,
            duration: windowHeight * 1.5
          })
            .setTween(tl1)
            .setPin(state.wrapperEl)
            .addTo(controller);
        }
        tl2 = gsap.timeline();
        if (isMobile()) {
          if (state.videoEl) {
            tl2
              .fromTo(state.videoEl, { width: '80%', height: '50vh', y: '0' }, { ease: 'power4.in', width: '100%', height: '100vh', y: -desOffset.height / 2 }, 'first')
              .to(state.detailsEl, { display: 'flex' }, 'second');
          } else {
            tl2
              .fromTo(state.imageEl, { width: '80%', height: '50vh', y: '0' }, { ease: 'power4.in', width: '100%', height: '100vh', y: -desOffset.height / 2 }, 'first')
              .to(state.detailsEl, { display: 'flex' }, 'second');
          }
          scene2 = new ScrollMagic.Scene({
            triggerElement: state.wrapperEl,
            offset: windowHeight * 1.5 + desOffset.height / 2,
            triggerHook: 0,
            duration: windowHeight * 0.5
          })
            .setTween(tl2)
            .setPin(state.wrapperEl)
            .addTo(controller);
        } else {
          let offset = (backgroundOffset.height - desOffset.height) / 3;
          if (largeThanTablet()) {
            offset = (backgroundOffset.height - desOffset.height) / 2;
          }
          if (state.videoEl) {
            tl2.fromTo(state.videoEl, { scale: 0.58 }, { ease: 'power4.in', scale: 1 }, 'first').to(state.detailsEl, { display: 'flex' }, 'second');
          } else {
            tl2.fromTo(state.imageEl, { scale: 0.58 }, { ease: 'power4.in', scale: 1 }, 'first').to(state.detailsEl, { display: 'flex' }, 'second');
          }
          scene2 = new ScrollMagic.Scene({
            triggerElement: state.wrapperEl,
            offset: windowHeight * 1.5 + offset,
            triggerHook: 0,
            duration: windowHeight * 0.5
          })
            .setTween(tl2)
            .setPin(state.wrapperEl)
            .addTo(controller);
        }
      },
      getNumberWidth: (n) => {
        if (n) {
          const point = n.indexOf('.') > 0;
          const length = point ? n.length - 1 : n.length;
          let width = largeThanTabletLandscape() ? (point ? length * 50 + 15 : length * 50) : length * 40 + 10;
          return {
            width: width + 'px'
          };
        }
      }
    };
    watch(
      () => state.showDesIndex,
      (n) => {
        if (n) {
          const l = props.fields.specifications.length;
          if (!isMobile()) {
            if (props.fields.hasDes?.value) {
              state.showDesStyle = {
                display: 'inline-block',
                top: '100px',
                left: 'unset',
                right: 0
              };
            } else {
              state.showDesStyle = {
                display: 'inline-block',
                left: `${(n - 1) * 280}px`,
                right: 'unset',
                width: '480px'
              };
              if (!props.fields.specifications[n - 1].fields.hasDes?.value) {
                state.showDesStyle.display = 'none';
              }
            }
          } else {
            if (props.fields.hasDes?.value) {
              state.showDesStyle = {
                display: 'inline-block',
                top: '80px',
                left: 0
              };
            } else {
              state.showDesStyle = {
                display: 'inline-block',
                bottom: `${(l - n + 1) * 90}px`
              };
              if (!props.fields.specifications[n - 1].fields.hasDes?.value) {
                state.showDesStyle.display = 'none';
              }
            }
          }
        } else {
          state.showDesStyle = null;
        }
      }
    );
    onMounted(() => {
      state.numbers = props.fields.specifications.map((s) => s.fields.number?.value + '');
      document.addEventListener('scroll', methods.onscroll, true);
      methods.scroll();
    });
    onUnmounted(() => {
      document.removeEventListener('scroll', methods.onscroll, true);
      scene1.destroy(true);
      scene2.destroy(true);
      controller.destroy(true);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
@import '../styles/rte/color.scss';
@import '../styles/rte/fontSize.scss';
.c-description-block {
  height: 316vh;
  overflow: hidden;
  &__content {
    @include grid-container;
    grid-template-rows: repeat(12, 1fr);
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  &__des {
    @include grid-block(7, 5, 1, 1);
    width: grid-width-m(5);
  }
  &__backgroud {
    height: 100vh;
    width: 100%;
    margin: 0 auto;
    &-group {
      @include grid-block(1, 12, 2, 11);
      position: relative;
      margin-top: $space-16;
    }
    z-index: 2;
    overflow: hidden;
    .e-background-image {
      height: 100%;
    }
  }
  &__details {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    display: none;
    margin-top: 20px;
    padding: $space-16 grid-width-m(1) 0 grid-width-m(1);
    z-index: 2;
    &-specifications-group {
      position: absolute;
      width: grid-width-m(10);
      bottom: 60px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      &-item {
        margin-left: 20px;
        padding-bottom: $space-24;
        &:last-child {
          padding-bottom: 0;
        }
        .e-animated-content__main {
          flex: 1;
          display: flex;
          align-items: flex-start;
        }
        &.vertical {
          .e-animated-content__main {
            flex-direction: column;
          }
        }
        &.horizontal {
          .e-animated-content__main {
            flex-direction: row;
            align-items: flex-end;
          }
        }
        &-number {
          font-size: 64px;
          line-height: 1;
        }
        &-unit {
          margin-bottom: $space-8;
        }
        &-left {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          margin-right: $space-4;
          justify-content: flex-start;
        }
        &-right {
          display: flex;
          flex-direction: column;
          width: auto;
          margin-bottom: $space-8;
        }
        &-des {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          font-size: 14px;
          .e-icon {
            margin-top: 2px;
            margin-left: $space-4;
          }
        }
      }
    }
    &-des-group {
      position: relative;
      display: flex;
      flex-direction: column;
      &-title {
        margin-left: $space-20;
        margin-top: 8px;
        display: flex;
        align-items: flex-end;
        .e-icon {
          margin-bottom: $space-4;
        }
      }
      &-body {
        margin-top: $space-20;
        margin-left: $space-20;
      }
    }
    &-des {
      display: none;
      width: 100%;
      height: fit-content;
      min-height: 380px;
      max-height: 520px;
      overflow: auto;
      position: absolute;
      bottom: $space-100;
      right: 0;
      margin: 0 auto;
      z-index: 3;
      background-color: $white;
      padding: $space-40 grid-width-m(1) $space-40 grid-width-m(1);
      font-size: 14px;
      line-height: 20px;
      .e-background-image {
        height: 100%;
      }
    }
  }
  &__slogan {
    &-top {
      position: absolute;
      z-index: -1;
      letter-spacing: -5px;
      &-in {
        z-index: 1;
      }
      &-out {
        width: 90%;
      }
    }
    &-bottom {
      position: absolute;
      z-index: -1;
      letter-spacing: -5px;
      &-in {
        z-index: 1;
      }
    }
  }

  @include tablet {
    height: 329vh;
    &__slogan {
      &-top-in {
        transform: scale(1.724);
      }
      &-top-out {
        width: auto;
      }
      &-bottom-in {
        transform: scale(1.724);
      }
      &-bottom-out {
        width: auto;
      }
    }
  }

  @include tablet-landscape {
    &__des {
      @include grid-block(17, 5, 1, 2);
      width: grid-width(5);
    }
    &__backgroud {
      margin: 0 auto;
      &-group {
        @include grid-block(1, 24, 2, 11);
        position: relative;
      }
    }
  }

  @include desktop {
    &__des {
      @include grid-block(17, 5, 1, 2);
      width: grid-width(5);
    }
    &__backgroud {
      margin: 0 auto;
      &-group {
        @include grid-block(1, 24, 2, 11);
        position: relative;
      }
    }
    &__details {
      @include grid-block(1, 24);
      height: 100vh;
      width: 100%;
      padding: $space-60 grid-width(1) $space-60 grid-width(1);
      &-specifications-group {
        flex-direction: row;
        justify-content: flex-start;
        flex: 3;
        &-item {
          padding-bottom: 0;
          width: auto;
          margin-right: $space-60;
          &:last-child {
            margin-right: 0;
          }
          &.vertical {
            &-left {
              width: 100%;
            }
          }
          &-number {
            font-size: 80px;
            line-height: 1;
          }
          &-des {
            .e-icon {
              margin-top: $space-4;
            }
          }
        }
      }
      &-des {
        width: grid-width(20);
        left: 0;
        padding: grid-width(1);
        &-group {
          width: grid-width(4);
          position: absolute;
          top: $space-30;
          right: grid-width(2.5);
        }
      }
    }
  }
}
</style>

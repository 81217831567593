<template>
  <div class="c-build-to-order-detail" ref="rootEl">
    <div class="c-build-to-order-detail__main">
      <jss-rich-text class="c-build-to-order-detail__primary-title" :field="fields.title" />
      <div class="c-build-to-order-detail__content">
        <jss-rich-text class="c-build-to-order-detail__secondary-title" :field="fields.detailTitle" />
        <div class="c-build-to-order-detail__details">
          <div class="c-build-to-order-detail__detail">
            <div class="c-build-to-order-detail__detail-title">{{ $t('Order number') }}</div>
            <div class="c-build-to-order-detail__detail-content">{{ orderDetail?.salesOrderInfo?.orderId }}</div>
          </div>
          <div class="c-build-to-order-detail__detail">
            <div class="c-build-to-order-detail__detail-title">{{ $t('Order date') }}</div>
            <div class="c-build-to-order-detail__detail-content">{{ $formatOrderDate(orderDetail?.salesOrderInfo?.createTime) }}</div>
          </div>
          <div class="c-build-to-order-detail__detail">
            <div class="c-build-to-order-detail__detail-title">{{ $t('Model detail') }}</div>
            <div class="c-build-to-order-detail__detail-content">{{ orderDetail?.vehicleInfo?.basicInfo?.model }}</div>
          </div>
          <div class="c-build-to-order-detail__detail">
            <div class="c-build-to-order-detail__detail-title">{{ $t('Name') }}</div>
            <div class="c-build-to-order-detail__detail-content">{{ orderDetail?.salesOrderInfo?.customerName }}</div>
          </div>
          <div class="c-build-to-order-detail__detail">
            <div class="c-build-to-order-detail__detail-title">{{ $t('Phone number') }}</div>
            <div class="c-build-to-order-detail__detail-content">+{{ orderDetail?.userInfo?.areaCode }}{{ orderDetail?.userInfo?.phoneNumber }}</div>
          </div>
          <div class="c-build-to-order-detail__detail">
            <div class="c-build-to-order-detail__detail-title">{{ $t('Email address') }}</div>
            <div class="c-build-to-order-detail__detail-content">{{ orderDetail?.salesOrderInfo?.email }}</div>
          </div>
          <div class="c-build-to-order-detail__detail">
            <div class="c-build-to-order-detail__detail-title">{{ $t('Invoice address') }}</div>
            <div class="c-build-to-order-detail__detail-content">
              {{
                $buildAddress(
                  orderDetail?.salesOrderInfo?.taxpayerStreet,
                  orderDetail?.salesOrderInfo?.taxpayerZipCode,
                  orderDetail?.salesOrderInfo?.taxpayerCity,
                  orderDetail?.salesOrderInfo?.countryName
                )
              }}
            </div>
          </div>
          <div class="c-build-to-order-detail__detail">
            <div class="c-build-to-order-detail__detail-title">{{ $t('Delivery address') }}</div>
            <div class="c-build-to-order-detail__detail-content">
              {{
                $buildAddress(
                  orderDetail?.vehicleInfo?.deliveryInfo?.street,
                  orderDetail?.vehicleInfo?.deliveryInfo?.zipCode,
                  orderDetail?.vehicleInfo?.deliveryInfo?.city,
                  orderDetail?.vehicleInfo?.deliveryInfo?.countryName
                )
              }}
            </div>
          </div>
          <div class="c-build-to-order-detail__detail">
            <div class="c-build-to-order-detail__detail-title">{{ $t('Lotus ID') }}</div>
            <div class="c-build-to-order-detail__detail-content">{{ orderDetail?.userInfo?.lotusId }}</div>
          </div>
          <div class="c-build-to-order-detail__detail">
            <div class="c-build-to-order-detail__detail-title">{{ $t('Order type') }}</div>
            <div class="c-build-to-order-detail__detail-content">{{ $t(orderDetail?.salesOrderInfo?.buyCarType === 100 ? 'Private' : 'Business') }}</div>
          </div>
        </div>
      </div>
      <div class="c-build-to-order-detail__buttons">
        <site-button v-bind="fields.button" />
      </div>
    </div>
    <transition name="bottom-slide-in">
      <div class="c-build-to-order-detail__side" v-if="!['mobile', 'tablet'].includes($deviceState.deviceType) || detailHasOpened">
        <img class="c-build-to-order-detail__model-image" :src="orderDetail?.vehicleInfo?.basicInfo?.image" :alt="orderDetail?.vehicleInfo?.basicInfo?.model" />
        <div class="c-build-to-order__delivery-date" v-html="$formatString(fields.expectedDeliveryDate?.value, { date: carEngine.expectedDeliveryDate })" v-if="carEngine" />
        <div class="c-build-to-order-detail__side-content">
          <div class="c-build-to-order-detail__model-title">{{ orderDetail?.vehicleInfo?.basicInfo?.model }}</div>
          <div class="c-build-to-order-detail__config-sections">
            <div class="c-build-to-order-detail__config-section">
              <div class="c-build-to-order-detail__config-section-title" @click="vehicleDetailHasOpened = !vehicleDetailHasOpened">
                <span>{{ $t('Vehicle details') }}</span>
                <accordion-toggle :active="vehicleDetailHasOpened" />
              </div>
              <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                <div class="c-build-to-order-detail__config-section-content" v-if="vehicleDetailHasOpened">
                  <div class="c-build-to-order__config-section-sub" v-for="(feature, index) in vehicleDetail" :key="index">
                    <!--                  <jss-image class="c-build-to-order__section-image" :media="fields.exteriorImage" />-->
                    <div class="c-build-to-order__config-section-subtitle">{{ feature.type }}</div>
                    <div class="c-build-to-order__vehicle-detail-content">
                      <template v-for="(info, j) in feature.infos" :key="j">
                        <label-value
                          class="c-build-to-order__vehicle-detail-item"
                          :label="info.value"
                          :value="info.price >= 0 ? $formatMoney(info.price, info.currency) : $t('Included').toLowerCase()"
                        />
                        <div class="c-build-to-order__vehicle-detail-value" v-html="info.label" />
                      </template>
                    </div>
                  </div>
                  <!--                  <div class="c-build-to-order-detail__config-section-sub">-->
                  <!--                    &lt;!&ndash;                  <jss-image class="c-build-to-order-detail__section-image" :media="fields.interiorImage" />&ndash;&gt;-->
                  <!--                    <label-value class="c-build-to-order-detail__config-section-subtitle" :label="$t('Interior')" value="€1,110" />-->
                  <!--                    <div class="c-build-to-order-detail__config-section-description">-->
                  <!--                      Fuji White, 21” Style 5112, Unpainted brake, calipers, Type Repair System, Body-coloured roof, Fixed panoramic roof, Nickel Atlas Exterior Pack, Pixel LED headlights with-->
                  <!--                      signature DRL-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  <div class="c-build-to-order-detail__config-section-sub">-->
                  <!--                    &lt;!&ndash;                  <jss-image class="c-build-to-order-detail__section-image" :media="fields.accessoriesImage" />&ndash;&gt;-->
                  <!--                    <label-value class="c-build-to-order-detail__config-section-subtitle" :label="$t('Accessories')" value="€1,110" />-->
                  <!--                    <div class="c-build-to-order-detail__config-section-description">-->
                  <!--                      Fuji White, 21” Style 5112, Unpainted brake, calipers, Type Repair System, Body-coloured roof, Fixed panoramic roof, Nickel Atlas Exterior Pack, Pixel LED headlights with-->
                  <!--                      signature DRL-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </transition>
            </div>
            <div class="c-build-to-order-detail__config-section">
              <div class="c-build-to-order-detail__config-section-title" @click="quotationHasOpened = !quotationHasOpened">
                <span>{{ $t('My quotation') }}</span>
                <accordion-toggle :active="quotationHasOpened" />
              </div>
              <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                <div class="c-build-to-order-detail__config-section-content" v-if="quotationHasOpened">
                  <label-value :label="$t('On the road price')" :value="$formatMoney(totalAmount, orderDetail?.currency)" />
                  <div class="c-build-to-order-detail__config-section-block">
                    <label-value :label="priceItem.label" :value="$formatMoney(priceItem.price, priceItem.currency)" v-for="priceItem in priceInfo" :key="priceItem.elementName" />
                    <div class="c-build-to-order-detail__config-section-description">
                      Incl. registration cost + delivery cost + recycling fee
                    </div>
                  </div>
                  <template v-if="benefitsList?.length > 0">
                    <label-value :label="`${$t('Offer')}/${$t('Service')}`" :value="$formatMoney(benefitsAmount, currency)" />
                    <div class="c-build-to-order__config-section-block">
                      <label-value
                        :label="benefits.name"
                        :value="Number(benefits.price) > 0 ? $formatMoney(benefits.price, currency) : $t('Included').toLowerCase()"
                        v-for="benefits in benefitsList"
                        :key="benefits.rightsId"
                      />
                    </div>
                  </template>
                  <label-value :label="$t('Total amount payable')" :value="$formatMoney(totalAmountPayable, orderDetail?.currency)" />
                  <div class="c-build-to-order-detail__config-section-block">
                    <label-value :label="$t('On the road price')" :value="$formatMoney(totalAmount, orderDetail?.currency)" />
                    <label-value :label="$t('Total deposit')" :value="`- ${$formatMoney(orderDetail?.salesOrderInfo?.totalAmountNumber, orderDetail?.currency)}`" />
                    <label-value :label="$t('Financed amount')" :value="Number(orderDetail?.financeAmount) > 0 ? `-${$formatMoney(orderDetail?.financeAmount, orderDetail?.currency)}` : '--'" />
                    <label-value theme="yellow" :label="$t('Customer balance')" :value="$formatMoney(orderDetail?.paymentInfo?.balancePaymentAmount, orderDetail?.currency)" v-if="totalAmount === 0" />
                  </div>
                  <label-value :label="$t('Deposit fee')" :value="$formatMoney(orderDetail?.salesOrderInfo?.totalAmountNumber, orderDetail?.currency)" />
                </div>
              </transition>
            </div>
            <div class="c-build-to-order-detail__config-section">
              <div class="c-build-to-order-detail__config-section-title" @click="personalDetailHasOpened = !personalDetailHasOpened">
                <span>{{ $t('Personal details') }}</span>
                <accordion-toggle :active="personalDetailHasOpened" />
              </div>
              <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                <div class="c-build-to-order-detail__config-section-content" v-if="personalDetailHasOpened">
                  <div class="c-build-to-order-detail__personal-info">
                    {{ $t('Name') }} <br />
                    {{ orderDetail?.userInfo?.fullName }}
                  </div>
                  <div class="c-build-to-order-detail__personal-info">
                    {{ $t('Phone number') }} <br />
                    +{{ orderDetail?.userInfo?.areaCode }}{{ orderDetail?.userInfo?.phoneNumber }}
                  </div>
                  <div class="c-build-to-order-detail__personal-info">
                    {{ $t('Email address') }} <br />
                    {{ orderDetail?.userInfo?.email }}
                  </div>
                  <div class="c-build-to-order-detail__personal-info">
                    {{ $t('Shipping address') }} <br />
                    {{
                      $buildAddress(
                        orderDetail?.vehicleInfo?.deliveryInfo?.street,
                        orderDetail?.vehicleInfo?.deliveryInfo?.zipCode,
                        orderDetail?.vehicleInfo?.deliveryInfo?.city,
                        orderDetail?.vehicleInfo?.deliveryInfo?.countryName
                      )
                    }}
                  </div>
                  <div class="c-build-to-order-detail__personal-info">
                    {{ $t('Billing address') }} <br />
                    {{
                      $buildAddress(
                        orderDetail?.salesOrderInfo?.taxpayerStreet,
                        orderDetail?.salesOrderInfo?.taxpayerZipCode,
                        orderDetail?.salesOrderInfo?.taxpayerCity,
                        orderDetail?.salesOrderInfo?.countryName
                      )
                    }}
                  </div>
                  <div class="c-build-to-order-detail__personal-info">
                    {{ $t('Preferred agent') }} <br />
                    {{ orderDetail?.userInfo?.store }}
                  </div>
                  <template v-if="orderDetail?.salesOrderInfo?.companyName">
                    <div class="c-build-to-order-detail__personal-info">
                      {{ $t('VAT number') }} <br />
                      {{ orderDetail?.salesOrderInfo?.vatNumber }}
                    </div>
                    <div class="c-build-to-order-detail__personal-info">
                      {{ $t('Company name') }} <br />
                      {{ orderDetail?.salesOrderInfo?.companyName }}
                    </div>
                    <div class="c-build-to-order-detail__personal-info">
                      {{ $t('Company registration number') }} <br />
                      {{ orderDetail?.salesOrderInfo?.companyRegistrationNumber }}
                    </div>
                  </template>
                </div>
              </transition>
              <div class="c-build-to-order-detail__footer">
                <label-value :label="$t('Total on road price')" :value="$formatMoney(totalAmount, orderDetail?.currency)" />
                <label-value class="c-build-to-order-detail__important-kv" :label="$t('Deposit paid')" :value="$formatMoney(orderDetail?.salesOrderInfo?.totalAmountNumber, orderDetail?.currency)" />
                <div class="c-build-to-order-detail__config-section-description">
                  *The deposit fee will be deducted from the total on-road price when full payment is settled.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="c-build-to-order__toolbar" ref="toolbarEl">
      <div class="c-build-to-order__detail-toggle">
        <span>{{ $t('Order details') }}</span>
        <accordion-toggle :active="detailHasOpened" @click="toggleDetailHasOpened" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @typedef BuildToOrderDetailFields
 * @property {SimpleField} title
 * @property {SimpleField} detailTitle
 * @property {ImageField} modelImage
 * */

import { onMounted, reactive, toRefs, inject } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import useDevice from '@/hooks/useDevice';
import { transitions } from '@/utils/transitions';
import { canUseDOM } from '@/utils/dom-utils';
import { analyzeFeatures } from '@/utils/mhp-utils';
import { getGlobalConfigs } from '@/utils/site-utils';
import { gtmPush } from '@/utils/gtm-utils';
import { updateDictionaryValue } from '@/services/dictService';
import { formatMoney, sum } from '@/utils/math-utils';
import { getCarModel } from '@/services/siteService';
import useAppStore from '@/store/appStore';

export default {
  name: 'BuildToOrderDetail',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    const route = useRoute();
    const { deviceState } = inject('device-common');
    /**@type AppStore*/
    const appStore = useAppStore();
    const toast = inject('toast');
    const loading = inject('loading');
    const { orderId } = route?.query || {};
    const state = reactive({
      orderId,
      vehicleDetailHasOpened: false,
      quotationHasOpened: false,
      personalDetailHasOpened: false,
      detailHasOpened: false,
      orderDetail: null,
      carEngine: null,
      priceInfo: [],
      vehicleDetail: [],
      invoiceInfo: null,
      totalAmount: null,
      totalAmountPayable: null,
      currency: null,
      features: [],
      benefitsList: [],
      benefitsAmount: 0
    });
    const methods = {
      // async loadConfiguration() {
      //   const [formulaNo] = getGlobalConfigs(props.page, 'formulaNo');
      //   const { filterId, transferCode } = state.orderDetail?.vehicleInfo?.basicInfo ?? {};
      //   const [res, ex] = await api.configuration.get(null, {
      //     filterId,
      //     transferCode,
      //     engineeringCode: 1,
      //     showPrice: 1
      //   });
      //   if (ex) {
      //     await toast.showEx(ex);
      //     return;
      //   }
      //   state.configuration = res;
      //   const [, features, priceIds, featureCodes, packageList, buttonFeatureTypes] = analyzeFeatures(res);
      //   state.features = features;
      // },
      toggleDetailHasOpened() {
        state.detailHasOpened = !state.detailHasOpened;
      },
      gtmCheckout(step, event) {
        const { filterId, transferCode } = state.orderDetail?.vehicleInfo?.basicInfo ?? {};
        let region = 'GB',
          language = 'en';
        if (props.page.itemLanguage !== 'en') {
          [language, region] = props.page.itemLanguage.split('-');
        }
        const options = [];
        for (let feature of state.vehicleDetail) {
          for (let info of feature.infos) {
            options.push({
              option_id: info.code,
              option_name: info.label,
              option_category: feature.type,
              option_price: info.price >= 0 ? formatMoney(info.price, info.currency) : 'included'
            });
          }
        }
        gtmPush({
          event,
          checkout_step: step,
          user_id: appStore.loginInfo?.lotusId,
          filter_id: filterId,
          transfer_code: transferCode,
          transaction_id: state.orderDetail?.salesOrderInfo?.orderId,
          options,
          region,
          language,
          currency: state.currency
        });
      },
      getAmount(name) {
        return state.orderDetail?.additionalTaxInfo?.find((x) => x.elementName === name)?.price ?? 0;
      }
    };
    if (canUseDOM()) {
      loading.show();
    }
    onMounted(async () => {
      const [res, ex] = await api.order.intactDetail(null, {
        id: orderId
      });
      console.log('res', res, ex);
      if (ex) {
        await toast.showEx(ex);
        return;
      }
      state.orderDetail = res;
      state.currency = res?.currency;
      const priceInfo = res?.additionalTaxInfo;
      state.carEngine = await getCarModel(res.vehicleInfo?.basicInfo?.series, res.vehicleInfo?.basicInfo?.filter);
      state.priceInfo = await updateDictionaryValue(priceInfo, 'elementName', 'label', (x) => x.visibility);
      state.vehicleDetail = JSON.parse(res?.vehicleInfo?.basicInfo?.infoJson ?? '[]');
      state.invoiceInfo = res?.invoiceInfoList?.length > 0 ? res?.invoiceInfoList[0] : null;
      state.totalAmount =
        state.priceInfo.find((x) => x.elementName === 'Total Price')?.price ??
        Math.max.apply(
          null,
          state.priceInfo.filter((x) => !isNaN(x.price)).map((x) => Number(x.price))
        ) ??
        null;
      state.totalAmountPayable = state.totalAmount - (res?.salesOrderInfo?.totalAmountNumber ?? 0) - (res?.financeAmount ?? 0);
      state.benefitsList = res?.rightsRespVOS?.sort((a, b) => b.price - a.price) ?? [];
      state.benefitsAmount = sum(res?.rightsRespVOS ?? [], 'price');
      methods.gtmCheckout('4', 'generate_lead');
      loading.hide();
    });
    return {
      ...toRefs(state),
      ...methods,
      deviceState,
      transitions
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-build-to-order-detail {
  $c: &;
  background: $black;
  &__main {
    padding: $space-40 grid-width-m(1);
    background: rgba(79, 79, 79, 0.3);
    color: $white;
  }
  &__content {
    padding: 24px 16px;
    background: rgba(79, 79, 79, 0.3);
    #{$c}__secondary-title {
      border-bottom: 1px solid $grey-light;
      padding-bottom: 12px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
  &__side {
    position: fixed;
    top: nth($header-height, 1);
    z-index: 2;
    width: 100vw;
    @include height-except-header;
    overflow-y: auto;
    background: $black;
    color: $white;
    @include h10;
    &-content {
      padding: 0 grid-width-m(1) 40px grid-width-m(1);
    }
  }
  &__primary-title {
    padding-bottom: $space-24;
  }
  &__important-kv {
    @include h8;
    color: $yellow;
  }
  &__details {
    margin-top: 40px;
  }
  &__detail {
    @include h10;
    + #{$c}__detail {
      margin-top: 24px;
    }
  }
  &__detail-title {
    font-weight: bold;
  }
  &__detail-content {
    font-family: lotusFontFamily('Overpass Light'), sans-serif;
    text-align: justify;
  }
  &__detail-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $grey-neutral;
    color: $yellow;
    padding: $space-16;
    .e-accordion-toggle {
      background: $yellow;
      color: $black;
      width: 18px;
      height: 18px;
      &__content {
        width: 18px;
        height: 18px;
        padding: 4px;
        &:before {
          width: calc(100% - 8px);
        }
        &:after {
          height: calc(100% - 8px);
        }
      }
    }
  }
  &__deposit-bar {
    display: none;
  }
  &__model-image {
    display: block;
    width: 100%;
    height: auto;
  }
  &__delivery-date {
    background: $yellow;
    text-align: center;
    padding: 8px;
    color: $black;
  }
  &__model-title {
    padding: 24px 0 32px 0;
    font-size: 16px;
  }
  &__section-image {
    width: 120px;
    height: auto;
    display: block;
    margin-bottom: 8px;
  }
  &__config-section {
    &-title {
      display: flex;
      justify-content: space-between;
      @include h9;
      padding: 16px 0;
    }
    &-subtitle {
      margin-bottom: 12px;
    }
    &-description {
      font-family: lotusFontFamily('Overpass Thin'), sans-serif;
      text-align: justify;
      margin-right: 8px;
    }
    &-content {
      > .e-label-value {
        margin: 10px 8px 10px 0;
      }
    }
    &-sub {
      & + & {
        margin-top: 16px;
      }
      .e-label-value,
      #{$c}__config-section-description {
        + .e-label-value,
        + #{$c}__config-section-description {
          margin-top: 8px;
        }
      }
    }
    &-block {
      background: rgba($grey-neutral, 0.5);
      padding: 16px 8px;
      .e-label-value,
      #{$c}__config-section-description {
        + .e-label-value,
        + #{$c}__config-section-description {
          margin-top: 16px;
        }
      }
      > .e-label-value {
        margin-right: 60px;
      }
    }
    + #{$c}__config-section {
      border-top: 1px solid rgba($white, 0.5);
      margin-top: 24px;
    }
  }
  &__footer {
    margin-top: 40px;
  }
  &__vehicle-detail-content {
    //background: rgba($grey-neutral, 0.5);
    //padding: 12px 16px;
    margin-top: 8px;
  }
  &__vehicle-detail-item {
    .e-label-value {
      &__label {
        font-size: 13px;
        opacity: 0.9;
      }
    }
  }
  &__vehicle-detail-value {
    @include h11;
    opacity: 0.6;
    + #{$c}__vehicle-detail-item {
      margin-top: 8px;
    }
  }
  &__personal-info {
    margin-bottom: 16px;
  }
  &__toolbar {
    position: fixed;
    top: 100vh;
    transform: translateY(-100%);
    z-index: 2;
    width: 100%;
    background: $white;
  }
  &__detail-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $grey-neutral;
    color: $yellow;
    padding: $space-16;
    .e-accordion-toggle {
      background: $yellow;
      color: $black;
      width: 18px;
      height: 18px;
      &__content {
        width: 18px;
        height: 18px;
        padding: 4px;
        &:before {
          width: calc(100% - 8px);
        }
        &:after {
          height: calc(100% - 8px);
        }
      }
    }
  }
  @include tablet-landscape {
    @include grid-container;
    &__main {
      @include grid-block(1, 12);
      padding: $space-40 grid-width(2);
    }
    &__content {
      padding: 40px 20px;
      #{$c}__secondary-title {
        padding-bottom: 24px;
      }
    }
    &__primary-title {
      padding-bottom: 40px;
    }
    &__side {
      position: static;
      height: auto;
      width: auto;
      @include grid-block(13, 12);
      &-content {
        padding: 0 grid-width(1, false) 40px grid-width(1, false);
      }
    }
    &__sections {
      padding: 0 grid-width(2);
    }
    &__detail {
      display: flex;
      justify-content: space-between;
      &-title {
        width: 140px;
        flex-shrink: 0;
      }
      &-content {
        margin-left: 24px;
        text-align: end;
      }
    }
    &__detail-toggle {
      display: none;
    }
    &__deposit-bar {
      display: block;
      @include grid-block(13, 12);
      padding: $space-20 grid-width(1, false);
      background: $grey-neutral;
      color: $white;
      &-kv {
        @include h7;
        color: $yellow;
        margin-top: 2px !important;
      }
      .e-label-value {
        + .e-label-value,
        #{$c}__deposit-bar-description {
          margin-top: 8px;
        }
      }
    }
  }
}
html[lang='ja-JP'] {
  .c-build-to-order-detail {
    $c: '.c-build-to-order-detail';
    &__detail-content {
      font-family: lotusFontFamily('Noto Sans JP Light'), sans-serif;
    }
    &__config-section {
      &-description {
        font-family: lotusFontFamily('Noto Sans JP Thin'), sans-serif;
      }
    }
  }
}
html[lang='ko-KR'] {
  .c-build-to-order-detail {
    $c: '.c-build-to-order-detail';
    &__detail-content {
      font-family: lotusFontFamily('Noto Sans KR Light'), sans-serif;
    }
    &__config-section {
      &-description {
        font-family: lotusFontFamily('Noto Sans KR Thin'), sans-serif;
      }
    }
  }
}
</style>

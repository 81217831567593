<template>
  <overall-settings v-bind="$props">
    <div class="c-brand-video" :class="rootClasses">
      <div class="c-brand-video__main" v-grid-layout="fields.gridLayout">
        <adaptive-media class="c-brand-video__media" :field="fields.media" show-play-button />
      </div>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef BrandVideoFields
 * @property {GlobalSettingEntry} gridLayout
 * @property {AdaptiveMedia} media
 * @property {GlobalSettingEntry} playButtonColor
 * */
import { computed } from 'vue';
import { settingValue } from '@/utils/site-utils';

export default {
  name: 'BrandVideo',
  props: {
    /**@type BrandVideoFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const computes = {
      rootClasses: computed(() => [`play-btn-${settingValue(props.fields.playButtonColor, 'white')}`])
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-brand-video {
  $c: &;
  @include grid-container;
  &__main {
    @include grid-block(1, 24, 1);
    height: 100vh;
  }
  &.play-btn- {
    &white {
      color: $white;
    }
    &grey {
      color: $grey;
    }
    &grey-light {
      color: $grey-light;
    }
    &grey-neutral {
      color: $grey-neutral;
    }
    &black {
      color: $black;
    }
  }
}
</style>

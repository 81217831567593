<template>
  <OverallSettings v-bind="$props">
    <div class="c-consent-detail" v-if="fields">
      <Heading class="c-consent-detail__title" :field="fields.title" :type="fields.titleType" rich />
      <div class="c-consent-detail__side" v-if="options && options.length">
        <Dropdown :options="options" :selectedOption="selectedOption" @change="onChange" />
      </div>
      <div class="c-consent-detail__main">
        <JssRichText :field="selectedOption?.data?.agreementContext" />
      </div>
    </div>
  </OverallSettings>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';

export default {
  name: 'ConsentDetailUSTemp',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      options: [],
      selectedOption: {}
    });
    const methods = {
      onChange(e, option) {
        state.selectedOption = option;
      }
    };
    onMounted(() => {
      state.options = props.fields.data.map((d) => ({
        data: d.fields,
        text: d.fields.version.value,
        value: d.id,
        code: d.fields.version.value
      }));
      state.selectedOption = state.options[0];
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-consent-detail {
  @include grid-container;
  &__title {
    @include grid-block(2, 10, 1);
    margin-bottom: 40px;
  }
  &__side {
    @include grid-block(2, 10, 2);
  }
  &__main {
    @include grid-block(2, 10, 3);
    &-title {
      margin-bottom: 10px;
    }
  }
  &__title {
    margin-bottom: 20px;
  }
  &__desc {
    margin-bottom: 10px;
  }
  @include tablet-landscape {
    &__title {
      @include grid-block(2, 20, 1);
      margin-bottom: 40px;
    }
    &__side {
      @include grid-block(2, 6, 2);
    }
    &__main {
      @include grid-block(10, 12, 2);
      &-title {
        margin-bottom: 20px;
      }
    }
    &__desc {
      margin-bottom: 20px;
    }
  }
}
</style>

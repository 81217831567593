<template>
  <overall-settings v-bind="$props">
    <div class="c-cookie-policy">
      <heading class="c-cookie-policy__title" :field="fields.title" :type="fields.titleType" rich />
      <div class="c-cookie-policy__side">
        <jss-rich-text class="c-cookie-policy__desc" :field="fields.description" />
        <dropdown :label="fields.dropdown.fields.label.value" :placeholder="fields.dropdown.fields.placeholder.value" :options="options" :selectedOption="selectedOption" @change="onChange" />
      </div>
      <div class="c-cookie-policy__main" v-if="selectedOption">
        <jss-rich-text class="c-cookie-policy__main-title" :field="selectedOption.data.fields.title" />
        <jss-rich-text class="c-cookie-policy__main-body" :field="selectedOption.data.fields.body" />
      </div>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef CookiePolicyFields
 * @property {CookiePolicyItem} data
 * */
import { reactive, toRefs } from 'vue';

export default {
  name: 'CookiePolicy',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      options: [],
      selectedOption: null
    });
    const methods = {
      onChange(e, option) {
        state.selectedOption = option;
      }
    };
    const options = props.fields.data.fields.versions.map((x) => ({
      data: x,
      text: x.fields.version.value,
      code: x.fields.version.value
    }));
    state.options = options;
    if (props.fields.data.fields.activeVersion) {
      state.selectedOption = options.find((x) => x.value === props.fields.data.fields.activeVersion?.fields.version.value) ?? options[0];
    } else {
      state.selectedOption = options[0];
    }
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-cookie-policy {
  @include grid-container;
  &__title {
    @include grid-block(2, 10, 1);
    margin-bottom: 40px;
  }
  &__side {
    @include grid-block(2, 10, 2);
  }
  &__main {
    @include grid-block(2, 10, 3);
    &-title {
      margin-bottom: 10px;
    }
  }
  &__title {
    margin-bottom: 20px;
  }
  &__desc {
    margin-bottom: 10px;
  }
  @include tablet-landscape {
    &__title {
      @include grid-block(2, 20, 1);
      margin-bottom: 40px;
    }
    &__side {
      @include grid-block(2, 6, 2);
    }
    &__main {
      @include grid-block(10, 12, 2);
      &-title {
        margin-bottom: 20px;
      }
    }
    &__desc {
      margin-bottom: 20px;
    }
  }
}
</style>

<template>
  <overall-settings v-bind="$props">
    <div class="c-description-block-new">
      <section class="c-description-block-new__grid--image">
        <adaptive-media class="c-description-block-new__media" :field="fields.media" />
      </section>

      <div class="c-description-block-new__grid">
        <section class="c-description-block-new__content">
          <section class="c-description-block-new__content__main">
            <div
              class="c-description-block-new__spTitle__content"
              :class="{
                'c-contentBlockImage--left': fields.remarksWordPostion?.fields.phrase?.value === 'left',
                'c-contentBlockImage--right': fields.remarksWordPostion?.fields.phrase?.value === 'right',
                'c-contentBlockImage--center': fields.remarksWordPostion?.fields.phrase?.value === 'center'
              }"
            >
              <animated-content type="fade-in" :hide-content="false">
                <Disclaimer class="c-description-block-new__spTitle" :field="fields.remarksTitle" :disclaimer="fields.titleDisclaimer" :showHover="true" :positionBottom="true" />
              </animated-content>
              <animated-content type="fade-in" :hide-content="false">
                <Disclaimer class="c-description-block-new__spTitle" :field="fields.remarksWord" :disclaimer="fields.wordDisclaimer" :showHover="true" :positionBottom="true" />
              </animated-content>
            </div>

            <div class="c-description-block-new__num">
              <div class="c-description-block-new__num-items" :class="[`num-align-${$settingValue(fields.numPosition, 'left')}`, page_list.length > 2 ? 'active' : '']">
                <AnimatedContent v-for="(item, index) in page_list" :key="item.id" type="bottom-fade-in" :delay="400 + 400 * index" offset="100%">
                  <div
                    :class="item.fields?.showColumn?.value ? `c-description-block-new__num__itemColumn` : `c-description-block-new__num__item`"
                    @mouseenter="iconRemarksHover($event, index)"
                    @mouseleave="iconRemarksOut($event, index)"
                    @click="iconRemarksClick($event, index)"
                  >
                    <div class="c-description-block-new__flip-no">
                      <flipped-number :style="`color:` + item.fields?.titleColor?.fields?.text?.value" :delay="(index + 1) * 300" :speed="2000" :number="item.fields.title.value">
                        <jss-rich-text :field="item.fields.title" />
                      </flipped-number>
                    </div>

                    <div class="remarks-and-icon">
                      <section class="num-item--remarks __ft-ps">
                        <jss-rich-text :field="item.fields.remarks" />
                      </section>

                      <template v-if="item.fields.disclaimer?.fields?.icon?.value">
                        <section class="num-item--icon">
                          <Icon :field="item.fields.disclaimer?.fields?.icon" />
                        </section>

                        <section class="num-item--dialog" :class="'num-item--dialog-' + index" v-show="item.iconRemarksShow">
                          <jss-rich-text :field="item.fields.disclaimer?.fields?.text" />
                        </section>
                      </template>
                      <template v-else-if="item.fields.iconRemarks && item.fields.icon.value">
                        <section class="num-item--icon">
                          <Icon :field="item.fields.icon" />
                        </section>

                        <section class="num-item--dialog" :class="'num-item--dialog-' + index" v-show="item.iconRemarksShow" v-if="!$isNullOrEmpty(item.fields.iconRemarks.value)">
                          <jss-rich-text :field="item.fields.iconRemarks" tag="div" />
                        </section>
                      </template>
                    </div>
                  </div>
                </AnimatedContent>
              </div>
              <div class="c-description-block-new__description">
                <JssRichText :field="fields.description" />
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, onMounted, onBeforeUnmount } from 'vue';
import { largeThanTablet, canUseDOM } from '@/utils/dom-utils';
import { isNullOrEmpty } from '../utils/obj-utils';

/**
 * @typedef DescriptionBlockNewFields
 * @property {GlobalSettingEntry} theme
 * @property {CheckField} turnOffParallax
 * @property {CheckField} turnOffImageAnimation
 * */

export default {
  name: 'DescriptionBlockNew',
  methods: { isNullOrEmpty },
  props: {
    /**@type DescriptionBlockNewFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      page_list: []
    });

    const methods = {
      largeThanTablet,
      iconRemarksHover(event, index) {
        if (largeThanTablet()) {
          event.stopPropagation();
          state.page_list.forEach((val, e) => {
            val.iconRemarksShow = e === index;
          });
        }
      },
      iconRemarksOut(event, index) {
        if (largeThanTablet()) {
          event.stopPropagation();
          state.page_list.forEach((val, e) => {
            if (e === index) {
              val.iconRemarksShow = false;
            }
          });
        }
      },
      iconRemarksClick(event, index) {
        if (!largeThanTablet()) {
          event.stopPropagation();
          state.page_list.forEach((val, e) => {
            val.iconRemarksShow = e === index;
          });
        }
      },
      iconRemarksHide() {
        state.page_list.forEach((val) => {
          val.iconRemarksShow = false;
        });
      }
    };

    onMounted(() => {
      state.page_list = props.fields?.list || [];
      if (canUseDOM()) {
        document.addEventListener('click', methods.iconRemarksHide, true);
      }
    });
    onBeforeUnmount(() => {
      document.removeEventListener('click', methods.iconRemarksHide, true);
    });

    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-description-block-new {
  position: relative;
  max-width: 100%;
  background-size: cover;
  height: 100vh;
  &__description {
    text-align: center;
  }
  &__grid--image {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  &__media {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  &__grid {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__imageOnly {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    @include grid-container;
    &__main {
      position: relative;
      height: 100%;
      @include grid-block(2, 10);
    }
  }
  &__spTitle {
    position: relative;
    width: 100%;
    font-size: 24px;
    line-height: 1.1;
    color: #fff200;
    padding: 12px 0 0 0;
    &__content {
      position: absolute;
      clear: both;
      width: 100%;
      top: 70px;
    }
  }
  &__word--sp {
    clear: both;
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    padding: 20px 0 0 0;
    color: #fff;
  }
  &__num {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 24px;
    color: #fff;
    z-index: 3;
    &__item {
      position: relative;
      display: flex;
      width: 100%;
      align-items: flex-end;
      padding-bottom: 32px;
      z-index: 3;
      .remarks-and-icon {
        flex: 1.6;
        display: flex;
        align-items: flex-end;
      }
      .num-item--remarks {
        font-size: 12px;
        line-height: 20px;
        // margin-bottom: 5px;
        margin-right: 4px;
        color: $white;
        font-weight: 400;
      }
      .num-item--dialog {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 100px;
        background: #fff;
        color: #000;
        padding: 40px;
      }
      .num-item--icon {
        display: flex;
        align-items: flex-end;
        width: 12px;
        height: 12px;
        position: relative;
        top: -2px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    &__itemColumn {
      position: relative;
      display: flex;
      width: 100%;
      align-items: flex-start;
      padding-bottom: 16px;
      z-index: 3;
      flex-direction: column;

      .num-item--remarks {
        font-size: 14px;
        margin-bottom: 5px;
        margin-right: 6px;
      }
      .num-item--dialog {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 100px;
        background: #fff;
        color: #000;
        padding: 40px;
      }
    }
    &__item_content {
      .c-description-block-new__num__item {
        .num-item--num {
          letter-spacing: 4px;
        }
      }
    }
  }

  &__flip-no {
    min-width: grid-width-m(4);
    flex: 1;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    .e-flipped-number {
      font-family: lotusFontFamily('Overpass Thin'), sans-serif;
      font-size: 38px;
      margin-right: 8px;
      line-height: 32px;
      font-weight: 250;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
  .c-contentBlockImage--left {
    left: 0;
    top: 70px;
  }

  .c-contentBlockImage--right {
    right: 0;
    top: 70px;
  }

  .c-contentBlockImage--center {
    left: 0;
    right: 0;
    top: 70px;
    margin: 0 auto;
    text-align: center;
  }
  @include small-mobile {
    &__flip-no {
      .e-flipped-number {
        font-size: 36px;
        margin-right: 8px;
        &__card {
          max-height: 50px;
        }
      }
    }
    &__num__item {
      align-items: center;
      .num-item--remarks {
        font-size: 12px;
      }
    }
  }
  @include tablet-landscape {
    &__flip-no {
      min-width: unset;
      flex: unset;
      display: block;
      .e-flipped-number {
        font-size: 56px;
        line-height: 1;
        position: relative;
        top: 4px;
      }
    }
    &__num__item {
      align-items: flex-end;
      .remarks-and-icon {
        flex: unset;
      }
    }
    &__content__main {
      @include grid-block(3, 20);
    }

    &__spTitle__content {
      width: grid-width(8); //340px;
      position: absolute;
    }
    &__spTitle {
      font-size: 32px;
    }
    &__num {
      bottom: 64px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &__item {
        padding-bottom: 0;
        .num-item--dialog {
          width: grid-width(12);
        }
      }
      &-items {
        display: flex;
        flex-direction: row;
        gap: grid-width(2);

        &.active {
          justify-content: space-between !important;
          .num-item--dialog {
            &-0 {
              left: 0;
              right: unset;
              transform: none !important;
            }
            &-1 {
              transform: translateX(calc(((100vw - 23 * 8px) * -4 / 24 + -4 * 8px) * 1));
            }
            &-2 {
              right: 0;
              left: unset;
              transform: none !important;
            }
          }
        }

        &.num-align-left {
          justify-content: flex-start;
          .num-item--dialog {
            left: 0;
            right: unset;
          }
        }
        &.num-align-center {
          justify-content: center;
          .num-item--dialog {
            transform: translateX(grid-width(-4));
          }
        }
        &.num-align-right {
          justify-content: flex-end;
          .num-item--dialog {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }
}
html[lang='ja-JP'] {
  .c-description-block-new {
    &__flip-no {
      .e-flipped-number {
        font-family: lotusFontFamily('Noto Sans JP Thin'), sans-serif;
      }
    }
  }
}
html[lang='ko-KR'] {
  .c-description-block-new {
    &__flip-no {
      .e-flipped-number {
        font-family: lotusFontFamily('Noto Sans KR Thin'), sans-serif;
      }
    }
  }
}
</style>

<template>
  <content-loading class="jss-loading" :loading="visible" />
</template>

<script>
export default {
  data() {
    return {
      visible: false
    };
  },
  props: {
    delay: {
      type: Number,
      default: 300
    }
  },
  created() {
    // Wait {this.delay}ms before making the loading graphic visible.
    // If whatever other async operation completes within that timeframe,
    // then whichever parent component invoked the loading component will
    // presumably re-render without the loading component.
    // This helps prevent the loading graphic from too briefly "flashing" on the screen
    // causing an undesirable flicker for users.
    setTimeout(() => {
      this.visible = true;
    }, this.delay);
  }
};
</script>

<style scoped lang="scss">
@import 'styles/variable.scss';
.jss-loading {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $blue-night;
  text-align: center;
}
</style>

<template>
  <overall-settings v-bind="$props">
    <div class="c-accordion">
      <div class="c-accordion__content" :class="contentClasses" v-grid-layout="fields.gridLayout">
        <heading class="c-accordion__title" :field="fields.title" :type="fields.titleType" rich />
        <jss-rich-text class="c-accordion__body" :field="fields.body" tag="div" />
        <div class="c-accordion__items">
          <div class="c-accordion__item" v-for="item in fields.items" :key="item.id">
            <div class="c-accordion__item-header" @click="toggleItem(item)">
              <heading class="c-accordion__item-title" :field="item.fields.title" :type="item.fields.titleType" rich />
              <accordion-toggle :active="openedIds.includes(item.id)" />
            </div>
            <jss-rich-text class="c-accordion__item-body" :class="[{ opened: openedIds.includes(item.id) }]" :field="item.fields.body" tag="div" />
          </div>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef AccordionFields
 * @property {GlobalSettingEntry} theme
 * @property {GlobalSettingEntry} gridLayout
 * @property {SimpleField} title
 * @property {GlobalSettingEntry} titleType
 * @property {SimpleField} body
 * @property {GlobalSettingEntry} align
 * @property {Array<AccordionItem>} items
 */
/**
 * @typedef AccordionItem
 * @property {{
 *   title: SimpleField,
 *   titleType: GlobalSettingEntry,
 *   body: SimpleField,
 *   collapsed: CheckField
 * }} fields
 */
import { computed, onMounted, reactive, toRefs } from 'vue';
import { settingValue } from '@/utils/site-utils';

export default {
  name: 'Accordion',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      openedIds: []
    });
    const computes = {
      contentClasses: computed(() => [`align-${settingValue(props.fields.align, 'left')}`])
    };
    const methods = {
      toggleItem(item) {
        const index = state.openedIds.findIndex((x) => x === item.id);
        if (index >= 0) {
          state.openedIds.splice(index, 1);
        } else {
          state.openedIds.push(item.id);
        }
      }
    };
    onMounted(() => {
      const openedIds = [];
      for (let item of props.fields.items) {
        if (!item.fields.collapsed.value) {
          openedIds.push(item.id);
        }
        state.openedIds.push(...openedIds);
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-accordion {
  $c: &;
  @include grid-container;
  &__content {
    display: flex;
    flex-direction: column;
    &.align-center {
      align-items: center;
    }
    &.align-right {
      align-items: flex-end;
    }
  }
  &__title {
    @include h3;
  }
  &__items {
    align-self: stretch;
    margin-top: 100px;
  }
  &__item {
    margin-top: 20px;
  }
  &__item-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    .e-accordion-toggle {
      margin-top: 10px;
      margin-left: 10px;
    }
  }
  &__item-title {
    @include h4;
  }
  &__item-body {
    margin-top: 10px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s linear;
    &.opened {
      max-height: 200px;
    }
  }
}
</style>

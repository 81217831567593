<template>
  <div class="c-checkout" :class="[{ 'on-app': $isOnApp($route) }]" ref="rootEl">
    <div v-if="recommended">
      <div class="c-checkout__wrapper">
        <div class="c-checkout__main" v-show="loaded">
          <checkout-steps :steps="steps" v-model:current="step" :mhpUrl="mhpUrl" v-if="steps?.length" />
          <div class="c-checkout__content">
            <checkout-verify
              ref="verifyRef"
              v-bind="fields.verifySection"
              :blind-order="blindOrder"
              :bound="bound"
              :features="features"
              :currency="currency"
              :bind-required="bindRequired"
              :step="step"
              :stepCode="stepCode"
            />
            <checkout-extras
              ref="extrasRef"
              v-bind="fields.extrasSection"
              :blind-order="blindOrder"
              :currency="currency"
              :step="step"
              :stepCode="stepCode"
              :package-details="packageDetails"
              :car-model="carModel"
              v-if="packageDetails"
            />
            <checkout-finance
              ref="financeRef"
              v-bind="fields.financeSection"
              :payment-options="paymentOptions"
              :customer-types="customerTypes"
              v-model:payment-option="paymentOption"
              v-model:customer-type="customerType"
              :blind-order="blindOrder"
              :agent-options="agentOptions"
              :car-series="carSeries"
              :car-model="carModel"
              :finance-level2="!!carSeries?.financeLevel2"
              :simulation="simulation"
              :finance-quote="financeQuote"
              :total-amount="totalAmount"
              :currency="currency"
              :step="step"
              :stepCode="stepCode"
              @change="onFinanceChange"
            />
            <checkout-delivery
              ref="deliveryRef"
              v-bind="fields.deliverySection"
              :email="email"
              :configuration="configuration"
              :blind-order="blindOrder"
              :finance-level2="!!carSeries?.financeLevel2"
              :customer-type="customerType"
              :simulation="simulation"
              :currency="currency"
              :step="step"
              :stepCode="stepCode"
            />
            <checkout-payment ref="paymentRef" :order-info="orderInfo" :payment-res="paymentRes" :failed="paymentWasFailed" :step="step" :stepCode="stepCode" />
          </div>
          <div class="c-checkout__actions">
            <div>
              <site-button v-bind="fields.configButton" v-if="['extras', 'verify'].includes(stepCode)" />
              <site-button v-bind="fields.prevButton" @click="goPrev" v-else-if="stepCode === 'order-details'" />
              <site-button v-bind="fields.contactButton" v-if="paymentWasFailed" />
            </div>
            <div v-if="stepCode !== 'verify'">
              <site-button v-bind="fields.nextButton" @click="goNext" :disabled="nextDisabled" v-if="stepCode !== 'payment'" />
              <site-button v-bind="fields.payButton" @click="onPay" v-if="stepCode === 'payment' && !paymentWasFailed" />
              <site-button v-bind="fields.retryButton" @click="onRetry" v-if="paymentWasFailed" />
            </div>
          </div>
        </div>
        <transition name="bottom-slide-in">
          <div class="c-checkout__aside" v-if="!$deviceComputes.isMobileOrTablet.value || openPriceBreakdown">
            <div class="c-checkout__aside-header" v-if="$deviceComputes.isMobileOrTablet.value">
              <icon name="close" @click="onClosePriceBreakdown" />
            </div>
            <checkout-summary
              :page="page"
              :fields="fields"
              :vin="vin"
              :step-code="stepCode"
              :finance-level2="!!carSeries?.financeLevel2"
              :payment-option="paymentOption"
              :configuration="configuration"
              :features="features"
              :car-model="carModel"
              :price-info="priceInfo"
              :additional-costs="additionalCosts"
              :benefits-list="benefitsList"
              :car-images="carImages"
              :simulation="simulation"
              :finance-quote="financeQuote"
              :down-payment="downPayment"
              :total-amount="totalAmount"
              :currency="currency"
              v-if="loaded"
            />
          </div>
        </transition>
      </div>
      <checkout-price-nav
        :fields="fields"
        :step-code="stepCode"
        :finance-level2="!!carSeries?.financeLevel2"
        :payment-option="paymentOption"
        :price-info="priceInfo"
        :simulation="simulation"
        :finance-quote="financeQuote"
        :down-payment="downPayment"
        :currency="currency"
        :show-price="!$deviceComputes.isMobileOrTablet.value"
        class="c-checkout__price-bar"
        @onOpenPriceBreakdown="onOpenPriceBreakdown"
      />
    </div>
    <checkout-recommending
      v-bind="fields.checkoutRecommending"
      :configuration="configuration"
      :features="features"
      :feature-codes="featureCodes"
      :car-model="carModel"
      :car-images="carImages"
      :total-amount="totalAmount"
      :currency="currency"
      :mhp-url="mhpUrl"
      ref="checkoutRecommendingRef"
      @continue="onContinue"
      v-else
    />
    <finance-calculator ref="financeCalculatorRef" />
    <notice-modal v-bind="fields.localeRedirectModal" ref="orderLocaleRedirectModalRef" />
    <notice-modal v-bind="fields.accountModal" ref="accountModalRef" />
    <notice-modal v-bind="fields.forbiddenPlaceOrderModal" ref="forbiddenPlaceOrderModalRef" />
    <gee-captcha ref="geeCaptchaRef" />
  </div>
</template>
<script>
/**
 * @typedef CheckoutFields
 * @property {{fields: CheckoutVerifyFields}} verifySection
 * @property {{fields: Object}} financeSection
 * @property {{fields: Object}} deliverySection
 * @property {{fields: Object}} paymentSection
 * @property {{fields: Object}} checkoutRecommending
 * @property {SimpleField} disclaimer
 * @property {SiteButton} prevButton
 * @property {SiteButton} nextButton
 * @property {NoticeModal} localeRedirectModal
 * @property {NoticeModal} accountModal
 * @property {NoticeModal} forbiddenPlaceOrderModal
 * */
import { toRefs, reactive, computed, inject, onMounted, nextTick, provide, onBeforeUnmount, getCurrentInstance, onBeforeMount, watch } from 'vue';
import {
  buildAddress,
  decodeData,
  formatModel,
  getAdaptiveImageField,
  getGlobalCfgValues,
  getGlobalConfigs,
  getPageAlpha2Code,
  isAppBrowser,
  isOnApp,
  scrollToTop,
  settingValue
} from '@/utils/site-utils';
import { appendQuery, getBetterUrl, getQueryStrings, redirectToLang, redirectToLogin } from '@/utils/uri-utils';
import { analyzeConfiguration, analyzeFeatures, buildMhpUrl, buildModelImgUrl, buildModelImgUrlNoBackdrop } from '@/utils/mhp-utils';
import { isNullOrEmpty, deepCopy, ifNullOrEmpty } from '@/utils/obj-utils';
import { equalString, toFistLetterUpper } from '@/utils/string-utils';
import { loadStripe } from '@/utils/stripe-utils';
import useAppStore from '@/store/appStore';
import services from '@/services';
import api from '@/api';
import { merge, debounce } from 'lodash';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { qtUtils } from '@/utils/ali-tracker-utils';
import { webStorage } from '@/utils/web-storage';
import { S_ORDER_INFO } from '@/utils/web-storage-keys';
import { gtmFormStep, gtmFormSubmit, gtmPush } from '@/utils/gtm-utils';
import { getBenefits, getBenefitsFromPackage } from '@/utils/benefit-utils';
import { formatDate } from '@/utils/date-utils';
import { CUSTOMER_TYPES, ERROR_CODES } from '@/utils/constants';
import BUS_EVENTS from '@/utils/bus-events';
import { addModalClass, canUseDOM, refreshPage, removeModalClasses } from '@/utils/dom-utils';
import { getDownPayment } from '@/services/siteService';
import { updateDictionaryValue } from '@/services/dictService';
import { formatMoney } from '@/utils/math-utils';
import { unWrapSitecoreItem } from '@/utils/sitecore-utils';

export default {
  props: {
    page: {
      type: Object
    },
    /**@type CheckoutFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    let market, filterId, transferCode, stripeHelper, stripe, detailHref, confirmPayment, clientSecret, token, redirectStatus, blindOrderId, benefitSet, scfSimulationTimeout;
    let paymentOptionChanged = false,
      businessDataChanged = false;
    const { proxy } = getCurrentInstance();
    const { $nativeCommon } = proxy;
    const $bus = inject('$bus');
    const toast = inject('toast');
    const loading = inject('loading');
    const appMethods = inject('appMethods');
    const { deviceState, deviceComputes } = inject('device-common');
    const { formType, leadsName, newsletterName } = props.page.fields;
    const [checkoutDetailLink] = getGlobalConfigs(props.page, 'checkoutDetailLink');
    detailHref = checkoutDetailLink?.value?.href;
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const finance = isNullOrEmpty(route.query.finance_values) ? null : decodeData(route.query.finance_values);
    const recommended = !isNullOrEmpty(route.query.vin) || route.query.recommended === 1;
    /**@type AppStore*/
    const appStore = useAppStore();
    const [downPaymentField] = getGlobalConfigs(props.page, 'downPayment');
    const computes = {
      accountType: computed(() => CUSTOMER_TYPES[state.customerType?.code] ?? 1),
      downPayment: computed(() => (state.blindOrder || state.autoConvert ? 0 : state.payableDownPayment)),
      reservationDownPayment: computed(() => state.blindOrder?.paymentAmountNumber ?? 0),
      balancePayment: computed(() => computes.totalAmount.value - Math.max(computes.reservationDownPayment.value, state.payableDownPayment)),
      bindRequired: computed(() => appStore?.hasLoggedIn && !state.bound?.email),
      stepCode: computed(() => (state.step < state.steps.length ? state.steps[state.step]?.fields.code.value : '')),
      customerTypes: computed(() =>
        props.fields.financeSection?.fields.customerTypes.map((x) => ({
          code: x.fields.code.value,
          name: x.fields.name.value,
          icon: x.fields.icon
        }))
      ),
      totalPrice: computed(
        () =>
          checkoutMethods.getPrice('Total Price') ??
          Math.max.apply(
            null,
            state.priceInfo.filter((x) => !isNaN(x.price)).map((x) => Number(x.price))
          ) ??
          null
      ),
      totalAmount: computed(() => {
        let result = computes.totalPrice.value;
        if (isNullOrEmpty(result)) return null;
        if (state.packageDetails?.rightsRespList && !isNaN(state.benefitsAmount)) result += state.benefitsAmount;
        return result;
      })
    };
    const state = reactive({
      steps: [],
      rootEl: null,
      step: 0,
      recommended,
      alpha2Code: null,
      configuration: null,
      currency: null,
      features: [],
      infoJson: '',
      featureCodes: [],
      buttonFeatureTypes: [],
      priceInfo: [],
      additionalCosts: [],
      benefitsList: [],
      benefitPriceIds: [],
      benefitsAmount: 0,
      carImages: [],
      email: null,
      blindOrder: null,
      retailPrice: null,
      financeLevel2: false,
      /**@type CarSeries*/
      carSeries: null,
      /**@type CarModel*/
      carModel: null,
      cwConfig: null,
      paymentOptions: [],
      formulaNo: null,
      paymentFormulaNo: null,
      payableDownPayment: Number(downPaymentField?.value),
      bound: {
        email: false,
        mobile: false
      },
      agentOptions: [],
      /**@type NoticeModalRef*/
      orderLocaleRedirectModalRef: null,
      /**@type NoticeModalRef*/
      accountModalRef: null,
      /**@type NoticeModalRef*/
      forbiddenPlaceOrderModalRef: null,
      paymentWasFailed: false,
      /**@type StockVehicleExtrasRef*/
      extrasRef: null,
      /**@type CheckoutVerifyRef*/
      verifyRef: null,
      /**@type FinanceRef*/
      financeRef: null,
      /**@type CheckoutDeliveryRef*/
      deliveryRef: null,
      /**@type CheckoutPaymentRef*/
      paymentRef: null,
      financeCalculatorInitialized: false,
      /**@type FinanceCalculatorRef*/
      financeCalculatorRef: null,
      /**@type CheckoutRecommendingRef*/
      checkoutRecommendingRef: null,
      /**@type GeeCaptchaRef*/
      geeCaptchaRef: null,
      loaded: false,
      customerType: null,
      businessType: null,
      paymentOption: null,
      agent: null,
      businessData: null,
      tradeInData: null,
      autoConvert: false,
      openPriceBreakdown: false,
      personalData: null,
      companyData: null,
      alternateInfo: null,
      shippingData: null,
      billingData: null,
      diffAddr: false,
      saveAddr: false,
      consentData: null,
      newsletterConsents: [],
      bindConsents: [],
      orderRules: [],
      orderRule: null,
      orderInfo: null,
      paymentRes: null,
      /**@type SantanderSimulation*/
      simulation: null,
      assetInfo: null,
      financeQuote: null,
      quoteReference: 'test porposal id',
      mhpUrl: null,
      vin: null,
      vid: null,
      nextDisabled: false,
      packageDetails: null
    });
    const _qt = {
      trackStep1Clk(result) {
        qtUtils.trackBtnClickWithCode(props.page, props.fields.nextButton, 'car_order_step1_pg_clk', {
          is_success: result
        });
      },
      trackStep2Clk(result) {
        qtUtils.trackBtnClickWithCode(props.page, props.fields.nextButton, 'car_order_step2_pg_clk', {
          is_success: result,
          city: state.shippingData?.city,
          country: state.shippingData?.country.code,
          reservation_type: state.customerType
        });
      },
      trackPayClk(result) {
        qtUtils.trackBtnClickWithCode(props.page, props.fields.nextButton, 'car_order_step3_pg_clk', {
          is_success: result,
          city: state.shippingData?.city,
          country: state.shippingData?.country.code,
          reservation_type: state.shippingData?.addressType
        });
      }
    };
    const _methods = {
      async analyzeConfiguration(res, isStock = false) {
        state.configuration = res;
        const [currency, features, priceIds, accePriceIds, featureCodes, packageList, buttonFeatureTypes] = analyzeFeatures(res, isStock);
        state.filterInfo = {
          id: res.id,
          basicPrice: res.basicPrice,
          engineeringCode: res.engineeringCode,
          groupName: res.groupName,
          model: formatModel(res.model),
          pno12: res.pno12,
          series: res.series
        };
        state.features = analyzeConfiguration(res, !isStock);
        state.featureCodes = featureCodes;
        state.buttonFeatureTypes = buttonFeatureTypes;
        state.priceIds = priceIds;
        state.accePriceIds = accePriceIds;
        state.packageList = packageList;
        state.carSeries = await services.site.getCarSeriesByCode(res.seriesCode);
        state.carModel = await services.site.getCarModelByCode(res.seriesCode, res.modelCode);
        state.payableDownPayment = await getDownPayment(props.page, res.seriesCode);
        if (state.carSeries?.financeLevel2 || equalString(state.alpha2Code, 'GB')) {
          state.paymentOptions = unWrapSitecoreItem(state.carSeries?.level2PaymentOptions);
        } else {
          state.paymentOptions = unWrapSitecoreItem(props.fields?.financeSection?.fields.level1PaymentOptions);
        }
        [state.formulaNo, state.paymentFormulaNo] = await services.site.getFormulaNos(props.page, res.series);
        state.mhpUrl = buildMhpUrl(
          props.page,
          filterId,
          state.configuration.series,
          state.carModel?.mhpRoute ?? state.configuration.model,
          state.buttonFeatureTypes,
          !isNullOrEmpty(benefitSet) ? benefitSet.split(',') : []
        );
        if (['200', 200].includes(state.blindOrder?.orderStatus)) {
          state.downPayment = 0;
        }
      },
      async loadConfiguration() {
        const [res, ex] = await api.configuration.get(null, {
          filterId,
          transferCode,
          engineeringCode: 1,
          showPrice: 1
        });
        if (!res) {
          await toast.showEx(ex);
          return null;
        }
        state.configuration = res;
        await _methods.analyzeConfiguration(res);
      },
      async loadStockDetail() {
        console.log('loadStockDetail');
        const [res, ex] = await api.order.stockDetailByVin(null, {
          country: state.alpha2Code,
          vin: state.vin
        });
        if (!res?.configurationDetails || !res?.detailPrice) {
          toast.showEx(ex);
          return;
        }
        transferCode = res.transferCode;
        filterId = res.filterId;
        state.vid = res.vid;
        console.log('vid', res.vid);
        const configuration = {
          filterName: res.filterName,
          seriesCode: res.seriesCode,
          modelCode: res.modelCode,
          series: res.series,
          model: res.vehicleModel,
          nextLevels: JSON.parse(res.configurationDetails)
        };
        await _methods.analyzeConfiguration(configuration, true);
        const priceList = JSON.parse(res.detailPrice);
        const priceInfo = (priceList ?? []).filter((x) => !['PFU', 'IPT'].includes(x.elementName));
        state.priceInfo = await updateDictionaryValue(priceInfo, 'elementName', 'label', (x) => x.visibility);
        const [currencyField] = getGlobalConfigs(props.page, 'currency');
        state.currency = currencyField?.value;
        if (state.priceInfo?.length > 0) {
          if (!isNullOrEmpty(state.priceInfo[0].currency)) {
            state.currency = state.priceInfo[0].currency;
          }
          state.retailPrice = checkoutMethods.getPrice('Vehicle Total Price', 'Total Price');
        }
        state.carImages = res?.images?.productDisplay?.map((x) => x.externalUrl) ?? [];
      },
      async getOptionalPackages() {
        if (!isNullOrEmpty(state.vin) && !isNullOrEmpty(state.carSeries?.series)) {
          let temp = [];
          const [packageDetails, packageEx] = await api.benefits.list(null, {
            carSerials: state.carSeries.series,
            country: state.alpha2Code,
            language: props.page.itemLanguage,
            // filterId,
            market
          });
          if (packageEx) {
            await toast.showEx(packageEx);
            return;
          }
          packageDetails?.rightsRespList.forEach((r) => {
            const { priceList } = r;
            const priceDetails = priceList?.length > 0 ? priceList.find((p) => equalString(p.country, state.alpha2Code)) : null;
            r.price = priceDetails ? formatMoney(priceDetails.price, priceDetails.currency) : null;
            r.selected = false;
            r.image = getAdaptiveImageField(deviceState, r.icon, r.padIconUrl);
          });
          state.packageDetails = packageDetails;
        }
      },
      async loadBenefits() {
        if (!isNullOrEmpty(benefitSet)) {
          const benefitIds = benefitSet.split(',');
          [state.benefitsList, state.benefitPriceIds, state.benefitsAmount] = await getBenefits(props.page, benefitIds);
        }
      },
      async loadPrice() {
        const benefitPriceIds = [];
        for (let bl of state.benefitsList) {
          if (!isNullOrEmpty(bl?.priceId)) {
            benefitPriceIds.push(bl?.priceId);
          }
        }
        state.priceInfo = await services.price.getCarPrices(
          filterId,
          transferCode,
          state.formulaNo,
          state.alpha2Code,
          state.configuration?.modelCode,
          state.priceIds,
          benefitPriceIds,
          state.accePriceIds
        );
        const [currencyField] = getGlobalConfigs(props.page, 'currency');
        state.currency = currencyField?.value;
        if (state.priceInfo?.length > 0) {
          if (!isNullOrEmpty(state.priceInfo[0].currency)) {
            state.currency = state.priceInfo[0].currency;
          }
          state.retailPrice = checkoutMethods.getPrice('Vehicle Total Price', 'Total Price');
        }
        state.additionalCosts = state.priceInfo.filter(
          (x) =>
            !['Base Retail Price', 'Option Price', 'Total Price Excl. VAT', 'Incl. VAT', 'Vehicle Total Price', 'Total Price', 'Accessories & Service'].includes(x.elementName) &&
            !state.benefitsList.some((y) => equalString(y.name, x.elementName))
        );
      },
      async getOrderRules() {
        const [resRule] = await api.order.rule.query(null, {
          items: [
            {
              orderType: 200,
              carSeriesCode: state.configuration?.seriesCode,
              carModelCode: state.configuration?.modelCode ?? '',
              country: state.alpha2Code
            }
          ]
        });
        state.orderRules = resRule;
        if (resRule?.length) {
          state.orderRule = resRule[0];
          if (state.orderRule?.orderAmountNumber >= 0) {
            state.payableDownPayment = state.orderRule?.orderAmountNumber;
          }
        }
        return true;
      },
      async checkPayment() {
        loading.show();
        if (redirectStatus === 'succeeded') {
          await _methods.startListening();
        } else {
          state.paymentWasFailed = true;
          if (!isNullOrEmpty(state.vin)) {
            await _methods.loadStockDetail();
            await _methods.getOptionalPackages();
          } else {
            await _methods.loadConfiguration();
            await _methods.loadBenefits();
            await _methods.loadPrice();
          }
          state.loaded = true;
          await loading.hide();
          await nextTick();
          await state.paymentRef.initWidget();
        }
      },
      async accountSignOut() {
        await nextTick();
        const button = await state.accountModalRef.open();
        if (button?.buttonCode === 'sign-out') {
          await appMethods.justLogout();
          refreshPage();
        } else {
          await _methods.checkBind();
          if (state.bound.email) {
            state.step = 1;
            state.email = appStore.loginInfo?.email;
            methods.checkStep();
          }
        }
      },
      fillReservationToBto() {
        state.verifyRef?.fillReservation();
        state.financeRef?.fillReservation();
        state.deliveryRef?.fillReservation();
      },
      onScroll() {
        if (!deviceComputes.isMobileOrTablet.value) return;
        const $checkoutMain = state.rootEl.querySelector('.c-checkout__main');
        const $bar = state.rootEl.querySelector('.c-checkout__price-bar');
        const mainRect = $checkoutMain.getBoundingClientRect();
        if (mainRect.bottom <= window.innerHeight) {
          $bar.style.position = 'sticky';
        } else {
          $bar.style.position = 'fixed';
        }
      },
      async getCarImages() {
        state.carImages = await services.mhp.getImages(props.page, state.configuration.series, state.carModel, filterId, state.buttonFeatureTypes);
      },
      async submitCheckout() {
        const paymentType = state.paymentOption?.name;
        const simulationId = state.simulation?.selectedSimulation?.simulationChecksum?.value ?? state.simulation?.selectedSimulation?.simulationChecksum ?? '';
        if (paymentType === 'santanderLoan' && state.carSeries.financeLevel2 && isNullOrEmpty(simulationId)) {
          await toast.showEx({ code: ERROR_CODES.NoSimulationError });
          return false;
        }
        const financeFormData = await state.financeRef.validate();
        const formData = await state.deliveryRef.validate();
        if (!formData) {
          return false;
        }
        const { tradeInLeadTypes } = financeFormData;
        const { personalData, companyData, shippingData, billingData, diffAddr, saveAddr, consentData, newsletterConsents, bindConsents, deliveryLeadTypes } = formData;
        const leadTypes = [...tradeInLeadTypes, ...deliveryLeadTypes];
        const leadsDetails = {};
        state.personalData = personalData;
        state.companyData = companyData;
        state.shippingData = shippingData;
        state.billingData = billingData;
        state.diffAddr = diffAddr;
        state.saveAddr = saveAddr;
        state.consentData = consentData;
        state.newsletterConsents = newsletterConsents;
        state.bindConsents = bindConsents;
        loading.show();
        const birthday = formatDate(state.personalData.dateOfBirth);
        const title = companyData?.title?.code ?? personalData?.title?.code ?? companyData?.title ?? personalData?.title ?? '';
        const userBody = {
          email: state.email,
          firstName: personalData.firstName,
          lastName: personalData.lastName,
          mobileAreaCode: personalData.mobile?.area.code,
          phone: personalData.mobile?.number,
          terms: true,
          zipcode: shippingData.zipCode,
          source: isOnApp(route) ? 102 : 202,
          password: '',
          title,
          middleName: '',
          address: shippingData.address,
          address2: shippingData.address2,
          city: shippingData.city ?? '',
          country: shippingData.country.code,
          contactsAddress: buildAddress(shippingData.address, shippingData.address2, shippingData.city, shippingData.country.text),
          birthday,
          preferLanguage: props.page.itemLanguage,
          communicatePreference: 0,
          businessEmail: companyData?.email ?? state.email ?? '',
          companyName: companyData?.companyName ?? '',
          vatNumber: companyData?.vatNumber ?? state.alternateInfo?.vatNumber ?? '',
          companyRegistrationNumber: companyData?.companyRegistrationNumber ?? '',
          accountType: computes.accountType.value
        };
        if (!isNullOrEmpty(companyData?.firstName)) {
          merge(userBody, {
            companyRelationships: [
              {
                mobileAreaCode: companyData?.mobile?.area.code ?? '',
                phone: companyData?.mobile?.number ?? '',
                firstName: companyData?.firstName ?? '',
                lastName: companyData?.lastName ?? ''
              }
            ]
          });
        }
        if (!appStore?.hasLoggedIn) {
          const [loginRes, loginEx] = await api.cidp.bto.emailRegisterLogin(null, userBody);
          if (loginEx) {
            loading.hide();
            let opts = null;
            await toast.showEx(loginEx);
            if (loginEx.code === 68013002 || loginEx.code === 68010030) {
              state.step = 1;
              state.verifyRef.reset();
            }
            _qt.trackStep2Clk(false);
            return false;
          }
          appStore.updateLoginInfo(loginRes);
          await _methods.checkBind();
        } else {
          const updateBody = { ...userBody };
          if (!state.bound?.email) {
            merge(updateBody, {
              registerType: 1
            });
          }
          const [, updateEx] = await api.cidp.user.update(null, updateBody);
          if (updateEx) {
            await toast.showEx(updateEx);
            loading.hide();
            _qt.trackStep2Clk(false);
            return false;
          }
        }
        const vehicleInfo = {
          series: state.configuration?.series,
          model: state.configuration?.model,
          featureList: state.featureCodes.join(','),
          packageList: [...state.packageList],
          saleCode: '',
          filter: state.configuration.filterName.toUpperCase(),
          transferCode: transferCode,
          vid: state.vid ?? '',
          vin: state.vin ?? '',
          pno12: state.configuration?.pno12,
          // image: '40c465e4-64ae-443e-a01c-03a921ea1997', // state.modelImg,
          imageUrl: state.carImages.length > 0 ? state.carImages[0] : '',
          imageUrlNoBackdrop: buildModelImgUrlNoBackdrop(props.page, state.configuration?.series, state.carModel, state.buttonFeatureTypes),
          filterId: filterId,
          infoJson: state.infoJson
        };
        const paymentInfo = {
          currency: state.currency,
          paymentScheme: '3',
          lendingInstitutions: '',
          discount: '',
          totalAmount: computes.totalAmount.value,
          balancePaymentAmount: computes.balancePayment.value,
          loanMethod: '',
          paymentMethod: '1',
          loanTerm: '',
          totalLoans: '',
          downPaymentAmount: state.blindOrder?.paymentAmountNumber ?? state.payableDownPayment
        };
        let benefitsInfo = null;
        for (let bl of state.benefitsList) {
          if (!isNullOrEmpty(bl?.priceId)) {
            benefitsInfo = {
              priceId: bl?.priceId ?? '',
              rightsId: bl?.id ?? ''
            };
          }
        }
        const soRightsAppCreateCo = {
          ...benefitsInfo,
          priceIds: [...state.benefitPriceIds, ...state.accePriceIds]
        };
        const [res, ex] = await services.order.submitOrder({
          page: props.page,
          formulaNo: state.paymentFormulaNo,
          customerType: state.customerType,
          loginInfo: appStore?.loginInfo,
          email: state.email,
          filterId,
          priceIds: state.priceIds,
          personalData,
          companyData,
          shippingData,
          billingData,
          consents: bindConsents,
          vehicleInfo,
          paymentInfo,
          blindOrder: state.blindOrder,
          alternateInfo: state.alternateInfo,
          agent: state.agent,
          businessData: state.businessData,
          tradeInData: state.tradeInData ?? null,
          paymentOption: state.paymentOption,
          soRightsAppCreateCo,
          simulationId,
          orderRule: state.orderRule,
          orderSource: isOnApp(route) ? 102 : 202
        });
        if (ex) {
          loading.hide();
          await toast.showEx(ex);
          _qt.trackStep2Clk(false);
          return false;
        }
        const formDetails = {
          selected_model: state.configuration?.series ?? null,
          selected_location_name: null,
          selected_location_type: null,
          selected_location_date: null,
          selected_location_time: null,
          customer_type: state.customerType?.code ?? null,
          dealer_name: null
        };
        if (leadTypes?.length) {
          leadTypes.forEach((l) => {
            leadsDetails[l.leadType] = res ?? null;
          });
        }
        if (!isNullOrEmpty(state.blindOrder) || state.autoConvert) {
          if (isAppBrowser()) {
            $nativeCommon.goPage({ type: 3, params: { orderId: res.orderId } });
            loading.hide();
            return false;
          }
          window.location = getBetterUrl(
            appendQuery(detailHref, {
              orderId: res.orderId
            }),
            props.page.itemLanguage,
            true
          );
          return false;
        }
        if (isAppBrowser()) {
          $nativeCommon.goPage({ type: 1, params: { orderId: res.orderId } });
          loading.hide();
          return false;
        }
        const [resP, exP] = await api.order.intactPay(null, {
          orderId: res.orderId,
          oneId: appStore?.loginInfo?.lotusId,
          type: 1
        });
        if (exP) {
          if (exP.code === 54090012) {
            window.location = getBetterUrl(
              appendQuery(detailHref, {
                orderId: res.orderId
              }),
              props.page.itemLanguage,
              true
            );
            return;
          }
          await toast.showEx(ex);
          _qt.trackStep2Clk(false);
          return false;
        }
        state.paymentRes = resP;
        if (consentData?.termsCondition && newsletterConsents?.length > 0) {
          const cptcBody = merge(
            {
              firstName: personalData.firstName,
              middleName: personalData.middleName ?? '',
              lastName: personalData.lastName,
              email: personalData.email,
              countryRegion: state.alpha2Code,
              channel: 'Official Website'
            },
            state.personalData?.externalData,
            state.companyData?.externalData,
            state.shippingData?.externalData,
            state.billingData?.externalData,
            state.consentData?.externalData
          );
          if (!isNullOrEmpty(consentData.isAboveTwenty)) {
            merge(cptcBody, { isAboveTwenty: consentData.isAboveTwenty });
          }
          const [newsletterRes] = await api.lcms.crm.cptc.create(null, cptcBody);
          if (leadTypes?.length) {
            leadTypes.forEach((l) => {
              if (equalString(l.consentType, 'CPTC')) {
                leadsDetails[l.leadType] = newsletterRes?.leadId ?? null;
              }
            });
          }
          // const needDoubleOptin = await services.site.countryNeedDoubleOptin(state.alpha2Code);
          // const body = {
          //   vehicleModelOfInterest: state.configuration.series,
          //   countryRegion: state.shippingData.country.data.alpha2Code,
          //   language: props.page.itemLanguage,
          //   needDoubleOptin,
          //   termsAndConditions: newsletterConsents.map((revisionNumber) => ({
          //     revisionNumber: revisionNumber,
          //     title: window.document.title,
          //     formUrl: window.location.href,
          //     effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
          //   })),
          //   phone: `${state.personalData.mobile?.area.code}${state.personalData.mobile?.number}`,
          //   firstName: state.personalData.firstName,
          //   middleName: '',
          //   lastName: state.personalData.lastName,
          //   email: state.email,
          //   channel: isOnApp(route) ? 'APP' : 'Official Website',
          //   company: state.companyData?.companyName ?? '',
          //   title
          // };
          // merge(body, state.personalData?.externalData, state.companyData?.externalData, state.shippingData?.externalData, state.billingData?.externalData, state.consentData?.externalData);
          // api.crm.newsletter
          //   .subscribe(null, body)
          //   .then((newsletterRes) => {
          //     if (leadTypes?.length) {
          //       leadTypes.forEach((l) => {
          //         if (equalString(l.consentType, 'termsCondition')) {
          //           leadsDetails[l.leadType] = newsletterRes.leadId ?? null;
          //         }
          //       });
          //     }
          //   })
          //   .catch();
        }
        state.orderInfo = merge({}, res, {
          quoteReference: state.quoteReference,
          businessType: state.businessType
        });
        webStorage.set(S_ORDER_INFO, state.orderInfo);
        _qt.trackStep2Clk(true);
        await nextTick();
        scrollToTop();
        await state.paymentRef.initWidget(resP);
        _methods.gtmCheckout('3', `checkout`);
        qtUtils.trackPv('car_order_step3_pg', {
          order_number: res.orderNo,
          model_selection: state.configuration.model
        });
        loading.hide();
        gtmFormSubmit(
          formType?.value,
          props.fields.verifySection?.fields?.accountForm?.id,
          {
            email: state.email,
            mobileAreaCode: state.personalData.mobile.area.code,
            phone: state.personalData.mobile.number
          },
          formDetails,
          leadsDetails
        );
        return true;
      },
      async checkBind() {
        const [bindRes] = await api.cidp.bindable();
        if (bindRes) {
          state.bound = bindRes;
        } else {
          state.bound = {
            phone: true,
            email: true
          };
        }
      },
      gtmCheckout(step, event) {
        const [filterId, transferCode] = getQueryStrings('filter_id', 'transfer_code');
        let region = 'GB',
          language = 'en';
        if (props.page.itemLanguage !== 'en') {
          [language, region] = props.page.itemLanguage.split('-');
        }
        const options = [];
        for (let feature of state.features) {
          for (let info of feature.infos) {
            options.push({
              option_id: info.code,
              option_name: info.label,
              option_category: feature.type,
              option_price: info.price?.price
            });
          }
        }
        gtmPush({
          event,
          checkout_step: step,
          filter_id: filterId,
          transfer_code: transferCode,
          options,
          region,
          language,
          currency: state.currency
        });
      },
      async startListening() {
        const [pk] = getQueryStrings('pk');
        stripeHelper = await loadStripe(props.page, toast);
        stripe = stripeHelper.initSdk({ publicKey: pk });
        await _methods.listening();
      },
      async listening() {
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
        switch (paymentIntent.status) {
          case 'succeeded':
            if (equalString(state.alpha2Code, 'GB')) {
              await _methods.watchOrderDetail();
            }
            if (!detailHref || !state.orderInfo?.orderId) return;
            setTimeout(() => {
              window.location = getBetterUrl(
                appendQuery(detailHref, {
                  orderId: state.orderInfo.orderId
                }),
                props.page.itemLanguage,
                true
              );
            }, 1000);
            break;
          case 'processing':
            console.log('Your payment is processing.');
            setTimeout(() => {
              _methods.listening();
            }, 2000);
            break;
          case 'requires_payment_method':
          default:
            state.loaded = true;
            state.paymentWasFailed = true;
            checkoutMethods.hideLoading();
            await nextTick();
            await state.paymentRef.initWidget(props.orderInfo);
            break;
        }
      },
      async watchOrderDetail() {
        const orderDetail = await _methods.listenOrderDetail(10000, 0);
        const { outerPaymentTradeNo, salesOrderInfo, userInfo } = orderDetail || {};
        const { customerType, businessEmail, email } = userInfo || {};
        const customerEmail = (customerType === 'individual' ? businessEmail : email) ?? email;
        const body = {
          QuoteReference: state.orderInfo?.quoteReference,
          EntityType: state.orderInfo?.businessType,
          OrderReference: salesOrderInfo?.orderId,
          PaymentReference: outerPaymentTradeNo ?? clientSecret.split('_')[0],
          LotusID: appStore?.loginInfo?.lotusId,
          CustomerEmail: customerEmail,
          PrePaymentAmount: state.orderInfo?.paymentAmountNumber
        };
        const [res, ex] = await api.codeWaver.generateProposal(null, body);
        if (ex) {
          console.error(ex);
        }
      },
      async listenOrderDetail(total, current) {
        const delay = 500;
        return new Promise((resolve) => {
          api.order
            .intactDetail(null, {
              id: state.orderInfo?.orderId
            })
            .then(([res, ex]) => {
              if (!isNullOrEmpty(res?.outerPaymentTradeNo)) {
                resolve(res);
              } else {
                if (current < total) {
                  setTimeout(() => {
                    current += delay;
                    _methods.listenOrderDetail(total, current).then((res) => {
                      resolve(res);
                    });
                  }, delay);
                } else {
                  resolve(res);
                }
              }
            });
        });
      },
      onConfirmFinanceCalculator(e) {
        state.simulation = { selectedProduct: e.selectedProduct, selectedSimulation: e.selectedSimulation };
      },
      onOpenFinanceCalculator() {
        if (equalString(state.alpha2Code, 'GB')) {
          if (!global.cwFinance) return;
          addModalClass('santander-open');
          const options = _methods.buildCwFinanceConfig();
          global.cwFinance.update(options);
          global.cwFinance.openCalculator();
        } else {
          if (!global.scfSimulation) return;
          addModalClass('santander-open');
          global.scfSimulation.configure(
            _methods.buildScfCfg({
              term: state.simulation?.selectedSimulation?.duration.value,
              deposit: state.simulation?.selectedSimulation?.downPayment.value,
              forceSelection: {
                term: state.simulation?.selectedSimulation?.duration.value,
                deposit: state.simulation?.selectedSimulation?.downPayment.value,
                annualMileage: state.simulation?.selectedSimulation?.annualMileage.value
              }
            })
          );
          global.scfSimulation.openSimulator();
        }
      },
      buildScfCfg(options, _forceSelection) {
        const defaultTerms = 60;
        const annualMileage = 15000;
        let deposit = finance?.deposit ?? Math.floor(state.retailPrice * 0.15) ?? state.downPayment;
        const _options = merge(
          {
            model: state.configuration?.model,
            assetPrice: state.retailPrice,
            assetImage: state.carImages?.length > 0 ? state.carImages[0] : '',
            term: defaultTerms,
            deposit,
            forceSelection: merge(
              {
                term: defaultTerms,
                deposit,
                annualMileage
              },
              _forceSelection
            )
          },
          options
        );
        if (state.simulation?.selectedSimulation?.downPayment.value) {
          merge(_options, {
            term: state.simulation?.selectedSimulation?.duration.value,
            deposit: state.simulation?.selectedSimulation?.downPayment.value,
            forceSelection: {
              deposit: state.simulation?.selectedSimulation?.downPayment.value,
              term: state.simulation?.selectedSimulation?.duration.value
            }
          });
        }
        if (state.simulation?.selectedSimulation?.monthlyPayment.value) {
          merge(_options, {
            forceSelection: {
              monthlyPayment: state.simulation?.selectedSimulation?.monthlyPayment.value
            }
          });
        }
        return _options;
      },
      buildCwFinanceConfig(options) {
        const basePrice = checkoutMethods.getPrice('Base Retail Price', 'Base Price');
        const featureOptions = [];
        state.features.forEach((x) =>
          x.infos.forEach((y) => {
            if (!isNullOrEmpty(y.buttonFeatureType)) {
              featureOptions.push({
                code: y.buttonFeatureType,
                name: y.desc,
                price: y.price?.price ?? 0
              });
            }
          })
        );
        featureOptions.push(
          {
            code: state.carModel?.packageCode,
            name: state.carModel?.packageCode,
            price: 0
          },
          {
            code: 'MHP_UK_MARKET',
            name: 'MHP_UK_MARKET',
            price: 0
          }
        );
        const productKey = state.paymentOption?.abbr;
        let productKeys = state.paymentOptions.map((x) => x.abbr);
        if (!isNullOrEmpty(productKey)) {
          productKeys = [productKey];
        }
        const businessType = state.customerType?.code === 'individual' ? 'PrivateIndividual' : state.businessData?.businessType?.key ?? 'SoleTrader';
        state.businessType = businessType;
        const _options = {
          element: false,
          environment: state.cwConfig?.env ?? 'development',
          alignment: 'center',
          basePrice,
          prepayment: state.downPayment,
          options: featureOptions,
          productKeys,
          modelID: Number(state.carSeries?.code ?? state.configuration?.seriesCode)
        };
        if (!isNullOrEmpty(businessType)) {
          merge(_options, {
            entity: businessType
          });
        }
        merge(_options, options);
        return _options;
      },
      async initFinanceInfo() {
        if (scfSimulationTimeout) clearTimeout(scfSimulationTimeout);
        loading.show();
        const defaultCustomerType = ifNullOrEmpty(props.fields?.financeSection?.fields?.defaultCustomerType?.fields?.code.value, 'individual');
        state.customerType = computes.customerTypes.value?.find((x) => x.code === defaultCustomerType);
        if (!finance || equalString(state.alpha2Code, 'GB')) {
          if (isNaN(state.retailPrice)) return;
          state.paymentOption = state.paymentOptions.find((x) => x.name === 'cash');
        } else {
          state.paymentOption = state.paymentOptions.find((x) => x.name === 'santanderLoan');
        }
        state.agentOptions = await services.agent.getSalesAgentOptions(props.page);
        checkoutMethods.gtmCheckout('1', 'checkout');
        qtUtils.trackPv('car_order_step1_pg');
        state.loaded = true;
        await nextTick();
        state.financeRef.init();
        if (state.blindOrder) {
          _methods.fillReservationToBto();
        }
        $bus.on(BUS_EVENTS.OPEN_FINANCE_CALCULATOR, _methods.onOpenFinanceCalculator);
        if (equalString(state.alpha2Code, 'GB')) {
          const [cwFinance, cwConfig] = await state.financeCalculatorRef.configCodeWaver(state.configuration.series);
          state.cwConfig = cwConfig;
          const options = _methods.buildCwFinanceConfig({
            quoteUpdate(quoteReference, quote) {
              if (!state.quoteReference || !state.financeQuote || paymentOptionChanged || businessDataChanged) {
                state.financeQuote = quote;
                state.quoteReference = quoteReference;
                if (paymentOptionChanged) paymentOptionChanged = false;
                if (businessDataChanged) businessDataChanged = false;
              }
            },
            quoteSelect(quoteReference, quote) {
              removeModalClasses('santander-open');
              state.quoteReference = quoteReference;
              state.financeQuote = quote;
            }
          });
          console.log('finance options', options);
          cwFinance.configure(options);
        } else if (state.carSeries?.financeLevel2) {
          const [scfSimulation, assetInfo] = await state.financeCalculatorRef.config(_methods.buildScfCfg(null, finance));
          state.assetInfo = assetInfo;
          $bus.on(BUS_EVENTS.CONFIRM_FINANCE_CALCULATOR, _methods.onConfirmFinanceCalculator);
          if (scfSimulation) {
            scfSimulation.scfQuotingVisibilityUpdated((e) => {
              state.financeCalculatorInitialized = true;
              if (!e.visible) {
                removeModalClasses('santander-open');
              }
            });
            scfSimulation.scfSelectedSimulationUpdated((e) => {
              if (!state.financeCalculatorInitialized || !state.simulation) {
                state.simulation = e;
              }
            });
            scfSimulationTimeout = setTimeout(() => {
              state.simulation = global.scfSimulation;
            }, 5000);
          }
        }
      },
      gotoStep(stepCode) {
        const index = state.steps.findIndex((x) => equalString(x.fields.code.value, stepCode));
        if (index >= 0) {
          state.step = index;
        }
        return index;
      }
    };
    const methods = {
      checkStep: () => {
        const { code } = state.steps[state.step]?.fields;
        gtmFormStep(formType?.value, props.fields.verifySection?.fields?.accountForm?.id, appStore?.loginInfo, Number(state.step) + 1, code?.value, null);
      },
      async goPrev() {
        --state.step;
        methods.checkStep();
        await nextTick();
        scrollToTop();
        if (computes.stepCode.value === 'order-details') {
          state.nextDisabled = !state.deliveryRef.checkMandatoryCheckbox();
        } else {
          state.nextDisabled = false;
        }
      },
      goNext: debounce(async () => {
        let stepCode = computes.stepCode.value;
        if (stepCode === 'extras') {
          loading.show();
          benefitSet = state.extrasRef.selectedPackageIds?.join(',') || '';
          loading.hide();
        }
        if (stepCode === 'finance') {
          const res = await state.financeRef.validate();
          if (!res) {
            return;
          }
          state.agent = res.agent;
          state.businessData = res.businessData;
          state.tradeInData = res.tradeInData;
        }
        if (stepCode === 'order-details') {
          const succeed = await _methods.submitCheckout();
          if (!succeed) return;
        }
        ++state.step;
        methods.checkStep();
        await nextTick();
        scrollToTop();
        stepCode = computes.stepCode.value;
        if (stepCode === 'order-details') {
          state.nextDisabled = !state.deliveryRef.checkMandatoryCheckbox();
        }
        checkoutMethods.gtmCheckout(state.step, 'checkout');
      }, 300),
      onFinanceChange(key, data) {
        state[key] = data;
        if (key === 'businessData' && global.cwFinance) {
          businessDataChanged = true;
          const options = _methods.buildCwFinanceConfig();
          global.cwFinance.update(options);
        }
      },
      onOpenPriceBreakdown() {
        state.openPriceBreakdown = true;
        document.documentElement.classList.add('price-breakdown-open');
      },
      onClosePriceBreakdown() {
        state.openPriceBreakdown = false;
        document.documentElement.classList.remove('price-breakdown-open');
      },
      onPay() {
        state.paymentRef.onPay();
      },
      onRetry() {
        state.paymentRef.onRetry();
      },
      async onContinue() {
        state.loaded = false;
        loading.show();
        state.recommended = true;
        await _methods.initFinanceInfo();
        loading.hide();
      }
    };
    const checkoutMethods = {
      _qt,
      validateGeeTest() {
        return state.geeCaptchaRef.validate();
      },
      goNext(options) {
        if (computes.stepCode.value === 'verify') {
          state.email = options.email;
        }
        methods.goNext();
      },
      gtmCheckout(step, event) {
        //   gtmCheckout(props.page, step, state.features, event, state.currency);
      },
      hideLoading() {
        loading.hide();
      },
      getPrice(name, ...fallbackNames) {
        return Number(state.priceInfo.find((x) => equalString(x.elementName, name) || fallbackNames.some((y) => equalString(x.elementName, y)))?.price);
      },
      updateAgent(agent) {
        state.agent = agent;
      },
      changeNextDisabled(disabled) {
        state.nextDisabled = disabled;
      }
    };
    provide('checkoutMethods', checkoutMethods);
    watch(
      () => state.paymentOption,
      () => {
        if (canUseDOM()) {
          if (global.cwFinance) {
            const options = _methods.buildCwFinanceConfig();
            paymentOptionChanged = true;
            global.cwFinance.update(options);
          }
        }
      }
    );
    watch(
      () => state.customerType,
      () => {
        if (canUseDOM()) {
          if (global.cwFinance) {
            const options = _methods.buildCwFinanceConfig();
            paymentOptionChanged = true;
            global.cwFinance.update(options);
          }
        }
      }
    );
    watch(
      () => state.packageDetails?.rightsRespList,
      (list) => {
        [state.benefitsList, state.benefitPriceIds, state.benefitsAmount] = getBenefitsFromPackage(list);
        console.log('state.benefitsAmount', state.benefitsAmount);
        state.additionalCosts = state.priceInfo.filter(
          (x) =>
            !['Base Retail Price', 'Option Price', 'Total Price Excl. VAT', 'Incl. VAT', 'Vehicle Total Price', 'Total Price', 'Accessories & Service'].includes(x.elementName) &&
            !state.benefitsList.some((y) => equalString(y.name, x.elementName))
        );
      },
      {
        deep: true
      }
    );
    if (canUseDOM()) {
      loading.show();
    }
    onBeforeMount(() => {
      loading.show();
    });
    onMounted(async () => {
      state.alpha2Code = getPageAlpha2Code(props.page);
      [market] = getGlobalCfgValues(props.page, ['market'], ['EU']);
      [token, transferCode, filterId, clientSecret, redirectStatus, blindOrderId, benefitSet, state.vin, state.vid] = getQueryStrings(
        'access_token',
        'transfer_code',
        'filter_id',
        'payment_intent_client_secret',
        'redirect_status',
        'blind_order_id',
        'benefit_set',
        'vin',
        'vid'
      );
      let allSteps = deepCopy(props.fields?.steps || []);
      if (isNullOrEmpty(state.vin) || !props.fields?.extrasSection?.fields) {
        const extraIndex = allSteps.findIndex((step) => step.fields.code.value === 'extras');
        if (extraIndex >= 0) {
          allSteps.splice(extraIndex, 1);
        }
      }
      state.steps = allSteps;
      if (clientSecret) {
        state.orderInfo = webStorage.get(S_ORDER_INFO);
        await _methods.checkPayment();
      } else {
        loading.show();
        if (isAppBrowser()) {
          const tkObj = await $nativeCommon.getTokenAsync();
          token = tkObj?.token;
          if (isNullOrEmpty(token)) {
            $nativeCommon.login();
            return;
          }
        }
        if (!isNullOrEmpty(token)) {
          const [userInfo] = await api.cidp.user.info(null, null, {
            headers: {
              authorization: token
            }
          });
          if (userInfo) {
            userInfo.token = token;
            await appStore.setLoginInfo(userInfo);
          }
        }
        if (appStore.hasLoggedIn) {
          await _methods.accountSignOut();
        }
        if (!isNullOrEmpty(state.vin)) {
          await _methods.loadStockDetail();
          await _methods.getOptionalPackages();
        } else {
          await _methods.loadConfiguration();
          await _methods.loadBenefits();
          await _methods.loadPrice();
          await _methods.getCarImages();
        }
        await _methods.getOrderRules();
        if (!isNullOrEmpty(blindOrderId)) {
          if (!appStore?.hasLoggedIn) {
            redirectToLogin(props.page);
          }
          let blindOrder = null,
            reservationDetailEx = null;
          [blindOrder, reservationDetailEx] = await api.order.detail(null, { id: blindOrderId });
          if (reservationDetailEx?.code === 54190013) {
            await toast.showError(props.fields.reservationNotBelongToAccountError?.value ?? 'The reservation order passed to the order page does not belong to the current account');
          }
          if (['200', 200].includes(blindOrder?.orderStatus)) {
            state.blindOrder = blindOrder;
          }
          if (!isNullOrEmpty(state.blindOrder)) {
            const [needRedirect, targetLang] = services.order.checkOrderLang(props.page, state.blindOrder.country);
            if (needRedirect) {
              await state.orderLocaleRedirectModalRef.open();
              redirectToLang(targetLang);
            }
            if (!appStore?.hasLoggedIn) {
              redirectToLogin(props.page, router);
              return;
            }
          }
        }
        if (state.bound?.email && (isOnApp(route) || state.blindOrder?.email)) {
          state.email = state.blindOrder?.email ?? appStore?.loginInfo?.email;
          _methods.gotoStep('finance');
        }
        await state.geeCaptchaRef.init();
        if (!state.recommended) {
          const rList = await state.checkoutRecommendingRef?.init();
          if (!rList?.length) {
            state.recommended = true;
            await nextTick();
            await _methods.initFinanceInfo();
          }
          state.loaded = true;
        } else {
          await _methods.initFinanceInfo();
        }
        loading.hide();
      }
    });
    onBeforeUnmount(() => {
      $bus.off(BUS_EVENTS.CONFIRM_FINANCE_CALCULATOR, _methods.onConfirmFinanceCalculator);
      $bus.off(BUS_EVENTS.OPEN_FINANCE_CALCULATOR, _methods.onOpenFinanceCalculator);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      deviceState
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-checkout {
  $c: &;
  &__main {
    padding: 40px grid-width-m(1) 120px grid-width-m(1);
  }
  &__aside {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background: $black;
    color: $white;
    padding-top: 54px;
    overflow-y: auto;
    &-header {
      padding: 16px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .e-site-button {
    &__text {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
    > div {
      &:first-child {
        .e-site-button {
          &,
          &:hover,
          &:visited {
            font-weight: normal;
            color: $grey-neutral;
          }
        }
      }
    }
    .e-site-button {
      width: 100%;
    }
  }
  &__price-bar {
    position: sticky;
    bottom: 0;
    z-index: 2;
  }
  @include mobile {
    &.on-app {
      #{$c}__aside {
        padding-top: 0;
      }
    }
  }
  @include tablet-landscape {
    &__wrapper {
      @include grid-container;
    }
    &__main {
      @include grid-block(1, 12);
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 40px;
    }
    &__content {
      padding: 0 grid-width(2);
      transition: all 0.4s cubic-bezier(0.355, 0.005, 0.26, 1);
    }
    &__aside {
      @include grid-block(13, 12);
      position: static;
      padding-top: 0;
      height: 100%;
      overflow-y: visible;
    }
    &__actions {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 grid-width(2);

      .e-site-button {
        width: 140px;
      }
    }
    &__price-bar {
      display: none;
    }
  }
  @include desktop {
    &__actions {
      .e-site-button {
        width: 220px;
      }
    }
  }
}
</style>

<template>
  <DashboardLayout :fields="fields?.dashboardHeader?.fields" active-menu="dashboard">
    <OverallSettings v-bind="$props">
      <div class="c-dashboard" v-if="fields">
        <div class="c-dashboard__main">
          <div class="c-dashboard__media" :class="{ show: !loading }">
            <AdaptiveMedia :field="fields.media" />
          </div>
          <div class="c-dashboard__media-mask" :class="{ active: showMask }"></div>
          <div class="c-dashboard__text" :class="{ show: !loading }">
            <div
              class="c-dashboard__text-eyebrow"
              v-html="
                $formatString(fields?.eyebrow?.value, {
                  time: hour > 12 ? (hour > 18 ? $tu('Evening') : $tu('Afternoon')) : $tu('Morning'),
                  name: appStore?.loginInfo?.firstName && appStore?.loginInfo?.lastName ? `${appStore?.loginInfo?.firstName} ${appStore?.loginInfo?.lastName}` : appStore?.loginInfo?.nickName
                })
              "
            />
            <div class="c-dashboard__text-title" v-html="title" />
            <div class="c-dashboard__text-push" v-if="appStore?.orderInfo && pushOrders?.length">
              <div class="c-dashboard__text-push-item" v-for="(item, index) in pushOrders" :key="index">
                <div class="c-dashboard__text-push-item-image">
                  <img :src="item.modelImage" :alt="item.model" v-if="item.modelImage" />
                </div>
                <div class="c-dashboard__text-push-item-main">
                  <div class="c-dashboard__text-push-item-main-id">{{ $t('Order number') }} : {{ item.orderId }}</div>
                  <div class="c-dashboard__text-push-item-main-title" v-html="$formatString(item.message?.fields.title?.value, { carModel: item.series })" />
                  <div class="c-dashboard__text-push-item-main-body" v-html="$formatString(item.message?.fields.body?.value, { carModel: item.series })" />
                  <div class="c-dashboard__text-push-item-main-btn">
                    <SiteButton :fields="merge({}, item.message?.fields?.button.fields, getOrderData(item))" v-if="item.jumpLink" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-dashboard__cards" :class="{ show: !loading }">
            <div class="c-dashboard__cards-push" :class="{ reverse: fields.pcCardsReverse?.value }" v-if="mainOrder">
              <diV class="c-dashboard__cards-message">
                <div class="c-dashboard__cards-border" :class="{ hideBorder }" v-if="largeThanTablet() && messageCard"></div>
                <AdaptiveMedia class="c-dashboard__cards-message-media" :field="messageCard?.fields.media" />
                <div class="c-dashboard__cards-message-main" v-if="messageCard">
                  <JssRichText class="c-dashboard__cards-message-eyebrow" :field="messageCard?.fields.eyebrow" />
                  <JssRichText class="c-dashboard__cards-message-title" :field="messageCard?.fields.title" />
                  <JssRichText class="c-dashboard__cards-message-body" :field="messageCard?.fields.body" />
                  <SiteButton class="c-dashboard__cards-message-btn" v-for="btn in messageCard?.fields.buttons" :key="btn.id" v-bind="btn" />
                </div>
              </diV>
              <diV class="c-dashboard__cards-order" v-if="mainOrder">
                <div class="c-dashboard__cards-border" :class="{ hideBorder }" v-if="!largeThanTablet()"></div>
                <div class="c-dashboard__cards-order-main">
                  <div class="c-dashboard__cards-order-text">
                    <div class="c-dashboard__cards-eyebrow">{{ $tu('My order') }}</div>
                    <div class="c-dashboard__cards-title">{{ $formatModel(mainOrder.model).toUpperCase() ?? '' }}</div>
                  </div>
                  <img class="c-dashboard__cards-order-media" :src="mainOrder.modelImage" :alt="mainOrder.model" v-if="mainOrder.modelImage" />
                  <div class="c-dashboard__cards-order-bottom">
                    <div class="c-dashboard__cards-order-bottom-status">
                      <div class="c-dashboard__cards-order-bottom-status-main" v-if="!mainOrder.carSeries.noConfigure">
                        <div class="c-dashboard__cards-order-bottom-status-label">{{ $tu('Current order status') }}</div>
                        <div class="c-dashboard__cards-order-bottom-status-text">
                          {{ mainOrder.status?.name }}
                        </div>
                      </div>
                    </div>
                    <SiteButton class="c-dashboard__cards-order-bottom-btn" :fields="merge({}, fields.orderButton.fields, getOrderData(mainOrder))" v-if="!mainOrder.carSeries.noConfigure" />
                  </div>
                </div>
              </diV>
            </div>
            <div class="c-dashboard__cards-items" :class="{ reverse: fields.pcCardsReverse?.value }">
              <div class="c-dashboard__cards-item" v-for="item in cards" :key="item.id">
                <DashboardCard v-bind="item" />
              </div>
            </div>
          </div>
        </div>
        <div class="c-dashboard__coming" v-if="fields.comingTitle.value || fields.comingBody.value">
          <JssRichText class="c-dashboard__coming-title" :field="fields.comingTitle" />
          <JssRichText class="c-dashboard__coming-body" :field="fields.comingBody" />
        </div>
      </div>
    </OverallSettings>
  </DashboardLayout>
</template>
<script>
import { reactive, toRefs, onMounted, onBeforeUnmount, computed, watch, inject } from 'vue';
import { largeThanTablet, getScrollTop } from '@/utils/dom-utils';
import { settingValue, formatModel } from '@/utils/site-utils';
import { equalString, formatString } from '@/utils/string-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import api from '@/api';
import useAppStore from '@/store/appStore';
import { merge } from 'lodash';
export default {
  name: 'Dashboard',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const loading = inject('loading');
    const appStore = useAppStore();
    const state = reactive({
      headerEl: null,
      selfHeaderEl: null,
      loading: true,
      showMask: false,
      hour: new Date().getHours(),
      title: null,
      pushOrders: [],
      hideBorder: false,
      noOrder: false,
      messageCard: null,
      cards: null
    });
    const computes = {
      mainOrder: computed(() => (appStore?.orderInfo?.length ? appStore?.orderInfo[0] : null))
    };
    const methods = {
      largeThanTablet,
      merge,
      initHeader: () => {
        state.headerEl = document.querySelector('.c-header');
        state.selfHeaderEl = document.querySelector('.s-dashboard-layout__header');
        state.headerEl?.classList.add('dashboard');
        state.selfHeaderEl?.classList.add('dashboard');
        setTimeout(() => {
          state.headerEl?.classList.add('show');
          state.selfHeaderEl?.classList.add('show');
        }, 2000);
      },
      initPage: () => {
        state.pushOrders = [];
        const activeOrder = appStore?.orderInfo.filter(
          (o) => o.userUpdateTime && !equalString(o.orderStatus, '302') && !equalString(o.orderStatus, '400') && !equalString(o.orderStatus, '402') && !equalString(o.orderStatus, '502')
        );
        const cancelOrder = appStore?.orderInfo.find(
          (o) => equalString(o.orderStatus, '302') || equalString(o.orderStatus, '400') || equalString(o.orderStatus, '402') || equalString(o.orderStatus, '502')
        );
        const { messageRules } = props.fields;
        const { titles } = props.fields;
        let title = null;
        let pushOrders = [];
        if (activeOrder.length) {
          activeOrder.forEach((o) => {
            if (!o.salesOrder && !o.soldOut && o.configurable) {
              let message = messageRules.find((m) => m.fields.statuses.some((s) => s.fields.code.value == 100));
              message.title = formatString(message.title?.value, { carModel: formatModel(o.model) });
              message.body = formatString(message.body?.value, { carModel: formatModel(o.model) });
              o.message = message;
            } else {
              messageRules.forEach(async (m) => {
                if (m.fields.statuses.some((s) => s.fields.code.value === o.orderStatus)) {
                  const [res, ex] = await api.order.intactDetail(null, { id: o.id });
                  const signable = res?.salesOrderInfo?.isCanSignContract ?? false;
                  if (signable) {
                    let message = m;
                    message.title = formatString(message.title?.value, { carModel: formatModel(o.model) });
                    message.body = formatString(message.body?.value, { carModel: formatModel(o.model) });
                    o.message = message;
                  }
                }
              });
            }
          });
          let pushOrders = activeOrder.filter((o) => !!o.message);
          state.pushOrders = pushOrders.slice(0, 2);
          const pushOrderLength = state.pushOrders.length;
          const firstOrder = state.pushOrders[0] ?? null;
          if (pushOrderLength) {
            if (pushOrderLength == 2) {
              title = titles.find((t) => settingValue(t.fields.type) === 'orders');
              state.title = title?.fields.text?.value;
              state.loading = false;
            } else {
              if (!firstOrder?.salesOrder) {
                title = titles.find((t) => settingValue(t.fields.type) === 'newOrder');
                state.title = formatString(title?.fields.text?.value, { carModel: firstOrder?.series.toUpperCase() });
                state.loading = false;
              } else {
                title = titles.find((t) => settingValue(t.fields.type) === 'signOrder');
                state.title = formatString(title?.fields.text?.value, { carModel: firstOrder?.series.toUpperCase() });
                state.loading = false;
              }
            }
          } else {
            title = titles.find((t) => settingValue(t.fields.type) === 'noOrder');
            state.title = title?.fields.text?.value;
            state.loading = false;
          }
        } else {
          if (isNullOrEmpty(cancelOrder)) {
            state.noOrder = true;
            state.cards = props.fields.noDataCards?.length ? props.fields.noDataCards : props.fields.cards;
            title = titles.find((t) => settingValue(t.fields.type) === 'noOrder');
            state.title = title?.fields.text?.value;
            state.loading = false;
          } else {
            pushOrders.push(merge(cancelOrder, { message: messageRules.find((m) => m.fields.statuses.some((s) => s.fields.code.value == 400)) }));
            title = titles.find((t) => settingValue(t.fields.type) === 'cancelOrder');
            state.pushOrders = pushOrders;
            state.title = title?.fields.text?.value;
            state.loading = false;
          }
        }
      },
      getOrderData: (item) => {
        return {
          link: { value: { href: item.jumpLink } }
        };
      },
      onscroll: () => {
        const scrollTop = getScrollTop();
        if (scrollTop) {
          state.hideBorder = true;
        } else {
          state.hideBorder = false;
        }
        if (scrollTop >= 600) {
          state.showMask = true;
        } else {
          state.showMask = false;
        }
      }
    };
    watch(
      () => appStore?.orderInfo,
      (v) => {
        if (v) {
          loading.hide();
          methods.initPage();
        }
      },
      {
        deep: true,
        immediate: true
      }
    );
    onMounted(() => {
      loading.show();
      state.messageCard = props.fields.messageCard;
      state.cards = props.fields.cards;
      methods.initHeader();
      document.addEventListener('scroll', methods.onscroll);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('scroll', methods.onscroll);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      appStore
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-dashboard {
  position: relative;
  &__media {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -2;
    &.show {
      video {
        opacity: 0;
        animation: fade-in 2s forwards linear;
      }
    }
    &-mask {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba($black, 0.6);
      opacity: 0;
      z-index: -1;
      transition: all 0.8s;
      &.active {
        opacity: 1;
      }
    }
  }
  &__text {
    min-height: calc(100vh - 56px - #{mobile-header-height()});
    padding: 48px 24px;
    &.show {
      opacity: 0;
      animation: fade-in 1.5s forwards linear 2.5s;
    }
    &-title {
      margin-top: 16px;
    }
    &-push {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      gap: 10px;
      margin-top: grid-width-m(4);
      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $black;
        &-image {
          width: 104px;
          height: 100%;
          > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        &-main {
          @include h11;
          color: $white;
          padding: 16px;
          flex: 1;
          &-btn {
            margin-top: 16px;
            .e-site-button {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__cards {
    position: relative;
    transform: translateY(200px);
    padding-bottom: 200px;
    margin-top: -120px;
    &.show {
      transform: translateY(120px);
      transition: transform 0.5s ease 3.5s;
    }
    &-push {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      &.reverse {
        align-items: flex-end;
      }
      gap: 96px;
      > div {
        flex: 1;
      }
    }
    &-eyebrow {
      @include h10;
      color: $grey-neutral;
    }
    &-title {
      margin-top: 16px;
      @include h5;
      color: $black;
    }
    &-body {
      margin-top: 32px;
    }
    &-btn {
      margin-top: 24px;
    }
    &-border {
      height: 8px;
      width: 100%;
      margin-top: -8px;
      background-color: $yellow;
      transition: all 0.8s ease;
      &.hideBorder {
        transform: translateY(8px);
        height: 0;
      }
    }
    &-message {
      min-height: grid-width(9);
      width: calc(100vw - 24px);
      margin: 0 grid-width(1.5);
      &-eyebrow {
        color: $grey-neutral;
      }
      &-title {
        margin-top: 16px;
        color: $black;
      }
      &-body {
        margin-top: 32px;
      }
      &-btn {
        margin-top: 24px;
      }
      &-media {
        height: grid-width-m(5) !important;
      }
      &-main {
        padding: 32px;
        background-color: $white;
      }
    }
    &-order {
      height: fit-content;
      width: calc(100vw - 24px);
      background-color: $white;
      &-main {
        padding: 32px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        > div {
          flex: 1;
        }
      }
      &-media {
        width: 100%;
        height: 300px;
        object-fit: contain;
      }
      &-bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 16px;
        &-status {
          display: flex;
          flex-direction: column;
          gap: 16px;
          @include h11;
          &-text {
            display: inline-block;
            color: $white;
            padding: 2px 10px;
            background-color: $black;
            border-radius: 1px;
            margin-top: 4px;
          }
        }
        &-btn {
          z-index: 2;
        }
      }
    }
    &-item {
      display: flex;
      height: fit-content;
      &:nth-child(odd) {
        width: 100%;
        justify-content: flex-start;
      }
      &:nth-child(even) {
        width: 100%;
        justify-content: flex-end;
      }
    }
    &-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      &.reverse {
        .c-dashboard__cards-item {
          direction: ltr;
          &:nth-child(odd) {
            justify-content: flex-end;
          }
          &:nth-child(even) {
            justify-content: flex-start;
          }
        }
      }
    }
  }
  &__coming {
    text-align: center;
    padding: 120px 0;
    background-color: $white;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @include tablet-landscape {
    &__text {
      min-height: calc(100vh - 56px - #{tablet-header-height()});
      padding: 64px grid-width(1.5);
      &-title {
        width: grid-width(10);
        margin-top: 16px;
      }
      &-push {
        margin-top: grid-width(1.5);
        &-item {
          &-image {
            width: 144px;
          }
          &-main {
            width: 352px;
            padding: 16px 24px;
          }
        }
      }
    }
    &__cards {
      &.show {
        transform: translateY(0);
      }
      &-push {
        flex-direction: row;
        gap: 0;
        &.reverse {
          align-items: flex-start;
        }
        & > div {
          flex: none;
        }
      }
      &-order {
        width: 50vw;
        &-main {
          padding: 80px;
        }
        &-media {
          height: auto;
        }
        &-bottom {
          flex-direction: row;
          align-items: flex-end;
        }
      }
      &-message {
        width: grid-width(9);
        margin-top: 120px;
        &-media {
          height: grid-width(5) !important;
        }
        &-main {
          padding: 48px 64px;
        }
      }
      &-items {
        &.reverse {
          direction: rtl;
        }
      }
      &-item {
        &:nth-child(odd) {
          width: 50%;
        }
        &:nth-child(even) {
          width: 50%;
        }
      }
    }
  }

  @include desktop {
    &__text {
      min-height: calc(100vh - 56px - #{desktop-header-height()});
    }
  }
}
.c-header {
  &.dashboard {
    transform: translateY(-120px);
  }
  &.show {
    transform: translateY(0);
  }
}
.s-dashboard-layout__header {
  &.dashboard {
    transform: translateY(-120px);
  }
  &.show {
    transform: translateY(0);
  }
}
</style>

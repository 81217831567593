<template>
  <div>
    <modal class="c-cookies" :content-class="rootClasses" ref="cookiesModalRef">
      <div class="c-cookies__content">
        <heading class="c-cookies__title" :field="fields.title" />
        <jss-rich-text class="c-cookies__body" :field="fields.body" tag="div" />
      </div>
      <div class="c-cookies__buttons">
        <site-button v-bind="fields.acceptButton" @click="onAccept" size="small" />
        <site-button v-bind="fields.rejectButton" @click="onReject" size="small" />
        <site-button v-bind="fields.manageButton" @click="onManage" size="small" />
      </div>
    </modal>
    <modal class="c-cookies-setting" ref="settingModalRef" closable>
      <heading class="c-cookies-setting__title" :field="fields.settingTitle" />
      <jss-rich-text class="c-cookies-setting__body" :field="fields.settingBody" tag="div" />
      <site-button class="c-cookies-setting__btn" v-bind="fields.allowAllButton" size="small" />
      <jss-rich-text class="c-cookies-setting__consents-title" :field="fields.consentsTitle" tag="div" />
      <div class="c-cookies-setting__consents">
        <div class="c-cookies-setting__consent" v-for="(cc, i) in fields.consents" :key="i">
          <div class="c-cookies-setting__consent-header">
            <accordion-toggle :active="expandedConsents.includes(cc)" @click="toggleExpandedConsents(cc)" />
            <jss-text class="c-cookies-setting__consent-title" :field="cc.fields.title" tag="div" />
            <site-switch :value="agreedConsents.includes(cc)" @change="toggleConsent(cc)" v-if="cc.fields.optional.value" />
            <jss-text class="c-cookies-setting__consent-required" :field="cc.fields.requiredMessage" tag="div" />
          </div>
          <jss-rich-text class="c-cookies-setting__consent-body" :field="cc.fields.body" tag="div" v-if="expandedConsents.includes(cc)" />
        </div>
      </div>
      <div class="c-cookies-setting__consents-buttons">
        <site-button v-bind="fields.rejectButton" size="small" />
        <site-button v-bind="fields.myChooseButton" size="small" />
      </div>
    </modal>
  </div>
</template>

<script>
/**
 * @typedef CookiesFields
 * @property {SimpleField} title
 * @property {SimpleField} body
 * @property {ButtonField} acceptButton
 * @property {ButtonField} manageButton
 * @property {SimpleField} settingTitle
 * @property {SimpleField} settingBody
 * @property {SimpleField} consentsTitle
 * @property {Array<CookiesConsent>} consents
 * @property {GlobalSettingEntry} layoutMode
 * @property {ButtonField} rejectButton
 * @property {ButtonField} allowAllButton
 * @property {ButtonField} myChooseButton
 * */
import { computed, onMounted, reactive, toRefs } from 'vue';
import { settingValue, themeClass } from '@/utils/site-utils';
import config from '@/temp/config';

export default {
  name: 'Cookies',
  props: {
    /**@type CookiesFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      /**@type ModalRef*/
      cookiesModalRef: null,
      /**@type ModalRef*/
      settingModalRef: null,
      expandedConsents: [],
      agreedConsents: []
    });
    const computes = {
      rootClasses: computed(() => [settingValue(props.fields.layoutMode, 'horizontal'), themeClass(props)])
    };
    const methods = {
      onAccept(e) {
        state.cookiesModalRef.close();
      },
      onReject(e) {
        state.cookiesModalRef.close();
      },
      onManage(e) {
        state.cookiesModalRef.close();
        state.settingModalRef.open();
      },
      toggleExpandedConsents(consentView) {
        const index = state.expandedConsents.findIndex((x) => x === consentView);
        if (index >= 0) {
          state.expandedConsents.splice(index, 1);
        } else {
          state.expandedConsents.push(consentView);
        }
      },
      toggleConsent(consentView) {
        const index = state.agreedConsents.findIndex((x) => x === consentView);
        if (index >= 0) {
          state.agreedConsents.splice(index, 1);
        } else {
          state.agreedConsents.push(consentView);
        }
      }
    };
    onMounted(() => {
      // state.agreedConsents = [...props.fields.consents];
      // if (config.sitecoreApiHost !== 'https://lotus-cm.nttdatadm.com') {
      //   state.cookiesModalRef.open();
      // }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-cookies {
  &.e-modal {
    padding-bottom: 20px;
    align-items: flex-end;
  }
  .e-modal__content {
    @include component-themes;
    padding: grid-width(0.5) grid-width(1);
  }
  &__title {
    @include h6;
  }
  &__body {
    margin-top: $space-20;
    text-align: justify;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    margin-top: $space-40;
    .e-site-button {
      + .e-site-button {
        margin-top: $space-20;
      }
    }
  }
}
.c-cookies-setting {
  &.e-modal {
    padding-bottom: $space-20;
    align-items: flex-end;
  }
  .e-modal__content {
    @include component-themes;
    height: 90vh;
    padding: grid-width(1);
  }
  &__title {
    @include h6;
    margin-top: $space-20;
    margin-bottom: $space-20;
  }
  &__body {
    text-align: justify;
  }
  &__btn {
    margin-top: $space-20;
  }
  &__consents-title {
    @include h6;
    margin-top: $space-20;
  }
  &__consents {
    margin-top: $space-20;
  }
  &__consent {
    border: 1px solid $grey-gainsboro;
  }
  &__consent-header {
    padding: $space-18 $space-24;
    display: flex;
    align-items: center;
  }
  &__consent-title {
    margin-left: $space-12;
    margin-right: auto;
  }
  &__consent-body {
    padding: $space-18 $space-24;
    background: $grey-light;
    text-align: justify;
  }
  &__consents-buttons {
    margin-top: $space-20;
    .e-site-button {
      + .e-site-button {
        margin-left: $space-20;
      }
    }
  }
}
@include tablet-landscape {
  .c-cookies {
    $c: &;
    .e-modal__content {
      width: grid-width(13);
      padding: grid-width(0.3) grid-width(0.5);
      &.horizontal {
        width: grid-width(20);
        display: flex;
        align-items: center;
        #{$c}__content {
          width: grid-width(13);
          margin-right: grid-width(1);
        }
        #{$c}__buttons {
          width: grid-width(6);
        }
      }
      &.vertical {
        width: grid-width(6);
      }
    }
    &__buttons {
      flex-direction: row;
      .e-site-button {
        width: calc((100% - 40px) / 3);
        + .e-site-button {
          margin-top: 0;
          margin-left: $space-20;
        }
      }
    }
  }
  .c-cookies-setting {
    .e-modal__content {
      width: grid-width(10);
      padding: 0 grid-width(0.5) grid-width(0.25) grid-width(0.5);
    }
    .e-modal__close {
      top: $space-16;
    }
  }
}
</style>

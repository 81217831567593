<template>
  <overall-settings v-bind="$props">
    <div class="c-add-detail" v-if="fields">
      <div class="c-add-detail__header">
        <div class="c-add-detail__back" @click="handleBack">
          <Icon class="c-add-detail__back__arrow" name="back" />
          BACK
        </div>
        <div class="c-add-detail__title">
          {{ fields?.title?.value }}
        </div>
      </div>
      <div class="c-add-detail__body">
        <div class="c-add-detail__package" v-for="item in packages" :key="item.packageName">
          <img :src="item.image" class="c-add-detail__package__img" alt="" />
          <div class="c-add-detail__package__content">
            <div class="c-add-detail__package__handle">
              {{ item.packageName }}
              <checkbox class="c-filter-menu__item__attr__check" :value="item?.checked" @change="(e, value) => handleChange(e, value, item)" />
            </div>
            <div class="c-add-detail__package__desc">{{ item.packageDesc }}</div>
            <div class="c-add-detail__package__price">{{ $formatMoney(item.price, item.currency) }}</div>
          </div>
        </div>
      </div>
      <div class="c-add-detail__footer">
        <stock-vehicle-price-nav
          v-if="priceNavParams"
          type="order"
          :params="priceNavParams"
          :priceOptions="priceOptions"
          :vehicleDetail="vehicleDetail"
          :page="page"
          :fields="fields"
          :model-title="fields?.headline?.value"
          :model-color="configuration.length > 0 && configuration[0] && configuration[0].value?.length > 0 ? configuration[0]?.value[0].label : null"
          :model-pay="fields?.pay?.value"
          :model-return-deposit="fields?.returnDeposit?.value"
          :model-agreement="fields?.agreement?.value"
          :model-vat="fields?.vat?.value"
          :model-total="fields?.total?.value"
        />
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from 'vue';
import api from '@/api';

export default {
  name: 'AddDetail',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup() {
    const {
      proxy: { $router, $route }
    } = getCurrentInstance();
    const { country, vin } = $route.query;
    const state = reactive({
      packages: [
        {
          id: 1,
          image: 'https://wlt-p-001.sitecorecontenthub.cloud/api/public/content/3e04b07b59284427a352e1af210a9e19?v=60daddd5',
          packageName: 'Addition A',
          packageDesc: 'A car that doesn’t just turn heads but turns conventional thinking on its head.',
          price: '10000',
          currency: 'EUR',
          checked: false
        },
        {
          id: 2,
          image: 'https://wlt-p-001.sitecorecontenthub.cloud/api/public/content/3e04b07b59284427a352e1af210a9e19?v=60daddd5',
          packageName: 'Addition A',
          packageDesc: 'A car that doesn’t just turn heads but turns conventional thinking on its head.',
          price: '10000',
          currency: 'EUR',
          checked: false
        },
        {
          id: 3,
          image: 'https://wlt-p-001.sitecorecontenthub.cloud/api/public/content/3e04b07b59284427a352e1af210a9e19?v=60daddd5',
          packageName: 'Addition A',
          packageDesc: 'A car that doesn’t just turn heads but turns conventional thinking on its head.',
          price: '10000',
          currency: 'EUR',
          checked: false
        },
        {
          id: 4,
          image: 'https://wlt-p-001.sitecorecontenthub.cloud/api/public/content/3e04b07b59284427a352e1af210a9e19?v=60daddd5',
          packageName: 'Addition A',
          packageDesc: 'A car that doesn’t just turn heads but turns conventional thinking on its head.',
          price: '10000',
          currency: 'EUR',
          checked: false
        }
      ],
      vehicleDetail: null,
      configuration: [],
      priceOptions: [],
      priceNavParams: null
    });
    const methods = {
      /**
       * 获取车辆详情
       */
      async getVehicleDetail() {
        const [res, ex] = await api.order.stockDetailByVin(null, {
          country: country,
          vin: vin
        });
        if (ex) {
          return false;
        }
        state.vehicleDetail = res;
        state.configuration = JSON.parse(res?.configurationDetails);
        const arr = res?.detailPrice.split(';');
        state.priceOptions = arr.map((item) => {
          const empty = item.split(':');
          const attr = [empty[0].replace(/\s.*/, ''), empty[0].replace(/\S+\s/, '')];
          return {
            label: attr[0],
            value: attr[1],
            price: empty[1]
          };
        });
        state.priceNavParams = {
          transfer_code: res?.transferCode,
          filter_id: res?.filterId,
          vin: res?.vin,
          vid: res?.vid
        };
      },
      /**
       * 返回上一页
       */
      handleBack() {
        $router.back();
      },
      handleChange(e, val, target) {
        state.packages.some((item) => {
          if (target.id === item.id) {
            item.checked = !item.checked;
            return true;
          }
        });
      }
    };
    onMounted(() => {
      methods.getVehicleDetail();
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-add-detail {
  $c: &;
  position: relative;

  &__header {
    margin: $space-50 grid-width(1.8) 0;
    padding-bottom: $space-32;
    border-bottom: 1px solid $grey-neutral;
    #{$c}__back {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;
      &__arrow {
        margin-right: $space-12;
        svg,
        path {
          height: 16px;
        }
      }
    }
    #{$c}__title {
      font-size: 32px;
      margin-top: $space-36;
    }
  }

  &__body {
    margin: $space-48 grid-width(1.8) 0;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: $space-24;
    grid-row-gap: $space-24;
    padding-bottom: $space-24;
  }

  &__package {
    border: 1px solid $grey-light;
    &__content {
      padding: $space-40 $space-32;
    }
    &__img {
      width: 100%;
    }
    &__handle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      line-height: 32px;
    }

    &__desc {
      font-size: 16px;
      line-height: 24px;
      color: $grey-next;
      margin-top: $space-16;
    }

    &__price {
      margin-top: $space-20;
      font-size: 16px;
      line-height: 32px;
    }
  }

  &__footer {
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 3;
  }
  .e-form-checkbox .e-form-checkbox__check {
    border-color: $grey-taubmans;
    background: transparent;
    .e-icon {
      svg,
      path {
        fill: $black;
      }
    }
  }
  .e-form-checkbox {
    &.checked {
      .e-form-checkbox__check {
        background: transparent;
        border-color: $black;
      }
    }
  }
  .e-form-checkbox {
    &.disabled {
      background: $grey-light;
      .e-form-checkbox__check {
        border-color: $grey-taubmans;
      }
    }
  }
}

@include mobile {
  .c-add-detail {
    $c: &;
    display: flex;
    flex-direction: column;
    &__overall {
      height: auto;
    }
    &__header {
      margin: $space-32 $space-24;
      border-bottom: 1px solid $grey-neutral;
    }

    &__body {
      display: block;
      margin: 0 $space-24;
    }

    &__package {
      margin-top: $space-32;
    }
  }
}
</style>

<template>
  <overall-settings v-bind="$props">
    <div class="c-emotional-text" :class="[themeClass, aniClass]" ref="rootEl">
      <jss-rich-text class="c-emotional-text__line1" :field="fields.line1" :tag="settingValue(fields.line1Type, 'div')" :id="line1Id" />
      <jss-rich-text class="c-emotional-text__line2" :field="fields.line1" :tag="settingValue(fields.line1Type, 'div')" :id="line2Id" />
    </div>
  </overall-settings>
</template>

<script>
import { computed, defineComponent, onMounted, onUnmounted, reactive, toRefs } from 'vue';
import { loadScrollMagic, settingValue, themeClass } from '@/utils/site-utils';
import gsap from 'gsap';
import { canUseDOM } from '@/utils/dom-utils';

/**
 * @typedef EmotionalTextFields
 * @property {SimpleField} line1
 * @property {GlobalSettingEntry} line1Type
 * @property {SimpleField} line2
 * @property {GlobalSettingEntry} line2Type
 * */
export default defineComponent({
  name: 'EmotionalText',
  props: {
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  setup(props) {
    let controller, scene, tl;
    const state = reactive({
      rootEl: null
    });
    const computes = {
      id: computed(() => `c-${props.rendering.uid}`),
      themeClass: computed(() => themeClass(props)),
      aniClass: computed(() => `${`ani-${settingValue(props?.fields?.animation, '1l2r')}`}`),
      line1Id: computed(() => `${computes.id.value}-line1`),
      line2Id: computed(() => `${computes.id.value}-line2`)
    };
    const methods = {
      settingValue,
      initScrollMagic() {
        const ScrollMagic = loadScrollMagic();
        controller = new ScrollMagic.Controller();
        const directionVal = settingValue(props?.fields?.animation, '1l2r');
        tl = gsap.timeline();
        tl.add(
          gsap.to(`#${computes.line1Id.value}`, {
            xPercent: directionVal === '1l2r' ? -50 : 50
          }),
          'first'
        ).add(
          gsap.to(`#${computes.line2Id.value}`, {
            xPercent: directionVal === '1l2r' ? 50 : -50
          }),
          'first'
        );
        scene = new ScrollMagic.Scene({
          triggerElement: state.rootEl,
          triggerHook: 'onEnter',
          duration: '100%'
        });
        scene.setTween(tl).addTo(controller);
      }
    };
    // if (canUseDOM()) {
    //   methods.initScrollMagic();
    // }
    onMounted(() => {
      methods.initScrollMagic();
    });
    onUnmounted(() => {
      scene.destroy(true);
      controller.destroy(true);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
});
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-emotional-text {
  $c: &;
  @include component-themes;
  overflow: hidden;
  &__line1 {
    @include h1;
    --offset: 100;
  }
  &__line2 {
    @include h1;
    --offset: 100;
  }
  &.ani-1l2r {
    #{$c}__line1 {
      transform: translateX(50%);
    }
    #{$c}__line2 {
      transform: translateX(-50%);
    }
  }
  &.ani-lr2l {
    #{$c}__line1 {
      transform: translateX(-50%);
    }
    #{$c}__line2 {
      transform: translateX(50%);
    }
  }
}
</style>

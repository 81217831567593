<template>
  <overall-settings v-bind="$props">
    <div class="c-closeups-dark-exterior" v-if="fields">
      <div class="c-closeups-dark-exterior-left">
        <BackgroundImage class="c-closeups-dark-exterior-first_image" v-if="fields.first_image?.value?.src" :image="fields.first_image" />
        <BackgroundImage class="c-closeups-dark-exterior-second_image" v-if="fields.third_image?.value?.src" :image="fields.third_image" />
        <AnimatedContent type="bottom-fade-in" :delay="100" class="c-closeups-dark-exterior-desc">
          <jss-rich-text :field="fields.second_description" tag="div" />
        </AnimatedContent>
      </div>
      <div class="c-closeups-dark-exterior-right">
        <AnimatedContent type="bottom-fade-in" :delay="100" class="c-closeups-dark-exterior-title">
          <jss-rich-text :field="fields.title" tag="div" />
          <jss-rich-text :field="fields.first_description" class="c-closeups-dark-exterior-text" tag="div" />
        </AnimatedContent>
        <BackgroundImage class="c-closeups-dark-exterior-third_image" v-if="fields.second_image?.value?.src" :image="fields.second_image" />
        <BackgroundImage class="c-closeups-dark-exterior-fourth_image" v-if="fields.fourth_image?.value?.src" :image="fields.fourth_image" />
      </div>
    </div>
  </overall-settings>
</template>

<script>
export default {
  name: 'CloseupsDarkExterior',
  props: {
    fields: {
      type: Object
    }
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/fontFamily.scss';
.c-closeups-dark-exterior {
  display: flex;
  width: 100vw;
  gap: 15px;
  flex-direction: column;
  padding: 0 15px;
  &-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    order: 2;
  }
  &-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    order: 1;
  }
  &-desc {
    width: 80%;
    padding: 30px 15px;
    flex-grow: 1;
  }
  &-title {
    width: 100%;
    padding: 60px;
    padding: 30px 15px;
    flex-grow: 1;
  }
  &-first_image {
    width: 100%;
    height: calc((100vw - 30px) * 9 / 16);
  }
  &-third_image {
    width: 75%;
    height: calc((75vw - 22.5px) * 11 / 17);
  }
  &-fourth_image {
    width: 75%;
    height: calc((75vw - 22.5px) * 11 / 17);
    align-self: flex-end;
  }
  &-second_image {
    width: 75%;
    height: calc((75vw - 22.5px) * 11 / 17);
    align-self: flex-end;
  }
  &-text {
    width: 75%;
    margin-top: 15px;
  }
  @include tablet-landscape {
    flex-direction: row;
    padding: 0;
    &-left {
      display: flex;
      flex-direction: column;
      width: 50%;
      align-items: flex-end;
      gap: 15px;
      order: 1;
    }
    &-right {
      display: flex;
      flex-direction: column;
      width: 50%;
      gap: 15px;
      order: 2;
      align-items: flex-start;
    }
    &-first_image {
      width: 100%;
      height: calc((50vw - 8px) * 9 / 16);
    }
    &-second_image {
      width: 75%;
      height: calc((37.5vw - 6px) * 11 / 17);
    }
    &-right {
      display: flex;
      flex-direction: column;
    }
    &-third_image {
      width: 75%;
      height: calc((37.5vw - 6px) * 11 / 17);
    }
    &-fourth_image {
      width: 75%;
      height: calc((37.5vw - 6px) * 11 / 17);
      align-self: flex-start;
    }
    &-desc {
      width: 75%;
      padding: 60px;
      flex-grow: 1;
    }
    &-title {
      width: 100%;
      padding: 60px;
      flex-grow: 1;
    }
    &-text {
      width: 75%;
      margin-top: 20px;
    }
  }
}
</style>

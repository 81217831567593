<template>
  <route-loading v-if="loading" />
  <layout v-else :route="appState.routeData" />
</template>

<script>
import { layoutServiceFactory } from '@/lib/layout-service-factory';
import { getCurrentInstance, onMounted, reactive, toRefs } from 'vue';
import Layout from './Layout';
import RouteLoading from './RouteLoading';

export default {
  name: 'not-found',
  components: {
    Layout,
    RouteLoading
  },
  props: {
    context: {
      type: Object
    }
  },
  setup(props, context) {
    const app = getCurrentInstance();
    const appState = app.appContext.config.globalProperties.$jss.store.state;
    const state = reactive({
      routeData: null,
      loading: true
    });
    onMounted(() => {
      const layoutServiceInstance = layoutServiceFactory.create();
      // get the route data for the new route
      layoutServiceInstance.fetchLayoutData('/404', props.context.language).then((routeData) => {
        if (routeData !== null && routeData.sitecore.route) {
          // Update the JSS store instance with the fetched data.
          // This will signal the RouteHandler to update/re-render, as well as any components
          // that are referencing the JSS store instance in their `data` object.
          app.appContext.config.globalProperties.$jss.store.setSitecoreData(routeData);
          state.loading = false;
        }
      });
    });
    return {
      ...toRefs(state),
      appState
    };
  }
};
</script>

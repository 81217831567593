<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
    <template v-slot:htmlAttrs="{ content }">{{ content }}</template>
    <template v-slot:bodyAttrs="{ content }">{{ content }}</template>
  </metainfo>
  <!-- Google Tag Manager (noscript) -->
  <noscript>
    <iframe :src="`https://www.googletagmanager.com/ns.html?id=${gtmUid?.value}`" height="0" width="0" style="display:none;visibility:hidden" />
  </noscript>
  <!-- End Google Tag Manager (noscript) -->
  <!--  <h1 style="display: none;visibility: hidden" v-html="$ifEmpty(route.fields?.pageTitle?.value, 'Lotus Cars Official Site')" />-->
  <div class="container" v-if="appStore.hasLoggedIn !== false || !route.fields.loginRequired.value">
    <placeholder name="jss-header" :rendering="route" :page="{ ...route }" />
    <placeholder name="jss-main" :rendering="route" :page="{ ...route }" />
    <placeholder name="jss-footer" :rendering="route" :page="{ ...route }" />
  </div>
  <content-loading class="layout-loading" :loading="showLoading" />
  <toast ref="toastRef" :rtl="rtl" />
  <notification ref="notificationRef" :rtl="rtl" />
</template>

<script>
import { defineComponent, getCurrentInstance, inject, onMounted, onUnmounted, onUpdated, provide, reactive, toRefs, watch, watchEffect } from 'vue';
import { getCurrentAlpha2Code, getGlobalConfigs, getMarket, isAppBrowser, loadAcsb, loadOneTrust, settingValue } from '@/utils/site-utils';
import { concatUrls, getBetterUrl, getQueryStrings, redirectToLogin, redirectToLogout } from '@/utils/uri-utils';
import { appendMeta, canUseDOM, getScrollbarWidth, removeModalClasses } from '@/utils/dom-utils';
import { isNullOrEmpty, isNullOrWhitespace } from '@/utils/obj-utils';
import { loadQtTracker, qtUtils } from '@/utils/ali-tracker-utils';
import { getQueryCookie } from '@/utils/cookies-util';
import { gtag, gtmPush, loadGTM } from '@/utils/gtm-utils';
import { equalString, ifEmpty } from '@/utils/string-utils';
import { formatMoney } from '@/utils/math-utils';
import { APP_CONST, NOTIFICATION_TYPES } from '@/utils/constants';
import { S_LANG, S_LOGIN_URL } from '@/utils/web-storage-keys';
import { webStorage } from '@/utils/web-storage';
import useAppStore from '@/store/appStore';
import { getSiteError } from '@/services/siteService';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useActiveMeta } from 'vue-meta';
import { merge, debounce } from 'lodash';
import api from '@/api';
import BUS_EVENTS from '@/utils/bus-events';
import CryptoJS from 'crypto-js';
import 'swiper/swiper.scss';
import 'video.js/dist/video-js.min.css';
import './styles/app.scss';
import utils from '@/utils';

export default defineComponent({
  name: 'Layout',
  props: {
    route: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    let checkLoginTimeout, checkOneTrustTimeout;
    const app = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const $bus = inject('$bus');
    /**@type AppStore*/
    const appStore = useAppStore();
    app.appContext.config.globalProperties.$formatInclMoney = (money, currency) => {
      return Number(money) > 0 ? formatMoney(money, currency) : t('Included').toLowerCase();
    };
    const [domain, defaultHreflang, areas, loginLink, rtl, unconditionalGTM, gtmUid] = getGlobalConfigs(
      props.route,
      'domain',
      'defaultHreflang',
      'areas',
      'loginLink',
      'rtl',
      'unconditionalGTM',
      'gtmUid'
    );
    const { hreflang } = props.route || {};
    const { noindex, nofollow, storeCode, disableCp, hreflangSet } = props.route?.fields || {};
    const { fullPath, path, params, query } = router.currentRoute.value ?? {};
    const { lang } = params ?? {};
    const { sc_mode, referrer } = query ?? {};
    const robotsMeta = [];
    noindex?.value && robotsMeta.push('noindex');
    nofollow?.value && robotsMeta.push('nofollow');
    const purePath = path.replace(new RegExp(`^/${lang}`), '').replace(/[\\/]$/, '');
    const defaultHreflangVal = defaultHreflang?.fields?.code?.value ?? 'en';
    let defaultHrefLang = {
      rel: 'alternate',
      hreflang: 'x-default',
      href: concatUrls(domain?.value, `/${defaultHreflangVal}`, purePath)
    };
    const hrefLangList = [defaultHrefLang];
    if (hreflang?.length > 0) {
      for (let hl of hreflang) {
        if (!equalString(hl, defaultHreflangVal)) {
          const hrefLangObj = {
            rel: 'alternate',
            hreflang: hl,
            href: concatUrls(domain?.value, `/${hl}`, purePath)
          };
          hrefLangList.push(hrefLangObj);
        }
      }
    } else if (hreflangSet?.length) {
      for (let hreflangData of hreflangSet) {
        const { code } = hreflangData?.fields || {};
        if (!equalString(code?.value, defaultHreflangVal)) {
          const hrefLangObj = {
            rel: 'alternate',
            hreflang: code?.value,
            href: concatUrls(domain?.value, `/${code?.value}`, purePath)
          };
          hrefLangList.push(hrefLangObj);
        }
      }
    } else if (areas?.length) {
      for (let area of areas) {
        for (let region of area.fields.regions) {
          if (!region.fields.languages) continue;
          for (let language of region.fields.languages) {
            const { code } = language?.fields || {};
            if (code.value) {
              if (!equalString(code?.value, defaultHreflangVal)) {
                const hrefLangObj = {
                  rel: 'alternate',
                  hreflang: code.value,
                  href: concatUrls(domain?.value, `/${code.value}`, purePath)
                };
                hrefLangList.push(hrefLangObj);
              }
            }
          }
        }
      }
    }
    const title = ifEmpty(props?.route?.fields?.pageTitle?.value, 'Lotus Cars Official Site');
    const ogTitle = ifEmpty(props?.route?.fields?.ogTitle?.value, title);
    const metas = {
      title: title,
      description: props?.route?.fields?.pageDescription?.value,
      htmlAttrs: {
        lang: props.route.itemLanguage === 'en' ? 'en-GB' : props.route.itemLanguage,
        prefix: 'og: https://ogp.me/ns#',
        itemtype: 'https://schema.org/Product'
      },
      meta: [
        { name: 'keywords', content: props?.route?.fields?.pageKeywords?.value },
        { name: 'apple-mobile-web-app-title', content: title },
        { property: 'og:type', content: 'article' },
        {
          property: 'og:locale',
          content: props.route.itemLanguage === 'en' ? 'en_US' : props.route.itemLanguage?.replace(/-/gi, '_')
        },
        {
          property: 'og:title',
          content: ogTitle
        },
        { property: 'og:description', content: props?.route?.fields?.pageDescription?.value },
        { property: 'og:image', content: props?.route?.fields?.ogImage?.value?.src },
        { property: 'og:image:width', content: props?.route?.fields?.ogImage?.value?.width ?? 144 },
        { property: 'og:image:height', content: props?.route?.fields?.ogImage?.value?.height ?? 144 },
        { property: 'og:image:type', content: props?.route?.fields?.ogImage?.value?.type ?? 'image/jpg' },
        { property: 'og:url', content: domain?.value ? concatUrls(domain?.value, fullPath) : '' },
        { property: 'twitter:domain', content: domain?.value ?? '' },
        { property: 'twitter:card', content: 'summary_large_image' },
        {
          property: 'twitter:title',
          content: ogTitle
        },
        { property: 'twitter:description', content: props?.route?.fields?.pageDescription?.value },
        { property: 'twitter:image', content: props?.route?.fields?.ogImage?.value?.src }
      ],
      link: [{ rel: 'canonical', href: concatUrls(domain?.value, fullPath) }]
    };
    if (robotsMeta.length > 0) {
      metas.meta.unshift({ name: 'robots', content: robotsMeta.join(',') });
    }
    if (hrefLangList.length > 0) {
      metas.link.push(...hrefLangList);
    }
    if (rtl?.value) {
      metas.htmlAttrs.class = 'rtl';
    }
    utils.sitecore.preloadAdaptiveMedias(props?.route, metas);
    const metaInfo = useActiveMeta();
    for (let metaName of Object.keys(metas)) {
      metaInfo[metaName] = metas[metaName];
    }
    const state = reactive({
      containerRef: null,
      toastRef: null,
      /**@type NotificationRef*/
      notificationRef: null,
      showLoading: false,
      gtmUid: gtmUid?.value
    });
    const checkHash = () => {
      const { hash } = global.location;
      if (isNullOrWhitespace(hash)) return;
      if (equalString(hash, '#region-modal')) {
        $bus.emit(BUS_EVENTS.OPEN_REGION_MODAL);
        return;
      }
      const hashVal = hash.slice(1);
      const anchor = document.getElementById(hashVal);
      if (!anchor) return;
      anchor.scrollIntoView();
    };
    const onResize = debounce(() => {
      const docWidth = document.documentElement.clientWidth || document.body.clientWidth;
      document.documentElement.style.fontSize = `${Math.floor((docWidth * 100) / 48) / 100}px`;
    }, 100);
    const onPopstate = debounce(() => {
      checkHash();
    }, 200);
    const pushPageData = () => {
      if (global.dataLayer.length > 0) {
        if (global.dataLayer[global.dataLayer.length - 1].event === 'page_data') return;
      }
      const { contentGroup, productName, productVariant } = props.route?.fields || {};
      let userId = null;
      let hashEmail = null;
      let hashPhone1 = null;
      let hashPhone2 = null;
      if (!isNullOrEmpty(appStore?.loginInfo?.lotusId)) {
        userId = appStore?.loginInfo?.lotusId;
      }
      if (!isNullOrEmpty(appStore?.loginInfo?.email)) {
        hashEmail = CryptoJS.SHA256(appStore?.loginInfo?.email?.trim().toLowerCase()).toString();
      }
      if (!isNullOrEmpty(appStore?.loginInfo?.mobileAreaCode) && !isNullOrEmpty(appStore?.loginInfo?.phone)) {
        hashPhone1 = CryptoJS.SHA256(`${appStore?.loginInfo?.mobileAreaCode?.trim()}${appStore?.loginInfo?.phone?.trim()}`).toString();
        hashPhone2 = CryptoJS.SHA256(`+${appStore?.loginInfo?.mobileAreaCode?.trim()}${appStore?.loginInfo?.phone?.trim()}`).toString();
      }
      const data = {
        event: 'page_data',
        platform_name: 'website',
        content_group: settingValue(contentGroup),
        page_country: getCurrentAlpha2Code(),
        page_language: props.route.itemLanguage.toLowerCase(),
        user_data: {
          user_id: userId,
          user_emid: hashEmail,
          user_phid_f1: hashPhone1,
          user_phid_f2: hashPhone2
        }
      };
      if (!isNullOrWhitespace(productName?.value)) {
        data.product_name = productName?.value;
      }
      if (!isNullOrWhitespace(productVariant?.value)) {
        data.product_variant = productVariant?.value;
      }
      gtmPush(data);
    };
    const checkLoginState = async () => {
      await watchLoginState();
    };
    const watchLoginState = async () => {
      const [token] = getQueryStrings('access_token');
      if (!isNullOrEmpty(token)) return;
      const { loginRequired } = props.route?.fields || {};
      if (!appStore.hasLoggedIn) {
        if (loginRequired?.value) {
          redirectToLogin(props.route, router);
        }
        return;
      }
      const [res, ex] = await api.cidp.user.info();
      if (ex) {
        await appMethods.justLogout();
        if (loginRequired?.value) {
          redirectToLogin(props.route, router);
        } else {
          $bus.emit(BUS_EVENTS.TOKEN_EXPIRED);
        }
        return;
      }
      if (res) {
        if (Object.keys(res).includes('token')) {
          delete res.token;
        }
        appStore.updateLoginInfo({
          ...res,
          checkTime: new Date().getTime()
        });
      }
      checkLoginTimeout = setTimeout(() => {
        watchLoginState();
      }, 5 * 60 * 1000);
    };
    const toastProvider = {
      async show(options) {
        await state.toastRef.open(options);
      },
      async showEx(ex, opts = null) {
        const msg = await getSiteError(ex);
        await toastProvider.showError(msg, opts);
      },
      // async showError(msg, opts = null) {
      //   if (!state.toastRef) return;
      //   await state.toastRef.open(
      //     merge(
      //       {
      //         message: msg,
      //         centered: true,
      //         closable: true,
      //         duration: 3200,
      //         showOk: true,
      //         okText: t('Go back').toUpperCase()
      //       },
      //       opts
      //     )
      //   );
      // },
      showError(message, sticky = false, link = null) {
        state.notificationRef.sendMessage({ message, sticky, type: NOTIFICATION_TYPES.error });
      },
      async showExByCode(code) {
        await toastProvider.showEx({ code });
      },
      async showSuccess(msg, opts = null) {
        // await notificationProvider.show(msg);
        await toastProvider.show(
          merge(
            {
              message: msg,
              icon: 'ok-bucket',
              duration: 3200,
              closable: true
            },
            opts
          )
        );
      },
      async confirm(msg, { okText = t('Ok'), cancelText = t('Cancel'), centered = true, middle = false }) {
        return new Promise((resolve) => {
          state.toastRef.open({
            message: msg,
            centered,
            middle,
            closable: true,
            showOk: true,
            showCancel: true,
            okText: okText,
            cancelText: cancelText,
            onOk() {
              resolve(true);
            },
            onCancel() {
              resolve(false);
            }
          });
        });
      }
    };
    const loadingProvider = {
      show() {
        state.showLoading = true;
        document.documentElement.classList.add('loading-open');
      },
      hide() {
        state.showLoading = false;
        document.documentElement.classList.remove('loading-open');
      }
    };
    const notificationProvider = {
      show(message, type = NOTIFICATION_TYPES.success, sticky = false, link = null) {
        state.notificationRef.sendMessage({ message, sticky, type });
      },
      showError(message, sticky = false, link = null) {
        state.notificationRef.sendMessage({ message, sticky, type: NOTIFICATION_TYPES.error });
      },
      async showEx(ex, sticky = false, link = null) {
        const msg = await getSiteError(ex);
        await notificationProvider.showError(msg, sticky);
      },
      async showExByCode(code, sticky = false, link = null) {
        await notificationProvider.showEx({ code }, sticky);
      },
      showSuccess(message, sticky = false, link = null) {
        state.notificationRef.sendMessage({ message, sticky, type: NOTIFICATION_TYPES.success });
      },
      showNormal(message, sticky = false, link = null) {
        state.notificationRef.sendMessage({ message, sticky, type: NOTIFICATION_TYPES.normal });
      },
      showFail(message, sticky = false, link = null) {
        state.notificationRef.sendMessage({ message, sticky, type: NOTIFICATION_TYPES.fail });
      },
      showWarn(message, sticky = false, link = null) {
        state.notificationRef.sendMessage({ message, sticky, type: NOTIFICATION_TYPES.warn });
      }
    };
    const appMethods = {
      async justLogout() {
        await api.cidp.signOut(null, {
          registerSource: '202'
        });
        await appStore.setLoginInfo(null);
      },
      async logout() {
        loadingProvider.show();
        await api.cidp.signOut(null, {
          registerSource: '202'
        });
        await appStore.setLoginInfo(null);
        redirectToLogout(props.route);
      },
      async logoutToLogin() {
        loadingProvider.show();
        await api.cidp.signOut(null, {
          registerSource: '202'
        });
        await appStore.setLoginInfo(null);
        redirectToLogin(props.route);
      },
      getGlobalConfigs(...args) {
        return getGlobalConfigs(props.route, ...args);
      }
    };
    provide('appStore', appStore);
    provide('toast', toastProvider);
    provide('loading', loadingProvider);
    provide('appMethods', appMethods);
    provide('notification', notificationProvider);
    provide('rtl', rtl?.value);
    const setOgUrl = () => {
      appendMeta(
        {
          property: 'og:url',
          content: window.location.href
        },
        'property',
        "meta[property='og:description']"
      );
    };
    const setPageTitle = () => {
      document.title = props?.route?.fields?.pageTitle?.value || 'Lotus Cars Official Site';
    };
    const startTracking = () => {
      pushPageData();
      loadGTM(props.route);
      loadQtTracker(props.route);
    };
    const onTrustCheck = () => {
      if (checkOneTrustTimeout) clearTimeout(checkOneTrustTimeout);
      const optanonConsent = getQueryCookie('OptanonConsent');
      if (/C0002:1/gi.test(optanonConsent?.groups)) {
        startTracking();
      } else {
        checkOneTrustTimeout = setTimeout(onTrustCheck, 5000);
      }
    };

    watch(
      () => props.route,
      (route) => {
        if (canUseDOM()) {
          webStorage.set(S_LANG, route.itemLanguage);
          setTimeout(() => {
            setPageTitle();
          }, 100);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    const addGoogleMarkup = async (code) => {
      const [res, ex] = await api.store.detail({ storeCode: code });
      if (ex) {
        await toastProvider.showEx(ex);
      } else {
        let temp = {
          '@context': 'https://schema.org',
          '@type': 'AutomotiveBusiness',
          name: res.storeName,
          image: res.storeImgDtoList[0]?.externalUrl,
          '@id': window.location.href,
          url: res.extra?.officialWebsiteUrl,
          telephone: res.serviceCall,
          address: {
            '@type': 'PostalAddress',
            streetAddress: res.addressDetail,
            addressLocality: res.countryName,
            postalCode: res.extra?.storeZipCode,
            addressCountry: res.country2Code
          },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: res.latitude,
            longitude: res.longitude
          },
          openingHoursSpecification: [
            {
              '@type': 'OpeningHoursSpecification',
              dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
              opens: res.workStartTime,
              closes: res.workEndTime
            }
          ]
        };
        const script = document.createElement('script');
        script.setAttribute('type', 'application/ld+json');
        script.textContent = JSON.stringify(temp);
        document.head.appendChild(script);
      }
    };
    if (storeCode && storeCode.value) {
      addGoogleMarkup(storeCode.value);
    }
    APP_CONST.lang = props.route?.itemLanguage;
    APP_CONST.market = getMarket(props.route);
    if (canUseDOM()) {
      const currentAlpha2Code = getCurrentAlpha2Code();
      const loginHref = getBetterUrl(loginLink?.value?.href, props.route.itemLanguage, true);
      webStorage.set(S_LOGIN_URL, loginHref);
      gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied'
      });
      if (unconditionalGTM?.value || disableCp?.value || equalString(currentAlpha2Code, 'CA') || equalString(currentAlpha2Code, 'US')) {
        startTracking();
      }
    }
    onMounted(() => {
      APP_CONST.lang = props.route?.itemLanguage;
      APP_CONST.market = getMarket(props.route);
      removeModalClasses('modal-open', 'menu-open', 'loading-open', 'market-detector-open', 'price-breakdown-open');
      setTimeout(() => {
        setPageTitle();
      }, 100);
      setOgUrl();
      checkLoginState().catch();

      const currentAlpha2Code = getCurrentAlpha2Code();
      if (!equalString(currentAlpha2Code, 'CA') && !equalString(currentAlpha2Code, 'US') && !equalString(sc_mode, 'edit') && !equalString(referrer, 'app') && !isAppBrowser() && !disableCp?.value) {
        loadOneTrust(props.route).catch();
        if (!unconditionalGTM?.value) {
          onTrustCheck();
        }
      }
      loadAcsb(props.route).catch();
      const scrollBarWidth = getScrollbarWidth();
      document.documentElement.style.setProperty('--scrollbar', `${scrollBarWidth}px`);
      document.documentElement.dataset.scrollbarWidth = scrollBarWidth;
      checkHash();
      window.addEventListener('resize', onResize, { passive: false });
      window.addEventListener('popstate', onPopstate, { passive: false });
      onResize();
      watchEffect(() => {
        const { qtPageName } = props.route?.fields ?? {};
        if (!isNullOrWhitespace(qtPageName?.value)) {
          qtUtils.trackPv(qtPageName.value);
        }
      });
    });
    onUnmounted(() => {
      removeModalClasses('modal-open', 'menu-open', 'loading-open', 'market-detector-open', 'price-breakdown-open', 'reservation-summary-open');
      if (checkLoginTimeout) {
        clearTimeout(checkLoginTimeout);
      }
      if (checkOneTrustTimeout) {
        clearInterval(checkOneTrustTimeout);
      }
      window.removeEventListener('resize', onResize);
      window.removeEventListener('popstate', onPopstate);
    });
    onUpdated(
      () => {
        setOgUrl();
      },
      () => props.route
    );
    return {
      ...toRefs(state),
      appStore,
      rtl: rtl?.value ?? false
    };
  }
});
</script>

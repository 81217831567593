<template>
  <div class="c-error-page">
    <adaptive-media class="c-error-page__media" :field="fields.media">
      <div class="c-error-page__content">
        <animated-content :turnOff="false" type="skew-in">
          <heading class="c-error-page__title" :field="fields.title" :type="fields.titleType" rich />
        </animated-content>
        <animated-content :turnOff="false" type="fade-in" :delay="1000">
          <jss-rich-text class="c-error-page__body" :field="fields.body" />
        </animated-content>
        <animated-content :turnOff="false" type="bottom-fade-in" :delay="1500">
          <site-button class="c-error-page__btn" v-bind="fields.button" />
        </animated-content>
      </div>
    </adaptive-media>
  </div>
</template>

<script>
/**
 * @typedef ErrorContentFields
 * @property {DropLinkField<AdaptiveMedia>} media
 * */
export default {
  name: 'ErrorContent',
  props: {
    fields: {
      type: Object
    }
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-error-page {
  color: $white;
  height: 100vh;
  &__media {
    width: 100%;
  }
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 grid-width-m(2);
  }
  &__body {
    margin-top: $space-20;
  }
  &__btn {
    margin-top: $space-40;
  }
  @include tablet-landscape {
    &__content {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 0 grid-width(2);
    }
    &__body {
      margin-top: $space-40;
    }
    &__btn {
      margin-top: $space-60;
    }
  }
}
</style>

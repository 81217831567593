import { getQueryStrings } from '@/utils/uri-utils';
import { isArray, isNullOrEmpty } from '@/utils/obj-utils';
import { APP_CONST } from '@/utils/constants';
import { merge } from 'lodash';
import { getDeviceInfo } from '@/utils/dom-utils';
import { equalString } from '@/utils/string-utils';

export const crmHooks = {
  beforeBuildOptions(method, url, params, data) {
    const [sourceType, leadSubSource, leadFormName, vehicleModelOfInterest] = getQueryStrings('utm_source', 'utm_medium', 'utm_campaign', 'utm_content');
    const checkFields = { sourceType, leadSubSource, leadFormName, vehicleModelOfInterest };
    const keys = Object.keys(checkFields);
    if (equalString(sourceType, 'QR-Code')) {
      for (let key of keys) {
        const checkVal = checkFields[key];
        if (isArray(checkVal) && checkVal.length > 0) {
          data[key] = checkVal[0];
        } else if (!isNullOrEmpty(checkVal)) {
          data[key] = checkVal;
        }
      }
    }
  },
  afterBuildOptions(options) {
    const { lang } = APP_CONST;
    const { data } = options;
    const checkFields = ['sourceType', 'leadSubSource', 'leadFormName', 'vehicleModelOfInterest'];
    merge(options, {
      data: {
        language: lang
      }
    });
    for (let cf of checkFields) {
      const fieldVal = data[cf];
      if (isArray(fieldVal)) {
        data[cf] = fieldVal.length > 0 && !isNullOrEmpty(fieldVal[0]) ? fieldVal[0] : cf === 'sourceType' ? 'Official Website' : '';
      }
    }
  }
};
export const loginHooks = {
  afterBuildOptions(options) {
    const deviceInfo = getDeviceInfo();
    merge(options, {
      headers: {
        deviceName: deviceInfo.browser.name,
        model: deviceInfo.os.name,
        systemVersion: deviceInfo.os.version
      }
    });
  }
};

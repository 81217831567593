<template>
  <div class="c-breadcrumb">
    <ul class="c-breadcrumb__navs">
      <li v-for="(nav, index) in navs" :key="index">
        <site-link :link="{ value: { href: nav.path, linktype: 'internal', text: nav.name } }" data-event_name="breadcrumb_click" />
        <icon name="right" v-if="index < navs.length - 1" />
      </li>
    </ul>
    <div class="c-breadcrumb__back-top" @click="backToTop">
      <jss-text class="c-breadcrumb__back-title" :field="fields.backToTopTitle" tag="div" />
      <icon :field="fields.icon" />
    </div>
  </div>
</template>

<script>
/**
 * @typedef BreadcrumbFields
 * @property {SimpleField} backToTopTitle
 * @property {IconField} icon
 * */
import { toRefs, reactive, onMounted } from 'vue';
import api from '@/api';
export default {
  name: 'Breadcrumb',
  props: {
    /**@type BreadcrumbFields*/
    fields: {
      type: Object
    },
    /**@type SitePage*/
    page: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      /**@type Array<BreadcrumbItem>*/
      navs: [
        {
          name: 'Home',
          path: '/'
        },
        {
          name: 'Home',
          path: '/'
        }
      ]
    });
    const methods = {
      backToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };
    onMounted(async () => {
      console.log('props.page', props.page);
      const [res] = await api.web.breadcrumb({
        id: props.page.itemId
      });
      state.navs = res ?? [];
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-breadcrumb {
  background: #f2f1f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $space-16 grid-width-m(1);
  @include h7;
  &__navs {
    flex-grow: 1;
    > li {
      display: inline-flex;
      .e-icon {
        position: relative;
        top: 5px;
        margin-left: $space-4;
        margin-right: $space-4;
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  &__back-top {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__back-title {
    display: none;
  }
  @include tablet-landscape {
    padding: $space-16 grid-width(1);
    &__back-top {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    &__back-title {
      display: block;
      white-space: nowrap;
    }
  }
}
</style>

import { bff, cms, crm, lts, ltsP, mhp, r, gm, cw } from '@/api/api-utils';
import { crmHooks, loginHooks } from '@/api/api-option-hooks';
const api = {
  web: {
    ipDetect: r('GET sitecore/cmsapi/currentLanguages'),
    item: r('GET sitecore/item/get'),
    breadcrumb: r('GET sitecore/item/breadrumbs')
  },
  store: {
    query: lts('GET api/sale/store/customer/pageQueryStore'),
    detail: lts(`GET api/sale/store/customer/getByCode`),
    countryList: lts('GET api/sale/store/customer/getStoreCountryInfo')
  },
  zipcode: {
    query: lts('GET api/sale/store/customer/getZipCodesBy')
  },
  cidp: {
    v2: {
      captcha: {
        get: bff('POST cidp/getCaptchaV2'),
        getAuth: bff('POST cidp/getAuthCaptchaV2'),
        getBind: bff('POST cidp/getCaptchaByBindV2'),
        checkBind: bff('POST cidp/checkLoggedCaptchaV2')
      }
    },
    v3: {
      captcha: {
        get: bff('POST cidp/getCaptchaV3')
      }
    },
    captcha: {
      get: bff('POST cidp/getCaptcha'),
      check: bff('POST cidp/checkCaptcha'),
      getAuth: bff('POST cidp/getAuthCaptcha'),
      checkAuth: bff('POST cidp/checkAuthCaptcha'),
      emailBind: {
        get: bff('POST cidp/getCaptchaByBind'),
        check: bff('POST cidp/checkLoggedCaptcha')
      }
    },
    phoneRegisterLogin: bff('POST cidp/phoneRegisterLogin'),
    loginByPhoneCaptcha: bff('POST cidp/loginByPhoneCaptcha'),
    login: bff('POST cidp/login', false, loginHooks),
    bindable: bff('POST cidp/validateLoginType'),
    tokenLogin: bff('POST cidp/tokenLogin'),
    getSecretByType: bff('POST cidp/getSecretByType'),
    updatePassword: bff('POST cidp/updatePassword'),
    completeUserInfo: bff('POST cidp/completeUserInfo'),
    signOut: bff('POST cidp/logout'),
    email: {
      validate: bff('POST cidp/validateEmailRegister'),
      register: bff('POST cidp/globalWebSiteEmailRegister')
    },
    user: {
      info: bff('GET cidp/userInfo'),
      update: bff('POST cidp/updateUserInfo')
    },
    bto: {
      mobileRegisterLogin: bff('POST cidp/phoneRegisterLoginNew'),
      emailRegisterLogin: bff('POST cidp/emailRegisterLogin')
    },
    updateByEmail: bff('POST cidp/updateByEmail'),
    updateByPhone: bff('POST cidp/updateByPhone'),
    details: {
      updatePhoneOrEmail: bff('POST cidp/updatePhoneOrEmail'),
      updateName: bff('POST cidp/update')
    },
    getControlCars: bff('GET cidp/getControlCars'),
    getControlCarsV3: bff('GET cidp/getControlCarsV3')
  },
  magicLink: {
    get: bff('GET cidp/getMagicLinkInfo'),
    login: bff('POST cidp/loginByMagicKey')
  },
  order: {
    submit: bff('POST order/orderSubmit'),
    intactSubmit: bff('POST order/intactSubmit'),
    intactDetail: bff('POST order/intactDetail'),
    detail: bff('POST order/orderDetail'),
    intactList: bff('POST order/intactList'),
    fullPay: bff('POST order/fullpay'),
    intactPay: bff('POST order/intactPay'),
    getPayInfo: bff('POST order/intactPayV1'),
    createContract: bff('POST order/createContract'),
    signShortUrl: bff('POST order/signShortUrl'),
    stockVehiclePages: bff('POST order/stockVehiclePages'), // 车辆库存列表
    stockDetailByVin: bff('POST order/stockDetailByVin'), // 库存车辆详情
    filterListLd: bff('POST order/filterListLd'), // 库存车过滤树
    getFilterConfiguration: bff('POST order/getFilterConfiguration'), // 库存车过滤树
    getFilterWeight: bff('POST order/filterWeight'),
    getConfig: bff('POST order/findOrderConfigList'),
    dictionary: bff('POST order/querySysDictValue'),
    getProvinceList: bff('POST order/getCountryState'),
    intactDetailByKey: bff('POST order/intactDetailByKey'),
    intactUpdateMessage: bff('POST order/intactUpdateMessage'),
    benefits: {
      get: bff('POST protocol/selectByBiz')
    },
    rule: {
      query: bff('POST order/getOrderRuleV2')
    }
  },
  reservation: {
    submit: bff('POST order/orderSubmitV2'),
    createOld: bff('POST order/orderSubmitBase'),
    create: bff('POST order/orderSubmitBaseV2'),
    prePay: bff('POST order/orderPay')
  },
  configuration: {
    get: bff('POST order/filterConfiguration'),
    priceDetail: bff('POST order/foreignDetail'),
    getPrice: lts('POST api/price/itemPriceInfo/foreignPage')
  },
  cms: {
    carPrice: cms('POST cms/getCarsPrice')
  },
  crm: {
    newsletter: {
      subscribe: crm('POST crm/newsletterSignup', false, crmHooks)
    },
    contactus: crm('POST crm/contactUs'),
    getArticles: crm('POST crm/getArticles'),
    getCategories: crm('POST crm/getCategories'),
    lead: {
      submit: crm('POST crm/lead')
    },
    fleet: crm('POST crm/fleetLead'),
    opp: crm('POST crm/opportunity'),
    testDriveReservation: crm('POST crm/testDriveReservation')
  },
  testDrive: {
    reservation: lts('POST api/saleForce/services/apexrest/createLead')
  },
  consent: {
    versions: bff('POST protocol/getVersionList'),
    all: bff('POST protocol/getAgreementInfo'),
    get: bff('POST protocol/getProtocolPDF'),
    bind: bff('POST crm/syncConsent'),
    getPDF: bff('POST protocol/getAgreementPdf')
  },
  meta: {
    countries: bff('POST protocol/getCountry'),
    states: bff('POST protocol/getState')
  },
  user: {
    loginByEmail: bff('POST cidp/emailRegisterLogin'),
    loginByPhone: bff('POST cidp/')
  },
  mhp: {
    product: {
      detail: mhp('GET products/products/{series}/details')
    }
  },
  benefits: {
    get: bff('POST protocol/detailMore'),
    list: lts('POST api/rights/rights/user/getRightsPackageDetail')
  },
  finance: {
    getAssetInfo: lts('POST api/finance/prejudication/listAssetId'),
    apply: lts('POST api/finance/prejudication/overseasSubmitApplicationInfo', true),
    query: lts('GET api/finance/prejudication/overseasApplicationByOrderId?orderId={orderId}', true)
  },
  price: {
    priceInfo: ltsP('POST itemPriceInfo/foreignPage')
  },
  stock: {
    recommending: lts('POST otd/server/stock/vehicle/stockMatch'),
    models: bff('POST order/stockGetCarModel'),
    filter: bff('POST order/stockGetConfiguration')
  },
  cc: {
    power: lts('GET api/lsc/lamFilter/efficiency')
  },
  map: {
    geocode: gm('GET geocode/json'),
    autoComplete: gm('GET place/autocomplete/json')
  },
  shop: {
    orderDetails: lts('GET api/front/mall/order/detail?orderCode={orderId}'),
    getGoodsBySkuList: lts('GET api/front/mall/goods/listByIds4Cart'),
    getGoodsList: lts('GET api/front/mall/goods/category/queryFullInfoByCode?channel=3'),
    getRecommendedList: lts('GET api/front/mall/goods/spuRecommendedList'),
    getListSpuBanner: lts('POST api/front/mall/goods/listSpuBanner', true),
    getGoodsSpuList: lts('GET api/front/mall/goods/v2/getGoodsSpuList?channel=3'),
    getGoodsSkuList: lts('GET api/front/mall/goods/v2/getGoodsSkuList'),
    getGoodsDetails: lts('GET api/front/mall/goods/goodsDetails'),
    getGoodCode: lts('GET api/front/mall/goods/category/queryByCode'),
    getGoodDetail: lts('GET api/front/mall/goods/goodsDetails'),
    addAddress: lts('POST cidpsso/receiveAddress/v1/add', true),
    addShoppingAddress: lts('POST api/front/mall/receivingAddress/add', true),
    getShoppingAddress: lts('GET api/front/mall/receivingAddress/pageQueryByUser', true),
    creatOrder: lts('POST api/front/mall/order/create', true),
    cancelOrder: lts('POST api/front/mall/order/cancel', true),
    refundByCustomer: lts('POST api/front/mall/refundOrder/refundByCustomer', true),
    payMulti: lts('POST api/front/mall/order/payMulti', true),
    saveInvoice: lts('POST api/front/mall/order/saveInvoice', true),
    getOrderList: lts('GET api/front/mall/order/list'),
    invoiceDetails: lts('GET api/front/mall/order/invoiceDetail?orderCode={orderId}', true),
    orderSummery: lts('POST api/price/priceFormula/foreignDetail', true),
    preCreate: lts('POST api/front/mall/order/preCreate', true),
    getRefundDetailByCustomer: lts('GET api/front/mall/refundOrder/getRefundDetailByCustomer?refundOrderCode={refundOrderCode}', true),
    getRulesList: lts('GET api/front/order/rule/getRulesList?orderRuleId={orderRuleId}', true),
    cancelRefund: lts('POST api/front/mall/refundOrder/cancelRefund', true),
    updateExpressByCustomer: lts('POST api/front/mall/refundOrder/updateExpressByCustomer', true),
    getExpress: lts('GET api/front/mall/order/express?expressCode={expressCode}', true),
    getRefundAddress: lts('POST api/front/mall/refundOrder/getRefundAddress', true),
    confirm: lts('POST api/front/mall/order/confirm', true),
    getSubscriptionInfo: bff('POST cidp/getSubscriptionInfo', true),
    category: {
      list: lts('GET api/front/mall/goods/getFrontCategory'),
      listWithCount: lts('GET api/front/mall/promotion/getFrontCategoryList'),
      queryByCode: lts('GET api/front/mall/goods/category/queryByCode')
    },
    cart: {
      query: lts('POST api/front/mall/shoppingCart/query'),
      add: lts('POST api/front/mall/shoppingCart/add'),
      delete: lts('POST api/front/mall/shoppingCart/delete'),
      update: lts('POST api/front/mall/shoppingCart/update'),
      getDetails: lts('GET api/front/mall/goods/listByIds4Cart')
    }
  },
  usedCar: {
    filter: bff('POST usedCar/getUsedCarFilter'),
    list: bff('POST usedCar/getUsedCarList'),
    detail: bff('POST usedCar/getUsedCarDetail')
  },
  lcms: {
    crm: {
      newsletter: {
        subscribe: lts('POST api/salesAppBff/customer/crm/retention/web/create', false, crmHooks)
      },
      cptc: {
        create: lts('POST api/saleForce/services/apexrest/createCPTC')
      }
    },
    order: {
      getPayInfo: lts('GET sales-order/v2.1/balanceOrderPay')
    }
  },
  codeWaver: {
    generateProposalOld: cw('POST workflows/{workflowId}/triggers/manual/paths/invoke'),
    generateProposal: lts('POST api/biz/cal/financeProposal')
  },
  vehicleConfig: {
    list: bff('POST order/vehicleConfigList'),
    edit: bff('POST order/vehicleConfigEdit'),
    save: bff('POST order/vehicleConfigSave'),
    remove: bff('POST order/vehicleConfigDelete')
  },
  aftersale: {
    queryCar: lts('POST api/aftersale/OverSeaCarCustomerCommonQuery')
  },
  oneForm: {
    createLead: lts('POST api/saleForce/services/apexrest/createLead')
  }
};
export default api;

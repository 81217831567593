<template>
  <OverallSettings v-bind="$props">
    <div class="c-content-cards" v-if="fields">
      <div class="c-content-cards__content">
        <div class="c-content-cards__principle-items">
          <PrincipleItem v-for="(item, index) in fields.principles" :key="item.id" v-bind="item" :index="index" />
        </div>
        <div class="c-content-cards__download" v-if="fields.downloads?.length">
          <div class="c-content-cards__download-items" ref="swiperEl">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(items, index) in downloadItemGroup" :key="index">
                <DownloadItem v-for="item in items" :key="item.id" v-bind="item" />
              </div>
            </div>
          </div>
          <div class="c-content-cards__pagination" v-if="downloadItemGroup.length > 1">
            <Icon :svg="fields.prevIcon?.value.svgCode" class="c-content-cards__pagination-left" size="small" ref="prevEl" />
            <div class="c-content-cards__pagination-item" ref="paginationEl" />
            <Icon :svg="fields.nextIcon?.value.svgCode" class="c-content-cards__pagination-right" size="small" ref="nextEl" />
          </div>
          <JssRichText :field="fields.body" tag="div" class="c-content-cards__download-desc" />
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { reactive, nextTick, onMounted, onUnmounted, computed, toRefs } from 'vue';
import Swiper from 'swiper';
export default {
  name: 'ContentCards',
  props: {
    fields: {
      type: Object
    }
  },

  setup(props, context) {
    let swiper = null;
    const state = reactive({
      /**@type {HTMLElement}*/
      swiperEl: null,
      /**@type {Icon|null}*/
      prevEl: null,
      /**@type {Icon|null}*/
      nextEl: null,
      /**@type {HTMLElement}*/
      paginationEl: null
    });
    const downloadItemGroup = computed(() => {
      let tmp = [];
      for (let i = 0; i < props.fields.downloads.length; i += 3) {
        tmp.push(props.fields.downloads.slice(i, i + 3));
      }
      return tmp;
    });
    const buildSwiper = () => {
      if (swiper) {
        swiper.destroy(true, true);
      }
      swiper = new Swiper(state.swiperEl, {
        slidesPerView: 'auto',
        pagination: {
          el: state.paginationEl,
          bulletElement: 'div',
          clickable: true
        },
        navigation: {
          prevEl: state.prevEl,
          nextEl: state.nextEl
        }
      });
    };
    onMounted(() => {
      window.addEventListener('resize', buildSwiper);
      nextTick(() => {
        buildSwiper();
      });
    });
    onUnmounted(() => {
      swiper && swiper.destroy(true, true);
      window.removeEventListener('resize', buildSwiper);
    });
    return {
      ...toRefs(state),
      downloadItemGroup
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';

.c-content-cards {
  @include component-overall-settings;
  @include component-themes;
  @include grid-container;
  color: $white;
  &__content {
    @include grid-block(2, 22);
    @include grid-container;
    justify-items: center;
  }
  &__principle-items {
    @include grid-block(1, 25);
    max-width: 400px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .s-principle-item {
      margin: 5px;
      &:nth-child(-n + 3) {
        margin-top: 0;
      }
    }
  }
  &__download {
    @include grid-block(2, 25);
    overflow: hidden;
    &-items {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      .swiper-wrapper {
        .swiper-slide {
          opacity: 0.9;
          &-active {
            opacity: 1;
          }
        }
      }
    }
    &-desc {
      display: flex;
      margin-top: $space-40;
    }
  }
  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: $space-30;
    padding-bottom: $space-30;
    &-left {
      transition: all 0.2s cubic-bezier(0.1, 0.1, 0.1, 1);
      transform: scale(1);
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
    &-right {
      transition: all 0.2s cubic-bezier(0.1, 0.1, 0.1, 1);
      transform: scale(1);
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
    &-item {
      display: flex;
      padding: 0 $space-12;
      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: $grey-neutral;
        cursor: pointer;
        &-active {
          background: $yellow;
        }
        + .swiper-pagination-bullet {
          margin-left: $space-12;
        }
      }
    }
  }
  @include tablet-landscape {
    &__principle-items {
      @include grid-block(2, 8);
      align-content: flex-start;
    }
    &__pagination {
      @include grid-block(18, 4, 1, 1);
      padding: 0;
    }
    &__download {
      @include grid-block(11, 16);
      @include grid-container;
      &-items {
        @include grid-block(1, 24);
      }
      &-desc {
        @include grid-block(1, 24);
      }
    }
  }
}
</style>

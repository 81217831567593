<template>
  <OverallSettings v-bind="$props">
    <div class="c-cta-content" v-if="fields">
      <div class="c-cta-content-media">
        <AdaptiveMedia :field="fields.media" :isBackground="false" />
      </div>
      <div class="c-cta-content-main">
        <JssRichText class="c-cta-content-main-title" :field="fields.titleGroup" />
        <SiteButton class="c-cta-content-main-title-btn" v-bind="fields.titleButton" />
        <JssRichText class="c-cta-content-main-body" :field="fields.bodyGroup" />
        <SiteButton class="c-cta-content-main-body-btn" v-bind="fields.bodyButton" />
        <JssRichText class="c-cta-content-main-des" :field="fields.description" />
      </div>
    </div>
  </OverallSettings>
</template>
<script>
export default {
  name: 'IconContent',
  props: {
    fields: {
      type: Object
    }
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-cta-content {
  @include grid-container;
  &-media {
    @include grid-block(1, 12, 1);
    img {
      width: 100%;
      height: auto;
    }
  }
  &-main {
    @include grid-block(2, 10, 2);
    padding: 24px 0 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > div,
    button {
      &:first-child {
        margin-bottom: 20px;
      }
      margin-bottom: 16px;
    }
    .e-site-button {
      min-width: 250px;
    }
    &-des {
      width: 80%;
    }
  }
  @include tablet-landscape {
    &-media {
      @include grid-block(1, 14, 1);
    }

    &-main {
      @include grid-block(16, 8, 1);
      justify-content: center;
    }
  }
}
</style>

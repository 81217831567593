<template>
  <OverallSettings v-bind="$props">
    <div class="c-exhibition-stand" v-if="fields">
      <div class="c-exhibition-stand__tabs">
        <div class="c-exhibition-stand__tabs-item" :class="{ active: selectedTab?.id === item.id }" v-for="item in fields.items" :key="item.id" @click="selectTab(item)" data-event_name="tab_click">
          <JssRichText :field="item.fields.name" />
        </div>
      </div>
      <div class="c-exhibition-stand__details" v-if="selectedTab">
        <div class="c-exhibition-stand__details-icon" :class="`__c-${selectedTab.fields.iconColor?.fields.phrase.value}`">
          <AnimatedContent type="fade-in-top-left" ease="cubic-bezier(0.055, 0.215, 0.000, 1.000)" :duration="500">
            <Icon :svg="selectedTab.fields.icon?.value.svgCode" />
          </AnimatedContent>
        </div>
        <div class="c-exhibition-stand__details-title">
          <AnimatedContent type="skew-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)" :duration="500" :delay="600">
            <JssRichText :field="selectedTab.fields.title" />
          </AnimatedContent>
        </div>
        <div class="c-exhibition-stand__details-body">
          <AnimatedContent type="fade-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)" :duration="500" :delay="700">
            <JssRichText :field="selectedTab.fields.des" />
          </AnimatedContent>
        </div>
        <div class="c-exhibition-stand__details-btn" v-if="!isMobile()">
          <AnimatedContent type="bottom-fade-in" :duration="500" :delay="800">
            <SiteButton v-bind="selectedTab.fields.button" />
          </AnimatedContent>
        </div>
      </div>
      <div class="c-exhibition-stand__image" v-if="selectedTab && showImage">
        <AnimatedContent type="fade-in" :duration="500">
          <AdaptiveBackImage :pc-image="selectedTab.fields.backgroudPC" :mobile-image="selectedTab.fields.backgroudMobile" :enlarge="false" />
        </AnimatedContent>
      </div>
      <div class="c-exhibition-stand__btn" v-if="selectedTab && isMobile()">
        <AnimatedContent type="bottom-fade-in" :duration="500" :delay="800">
          <SiteButton v-bind="selectedTab.fields.button" />
        </AnimatedContent>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { toRefs, onMounted, reactive, nextTick, onBeforeUnmount } from 'vue';
import { isMobile } from '@/utils/dom-utils';
export default {
  name: 'ExhibitionStand',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      mainEl: null,
      ImageEl: null,
      desEl: null,
      selectedTab: null,
      selectedPoint: null,
      imageHeight: null,
      imageWidth: null,
      resize: true,
      showImage: true
    });
    const methods = {
      isMobile,
      selectTab: (i) => {
        state.showImage = false;
        state.selectedTab = i;
        nextTick(() => {
          state.showImage = true;
        });
      }
    };
    onMounted(() => {
      state.selectedTab = JSON.parse(JSON.stringify(props.fields.selectedTab ?? props.fields.items[0]));
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-exhibition-stand {
  @include grid-container;
  margin-top: 54px;
  &__tabs {
    @include grid-block(2, 10, 1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &-item {
      opacity: 0.3;
      cursor: pointer;
      padding-left: $space-30;
      padding-right: $space-30;
      &:last-child {
        margin: 0;
      }
      &.active {
        opacity: 1;
      }
    }
  }
  &__details {
    @include grid-block(2, 10, 2);
    margin-top: $space-56;
    &-icon {
      color: #b8b8b8;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    &-title {
      margin-top: $space-8;
      margin-left: $space-20;
    }
    &-body {
      margin-top: $space-16;
      margin-left: $space-20;
    }
    &-btn {
      margin-top: $space-24;
      margin-left: $space-20;
    }
  }
  &__image {
    @include grid-block(1, 12, 3);
    height: 270px;
    margin-top: $space-40;
    .e-background-image {
      height: 100%;
    }
    .e-animated-content {
      height: 100%;
      &__main {
        height: 100%;
      }
    }
  }
  &__btn {
    @include grid-block(2, 10, 4);
    margin-top: $space-60;
    margin-bottom: $space-56;
    .e-site-button {
      width: 100%;
    }
  }
  @include tablet-landscape {
    margin-top: 64px;
    &__tabs {
      @include grid-block(3, 20, 1);
      justify-content: center;
      &-item {
        padding-left: $space-30;
        padding-right: $space-30;
      }
    }
    &__details {
      @include grid-block(2, 10, 2);
    }
    &__image {
      @include grid-block(2, 22, 3);
      height: grid-width(math.div(22 * 35, 100));
      margin-top: 0;
      margin-bottom: grid-width(2);
      .e-background-image {
        height: 100%;
      }
    }
  }
}
</style>

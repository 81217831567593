<template>
  <overall-settings v-bind="$props">
    <div class="c-campaign">
      <!--      <svg viewBox="0 0 100 100">-->
      <!--        <rect x="0" y="0" width="100%" height="100%" fill="black" />-->
      <!--      </svg>-->
      <background-image :image="fields.image" class="c-campaign__image" :class="imageClasses" />
      <div class="c-campaign__content" :class="contentClasses">
        <div class="c-campaign__heading">
          <jss-rich-text class="c-campaign__title" :field="fields.title" :tag="titleTag" />
          <jss-text class="c-campaign__desc" :field="fields.description" tag="div" />
        </div>
        <jss-rich-text class="c-campaign__body" :field="fields.body" tag="div" />
      </div>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef CampaignFields
 * @property {GlobalSettingEntry} theme
 * @property {SimpleField} title
 * @property {GlobalSettingEntry} titleType
 * @property {GlobalSettingEntry} titleAlign
 * @property {SimpleField} description
 * @property {SimpleField} body
 * @property {ImageField} image
 * @property {GlobalSettingEntry} imageSize
 * */
import { computed } from 'vue';

export default {
  name: 'Campaign',
  props: {
    /**@type CampaignFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const computes = {
      imageClasses: computed(() => [`size-${props?.fields?.imageSize?.fields.phrase.value ?? 'full-width'}`]),
      contentClasses: computed(() => [`title-${props?.fields?.titleAlign?.fields.phrase.value ?? 'left'}`]),
      titleTag: computed(() => `${props?.fields?.titleType?.fields.phrase.value ?? 'h2'}`)
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-campaign {
  $c: &;
  @include component-themes;
  @include grid-container;
  height: 100vh;
  &__image {
    height: 100%;
    &.size- {
      &small {
        @include grid-block(8, 10, 1);
      }
      &full-width {
        @include grid-block(1, 24, 1);
      }
    }
  }
  &__content {
    @include grid-block(4, 18, 1);
    padding: grid-width(2) 0 grid-width(1) 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    mix-blend-mode: hard-light;
    &.title- {
      &left {
        #{$c}__heading {
          align-self: flex-start;
        }
        #{$c}__body {
          align-self: flex-end;
          text-align: end;
        }
      }
      &right {
        #{$c}__heading {
          align-self: flex-end;
          text-align: end;
        }
        #{$c}__body {
          align-self: flex-start;
        }
      }
    }
  }
  &__title {
    @include h3;
  }
  &__desc {
    margin-top: $space-24;
  }
  &__body {
    width: grid-width(8);
  }
  @include tablet-landscape {
    &__desc {
      margin-top: $space-40;
    }
  }
}
</style>

<template>
  <div class="c-checkout-detail" v-if="fields && orderDetail" ref="rootEl">
    <div class="c-checkout-detail__banner">
      <div class="c-checkout-detail__image-wrapper">
        <img class="c-checkout-detail__image" :src="orderDetail?.vehicleInfo?.basicInfo?.image" :alt="orderDetail?.vehicleInfo?.basicInfo?.model" />
        <div class="c-checkout-detail__image-curtain" />
      </div>
    </div>
    <div class="c-checkout-detail__content">
      <div class="c-checkout-detail__content-wrapper">
        <div class="c-checkout-detail__subtitle" v-html="$formatString(fields.subtitle.value, fData)" />
        <div class="c-checkout-detail__steps">
          <div class="c-checkout-detail__step" v-for="(step, index) in mySteps" :key="step.id">
            <div class="c-checkout-detail__step-index">{{ (index + 1).toString().padStart(2, '0') }}</div>
            <template-string class="c-checkout-detail__step-title" :field="step.fields.title" :data="fData" />
            <template-string class="c-checkout-detail__step-subtitle" :field="step.fields.subtitle" :data="fData" />
            <site-button class="c-checkout-detail__step-button" v-bind="step.fields.button" @click="onButtonClick(step.fields.button)" />
          </div>
        </div>
        <div class="c-checkout-detail__tabs">
          <div class="c-checkout-detail__tab-headers">
            <div class="c-checkout-detail__tab-headers--wrapper">
              <div class="c-checkout-detail__tab-header" :class="[{ active: activeIndex === index }]" v-for="(tab, index) in tabs" :key="index" v-html="tab.title" />
            </div>
          </div>
          <div class="c-checkout-detail__tab-contents">
            <div class="c-checkout-detail__tab-content" v-show="activeIndex === 0">
              <div class="c-checkout-detail__section">
                <div class="c-checkout-detail__section-title" v-html="$t('Order')" />
                <div class="c-checkout-detail__section-content">
                  <div class="c-checkout-detail__section-grid">
                    <div class="c-checkout-detail__kv">
                      <label>{{ $t('Name') }}</label>
                      <div>
                        {{
                          $concatString([orderDetail?.vehicleInfo?.deliveryInfo?.firstName, orderDetail?.vehicleInfo?.deliveryInfo?.middleName, orderDetail?.vehicleInfo?.deliveryInfo?.lastName], ' ')
                        }}
                      </div>
                    </div>
                    <div class="c-checkout-detail__kv">
                      <label>{{ $t('Order number') }}</label>
                      <div>{{ orderInfo?.orderId }}</div>
                    </div>
                    <div class="c-checkout-detail__kv">
                      <label>{{ $t('Reference') }}</label>
                      <div>{{ $route.query?.orderId }}</div>
                    </div>
                    <div class="c-checkout-detail__kv">
                      <label>{{ $t('Order date') }}</label>
                      <div>{{ $formatDate(orderInfo?.createTime, alpha2Code === 'SE' ? 'yyyy-MM-dd' : 'dd-MM-yyyy') }}</div>
                    </div>
                    <div class="c-checkout-detail__kv">
                      <label>{{ $t('Lotus id') }}</label>
                      <div>{{ orderDetail?.userInfo?.lotusId }}</div>
                    </div>
                  </div>
                  <div class="c-checkout-detail__invoices">
                    <label>{{ $t('Invoices') }}</label>
                    <div class="c-checkout-detail__invoices--wrapper">
                      <div class="c-checkout-detail__invoice" v-for="(invoice, index) in orderDetail.invoiceInfoList" :key="index">
                        <p>{{ invoice.invoiceType }} {{ invoice.invoiceNo }}</p>
                        <p>{{ $formatMoney(Math.abs(invoice.invoiceAmount), orderDetail.currency) }}</p>
                        <a :href="invoice.pdfUrl" :download="invoice.pdfSn" v-if="!$deviceComputes.isMobileOrTablet.value">
                          <icon name="download-a" size="tiny" />
                          <jss-text :field="fields.downloadText" tag="span" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-checkout-detail__section">
                <div class="c-checkout-detail__section-title" v-html="$t('Personal details').replace(/\s/gi, '<br />')" />
                <div class="c-checkout-detail__section-content">
                  <div class="c-checkout-detail__section-grid">
                    <div class="c-checkout-detail__kv">
                      <label>{{ $t('Phone number') }}</label>
                      <div>+{{ userInfo?.areaCode }} {{ userInfo?.phoneNumber }}</div>
                    </div>
                    <div class="c-checkout-detail__kv">
                      <label>{{ $t('Email address') }}</label>
                      <div>{{ userInfo?.email }}</div>
                    </div>
                    <div class="c-checkout-detail__kv">
                      <label>{{ $t('Invoice') }} / {{ $t('Billing address') }}</label>
                      <div>{{ $buildAddress(orderInfo?.taxpayerStreet, orderInfo?.taxpayerZipCode, orderInfo?.taxpayerCity, orderInfo?.countryName) }}</div>
                    </div>
                    <div class="c-checkout-detail__kv">
                      <label>{{ $t('Shipping address') }}</label>
                      <div>
                        {{
                          $buildAddress(
                            orderDetail?.vehicleInfo?.deliveryInfo?.street,
                            orderDetail?.vehicleInfo?.deliveryInfo?.zipCode,
                            orderDetail?.vehicleInfo?.deliveryInfo?.city,
                            orderDetail?.vehicleInfo?.deliveryInfo?.countryName
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-checkout-detail__section">
                <div class="c-checkout-detail__section-title" v-html="$t('Price explanation').replace(/\s/gi, '<br />')" />
                <div class="c-checkout-detail__section-content">
                  <div class="c-checkout-detail__section-item">
                    <div class="c-checkout-detail__section-lvs">
                      <div class="c-checkout-detail__section-lv-header model">
                        <p>{{ $t('Package') }}</p>
                        <p>{{ $t('Base price') }}</p>
                      </div>
                      <div class="c-checkout-detail__section-lv">
                        <checkout-price-item :label="carEngine?.name" :price-list="priceInfo" element-name="Base Retail Price" />
                      </div>
                    </div>
                    <div class="c-checkout-detail__section-lvs" v-for="(feature, i) in features" :key="i">
                      <div class="c-checkout-detail__section-lv-header model" v-if="feature?.infos?.length">
                        <p>{{ feature.type }}</p>
                      </div>
                      <div class="c-checkout-detail__section-lv" v-if="feature?.infos?.length">
                        <label-value :label="info.label" :label-desc="info.value" :value="$formatInclMoney(info.price, info.currency)" v-for="(info, j) in feature.infos" :key="j" />
                      </div>
                    </div>
                    <checkout-price-item class="c-checkout-detail__lined-price" :price-list="priceInfo" element-name="Registration of Vehicle" />
                    <checkout-price-item class="c-checkout-detail__total-price" :label="$t('Total')" :price-list="priceInfo" element-name="Total price" />
                    <div class="c-checkout-detail__section-lvs">
                      <div class="c-checkout-detail__section-lv-header model">
                        <p>{{ $t('Your configuration') }}</p>
                      </div>
                      <div class="c-checkout-detail__section-lv">
                        <block-summary>
                          <template #title>
                            <checkout-price-item :price-list="priceInfo" element-name="Total Price" />
                          </template>
                          <template v-for="(pricing, i) in priceInfo" :key="i">
                            <label-value :label="pricing.label" :value="$formatInclMoney(pricing.price, pricing.currency)" v-if="pricing.visibility" />
                          </template>
                        </block-summary>
                        <block-summary>
                          <template #title>
                            <label-value :label="`${$t('Offer')}/${$t('Service')}`" :value="$formatMoney(benefitsAmount, currency)" />
                          </template>
                          <template v-for="benefits in benefitsList" :key="benefits.rightsId">
                            <label-value :label="benefits.name" :value="Number(benefits.price) > 0 ? $formatMoney(benefits.price, currency) : $t('Included').toLowerCase()" />
                          </template>
                        </block-summary>
                        <block-summary>
                          <template #title>
                            <checkout-price-summary
                              class="c-checkout-detail__payable-price"
                              :label="$t('Total price payable')"
                              :price-list="[{ elementName: 'Deposit', price: orderInfo.paymentAmountNumber }, ...priceInfo]"
                              formula="Total price - Deposit"
                            />
                          </template>
                          <checkout-price-item :label="$t('On the road price')" :price-list="priceInfo" element-name="Total price" />
                          <label-value :label="$t('Total deposit')" :value="`-${$formatMoney(orderInfo.paymentAmountNumber, orderDetail.currency)}`" />
                        </block-summary>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal class="c-checkout-detail__finance-modal" ref="financeModalRef" closable>
      <jss-rich-text class="c-checkout-detail__finance-modal-title fs-26 fs-32-tl mg-t-96 mg-b-48" :field="fields.applyFinanceTitle" />
      <background-image class="mg-b-40" :image="fields.applyFinanceImage" :is-background="false" />
      <jss-rich-text class="c-checkout-detail__finance-modal-body mg-b-56" :field="fields.applyFinanceBody" />
      <div class="c-checkout-detail__finance-modal-buttons">
        <site-button v-bind="fields.applyFinanceButton" @click="onSubmitFinance" />
      </div>
    </modal>
  </div>
</template>

<script>
/**
 * @typedef CheckoutDetailFields
 * @property {SimpleField} downloadText
 * @property {SimpleField} applyFinanceTitle
 * @property {ImageField} applyFinanceImage
 * @property {SimpleField} applyFinanceBody
 * @property {ButtonField} applyFinanceButton
 * */
import { useI18n } from 'vue-i18n';
import { computed, inject, nextTick, onMounted, reactive, toRefs } from 'vue';
import api from '@/api';
import { appendQuery, getBetterUrl, getQueryStrings, redirectToLogin } from '@/utils/uri-utils';
import { getCarModel, getCarModelByFilterId, getCarSeries } from '@/services/siteService';
import { updateDictionaryValue } from '@/services/dictService';
import { formatMoney } from '@/utils/math-utils';
import useAppStore from '@/store/appStore';
import { gtmPush } from '@/utils/gtm-utils';
import { debounce } from 'lodash';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { formatModel, getCurrentAlpha2Code, getPageAlpha2Code } from '@/utils/site-utils';
import { getSantanderLoanConfig } from '@/services/financeService';
import { useRoute, useRouter } from 'vue-router';
import gsap from 'gsap';
import { onWidthChanged } from '@/hooks/onWidthChanged';
import { arraySum } from '@/utils/array-utils';
import { concatString, equalString } from '@/utils/string-utils';

export default {
  name: 'CheckoutDetail',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const loading = inject('loading');
    const toast = inject('toast');
    // const notification = inject('notification');
    /**@type AppStore*/
    const appStore = useAppStore();
    const tabs = [
      {
        title: t('Order details')
      }
    ];
    const _methods = {
      gtmCheckout(step, event) {
        const { filterId, transferCode } = state.orderDetail?.vehicleInfo?.basicInfo ?? {};
        let region = 'GB',
          language = 'en';
        if (props.page.itemLanguage !== 'en') {
          [language, region] = props.page.itemLanguage.split('-');
        }
        const options = [];
        for (let feature of state.features) {
          for (let info of feature.infos) {
            options.push({
              option_id: info.code,
              option_name: info.label,
              option_category: feature.type,
              option_price: info.price >= 0 ? formatMoney(info.price, info.currency) : 'included'
            });
          }
        }
        gtmPush({
          event,
          checkout_step: step,
          user_id: appStore.loginInfo?.lotusId,
          filter_id: filterId,
          transfer_code: transferCode,
          transaction_id: state.orderDetail?.salesOrderInfo?.orderId,
          options,
          region,
          language,
          currency: state.currency
        });
      },
      runAnimation() {
        if (!state.rootEl) return;
        state.animating = true;
        const $imageWrapper = state.rootEl.querySelector('.c-checkout-detail__image-wrapper');
        const $imageCurtain = state.rootEl.querySelector('.c-checkout-detail__image-curtain');
        const $content = state.rootEl.querySelector('.c-checkout-detail__content');
        const $contentWrapper = state.rootEl.querySelector('.c-checkout-detail__content-wrapper');
        const contentHeight = $contentWrapper.scrollHeight;
        $content.style.height = contentHeight + 'px';
        $imageCurtain.style.display = 'block';
        gsap.killTweensOf([$imageWrapper, ani, $contentWrapper]);
        const ani = {
          percent: 0
        };
        const tl = gsap.timeline();
        tl.fromTo(
          $imageWrapper,
          {
            scale: 0.6
          },
          {
            scale: 1,
            ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
            duration: 1
          },
          0
        );
        tl.fromTo(
          ani,
          {
            percent: 0
          },
          {
            percent: 100,
            duration: 1,
            onUpdate() {
              if (ani.percent <= 50) {
                $imageCurtain.style.clipPath = `polygon(0% 0%, 100% 0%, 100% ${100 - ani.percent * 2}%, ${100 - ani.percent * 2}% 100%, 0% 100%)`;
              } else {
                $imageCurtain.style.clipPath = `polygon(0% 0%, ${200 - ani.percent * 2}% 0%, 0% ${200 - ani.percent * 2}%`;
              }
            },
            onComplete() {
              $imageCurtain.style.display = 'none';
            }
          },
          1
        );
        tl.fromTo(
          $contentWrapper,
          {
            height: 0
          },
          {
            height: contentHeight,
            duration: 15,
            onComplete() {
              state.animating = false;
            }
          },
          2
        );
      }
    };
    const methods = {
      async onButtonClick(button) {
        const { dataName } = button?.fields || {};
        switch (dataName.value) {
          case 'apply-finance':
            {
              await state.financeModalRef.open();
            }
            break;
          default:
            break;
        }
      },
      async onSubmitFinance() {
        state.financeModalRef.close().catch();
        loading.show();
        const { financeRedirectLink } = props.fields;
        const { orderId } = route.query || {};
        const financeRedirectHref = getBetterUrl(
          appendQuery(financeRedirectLink.value.href, {
            orderId
          }),
          props.page.itemLanguage,
          true
        );
        const [res, ex] = await api.finance.apply(null, {
          channel: '1',
          channelCode: state.assetInfo?.channelCode,
          productCode: state.carSeries?.financeProduct?.fields?.code,
          orderId,
          redirectionURL: financeRedirectHref,
          simulationId: state.orderDetail.simulationId,
          assetId: state.assetInfo?.assetCode
        });
        console.log('finance res', res, ex);
        if (ex) {
          loading.hide();
          await toast.showEx(ex);
          return;
        }
        if (!res.url) {
          loading.hide();
          await toast.showEx(null);
          return;
        }
        window.location = res.url;
      }
    };
    const state = reactive({
      rootEl: null,
      activeIndex: 0,
      orderDetail: null,
      carSeries: null,
      carEngine: null,
      priceInfo: [],
      features: [],
      totalAmount: null,
      totalAmountPayable: null,
      currency: null,
      benefitsList: [],
      benefitsAmount: 0,
      mySteps: [],
      assetInfo: null,
      animating: false,
      /**@type ModalRef*/
      financeModalRef: null
    });
    const computes = {
      orderInfo: computed(() => state.orderDetail?.salesOrderInfo),
      userInfo: computed(() => state.orderDetail?.userInfo),
      alpha2Code: computed(() => getPageAlpha2Code(props.page)),
      expectedDeliveryDate: computed(() => state.orderDetail?.vehicleInfo?.deliveryInfo.preferredDeliveryTime ?? state.carEngine?.expectedDeliveryDate ?? '&nbsp;'),
      fData: computed(() => {
        const { firstName, middleName, lastName } = computes.userInfo.value || {};
        return {
          model: formatModel(state.orderDetail?.vehicleInfo?.basicInfo?.model),
          name: concatString([firstName, middleName, lastName], ' '),
          firstName,
          email: computes.userInfo.value?.email,
          date: computes.expectedDeliveryDate.value
        };
      })
    };
    onWidthChanged({
      callback: debounce(() => {
        _methods.runAnimation();
      }, 100)
    });
    onMounted(async () => {
      const [orderId] = getQueryStrings('orderId');
      const alpha2Code = getCurrentAlpha2Code();
      loading.show();
      const [res, ex] = await api.order.intactDetail(null, {
        id: orderId
      });
      if (ex) {
        if (ex.code === 60000000) {
          redirectToLogin(props.page, router);
        }
        await toast.showEx(ex);
        return;
      }
      state.orderDetail = res;
      state.currency = res?.currency;
      const priceInfo = res?.additionalTaxInfo;
      state.carSeries = await getCarSeries(res.vehicleInfo?.basicInfo?.series);
      state.carEngine = await getCarModelByFilterId(res.vehicleInfo?.basicInfo?.series, res.vehicleInfo?.basicInfo?.filterId);
      if (!state.carEngine) {
        state.carEngine = await getCarModel(res.vehicleInfo?.basicInfo?.series, res.vehicleInfo?.basicInfo?.model);
      }
      if (!state.carEngine) {
        state.carEngine = await getCarModel(res.vehicleInfo?.basicInfo?.series, res.vehicleInfo?.basicInfo?.filter);
      }
      state.priceInfo = await updateDictionaryValue(priceInfo, 'elementName', 'label', (x) => true);
      state.features = JSON.parse(res?.vehicleInfo?.basicInfo?.infoJson ?? '[]');
      state.invoiceInfo = res?.invoiceInfoList?.length > 0 ? res?.invoiceInfoList[0] : null;
      state.totalAmount =
        state.priceInfo.find((x) => x.elementName === 'Total Price')?.price ??
        Math.max.apply(
          null,
          state.priceInfo.filter((x) => !isNaN(x.price)).map((x) => Number(x.price))
        ) ??
        null;
      state.totalAmountPayable = state.totalAmount - (res?.salesOrderInfo?.totalAmountNumber ?? 0) - (res?.financeAmount ?? 0);
      state.benefitsList = res?.rightsRespVOS?.sort((a, b) => b.price - a.price) ?? [];
      state.benefitsAmount = arraySum(res?.rightsRespVOS ?? [], (e) => e.price);
      state.mySteps = props.fields.steps.filter((x) => !x.fields.showInFinance.value || state.orderDetail.simulationId) ?? [];
      _methods.gtmCheckout('4', 'generate_lead');
      if (state.orderDetail.simulationId && !equalString(alpha2Code, 'GB')) {
        const config = await getSantanderLoanConfig(alpha2Code);
        const [assetRes] = await api.finance.getAssetInfo(null, {
          channelCode: config.channelCode,
          country: alpha2Code,
          model: res?.vehicleInfo?.basicInfo?.model // 'ELETRE Base' // options.model
        });
        if (!assetRes?.length || isNullOrEmpty(assetRes[0].assetCode)) {
          toast.showError('Asset information failed to load');
        } else {
          state.assetInfo = assetRes[0];
          console.log('state.assetInfo', state.assetInfo);
        }
      }
      loading.hide();
      await nextTick();
      _methods.runAnimation();
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      tabs
    };
  }
};
</script>

<style lang="scss">
@use "sass:math";
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-checkout-detail {
  $c: &;
  background: $black;
  color: $white;
  padding-top: 48px;
  padding-bottom: 100px;
  &__banner {
    display: flex;
    justify-content: center;
    height: calc((100vw - 48px) * #{math.div(9, 16)});
    position: relative;
    overflow: hidden;
  }
  &__image {
    display: block;
    width: 100%;
    height: 100%;
    &-wrapper {
      width: calc(100vw - 48px);
      height: calc((100vw - 48px) * #{math.div(9, 16)});
      position: relative;
      z-index: 1;
    }
    &-curtain {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $yellow;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 0% 100%);
    }
  }
  &__title {
    font-size: 15vw;
    font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
    color: $yellow;
    white-space: nowrap;
    text-align: center;
    //&-wrapper {
    //  display: flex;
    //  position: absolute;
    //  transform: translate(50%, -50%);
    //  top: 50%;
    //}
  }
  &__content {
    &-wrapper {
      height: 0;
      overflow: hidden;
    }
  }
  &__subtitle {
    @include h6;
    width: calc(100vw - 48px);
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 24px;
  }
  &__steps {
    padding: 0 24px;
  }
  &__step {
    &-index {
      font-size: 80px;
      color: $yellow;
    }
    &-subtitle {
      font-size: 12px;
      color: $grey-taubmans;
      margin-top: 8px;
    }
    &-button {
      width: 100%;
      margin-top: 24px;
    }
  }
  &__tabs {
    margin-top: 100px;
    padding: 0 24px;
  }
  &__tab-headers {
    display: flex;
    justify-content: center;
    &--wrapper {
      display: flex;
      border-bottom: 1px solid rgba($white, 0.6);
    }
  }
  &__tab-header {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      bottom: -1px;
      border-bottom: 1px solid currentColor;
      transition: all 0.2s;
    }
    &.active {
      color: $yellow;
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        bottom: -1px;
        border-bottom: 1px solid currentColor;
      }
    }
    & + & {
      margin-left: 48px;
    }
  }
  &__tab-contents {
    margin-top: 60px;
  }
  &__kv {
    > label {
      display: block;
      font-family: lotusFontFamily('Overpass Bold'), sans-serif;
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 8px;
    }
    > div {
      color: $grey-taubmans;
    }
    & + & {
      margin-top: 24px;
    }
  }
  &__section {
    &-title {
      @include h6;
      text-transform: uppercase;
    }
    &-content {
      margin-top: 24px;
    }
    &:not(:last-child) {
      padding-bottom: 96px;
      border-bottom: 1px solid $grey-dark;
    }
    &-lvs {
      &:empty {
        display: none;
      }
      & + & {
        margin-top: 48px;
      }
    }
    &-lv {
      color: $grey-taubmans;
      .e-label-value {
        padding: 0;
        font-size: 16px;
        align-items: flex-end;
        &__left {
          display: flex;
          flex-direction: column;
        }
        &__label {
          order: 2;
          &-desc {
            order: 1;
          }
        }
        + .e-label-value {
          margin-top: 16px;
        }
      }
      .e-block-summary {
        &__main {
          background: transparent;
          color: $grey-taubmans;
          border: 1px solid $grey-dark;
          padding-top: 12px;
          padding-bottom: 12px;
          .e-label-value {
            + .e-label-value {
              border-top: none;
            }
          }
        }
      }
    }
    &-lv-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      font-size: 14px;
      font-family: lotusFontFamily('Overpass Bold'), sans-serif;
      text-transform: uppercase;
      &.model {
        margin-bottom: 8px;
      }
    }
    & + & {
      margin-top: 96px;
    }
  }
  &__invoices {
    margin-top: 24px;
    > label {
      display: block;
      font-family: lotusFontFamily('Overpass Bold'), sans-serif;
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
  &__invoice {
    color: $grey-taubmans;
    > a {
      color: $white;
      display: inline-flex;
      align-items: center;
      > .e-icon {
        color: $yellow;
        margin-right: 4px;
      }
    }
    & + & {
      margin-top: 8px;
    }
  }
  &__lined-price {
    border-bottom: 1px solid $grey-dark;
    margin-top: 64px;
  }
  &__total-price {
    @include h7;
    margin-top: 8px;
  }
  &__finance-modal {
    &-title {
      padding: 0 24px;
      font-size: 26px;
    }
    &-body {
      padding: 0 24px;
    }
    &-buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 64px;
    }
  }
  @include tablet-landscape {
    &__banner {
      height: 50vw * math.div(9, 16);
      position: relative;
    }
    &__image {
      &-wrapper {
        width: 50vw;
        height: 50vw * math.div(9, 16);
        position: relative;
      }
    }
    &__title {
      font-size: 8vw;
    }
    &__subtitle {
      width: 50vw;
      margin-top: 40px;
      margin-bottom: 40px;
      margin-left: 25vw;
    }
    &__steps {
      display: flex;
      justify-content: center;
      padding: 0 grid-width(1);
    }
    &__step {
      & + & {
        margin-left: grid-width(2);
      }
    }
    &__tabs {
      margin-top: 200px;
    }
    &__tab-contents {
      padding: 0 grid-width(3);
      margin-top: 100px;
    }
    &__kv {
      &:nth-child(even) {
        text-align: end;
      }
      & + & {
        margin-top: 0;
      }
    }
    &__section {
      display: flex;
      &-title {
        width: grid-width(7);
        flex-shrink: 0;
      }
      &-content {
        flex-grow: 1;
        margin-top: 0;
      }
      &-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: grid-width(2);
        grid-row-gap: 48px;
      }
    }
    &__invoices {
      margin-top: 48px;
      &--wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: grid-width(2);
        grid-column-gap: 48px;
      }
    }
    &__invoice {
      &:nth-child(even) {
        text-align: end;
      }
      & + & {
        margin-top: 0;
      }
    }
    &__lined-price {
      margin-top: 96px;
    }
    &__total-price {
      margin-bottom: 96px;
    }
    &__finance-modal {
      .e-modal {
        &__content {
          width: grid-width(14);
        }
      }
      &-title {
        padding: 0 64px;
        font-size: 32px;
      }
      &-body {
        padding: 0 64px;
      }
    }
  }
}
html[lang='ja-JP'] {
  .c-checkout-detail {
    &__title {
      font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
    }
    &__kv {
      > label {
        font-family: lotusFontFamily('Noto Sans JP Bold'), sans-serif;
      }
    }
    &__section {
      &-lv-header {
        font-family: lotusFontFamily('Noto Sans JP Bold'), sans-serif;
      }
    }
    &__invoices {
      > label {
        font-family: lotusFontFamily('Noto Sans JP Bold'), sans-serif;
      }
    }
  }
}
html[lang='ko-KR'] {
  .c-checkout-detail {
    &__title {
      font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
    }
    &__kv {
      > label {
        font-family: lotusFontFamily('Noto Sans KR Bold'), sans-serif;
      }
    }
    &__section {
      &-lv-header {
        font-family: lotusFontFamily('Noto Sans KR Bold'), sans-serif;
      }
    }
    &__invoices {
      > label {
        font-family: lotusFontFamily('Noto Sans KR Bold'), sans-serif;
      }
    }
  }
}
</style>

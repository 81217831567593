<template>
  <teleport to="body">
    <div class="c-activ-engage" v-if="loaded">
      <div class="c-activ-engage__chat" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" @click="onChatClick">
        <icon :field="fields.chatIcon" />
      </div>
      <transition name="sms-ani">
        <div class="c-activ-engage__sms" v-if="active" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" @click="onSmsClick">
          <icon :field="fields.smsIcon" />
        </div>
      </transition>
    </div>
  </teleport>
</template>

<script>
/**
 * @typedef ActivEngageFields
 * @property {ImageField} chatIcon
 * @property {ImageField} smsIcon
 * */

import { inject, onMounted, reactive, toRefs, watchEffect } from 'vue';
import { injectScript } from '@/utils/dom-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { sitecoreComponentProps } from '@/utils/constants';

export default {
  name: 'ActivEngage',
  props: {
    /**@type ActivEngageFields*/
    fields: {
      type: Object
    },
    ...sitecoreComponentProps
  },
  setup(props) {
    let mouseLeaveTimeout,
      chatTimeout = null;
    const { deviceComputes } = inject('device-common');
    const methods = {
      onMouseEnter() {
        if (!deviceComputes.isMobileOrTablet.value) {
          if (mouseLeaveTimeout) clearTimeout(mouseLeaveTimeout);
          state.active = true;
        }
      },
      onMouseLeave() {
        if (!deviceComputes.isMobileOrTablet.value) {
          if (mouseLeaveTimeout) {
            clearTimeout(mouseLeaveTimeout);
          }
          mouseLeaveTimeout = setTimeout(() => {
            state.active = false;
          }, 1000);
        }
      },
      onChatClick() {
        if (deviceComputes.isMobileOrTablet.value) {
          console.log('is mobile', true, state.active);
          if (!state.active) {
            console.log('chat click');
            state.active = true;
            if (chatTimeout) clearTimeout(chatTimeout);
            chatTimeout = setTimeout(() => {
              state.active = false;
            }, 3000);
            return;
          }
        }
        if (state.active) {
          global.ActivEngage.launchChat({
            data: {
              launchSource: 'official-website-sticky-button'
            }
          });
        }
      },
      onSmsClick() {
        global.ActivEngage.launchSms({
          data: {
            launchSource: 'official-website-sticky-button'
          }
        });
      }
    };
    const state = reactive({
      loaded: false,
      active: false
    });
    onMounted(() => {
      watchEffect(() => {
        state.loaded = false;
        const sdkUrl = props.fields?.sdkUrl?.value;
        if (isNullOrEmpty(sdkUrl)) return;
        if (global.ActivEngage) {
          state.loaded = true;
        } else {
          document.body.setAttribute('chat-account', props.fields.account?.value ?? 'www.lotuscars.com');
          injectScript(sdkUrl, () => !!global.ActivEngage).then(() => {
            global.ActivEngage.events.onReady(() => {
              state.loaded = true;
            });
          });
        }
      });
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-activ-engage {
  $c: &;
  position: fixed;
  z-index: 3;
  right: 16px;
  bottom: 16px;
  svg {
    height: 24px;
  }
  &__chat {
    width: 54px;
    height: 54px;
    overflow: hidden;
    border-radius: 100%;
    background: #fff200;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__sms {
    width: 54px;
    height: 54px;
    overflow: hidden;
    border-radius: 100%;
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 70px;
    cursor: pointer;
    box-shadow: 0 0 1px rgba($black, 0.1);
  }
  .sms-ani {
    &-enter-active,
    &-leave-active {
      transition: all 0.6s;
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateY(50%);
    }
    &-enter-to,
    &-leave-from {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
[id*='ae-launcher-container-'] {
  display: none;
}
</style>

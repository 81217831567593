<template><div /></template>
<script>
import { onMounted } from 'vue';
import { injectScript } from '@/utils/dom-utils';
import { isAppBrowser, settingValue } from '@/utils/site-utils';
import { getChatConfiguration } from '@/services/siteService';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { useRoute } from 'vue-router';
import { equalString, ifEmpty } from '@/utils/string-utils';
import { getPageLanguage } from '../utils/site-utils';

/**
 * @typedef ChatFields
 * @property {GlobalSettingEntry} theme
 * */
let chatInitialized = false;
export default {
  name: 'Chat',
  props: {
    page: {
      type: Object
    },
    /**@type ChatFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    /**@type ChatConfiguration|null*/
    let config = null;
    const route = useRoute();
    let initESW = function(baseUrl) {
      const { firstNameLabel, lastNameLabel, emailLabel, localeLabel } = props.fields || {};
      let language = getPageLanguage(props.page);
      if (language === 'nl') language = 'nl-NL';
      if (language === 'nb') language = 'no';
      global.embedded_svc.settings.displayHelpButton = true; //Or false
      global.embedded_svc.settings.language = language; //For example, enter 'en' or 'en-US'

      global.embedded_svc.settings.enabledFeatures = config.enableFeatures?.split(';') ?? ['LiveAgent'];
      global.embedded_svc.settings.entryFeature = config.entryFeature ?? 'LiveAgent';
      global.embedded_svc.settings.extraPrechatFormDetails = [
        { label: ifEmpty(firstNameLabel?.value, 'First Name'), transcriptFields: ['FirstName__c'] },
        { label: ifEmpty(lastNameLabel?.value, 'Last Name'), transcriptFields: ['LastName__c'] },
        { label: ifEmpty(emailLabel?.value, 'Email'), transcriptFields: ['Email__c'] },
        { label: ifEmpty(localeLabel?.value, 'Locale'), value: props.page.itemLanguage, transcriptFields: ['Locale__c'] }
      ];
      global.embedded_svc.init(config.server, config.serviceUrl, baseUrl, config?.token, config?.group, {
        baseLiveAgentContentURL: config.baseLiveAgentContentURL,
        deploymentId: config?.deploymentId,
        buttonId: config.buttonId,
        baseLiveAgentURL: config.baseLiveAgentURL,
        eswLiveAgentDevName: config.eswLiveAgentDevName,
        isOfflineSupportEnabled: config.isOfflineSupportEnabled === '1'
      });
    };
    onMounted(async () => {
      if (!chatInitialized && !equalString(route.query?.referrer, 'app') && !isAppBrowser()) {
        config = await getChatConfiguration();
        if (isNullOrEmpty(config?.sdkUrl)) return;
        document.body.classList.add(`chat-${settingValue(props.fields.layout, 'LeftBottom')}`);
        chatInitialized = true;
        if (!global.embedded_svc) {
          const loaded = await injectScript(config.sdkUrl);
          if (loaded) {
            initESW(null);
          }
        } else {
          initESW(config.baseUrl);
        }
      }
    });
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
body {
  .embeddedServiceHelpButton .helpButton {
    width: 54px;
    height: 54px;
    overflow: hidden;
    border-radius: 100%;
    background: #fff200;
  }
  .embeddedServiceHelpButton .helpButton .uiButton {
    padding: 0;
    background: transparent;
    font-size: 20px;
    box-shadow: none;
    width: 54px;
    height: 54px;
  }
  .embeddedServiceHelpButton .embeddedServiceIcon::before {
    font-size: 30px;
    color: #000;
  }
  .embeddedServiceHelpButton .embeddedServiceIcon {
    display: inline-block;
    margin: 0 0 0 12px;
  }
  .embeddedServiceHelpButton .uiButton .helpButtonLabel {
    max-width: 54px;
    font-size: 12px;
    color: #000;
  }
  //
  //.embeddedServiceSidebarMinimizedDefaultUI {
  //  width: 54px;
  //  height: 54px;
  //  overflow: hidden;
  //  border-radius: 100%;
  //  background: #fff200;
  //  min-width: 0;
  //  box-shadow: none;
  //  border: 0;
  //  font-size: 30px;
  //}

  &.chat-leftTop {
    .embeddedServiceSidebarMinimizedDefaultUI,
    .helpButton {
      left: 10px;
      right: auto;
      top: 10px;
      bottom: auto;
    }
  }
  &.chat-leftBottom {
    .embeddedServiceSidebarMinimizedDefaultUI,
    .helpButton {
      left: 10px;
      right: auto;
      top: auto;
      bottom: 10px;
    }
  }
  &.chat-rightTop {
    .embeddedServiceSidebarMinimizedDefaultUI,
    .helpButton {
      left: auto;
      right: 10px;
      top: 10px;
      bottom: auto;
    }
  }
  &.chat-rightBottom {
    .embeddedServiceSidebarMinimizedDefaultUI,
    .helpButton {
      left: auto;
      right: 10px;
      top: auto;
      bottom: 10px;
    }
  }

  //.embeddedServiceSidebarMinimizedDefaultUI .minimizedImage,
  .embeddedServiceSidebarMinimizedDefaultUI .embeddedServiceIcon {
    margin: 0;
  }
  //.embeddedServiceSidebarMinimizedDefaultUI .minimizedText {
  //  display: none;
  //}
  //.sidebarHeader[embeddedService-chatHeader_chatHeader] {
  //  padding: 0 40px 0 32px;
  //
  //  img {
  //    display: none;
  //  }
  //}
  //[embeddedservice-chatheader_chatheader-host] {
  //  background: #fff;
  //  padding-top: 46px;
  //  height: auto;
  //}
  //h2[embeddedService-chatHeader_chatHeader] {
  //  color: #000;
  //  font-size: 20px;
  //  font-weight: bold;
  //}
  //embeddedservice-chat-header lightning-icon,
  //embeddedservice-chat-header svg.slds-icon-text-default {
  //  fill: #000;
  //}
  //button[embeddedService-chatHeader_chatHeader] {
  //  cursor: pointer;
  //
  //  &.minimizeButton {
  //    display: none;
  //  }
  //}
  //.embeddedServiceSidebar.layout-docked .dockableContainer {
  //  width: 100%;
  //  height: 100%;
  //  max-height: none;
  //  border-radius: 0;
  //  max-width: none;
  //  left: auto;
  //  right: 0;
  //  top: auto;
  //  bottom: 0;
  //}
  //.embeddedServiceSidebarForm .backgroundImg,
  //.embeddedServiceSidebarForm .backgroundImgColorMask {
  //  display: none;
  //}
  //.embeddedServiceSidebarForm .fieldList {
  //  margin: 12px 32px 0 12px;
  //}
  //.embeddedServiceSidebarForm .embeddedServiceSidebarFormField {
  //  margin-top: 36px;
  //  margin-left: 20px;
  //}
  //.embeddedServiceSidebarForm.buttonWrapper {
  //  padding: 36px 40px 38px 32px;
  //}
  //.embeddedServiceSidebarFormField .uiInput .uiLabel-left {
  //  display: inline-block;
  //  font-size: 14px;
  //  padding-bottom: 8px;
  //}
  //.embeddedServiceSidebarFormField .uiInput .required {
  //  color: #000;
  //}
  //.embeddedServiceSidebarFormField .uiInputSelect:after {
  //  top: calc(50% + 14px);
  //}
  //.embeddedServiceSidebarForm.formContent {
  //  height: calc(100% - 110px);
  //}
  //.embeddedServiceSidebarButton {
  //  background: #fff;
  //  border: 1px solid #000;
  //  border-radius: 0;
  //  height: auto;
  //  padding: 16px 0;
  //}
  //.embeddedServiceSidebarButton .label {
  //  color: #000;
  //  font-size: 14px;
  //}
  //.embeddedServiceSidebarFormField .slds-style-inputtext,
  //.embeddedServiceSidebarFormField .slds-style-select {
  //  border-radius: 0;
  //  font-size: 16px;
  //  border: 1px solid #4f4f4f;
  //}
  //.embeddedServiceSidebarDialogState #dialogTextTitle {
  //  font-size: 24px;
  //}
  //.embeddedServiceSidebarDialogState #dialogTextBody {
  //  font-size: 16px;
  //}
  //.embeddedServiceSidebarDialogState .dialogButtonContainer {
  //  padding: 36px 40px 38px 32px;
  //}

  @include tablet-landscape {
    //.embeddedServiceSidebar.layout-docked .dockableContainer {
    //  width: 415px;
    //  height: 80%;
    //}

    &.chat-LeftTop {
      .embeddedServiceSidebar.layout-docked .dockableContainer {
        left: 10px;
        right: auto;
        top: 10px;
        bottom: auto;
      }
    }
    &.chat-LeftBottom {
      .embeddedServiceSidebar.layout-docked .dockableContainer {
        left: 10px;
        right: auto;
        top: auto;
        bottom: 10px;
      }
    }
    &.chat-RightTop {
      .embeddedServiceSidebar.layout-docked .dockableContainer {
        left: auto;
        right: 10px;
        top: 10px;
        bottom: auto;
      }
    }
    &.chat-RightBottom {
      .embeddedServiceSidebar.layout-docked .dockableContainer {
        left: auto;
        right: 10px;
        top: auto;
        bottom: 10px;
      }
    }
    //
    //[embeddedservice-chatheader_chatheader-host] {
    //  background: #fff200;
    //  padding: 0;
    //  height: 48px;
    //}
    //h2[embeddedService-chatHeader_chatHeader] {
    //  font-size: 16px;
    //}
    //.sidebarHeader[embeddedService-chatHeader_chatHeader] {
    //  padding: 0 40px 0 32px;
    //
    //  img {
    //    display: block;
    //    width: 30px;
    //    height: 30px;
    //    border: 1px solid #000;
    //    background: #fff200;
    //    border-radius: 100%;
    //  }
    //}
    //.embeddedServiceSidebarForm.buttonWrapper {
    //  padding: 36px 40px 38px 32px;
    //}
    //button[embeddedService-chatHeader_chatHeader] {
    //  width: 20px;
    //  height: 20px;
    //  min-width: 0;
    //  min-height: 0;
    //  padding-top: 0 !important;
    //
    //  &.minimizeButton {
    //    display: block;
    //    margin-top: -15px;
    //    margin-right: 20px;
    //  }
    //}
    //.embeddedServiceSidebarButton {
    //  width: 184px;
    //}
  }
}
body {
  .embeddedServiceHelpButton {
    z-index: 10;
  }
}
html.rtl {
  body .embeddedServiceHelpButton .embeddedServiceIcon {
    margin: 0 12px 0 0;
  }
}
</style>

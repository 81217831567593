<template>
  <div
    class="c-build-to-order"
    :class="[deviceState.browser.toLowerCase(), { 'scroll-to-top': scrollDirection === 'top' }, { 'detail-has-opened': ['mobile', 'tablet'].includes(deviceState.deviceType) && detailHasOpened }]"
    ref="rootEl"
    v-if="loaded"
  >
    <div class="c-build-to-order__content">
      <div class="c-build-to-order__main">
        <div class="c-build-to-order__forms" v-if="!paymentWasFailed">
          <div class="c-build-to-order__steps" v-if="!$isOnApp($route)">
            <template v-for="stepNumber in [1, 2, 3]" :key="stepNumber">
              <div class="c-build-to-order__step" :class="[{ finished: stepNumber <= step }]">
                {{ stepNumber }}
              </div>
              <div class="c-build-to-order__step-line" :class="[{ finished: stepNumber + 1 <= step }]" v-if="stepNumber < 3" />
            </template>
          </div>
          <div class="c-build-to-order__sections">
            <!--          <div class="c-build-to-order__section" v-show="step === 1">-->
            <!--            <heading class="c-build-to-order__primary-title" :field="fields.firstTitle" rich />-->
            <!--            <dynamic-form :form="fields.verifyForm" ref="verifyFormRef" />-->
            <!--          </div>-->
            <div class="c-build-to-order__section" v-show="step === 1">
              <heading class="c-build-to-order__primary-title" :field="fields.secondTitle" rich />
              <div
                class="c-build-to-order__description"
                v-html="$formatString(codeHasSent ? fields.codeHasSentDesc?.value : fields.sendCodeDesc?.value, { email: emailFormCfg.email.controlProps.value, seconds })"
              />
              <dynamic-form :form="fields.mobileForm" :data="mobileFormCfg" ref="mobileFormRef" :visible="accountType === 'mobile'" @btn-click="onMobileFormButtonClick" />
              <dynamic-form :form="fields.emailForm" :data="emailFormCfg" ref="emailFormRef" :visible="accountType === 'email'" @change="onEmailChange" @btn-click="onEmailFormButtonClick" />
              <gee-captcha captcha-name="web-common" ref="geeCaptchaRef" />
              <!--              <div class="c-build-to-order__vt" @click="switchAccountType">-->
              <!--                <jss-rich-text class="c-build-to-order__vt-link" :field="accountType === 'mobile' ? fields.emailFormLinkText : fields.mobileFormLinkText" tag="div" />-->
              <!--                <icon name="forward" />-->
              <!--              </div>-->
            </div>
            <div class="c-build-to-order__section" v-show="step === 2">
              <heading class="c-build-to-order__primary-title" :field="fields.thirdTitle" rich />
              <dynamic-form :form="fields.addressTypeForm" :data="addressTypeFormCfg" ref="addressTypeFormRef" />
              <heading class="c-build-to-order__secondary-title" font-family="overpass" :field="fields.contactTitle" rich />
              <dynamic-form :form="fields.contactForm" :data="contactFormCfg" ref="contactFormRef" :visible="!blindOrder" />
              <div v-if="blindOrder" class="c-build-to-order__phone">+{{ blindOrder.areaCode }}{{ blindOrder.buyerPhone }}</div>
              <transition :css="false" @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                <div class="c-build-to-order__alternate" v-if="alpha2Code === 'IT' && addressTypeFormCfg.addressType?.controlProps?.selectedOption?.code !== 'company'">
                  <heading class="c-build-to-order__secondary-title" font-family="overpass" :field="fields.alternateTitle" rich />
                  <jss-rich-text class="c-build-to-order__subtitle" :field="fields.alternateSubtitle" tag="div" />
                  <dynamic-form :form="fields.alternateForm" ref="alternateFormRef" />
                </div>
              </transition>
              <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                <div class="c-build-to-order__company" v-show="addressTypeFormCfg.addressType?.controlProps?.selectedOption?.code === 'company'">
                  <heading class="c-build-to-order__secondary-title" font-family="overpass" :field="fields.companyTitle" rich />
                  <dynamic-form :form="fields.companyForm" ref="companyFormRef" />
                </div>
              </transition>
              <div class="c-build-to-order__shipping">
                <heading class="c-build-to-order__secondary-title" font-family="overpass" :field="fields.shippingTitle" rich />
                <div class="c-build-to-order__address-switch">
                  <jss-rich-text class="c-build-to-order__address-switch-text" :field="fields.diffAddressText" />
                  <site-switch :value="useDifferentAddress" @change="useDifferentAddress = !useDifferentAddress" />
                </div>
                <dynamic-form :form="fields.shippingForm" :data="shippingFormCfg" ref="shippingFormRef" @change="onShippingFormChange" />
              </div>
              <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                <div class="c-build-to-order__billing" v-if="useDifferentAddress">
                  <heading class="c-build-to-order__secondary-title" font-family="overpass" :field="fields.billingTitle" rich />
                  <dynamic-form :form="fields.billingForm" :data="billingFormCfg" ref="billingFormRef" />
                </div>
              </transition>
              <div v-if="fields.storeForm">
                <heading class="c-build-to-order__secondary-title" :field="fields.storeTitle" rich />
                <jss-rich-text class="c-build-to-order__secondary-subtitle" :field="fields.storeSubtitle" tag="div" />
                <dynamic-form :form="fields.storeForm" ref="storeFormRef" :data="storeFormCfg" />
              </div>
              <transition :css="false" @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                <trade-in :fields="fields" :agent="storeFormCfg.store.controlProps.selectedOption" ref="tradeInRef" />
              </transition>
              <heading class="c-build-to-order__primary-title" :field="fields.financeTitle" rich v-if="fields.financeForm" />
              <div class="c-build-to-order__finance-form" v-if="fields.financeForm">
                <img class="c-build-to-order__regulatory-img" alt="" v-bind="fields.regulatoryImage.value" v-if="fields.regulatoryImage?.value" />
                <dynamic-form :form="fields.financeForm" ref="financeFormRef" />
                <jss-rich-text class="c-build-to-order__finance-desc" :field="fields.financeDescription" tag="div" />
              </div>
              <div class="c-build-to-order__consents">
                <dynamic-form :form="fields.consentForm" ref="consentFormRef" />
              </div>
            </div>
            <div class="c-build-to-order__section" v-show="step === 3">
              <heading class="c-build-to-order__section-title" :field="fields.fourthTitle" rich />
              <div ref="payWidgetRef" />
            </div>
          </div>
        </div>
        <div class="c-build-to-order__failed" v-else>
          <jss-rich-text class="c-build-to-order__failed-overline" :field="fields.failedOverline" />
          <jss-rich-text class="c-build-to-order__failed-title" :field="fields.failedTitle" />
          <jss-rich-text class="c-build-to-order__failed-message" :field="fields.failedErrorMessage" />
          <jss-rich-text class="c-build-to-order__failed-subtitle" :field="fields.failedSubtitle" />
          <jss-rich-text class="c-build-to-order__failed-body" :field="fields.failedBody" />
          <div class="c-build-to-order__failed-widget" ref="failedWidgetRef" />
        </div>
      </div>
      <transition name="bottom-slide-in">
        <div class="c-build-to-order__side" v-if="!['mobile', 'tablet'].includes(deviceState.deviceType) || detailHasOpened">
          <img class="c-build-to-order__model-image" :src="modelImg" alt="Model image" v-if="modelImg" />
          <div class="c-build-to-order__delivery-date" v-html="$formatString(fields.expectedDeliveryDate?.value, { date: carModel?.expectedDeliveryDate })" v-if="$isNullOrEmpty($route.query.vin)" />
          <div class="c-build-to-order__side-content">
            <div class="c-build-to-order__model-title">{{ filterInfo?.model }}</div>
            <label-value
              class="c-build-to-order-detail__important-kv"
              :label="$t('Deposit due today')"
              :value="$formatMoney(downPayment, currency)"
              v-if="['mobile', 'tablet'].includes(deviceState.deviceType)"
            />
            <div class="c-build-to-order__config-sections">
              <div class="c-build-to-order__config-section">
                <div class="c-build-to-order__config-section-title" @click="vehicleDetailHasOpened = !vehicleDetailHasOpened">
                  <span>{{ $t('Vehicle details') }}</span>
                  <accordion-toggle :active="vehicleDetailHasOpened" />
                </div>
                <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                  <div class="c-build-to-order__config-section-content" v-if="vehicleDetailHasOpened">
                    <div class="c-build-to-order__config-section-sub" v-for="(feature, index) in features" :key="index">
                      <!--                  <jss-image class="c-build-to-order__section-image" :media="fields.exteriorImage" />-->
                      <div class="c-build-to-order__config-section-subtitle">{{ feature.type }}</div>
                      <div class="c-build-to-order__vehicle-detail-content">
                        <template v-for="(info, j) in feature.infos" :key="j">
                          <label-value
                            class="c-build-to-order__vehicle-detail-item"
                            :label="info.label"
                            :value="Number(info.price?.price) > 0 ? $formatMoney(info.price.price, info.price.currency) : $t('Included').toLowerCase()"
                          />
                          <div class="c-build-to-order__vehicle-detail-value" v-html="info.value" />
                        </template>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <div class="c-build-to-order__config-section">
                <div class="c-build-to-order__config-section-title" @click="quotationHasOpened = !quotationHasOpened">
                  <span>{{ $t('My quotation') }}</span>
                  <accordion-toggle :active="quotationHasOpened" />
                </div>
                <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                  <div class="c-build-to-order__config-section-content" v-if="quotationHasOpened">
                    <label-value :label="$t('On the road price')" :value="$formatMoney(totalAmount, currency)" />
                    <div class="c-build-to-order__config-section-block">
                      <label-value
                        :label="price.label"
                        :value="$formatMoney(price.price, price.currency)"
                        v-for="(price, index) of priceInfo.filter((x) => x.elementName !== 'Total Price' && x.visibility)"
                        :key="index"
                      />
                      <!--                    <div class="c-build-to-order__config-section-description">-->
                      <!--                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vestibulum interdum ex id condimentum. Nam lorem nisi,-->
                      <!--                    </div>-->
                    </div>
                    <template v-if="benefitsList?.length > 0">
                      <label-value :label="`${$t('Offer')}/${$t('Service')}`" :value="$formatMoney(benefitsAmount, currency)" />
                      <div class="c-build-to-order__config-section-block">
                        <label-value
                          :label="benefits.name"
                          :value="Number(benefits.price) > 0 ? $formatMoney(benefits.price, currency) : $t('Included').toLowerCase()"
                          v-for="benefits in benefitsList"
                          :key="benefits.id"
                        />
                      </div>
                    </template>
                    <label-value :label="$t('Total amount payable')" :value="$formatMoney(balancePayment, currency)" />
                    <div class="c-build-to-order__config-section-block">
                      <label-value :label="$t('On the road price')" :value="$formatMoney(totalAmount, currency)" />
                      <label-value :label="$t('Total deposit')" :value="`-${$formatMoney(downPayment, currency)}`" />
                    </div>
                    <label-value :label="$t('To pay now* deposit fee')" :value="$formatMoney(downPayment, currency)" />
                    <div class="c-build-to-order__config-section-block">
                      <label-value :label="$t('Due today')" :value="$formatMoney(downPayment, currency)" />
                    </div>
                  </div>
                </transition>
              </div>
              <div class="c-build-to-order__config-section" v-if="step > 2">
                <div class="c-build-to-order__config-section-title" @click="personalDetailHasOpened = !personalDetailHasOpened">
                  <span>{{ $t('Personal details') }}</span>
                  <accordion-toggle :active="personalDetailHasOpened" />
                </div>
                <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                  <div class="c-build-to-order__config-section-content" v-if="personalDetailHasOpened">
                    <div class="c-build-to-order__personal-info">
                      {{ $t('Name') }} <br />
                      {{ shippingFormCfg.firstName.controlProps.value }} {{ shippingFormCfg.lastName.controlProps.value }}
                    </div>
                    <div class="c-build-to-order__personal-info">
                      {{ $t('Phone number') }} <br />
                      +{{ mobile?.area.code }}{{ mobile?.number }}
                    </div>
                    <div class="c-build-to-order__personal-info">
                      {{ $t('Email address') }} <br />
                      {{ email }}
                    </div>
                    <template v-if="addressTypeFormCfg.addressType?.controlProps?.selectedOption?.code === 'company'">
                      <div class="c-build-to-order__personal-info">
                        {{ $t('Business email') }}<br />
                        {{ email }}
                      </div>
                      <div class="c-build-to-order__personal-info">
                        {{ $t('VAT number') }}<br />
                        {{ companyFormData?.vatNumber }}
                      </div>
                      <div class="c-build-to-order__personal-info">
                        {{ $t('Company name') }}<br />
                        {{ companyFormData?.companyName }}
                      </div>
                      <div class="c-build-to-order__personal-info">
                        {{ $t('Company registration number') }}<br />
                        {{ companyFormData?.companyRegistrationNumber }}
                      </div>
                    </template>
                    <div class="c-build-to-order__personal-info">
                      {{ $t('Shipping address') }} <br />
                      {{
                        $buildAddress(
                          shippingFormCfg.address.controlProps?.value,
                          shippingFormCfg.address2.controlProps?.value,
                          shippingFormCfg.city.controlProps?.value,
                          shippingFormCfg.country.controlProps?.selectedOption?.text
                        )
                      }}
                    </div>
                    <div class="c-build-to-order__personal-info">
                      {{ $t('Billing address') }} <br />
                      {{
                        useDifferentAddress
                          ? $buildAddress(
                              billingFormCfg.address.controlProps?.value,
                              billingFormCfg.address2.controlProps?.value,
                              billingFormCfg.city.controlProps?.value,
                              billingFormCfg.country.controlProps?.selectedOption?.text
                            )
                          : $buildAddress(
                              shippingFormCfg.address.controlProps?.value,
                              shippingFormCfg.address2.controlProps?.value,
                              shippingFormCfg.city.controlProps?.value,
                              shippingFormCfg.country.controlProps?.selectedOption?.text
                            )
                      }}
                    </div>
                  </div>
                </transition>
              </div>
              <jss-rich-text class="c-build-to-order__disclaimer" :field="fields.iptPfuDisclaimer" v-if="$equalString(alpha2Code, 'IT')" />
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="c-build-to-order__toolbar" ref="toolbarEl">
      <div class="c-build-to-order__detail-toggle" @click="toggleDetailHasOpened">
        <span>{{ $t('Order details') }}</span>
        <accordion-toggle :active="detailHasOpened" />
      </div>
      <div class="c-build-to-order__buttons" :class="[`step_${step}`]">
        <template v-if="paymentWasFailed">
          <div class="c-build-to-order__buttons-wrapper">
            <site-button v-bind="fields.contactButton" />
          </div>
          <div class="c-build-to-order__buttons-wrapper">
            <site-button v-bind="fields.retryButton" @click="onPay" />
          </div>
        </template>
        <template v-else>
          <div class="c-build-to-order__buttons-wrapper" v-if="step < 3">
            <site-button v-bind="fields.configButton" v-if="step === 1" />
            <site-button v-bind="fields.prevButton" v-else-if="step === 2" @click="goPrev" />
          </div>
          <div class="c-build-to-order__buttons-wrapper">
            <site-button v-bind="fields.nextButton" v-if="step < 3" @click="goNext" />
            <site-button v-bind="fields.payButton" v-if="step === 3" @click="onPay" />
          </div>
        </template>
      </div>
      <div class="c-build-to-order__deposit-bar">
        <div class="c-build-to-order__deposit-bar-left">
          <label-value :label="$t('Total on the road price')" :value="$formatMoney(totalAmount, currency)" />
          <label-value class="c-build-to-order__deposit-bar-kv" :label="$t('Deposit due today')" :value="$formatMoney(downPayment, currency)" />
          <jss-rich-text class="c-build-to-order__deposit-bar-description" :field="fields.depositDescription" tag="div" />
        </div>
        <!--        <site-button v-bind="fields.priceButton" @click="openPriceModal" />-->
      </div>
    </div>
  </div>
  <modal ref="confirmModalRef" class="c-build-to-order__confirm-modal" :class="{ convert: autoConvert || blindOrder }" theme="black" closable>
    <jss-rich-text class="c-build-to-order__confirm-modal-title" :field="autoConvert || blindOrder ? fields.reservationConfirmTitle : fields.confirmTitle" />
    <jss-rich-text class="c-build-to-order__confirm-modal-subtitle" :field="fields.confirmSubtitle" />
    <div class="c-build-to-order__confirm-modal-content">
      <div class="c-build-to-order__confirm-modal-section">
        <div class="c-build-to-order__confirm-modal-label">{{ $t('Model detail') }}</div>
        <div class="c-build-to-order__confirm-modal-text">
          {{ filterInfo?.model }}
          {{
            features
              ?.filter((item, index) => index === 0)
              .map((feature) => feature.infos.map((x) => x.value).join(', '))
              .join(', ')
          }}
        </div>
      </div>
      <div class="c-build-to-order__confirm-modal-section">
        <div class="c-build-to-order__confirm-modal-label">{{ $t('Name') }}</div>
        <div class="c-build-to-order__confirm-modal-text">{{ shippingFormData?.firstName }} {{ shippingFormData?.lastName }}</div>
      </div>
      <div class="c-build-to-order__confirm-modal-section">
        <div class="c-build-to-order__confirm-modal-label">{{ $t('Phone number') }}</div>
        <div class="c-build-to-order__confirm-modal-text">+{{ mobile?.area.code }}{{ mobile?.number }}</div>
      </div>
      <div class="c-build-to-order__confirm-modal-section">
        <div class="c-build-to-order__confirm-modal-label">{{ $t('Email address') }}</div>
        <div class="c-build-to-order__confirm-modal-text">{{ email }}</div>
      </div>
      <div class="c-build-to-order__confirm-modal-section">
        <div class="c-build-to-order__confirm-modal-label">{{ $t('Invoice address') }}</div>
        <div class="c-build-to-order__confirm-modal-text">
          {{ $buildAddress(billingFormData?.address, billingFormData?.address2, billingFormData?.zipCode, billingFormData?.city, billingFormData?.country.text) }}
        </div>
      </div>
      <div class="c-build-to-order__confirm-modal-section">
        <div class="c-build-to-order__confirm-modal-label">{{ $t('Delivery address') }}</div>
        <div class="c-build-to-order__confirm-modal-text">
          {{ $buildAddress(shippingFormData?.address, shippingFormData?.address2, shippingFormData?.zipCode, shippingFormData?.city, shippingFormData?.country.text) }}
        </div>
      </div>
      <template v-if="addressTypeFormCfg.addressType?.controlProps?.selectedOption?.code === 'company'">
        <div class="c-build-to-order__confirm-modal-section">
          <div class="c-build-to-order__confirm-modal-label">{{ $t('Business email') }}</div>
          <div class="c-build-to-order__confirm-modal-text">{{ email }}</div>
        </div>
        <div class="c-build-to-order__confirm-modal-section">
          <div class="c-build-to-order__confirm-modal-label">{{ $t('VAT number') }}</div>
          <div class="c-build-to-order__confirm-modal-text">{{ companyFormData?.vatNumber }}</div>
        </div>
        <div class="c-build-to-order__confirm-modal-section">
          <div class="c-build-to-order__confirm-modal-label">{{ $t('Company name') }}</div>
          <div class="c-build-to-order__confirm-modal-text">{{ companyFormData?.companyName }}</div>
        </div>
        <div class="c-build-to-order__confirm-modal-section">
          <div class="c-build-to-order__confirm-modal-label">{{ $t('Company registration number') }}</div>
          <div class="c-build-to-order__confirm-modal-text">{{ companyFormData?.companyRegistrationNumber }}</div>
        </div>
      </template>
    </div>
    <div class="c-build-to-order__confirm-modal-footer">
      <site-button v-bind="autoConvert || blindOrder ? fields.confirmConvertButton : fields.confirmButton" @click="submitOrder" />
    </div>
  </modal>
  <modal class="c-build-to-order__price-modal" ref="priceModalRef" closable>
    <jss-rich-text class="c-build-to-order__price-modal-title" :field="fields.summaryTitle" />
    <div class="c-build-to-order__price-modal-content">
      <label-value class="c-build-to-order__price-modal-amount" :label="filterInfo?.model" :value="$formatMoney(totalAmount, currency)" />
      <div class="c-build-to-order__config-section">
        <div class="c-build-to-order__config-section-title">
          <span>{{ $t('Details') }}</span>
          <!--          <span class="c-build-to-order__config-section-edit" @click="openMhp">{{ $t('Edit') }}</span>-->
        </div>
        <div class="c-build-to-order__config-section-content">
          <div class="c-build-to-order__config-section-sub" v-for="(feature, index) in features" :key="index">
            <!--                  <jss-image class="c-build-to-order__section-image" :media="fields.exteriorImage" />-->
            <div class="c-build-to-order__config-section-subtitle">{{ feature.type }}</div>
            <div class="c-build-to-order__vehicle-detail-content">
              <template v-for="(info, j) in feature.infos" :key="j">
                <label-value class="c-build-to-order__vehicle-detail-item" :label="info.label" :value="info.price ? $formatMoney(info.price.price, info.price.currency) : ''" />
                <div class="c-build-to-order__vehicle-detail-value" v-html="info.value" />
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="c-build-to-order__config-section">
        <div class="c-build-to-order__config-section-content">
          <label-value :label="$t('On the road price')" :value="$formatMoney(totalAmount, currency)" />
          <div class="c-build-to-order__config-section-block">
            <label-value
              :label="price.label"
              :value="$formatMoney(price.price, price.currency)"
              v-for="(price, index) of priceInfo.filter((x) => x.elementName !== 'Total Price' && x.visibility)"
              :key="index"
            />
            <!--                    <div class="c-build-to-order__config-section-description">-->
            <!--                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vestibulum interdum ex id condimentum. Nam lorem nisi,-->
            <!--                    </div>-->
          </div>
          <template v-if="benefitsList?.length > 0">
            <label-value :label="`${$t('Offer')}/${$t('Service')}`" :value="$formatMoney(benefitsAmount, currency)" />
            <div class="c-build-to-order__config-section-block">
              <label-value
                :label="benefits.name"
                :value="benefits.price ? $formatMoney(benefits.price, currency) : $t('Included offer').toLowerCase()"
                v-for="benefits in benefitsList"
                :key="benefits.id"
              />
            </div>
          </template>
          <label-value :label="$t('Total amount payable')" :value="$formatMoney(balancePayment, currency)" />
          <div class="c-build-to-order__config-section-block">
            <label-value :label="$t('On the road price')" :value="$formatMoney(totalAmount, currency)" />
            <label-value :label="$t('Total deposit')" :value="`-${$formatMoney(downPayment, currency)}`" />
          </div>
          <label-value :label="$t('To pay now* deposit fee')" :value="$formatMoney(downPayment, currency)" />
          <div class="c-build-to-order__config-section-block">
            <label-value :label="$t('Due today')" :value="$formatMoney(downPayment, currency)" />
          </div>
        </div>
      </div>
    </div>
    <div class="c-build-to-order__price-modal-toolbar">
      <div class="c-build-to-order__price-modal-toolbar-money" v-if="blindOrder">
        <span>{{ $t('Deduct from reservation to pay now') }}:</span>
        <span>{{ $formatMoney(0, currency) }}</span>
      </div>
      <div class="c-build-to-order__price-modal-toolbar-money" v-else>
        <span>{{ $t('To pay now') }}:</span>
        <span>{{ $formatMoney(downPayment, currency) }}</span>
      </div>
      <jss-rich-text class="c-build-to-order__price-desc" :field="fields.priceDescription" />
    </div>
  </modal>
  <notice-modal v-bind="fields.localeRedirectModal" ref="orderLocaleRedirectModalRef" />
  <notice-modal v-bind="fields.accountModal" ref="accountModalRef" />
  <notice-modal v-bind="fields.forbiddenPlaceOrderModal" ref="forbiddenPlaceOrderModalRef" />
</template>

<script>
/**
 * @typedef BuildToOrderFields
 * @property {SimpleField} firstTitle
 * @property {Form} verifyForm
 * @property {Form} mobileForm
 * @property {Form} emailForm
 * @property {SimpleField} emailFormLinkText
 * @property {SimpleField} mobileFormLinkText
 * @property {SimpleField} secondTitle
 * @property {SimpleField} sendCodeDesc
 * @property {SimpleField} codeHasSentDesc
 * @property {Form} addressTypeForm
 * @property {SimpleField} contactTitle
 * @property {Form} contactForm
 * @property {SimpleField} companyTitle
 * @property {Form} companyForm
 * @property {SimpleField} shippingTitle
 * @property {Form} shippingForm
 * @property {SimpleField} billingTitle
 * @property {Form} billingForm
 * @property {SimpleField} financeTitle
 * @property {ImageField} regulatoryImage
 * @property {Form} financeForm
 * @property {SimpleField} financeDescription
 * @property {SimpleField} diffAddressText
 * @property {SimpleField} confirmTitle
 * @property {ButtonField} confirmButton
 * @property {ButtonField} confirmConvertButton
 * @property {SimpleField} thirdTitle
 * @property {SimpleField} fourthTitle
 * @property {SimpleField} failedOverline
 * @property {SimpleField} failedTitle
 * @property {SimpleField} failedSubtitle
 * @property {SimpleField} failedErrorMessage
 * @property {SimpleField} failedBody
 * @property {ButtonField} configButton
 * @property {ButtonField} nextButton
 * @property {ButtonField} prevButton
 * @property {ButtonField} payButton
 * @property {ButtonField} contactButton
 * @property {ButtonField} retryButton
 * @property {ButtonField} priceButton
 * @property {SimpleField} modelTitle
 * @property {SimpleField} expectedDeliveryDate
 * @property {SimpleField} depositDescription
 * @property {SimpleField} priceDescription
 * @property {SimpleField} alternateTitle
 * @property {SimpleField} alternateSubtitle
 * @property {Form} alternateForm
 * @property {SimpleField} reservationConfirmTitle
 * @property {SimpleField} reservationConvertMsg
 * @property {SimpleField} reservationNotBelongToAccountError
 * @property {NoticeModal} localeRedirectModal
 * @property {NoticeModal} accountModal
 * @property {NoticeModal} forbiddenPlaceOrderModal
 * */
import { computed, getCurrentInstance, inject, nextTick, onBeforeUnmount, onMounted, reactive, toRefs, watch } from 'vue';
import { buildAddress, formatModel, getCurrentAlpha2Code, getGlobalConfigs, getPageAlpha2Code, isAppBrowser, isOnApp, scrollToTop, settingValue } from '@/utils/site-utils';
import useDevice from '@/hooks/useDevice';
import { transitions } from '@/utils/transitions';
import { addressTypes, CAPTCHA_SCENE, captchaTypes } from '@/utils/constants';
import { equalString } from '@/utils/string-utils';
import useAppStore from '@/store/appStore';
import api from '@/api';
import { isNullOrEmpty } from '@/utils/obj-utils';
import services from '../services';
import { loadStripe } from '@/utils/stripe-utils';
import { appendQuery, getBetterUrl, getQueryStrings, redirectToLang, redirectToLogin } from '@/utils/uri-utils';
import { canUseDOM, refreshPage } from '@/utils/dom-utils';
import { webStorage } from '@/utils/web-storage';
import { S_ORDER_INFO } from '@/utils/web-storage-keys';
import { gtmFormView, gtmFormStart, gtmFormStep, gtmFormSubmit, gtmPush } from '@/utils/gtm-utils';
import { analyzeFeatures, buildModelImgUrl, buildModelImgUrlNoBackdrop } from '@/utils/mhp-utils';
import { debounce, difference, merge } from 'lodash';
import { formatDate } from '@/utils/date-utils';
import { useRoute, useRouter } from 'vue-router';
import { updateDictionaryValue } from '@/services/dictService';
import { qtUtils } from '@/utils/ali-tracker-utils';
import { GET_CAPTCHA_SCENE, getCaptchaExecutor } from '@/utils/captcha-utils';

export default {
  name: 'BuildToOrder',
  props: {
    /**@type BuildToOrderFields*/
    fields: {
      type: Object
    },
    page: {
      type: Object
    },
    errorComponent: null,
    rendering: null
  },
  setup(props) {
    /**@type AppStore*/
    const appStore = useAppStore();
    const toast = inject('toast');
    const loading = inject('loading');
    const appMethods = inject('appMethods');
    const route = useRoute();
    const router = useRouter();
    const { deviceState } = useDevice();
    const { proxy } = getCurrentInstance();
    const { formType } = props.page.fields;
    const { $nativeCommon } = proxy;
    let getCaptcha, checkCaptcha;
    let token = null,
      transferCode = null,
      filterId = null,
      codeTimeout = null,
      confirmPayment = null,
      detailHref = null,
      clientSecret = null,
      redirectStatus = null,
      stripeHelper = null,
      stripe = null,
      blindOrderId = null,
      benefitSet = null,
      vin = null,
      vid = null;
    const [downPayment] = getGlobalConfigs(props.page, 'downPayment');
    const state = reactive({
      rootEl: null,
      toolbarEl: null,
      step: 1,
      loaded: false,
      accountType: 'email',
      submitted: false,
      paymentWasFailed: false,
      failedWidgetRef: null,
      vehicleDetailHasOpened: false,
      quotationHasOpened: false,
      personalDetailHasOpened: false,
      detailHasOpened: false,
      /**@type DynamicForm*/
      verifyFormRef: null,
      /**@type DynamicForm*/
      emailFormRef: null,
      /**@type DynamicForm*/
      mobileFormRef: null,
      useDifferentAddress: false,
      codeHasSent: false,
      codeWasVerified: false,
      mobileCaptchaVerified: false,
      emailCaptchaVerified: false,
      orderRules: [],
      orderRule: null,
      emailFormCfg: {
        email: {
          controlProps: {
            disabled: false
          },
          buttons: {
            btnSend: {
              visible: true
            },
            btnResend: {
              visible: false
            }
          }
        },
        code: {
          controlProps: {
            value: null
          },
          buttons: {
            btnVerify: {
              disabled: true
            }
          }
        }
      },
      mobileFormCfg: {
        mobile: {
          controlProps: {
            disabled: false
          },
          buttons: {
            btnSend: {
              visible: true
            },
            btnResend: {
              visible: false
            }
          }
        },
        code: {
          controlProps: {
            value: null
          },
          buttons: {
            btnVerify: {
              disabled: true
            }
          }
        }
      },
      addressTypeFormCfg: {
        addressType: {
          controlProps: {
            selectedOption: {
              code: 'private'
            }
          }
        }
      },
      contactFormCfg: {
        email: {
          visible: true
        },
        mobile: {
          visible: false,
          controlProps: {
            value: null,
            disabled: false
          }
        }
      },
      shippingFormCfg: {
        firstName: {
          controlProps: {
            value: null
          }
        },
        lastName: {
          controlProps: {
            value: null
          }
        },
        address: {
          controlProps: {
            value: null
          }
        },
        address2: {
          controlProps: {
            value: null
          }
        },
        zipCode: {
          controlProps: {
            value: null
          }
        },
        city: {
          controlProps: {
            value: null
          }
        },
        country: {
          controlProps: {
            options: [],
            selectedOption: null
          }
        },
        province: {
          visible: false,
          controlProps: {
            options: [],
            selectedOption: null
          }
        }
      },
      billingFormCfg: {
        firstName: {
          controlProps: {
            value: null
          }
        },
        lastName: {
          controlProps: {
            value: null
          }
        },
        address: {
          controlProps: {
            value: null
          }
        },
        address2: {
          controlProps: {
            value: null
          }
        },
        zipCode: {
          controlProps: {
            value: null
          }
        },
        city: {
          controlProps: {
            value: null
          }
        },
        country: {
          controlProps: {
            options: [],
            selectedOption: null
          }
        }
      },
      /**@type DynamicForm*/
      addressTypeFormRef: null,
      /**@type DynamicForm*/
      contactFormRef: null,
      /**@type DynamicForm*/
      companyFormRef: null,
      /**@type DynamicForm*/
      shippingFormRef: null,
      /**@type DynamicForm*/
      billingFormRef: null,
      /**@type DynamicForm*/
      consentFormRef: null,
      /**@type ModalRef*/
      confirmModalRef: null,
      payWidgetRef: null,
      configuration: null,
      carSeries: null,
      carModel: null,
      features: [],
      featureCodes: [],
      buttonFeatureTypes: [],
      priceIds: [],
      accePriceIds: [],
      priceInfo: [],
      packageList: [],
      filterInfo: null,
      totalAmount: null,
      mobile: null,
      email: null,
      addressTypeFormData: null,
      companyFormData: null,
      shippingFormData: null,
      billingFormData: null,
      consentFormData: null,
      orderInfo: null,
      modelImg: null,
      blindOrder: null,
      storeFormData: null,
      tradeInData: null,
      /**@type DynamicForm*/
      storeFormRef: null,
      storeFormCfg: {
        store: {
          controlProps: {
            options: [],
            selectedOption: null
          }
        }
      },
      /**@type DynamicForm*/
      financeFormRef: null,
      financeFormData: null,
      priceModalRef: null,
      alpha2Code: null,
      /**@type DynamicForm*/
      alternateFormRef: null,
      autoConvert: false,
      alternateInfo: null,
      seconds: 0,
      /**@type String*/
      scrollDirection: null,
      /**@type NoticeModalRef*/
      orderLocaleRedirectModalRef: null,
      /**@type NoticeModalRef*/
      forbiddenPlaceOrderModalRef: null,
      /**@type NoticeModalRef*/
      accountModalRef: null,
      /**@type TradeInRef*/
      tradeInRef: null,
      /**@type GeeCaptchaRef*/
      geeCaptchaRef: null,
      publicKey: null,
      benefitsList: [],
      benefitPriceIds: [],
      benefitsAmount: 0,
      bound: null,
      formulaNo: null,
      paymentFormulaNo: null,
      downPayment: Number(downPayment.value),
      emailChanged: false
    });
    const computes = {
      currency: computed(() => (isNullOrEmpty(state.priceInfo) ? '' : state.priceInfo[0].currency)),
      downPayment: computed(() => (isNullOrEmpty(state.blindOrder) && !state.autoConvert ? Number(state.downPayment) : 0)),
      balancePayment: computed(() => state.totalAmount - computes.downPayment.value),
      bindRequired: computed(() => appStore.hasLoggedIn && !state.bound?.email)
    };
    watch(
      () => state.step,
      (v) => {
        let stepName = '';
        switch (v) {
          case 1:
            {
              stepName = 'email_verify';
            }
            break;
          case 2:
            {
              stepName = 'personal_details';
            }
            break;
          case 3:
            {
              stepName = 'pay';
            }
            break;
          default:
            break;
        }
        gtmFormStep(formType?.value, props.fields.emailForm.id, appStore?.loginInfo, v.value, stepName, null);
      },
      { immediate: true }
    );
    const _qt = {
      trackStep1Clk(result) {
        qtUtils.trackBtnClickWithCode(props.page, props.fields.nextButton, 'car_order_step1_pg_clk', {
          is_success: result
        });
      },
      trackStep2Clk(result) {
        qtUtils.trackBtnClickWithCode(props.page, props.fields.nextButton, 'car_order_step2_pg_clk', {
          is_success: result,
          city: state.shippingFormData?.city,
          country: state.shippingFormData?.country.code,
          reservation_type: state.addressTypeFormData?.addressType
        });
      },
      trackPayClk(result) {
        qtUtils.trackBtnClickWithCode(props.page, props.fields.nextButton, 'car_order_step3_pg_clk', {
          is_success: result,
          city: state.shippingFormData?.city,
          country: state.shippingFormData?.country.code,
          reservation_type: state.addressTypeFormData?.addressType
        });
      }
    };
    const stepFn = {
      async step1() {
        let valid = false,
          formRef = state[`${state.accountType}FormRef`],
          formData;
        if (!state.codeWasVerified) {
          [valid, formData] = await formRef.validate();
          if (valid) {
            state.codeWasVerified = await _methods.verifyCaptcha();
            _qt.trackStep1Clk(state.codeWasVerified);
          } else {
            _qt.trackStep1Clk(false);
          }
        }
        if (state.codeWasVerified) {
          state.step++;
          state.mobile = formData?.mobile;
          state.email = formData?.email;
          await nextTick();
          scrollToTop();
          qtUtils.trackPv('car_order_step2_pg');
        }
      },
      async step2() {
        let addressTypeFormData = null,
          contactValid = true,
          contactFormData = null,
          companyValid = true,
          companyFormData = null,
          shippingValid = true,
          shippingFormData = null,
          consentValid = true,
          consentFormData = null,
          billingValid = true,
          billingFormData = null,
          storeValid = true,
          storeFormData = null,
          financeValid = true,
          financeFormData = null,
          alternateInfoValid = true,
          alternateInfo = null;
        [, addressTypeFormData] = await state.addressTypeFormRef.validate();
        if (!state.blindOrder) {
          [contactValid, contactFormData] = await state.contactFormRef.validate();
        }
        if (addressTypeFormData?.addressType.code === 'company') {
          [companyValid, companyFormData] = await state.companyFormRef.validate();
        }
        [shippingValid, shippingFormData] = await state.shippingFormRef.validate();
        billingFormData = shippingFormData;
        [consentValid, consentFormData] = await state.consentFormRef.validate();
        if (state.useDifferentAddress) {
          [billingValid, billingFormData] = await state.billingFormRef.validate();
        }
        if (props.fields.storeForm && state.storeFormRef) {
          [storeValid, storeFormData] = await state.storeFormRef.validate();
        }
        if (props.fields.financeForm && state.financeFormRef) {
          [financeValid, financeFormData] = await state.financeFormRef.validate();
        }
        if (!isNullOrEmpty(state.blindOrder)) {
          state.mobile = {
            area: {
              code: state.blindOrder.areaCode
            },
            number: state.blindOrder.buyerPhone
          };
        } else if (state.accountType === 'email') {
          state.mobile = contactFormData.mobile;
        } else if (state.accountType === 'mobile') {
          state.email = contactFormData.email;
        }
        if (state.alternateFormRef) {
          [alternateInfoValid, alternateInfo] = await state.alternateFormRef.validate();
        }
        const [tradeInValid, tradeInData] = await state.tradeInRef.validate();
        if (!contactValid || !companyValid || !shippingValid || !billingValid || !consentValid || !storeValid || !financeValid || !alternateInfoValid || !tradeInValid) {
          _qt.trackStep2Clk(false);
          return;
        }
        state.addressTypeFormData = addressTypeFormData;
        state.companyFormData = companyFormData;
        state.shippingFormData = shippingFormData;
        state.billingFormData = billingFormData;
        state.consentFormData = consentFormData;
        state.alternateInfo = alternateInfo;
        state.storeFormData = storeFormData;
        state.tradeInData = tradeInData;
        state.financeFormData = financeFormData;
        await state.confirmModalRef.open();
      }
    };
    const _methods = {
      async loginByToken(token) {
        const [userInfo] = await api.cidp.user.info(null, null, {
          headers: {
            authorization: token
          }
        });
        if (userInfo) {
          userInfo.token = token;
          await appStore.setLoginInfo(userInfo);
        }
      },
      startCodeCountdown(accountType, seconds) {
        const formItemCfg = state[`${accountType}FormCfg`];
        const account = formItemCfg[accountType];
        const { btnSend, btnResend } = account.buttons;
        btnSend.visible = false;
        btnResend.visible = true;
        btnResend.disabled = true;
        const fn = (seconds) => {
          state.seconds = seconds;
          if (seconds > 0) {
            codeTimeout = setTimeout(() => {
              fn(--seconds);
            }, 1000);
          } else {
            btnResend.disabled = false;
          }
        };
        fn(seconds);
      },
      async onFormButtonClick(accountType, e, item, btnName) {
        const formRef = state[`${accountType}FormRef`];
        const formCfg = state[`${accountType}FormCfg`];
        const { code } = formCfg;
        const { btnVerify } = code.buttons;
        switch (btnName) {
          case 'btnSend':
          case 'btnResend':
            {
              const [valid, account] = formRef.validateItemByName(accountType, true);
              if (!valid) return;
              loading.show();
              let geeResult = await state.geeCaptchaRef.validate();
              const body = merge(
                {
                  captchaType: captchaTypes[accountType],
                  captchaScene: CAPTCHA_SCENE.registerLogin
                },
                geeResult
              );
              if (accountType === 'mobile') {
                merge(body, {
                  mobileAreaCode: account?.area?.code,
                  phone: account?.number
                });
              }
              if (accountType === 'email') {
                merge(body, {
                  email: account
                });
              }
              let res, ex;
              if (computes.bindRequired.value) {
                merge(body, {
                  captchaScene: CAPTCHA_SCENE.bindEmail
                });
              }
              [res, ex] = await getCaptcha(null, body);
              if (ex) {
                await toast.showEx(ex);
                loading.hide();
                return;
              }
              state.codeHasSent = true;
              _methods.startCodeCountdown(accountType, 60);
              btnVerify.disabled = false;
              loading.hide();
            }
            break;
          case 'btnVerify':
            break;
          default:
            break;
        }
      },
      async verifyCaptcha() {
        const { accountType } = state;
        const formRef = state[`${accountType}FormRef`];
        const formCfg = state[`${accountType}FormCfg`];
        const account = formCfg[accountType];
        const { code } = formCfg;
        const { btnResend } = account.buttons;
        const [valid, formData] = await formRef.validate();
        if (!valid) return false;
        loading.show();
        const accountData = formData[accountType];
        const body = {
          captchaType: captchaTypes[accountType],
          captcha: formData.code
        };
        if (accountType === 'mobile') {
          merge(body, {
            mobileAreaCode: accountData?.area?.code,
            phone: accountData?.number
          });
        } else {
          merge(body, {
            email: accountData
          });
        }
        if (computes.bindRequired.value) {
          merge(body, {
            captchaScene: CAPTCHA_SCENE.bindEmail
          });
        }
        const [, ex] = await checkCaptcha(null, body);
        if (ex) {
          await toast.showEx(ex);
          loading.hide();
          return false;
        }
        if (codeTimeout) {
          clearTimeout(codeTimeout);
          btnResend.disabled = false;
          formRef.setItemInfoMsg(accountType, null);
        }
        formRef.setItemSuccess(accountType);
        formRef.setItemMsg('code', null);
        code.disabled = true;
        state[`${accountType}CaptchaVerified`] = true;
        state.contactFormCfg.email.visible = accountType === 'mobile';
        state.contactFormCfg.mobile.visible = accountType === 'email';
        loading.hide();
        return true;
      },
      async analyzeConfiguration(res, isStock = false) {
        console.log('configuration', res);
        state.configuration = res;
        const [currency, features, priceIds, accePriceIds, featureCodes, packageList, buttonFeatureTypes] = analyzeFeatures(res, isStock);
        state.filterInfo = {
          id: res.id,
          basicPrice: res.basicPrice,
          engineeringCode: res.engineeringCode,
          groupName: res.groupName,
          model: formatModel(res.model),
          pno12: res.pno12,
          series: res.series
        };
        state.currency = currency;
        state.features = features;
        state.featureCodes = featureCodes;
        state.buttonFeatureTypes = buttonFeatureTypes;
        state.priceIds = priceIds;
        state.accePriceIds = accePriceIds;
        state.packageList = packageList;
        state.carSeries = await services.site.getCarSeriesByCode(res.seriesCode);
        state.carModel = await services.site.getCarModelByCode(res.seriesCode, res.modelCode);
      },
      async loadConfiguration() {
        const [res, ex] = await api.configuration.get(null, {
          filterId,
          transferCode,
          engineeringCode: 1,
          showPrice: 1
        });
        if (ex) {
          await toast.showEx(ex);
          return false;
        }
        await _methods.analyzeConfiguration(res);
        state.modelImg = buildModelImgUrl(props.page, filterId, res.series, state.buttonFeatureTypes, state.carModel);
      },
      async loadStockDetail() {
        const [res, ex] = await api.order.stockDetailByVin(null, {
          country: state.alpha2Code,
          vin: vin
        });
        if (!res?.configurationDetails || !res?.detailPrice) {
          toast.showEx(ex);
          return;
        }
        transferCode = res.transferCode;
        filterId = res.filterId;
        vid = res.vid;
        const configuration = {
          filterName: res.filterName,
          seriesCode: res.seriesCode,
          modelCode: res.modelCode,
          series: res.series,
          model: res.vehicleModel,
          nextLevels: JSON.parse(res.configurationDetails)
        };
        console.log('configuration', configuration);
        await _methods.analyzeConfiguration(configuration, true);
        const priceList = JSON.parse(res.detailPrice);
        const priceInfo = (priceList ?? []).filter((x) => !['PFU', 'IPT'].includes(x.elementName));
        state.priceInfo = await updateDictionaryValue(priceInfo, 'elementName', 'label', (x) => x.visibility);
        state.totalAmount =
          state.priceInfo.find((x) => x.elementName === 'Total Price')?.price ??
          Math.max.apply(
            null,
            state.priceInfo.filter((x) => !isNaN(x.price)).map((x) => Number(x.price))
          ) ??
          null;
        state.modelImg = res?.images?.productDisplay[0].externalUrl;
      },
      async loadBenefits() {
        const alpha2Code = getPageAlpha2Code(props.page);
        if (!isNullOrEmpty(benefitSet)) {
          const resAll = await Promise.all(
            benefitSet.split(',').map((x) =>
              api.benefits.get(null, {
                id: x,
                language: props.page.itemLanguage
              })
            )
          );
          const benefitsList = [];
          let totalBenefitsAmount = 0;
          for (let res of resAll) {
            const [benefitsRes] = res;
            if (benefitsRes) {
              const { id, name, remark, rightsExplain, priceId, priceList, multilingualList } = benefitsRes;
              const multilingualListData = JSON.parse(multilingualList);
              const currentLangData = multilingualListData?.find((x) => equalString(x.dictValue?.split('-')[0], props.page.itemLanguage?.split('-')[0]));
              const priceItem = priceList?.find((x) => equalString(alpha2Code, x.country));
              let price = Number(priceItem?.price) ?? 0;
              if (isNaN(price)) {
                price = 0;
              }
              const currency = priceItem?.currency;
              benefitsList.push({
                id,
                code: name,
                name: currentLangData?.name ?? name,
                remark: currentLangData?.remark ?? remark,
                rightsExplain: currentLangData?.rightsExplain ?? rightsExplain,
                price,
                priceId,
                currency
              });
              totalBenefitsAmount += price;
            }
          }
          benefitsList.sort((a, b) => b.price - a.price);
          state.benefitsList = benefitsList;
          state.benefitsAmount = totalBenefitsAmount;

          let benefitsInfo = null;
          let benefitPriceIds = [];
          for (let bl of state.benefitsList) {
            if (!isNullOrEmpty(bl?.priceId)) {
              benefitPriceIds.push(bl?.priceId);
              benefitsInfo = {
                priceId: bl?.priceId ?? '',
                rightsId: bl?.id ?? ''
              };
            }
          }
          state.benefitPriceIds = benefitPriceIds;
        }
      },
      async loadPrice() {
        [state.formulaNo, state.paymentFormulaNo] = await services.site.getFormulaNos(props.page, state.configuration?.series);
        const alpha2Code = getCurrentAlpha2Code();
        let benefitPriceIds = [];
        for (let bl of state.benefitsList) {
          if (!isNullOrEmpty(bl?.priceId)) {
            benefitPriceIds.push(bl?.priceId);
          }
        }
        const priceBody = {
          formulaNo: state.formulaNo,
          country: alpha2Code,
          priceCategory: 'Sale Price',
          sourceRange: ['Car Configurator', 'Product Center'],
          source: 'Car Configurator',
          sourceItemId: filterId,
          sourceItemIds: state.priceIds, // ['188,1567401186576506882', '188,1567401187889324033'],
          additionalSourceItemIds: [...state.accePriceIds]
        };
        if (benefitPriceIds.length > 0) {
          priceBody.additionalSourceItemIds.push(...benefitPriceIds);
        }
        if (alpha2Code === 'NO') {
          const [resWeight] = await api.order.getFilterWeight(null, {
            filterId,
            transferCode,
            language: props.page.itemLanguage
          });
          if (!isNullOrEmpty(resWeight)) {
            priceBody.inputName = [
              {
                elementName: 'Weight',
                value: resWeight
              }
            ];
          }
        }
        const [resPrice, exPrice] = await api.configuration.priceDetail(null, priceBody);
        if (exPrice) {
          await toast.showEx(exPrice);
          return false;
        }
        const priceInfo = (resPrice?.priceInfo ?? []).filter((x) => !['PFU', 'IPT'].includes(x.elementName));
        state.priceInfo = await updateDictionaryValue(priceInfo, 'elementName', 'label', (x) => x.visibility);
        state.totalAmount =
          state.priceInfo.find((x) => x.elementName === 'Total Price')?.price ??
          Math.max.apply(
            null,
            state.priceInfo.filter((x) => !isNaN(x.price)).map((x) => Number(x.price))
          ) ??
          null;
        return true;
      },
      async getOrderRules() {
        const [downPaymentField] = getGlobalConfigs(props.page, 'downPayment');
        const alpha2Code = getPageAlpha2Code(props.page);
        const [resRule] = await api.order.rule.query(null, {
          items: [
            {
              orderType: 200,
              carSeriesCode: state.configuration?.seriesCode,
              carModelCode: state.configuration?.modelCode ?? '',
              country: alpha2Code
            }
          ]
        });
        state.orderRules = resRule ?? [];
        if (resRule?.length) {
          state.orderRule = resRule[0];
          state.downPayment = state.orderRule?.orderAmountNumber ?? Number(downPaymentField?.value);
        }
      },
      async checkPayment() {
        loading.show();
        // const { productName, productVariant } = props.page?.fields || {};

        // gtmPush({
        //   event: 'generate_lead',
        //   lead_id: clientSecret?.replace(/_secret/gi, ''),
        //   user_id: appStore.loginInfo?.lotusId,
        //   product_name: productName?.value,
        //   product_variant: productVariant?.value
        // });
        if (redirectStatus === 'succeeded') {
          const [pk] = getQueryStrings('pk');
          stripeHelper = await loadStripe(props.page, toast);
          stripe = stripeHelper.initSdk({ publicKey: pk });
          await _methods.watching();
        } else {
          state.loaded = true;
          state.paymentWasFailed = true;
          const [resP, exP] = await api.order.intactPay(null, {
            orderId: state.orderInfo.orderId,
            oneId: appStore?.loginInfo?.lotusId,
            type: 1
          });
          if (exP) {
            await toast.showEx(exP);
            loading.hide();
            return;
          }
          await _methods.loadConfiguration();
          await _methods.loadBenefits();
          await _methods.loadPrice();
          await loading.hide();
          await nextTick();
          stripeHelper = await loadStripe(props.page, toast);
          stripe = stripeHelper.initSdk(resP);
          setTimeout(() => {
            confirmPayment = stripeHelper.initWidget(stripe, state.failedWidgetRef, resP);
          }, 200);
        }
      },
      async watching() {
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
        switch (paymentIntent.status) {
          case 'succeeded':
            if (!detailHref || !state.orderInfo?.orderId) return;
            setTimeout(() => {
              window.location = appendQuery(detailHref, {
                orderId: state.orderInfo.orderId
              });
            }, 1000);
            break;
          case 'processing':
            console.log('Your payment is processing.');
            setTimeout(() => {
              _methods.watching();
            }, 2000);
            break;
          case 'requires_payment_method':
          default:
            state.loaded = true;
            state.paymentWasFailed = true;
            loading.hide();
            await nextTick();
            confirmPayment = stripeHelper.initWidget(stripe, state.failedWidgetRef, state.orderInfo);
            break;
        }
      },
      async checkReservation() {},
      async accountSignOut() {
        const button = await state.accountModalRef.open();
        if (button.buttonCode === 'sign-out') {
          await appMethods.justLogout();
          refreshPage();
        }
      },
      gtmCheckout(step, event) {
        let region = 'GB',
          language = 'en';
        if (props.page.itemLanguage !== 'en') {
          [language, region] = props.page.itemLanguage.split('-');
        }
        const options = [];
        for (let feature of state.features) {
          for (let info of feature.infos) {
            options.push({
              option_id: info.code,
              option_name: info.label,
              option_category: feature.type,
              option_price: info.price?.price
            });
          }
        }
        gtmPush({
          event,
          checkout_step: step,
          filter_id: filterId,
          transfer_code: transferCode,
          options,
          region,
          language,
          currency: computes.currency.value
        });
      },
      fillReservationToBto() {
        state.emailFormCfg.email.controlProps.value = state.blindOrder.email;
        if (state.blindOrder.storeId && state.storeFormRef) {
          state.storeFormRef.updateItem('store', {
            controlProps: {
              selectedOption: state.storeFormCfg.store.controlProps.options.find((x) => x.code === state.blindOrder.storeId)
            }
          });
        }
        if (state.blindOrder.detailAddress?.name?.indexOf(' ') > 0) {
          const [firstName, ...lastName] = state.blindOrder.detailAddress?.name?.split(' ');
          state.shippingFormCfg.firstName.controlProps.value = firstName;
          state.shippingFormCfg.lastName.controlProps.value = lastName.join(' ');
        }
        if (state.blindOrder.detailAddress?.street.indexOf(',') > 0) {
          const [address, address2] = state.blindOrder.detailAddress?.street.split(',');
          state.shippingFormCfg.address.controlProps.value = address;
          state.shippingFormCfg.address2.controlProps.value = address2;
        }
        state.shippingFormCfg.zipCode.controlProps.value = state.blindOrder.detailAddress?.zipCode;
        state.shippingFormCfg.city.controlProps.value = state.blindOrder.detailAddress?.city;
        if (_methods.isReservationShippingDiffFromBilling()) {
          state.useDifferentAddress = true;
          if (state.blindOrder.billingAddress?.name?.indexOf(' ') > 0) {
            const [firstName, lastName] = state.blindOrder.billingAddress?.name?.split(' ');
            state.billingFormCfg.firstName.controlProps.value = firstName;
            state.billingFormCfg.lastName.controlProps.value = lastName;
          }
          if (state.blindOrder.billingAddress?.street.indexOf(',') > 0) {
            const [address, address2] = state.blindOrder.billingAddress?.street.split(',');
            state.billingFormCfg.address.controlProps.value = address;
            state.billingFormCfg.address2.controlProps.value = address2;
          }
          state.billingFormCfg.zipCode.controlProps.value = state.blindOrder.billingAddress?.zipCode;
          state.billingFormCfg.city.controlProps.value = state.blindOrder.billingAddress?.city;
        }
      },
      isReservationShippingDiffFromBilling() {
        if (!state.blindOrder) return false;
        const { detailAddress, billingAddress } = state.blindOrder;
        const keys = ['name', 'street', 'city', 'country'];
        for (let key of keys) {
          if (detailAddress[key] !== billingAddress[key]) return true;
        }
        return false;
      },
      async updateProvinceList(alpha2Code) {
        console.log('alpha2Code', alpha2Code);
        if (equalString(alpha2Code, 'IT')) {
          console.log('ipt true');
          merge(state.shippingFormCfg.province, {
            visible: true,
            controlProps: {
              options: await services.site.getProvinceOptions(alpha2Code),
              selectedOption: null
            }
          });
          console.log('state.shippingFormCfg.province', state.shippingFormCfg.province);
        } else {
          state.shippingFormCfg.province.visible = false;
          state.shippingFormCfg.province.controlProps.options = [];
          state.shippingFormCfg.province.controlProps.selectedOption = null;
        }
      },
      async checkBind() {
        const [bindRes] = await api.cidp.bindable();
        console.log('bindRes', bindRes);
        if (bindRes) {
          state.bound = bindRes;
        } else {
          state.bound = {
            phone: true,
            email: true
          };
        }
      }
    };
    const methods = {
      onEmailChange: () => {
        if (!state.emailChanged) {
          state.emailChanged = true;
          gtmFormStart(formType?.value, props.fields.emailForm.id);
        }
      },
      toggleDetailHasOpened() {
        state.detailHasOpened = !state.detailHasOpened;
        if (state.detailHasOpened) {
          document.documentElement.classList.add('modal-open');
        } else {
          document.documentElement.classList.remove('modal-open');
        }
      },
      switchAccountType() {
        state.accountType = state.accountType === 'mobile' ? 'email' : 'mobile';
      },
      goPrev: debounce(async () => {
        const { accountType } = state;
        const formRef = state[`${accountType}FormRef`];
        const { email, code } = state.emailFormCfg;
        await appStore.setLoginInfo(null);
        email.controlProps.value = '';
        email.buttons.btnSend.visible = true;
        email.buttons.btnResend.visible = false;
        code.controlProps.value = '';
        code.buttons.btnVerify.disabled = true;
        state.codeWasVerified = false;
        formRef.setItemMsg('email', null);
        formRef.setItemMsg('code', null);
        state.step -= 1;
        await nextTick();
        scrollToTop();
      }, 100),
      goNext: debounce(() => {
        stepFn[`step${state.step}`]();
      }, 100),
      async onMobileFormButtonClick(e, item, btnName) {
        await _methods.onFormButtonClick(state.accountType, e, item, btnName);
      },
      async onEmailFormButtonClick(e, item, btnName) {
        await _methods.onFormButtonClick(state.accountType, e, item, btnName);
      },
      async submitOrder() {
        state.confirmModalRef.close().catch();
        loading.show();
        _methods.gtmCheckout('1', 'checkout_verify_code');
        _methods.gtmCheckout('2', `checkout`);
        const birthday = formatDate(state.shippingFormData.dateOfBirth);
        if (!appStore?.hasLoggedIn) {
          const [loginRes, loginEx] = await api.cidp.bto[`${state.accountType}RegisterLogin`](null, {
            email: state.email,
            firstName: state.shippingFormData.firstName,
            lastName: state.shippingFormData.lastName,
            mobileAreaCode: state.mobile?.area.code,
            phone: state.mobile?.number,
            terms: true,
            zipcode: state.shippingFormData.zipCode,
            source: isOnApp(route) ? '102' : '202',
            password: '',
            title: state.companyFormData?.title ?? '',
            middleName: '',
            address: state.shippingFormData.address,
            address2: state.shippingFormData.address2,
            city: state.shippingFormData.city ?? '',
            country: state.shippingFormData.country.code,
            contactsAddress: buildAddress(state.shippingFormData.address, state.shippingFormData.address2, state.shippingFormData.city, state.shippingFormData.country.text),
            birthday,
            preferLanguage: props.page.itemLanguage,
            communicatePreference: 0,
            businessEmail: state.email ?? '',
            companyName: state.companyFormData?.companyName ?? '',
            vatNumber: state.companyFormData?.vatNumber ?? state.alternateInfo?.vatNumber ?? '',
            companyRegistrationNumber: state.companyFormData?.companyRegistrationNumber ?? '',
            accountType: addressTypes[state.addressTypeFormData?.addressType.code] ?? 1
          });
          if (loginEx) {
            loading.hide();
            let opts = null;
            if (loginEx.code === 68013002) {
              opts = {
                okText: props.fields.changeEmailText?.value ?? 'CHANGE THE EMAIL ADDRESS'
              };
            }
            await toast.showEx(loginEx, opts);
            if (loginEx.code === 68013002) {
              state.emailFormCfg.email.controlProps.value = '';
              state.emailFormCfg.code.controlProps.value = '';
              state.step = 1;
            }
            _qt.trackStep2Clk(false);
            return;
          }
          appStore.updateLoginInfo(loginRes);
          await _methods.checkBind();
        } else {
          if (!state.bound?.email) {
            const accountType = addressTypes[state.addressTypeFormData?.addressType.code] ?? 1;
            const updateBody = {
              registerType: 1,
              email: state.email,
              firstName: state.shippingFormData.firstName,
              lastName: state.shippingFormData.lastName,
              mobileAreaCode: state.mobile?.area.code,
              phone: state.mobile?.number,
              terms: true,
              zipcode: state.shippingFormData.zipCode,
              source: isOnApp(route) ? '102' : '202',
              password: '',
              title: state.companyFormData?.title ?? '',
              middleName: '',
              address: state.shippingFormData.address,
              address2: state.shippingFormData.address2,
              city: state.shippingFormData.city ?? '',
              country: state.shippingFormData.country.code,
              contactsAddress: buildAddress(state.shippingFormData.address, state.shippingFormData.address2, state.shippingFormData.city, state.shippingFormData.country.text),
              street: buildAddress(state.shippingFormData.address, state.shippingFormData.address2),
              birthday,
              preferLanguage: props.page.itemLanguage,
              communicatePreference: 0,
              businessEmail: accountType === 1 ? '' : state.email ?? '',
              companyName: state.companyFormData?.companyName ?? '',
              vatNumber: state.companyFormData?.vatNumber ?? state.alternateInfo?.vatNumber ?? '',
              companyRegistrationNumber: state.companyFormData?.companyRegistrationNumber ?? '',
              accountType
            };
            const [, updateEx] = await api.cidp.user.update(null, updateBody);
            if (updateEx) {
              await toast.showEx(updateEx);
              loading.hide();
              _qt.trackStep2Clk(false);
              return;
            }
          }
        }
        const vehicleInfo = {
          series: state.configuration?.series,
          model: state.configuration?.model,
          featureList: state.featureCodes.join(','),
          packageList: [...state.packageList],
          saleCode: '',
          filter: state.configuration?.filterName,
          transferCode: transferCode,
          vid: vid ?? '',
          vin: vin ?? '',
          pno12: state.configuration?.pno12,
          // image: '40c465e4-64ae-443e-a01c-03a921ea1997', // state.modelImg,
          imageUrl: state.modelImg,
          imageUrlNoBackdrop: buildModelImgUrlNoBackdrop(props.page, state.configuration?.series, state.carModel, state.buttonFeatureTypes),
          filterId: filterId,
          infoJson: JSON.stringify(
            state.features.map((x) => ({
              type: x.type,
              infos: x.infos.map((i) => ({
                label: i.label,
                value: i.value,
                price: i.price?.price,
                currency: i.price?.currency
              }))
            })) ?? []
          )
        };
        const paymentInfo = {
          currency: computes.currency.value,
          paymentScheme: '3',
          lendingInstitutions: '',
          discount: '',
          totalAmount: state.totalAmount,
          balancePaymentAmount: computes.balancePayment.value,
          loanMethod: '',
          paymentMethod: '1',
          loanTerm: '',
          totalLoans: '',
          downPaymentAmount: state.blindOrder?.paymentAmountNumber ?? state.downPayment
        };
        let benefitsInfo = null;
        let benefitPriceIds = [];
        for (let bl of state.benefitsList) {
          if (!isNullOrEmpty(bl?.priceId)) {
            benefitPriceIds.push(bl?.priceId);
            benefitsInfo = {
              priceId: bl?.priceId ?? '',
              rightsId: bl?.id ?? ''
            };
          }
        }
        const soRightsAppCreateCo = {
          ...benefitsInfo,
          priceIds: [...benefitPriceIds, ...state.accePriceIds]
        };
        const allConsents = [
          ...new Set([
            ...state.contactFormRef.getVerifiedConsents(),
            ...state.companyFormRef.getVerifiedConsents(),
            ...state.shippingFormRef.getVerifiedConsents(),
            ...state.consentFormRef.getVerifiedConsents()
          ])
        ];
        const financeLeadTypes = state.financeFormRef.getLeadTypes();
        const consentLeadTypes = state.consentFormRef.getLeadTypes();
        const leadTypes = [...financeLeadTypes, ...consentLeadTypes];
        const newsletterConsents = state.consentFormData?.termsCondition ? state.consentFormRef.getItemConsents('termsCondition') : [];
        const bindConsents = difference(allConsents, newsletterConsents);
        const leadsDetails = {};
        const [res, ex] = await services.order.intactSubmit({
          page: props.page,
          addressType: state.addressTypeFormData?.addressType,
          loginInfo: appStore?.loginInfo,
          mobile: state.mobile,
          email: state.email ?? state.blindOrder?.email,
          filterId,
          priceIds: [...state.priceIds],
          vehicleInfo,
          paymentInfo,
          companyFormData: state.companyFormData,
          shippingFormData: state.shippingFormData,
          billingFormData: state.billingFormData,
          consents: bindConsents,
          blindOrder: state.blindOrder,
          alternateInfo: state.alternateInfo,
          storeFormData: state.storeFormData,
          tradeInData: state.tradeInData,
          financeFormData: state.financeFormData,
          soRightsAppCreateCo,
          formulaNo: state.paymentFormulaNo,
          orderRule: state.orderRule,
          orderSource: isOnApp(route) ? 102 : 202
        });
        if (ex) {
          loading.hide();
          await toast.showEx(ex);
          _qt.trackStep2Clk(false);
          return;
        }
        if (leadTypes?.length) {
          leadTypes.forEach((l) => {
            leadsDetails[l.leadType] = res ?? null;
          });
        }
        if (!isNullOrEmpty(state.blindOrder) || state.autoConvert) {
          if (isAppBrowser()) {
            $nativeCommon.goPage({ type: 3, params: { orderId: res.orderId } });
            loading.hide();
            return;
          }
          window.location = appendQuery(detailHref, {
            orderId: res.orderId
          });
          return;
        }
        if (isAppBrowser()) {
          $nativeCommon.goPage({ type: 1, params: { orderId: res.orderId } });
          loading.hide();
          return false;
        }
        const [resP, exP] = await api.order.intactPay(null, {
          orderId: res.orderId,
          oneId: appStore?.loginInfo?.lotusId,
          type: 1
        });
        if (exP) {
          await toast.showEx(ex);
          _qt.trackStep2Clk(false);
          return;
        }
        if (state.consentFormData?.CPTC) {
          const cptcBody = merge(
            {
              firstName: state.shippingFormData.firstName,
              middleName: state.shippingFormData.middleName ?? '',
              lastName: state.shippingFormData.lastName,
              email: state.shippingFormData.email,
              countryRegion: state.alpha2Code,
              channel: 'Official Website'
            },
            state.companyFormData?.externalData,
            state.shippingFormData?.externalData,
            state.billingFormData?.externalData,
            state.consentFormData?.externalData
          );
          if (!isNullOrEmpty(state.consentFormData.isAboveTwenty)) {
            merge(cptcBody, { isAboveTwenty: state.consentFormData.isAboveTwenty });
          }
          const [newsletterRes] = await api.lcms.crm.cptc.create(null, cptcBody);
          if (leadTypes?.length) {
            leadTypes.forEach((l) => {
              if (equalString(l.consentType, 'CPTC')) {
                leadsDetails[l.leadType] = newsletterRes?.leadId ?? null;
              }
            });
          }
          const formDetails = {
            selected_model: state.configuration?.series ?? null,
            selected_location_name: null,
            selected_location_type: null,
            selected_location_date: null,
            selected_location_time: null,
            customer_type: state.addressTypeFormData?.addressType?.code ?? null,
            dealer_name: null
          };
          // api.crm.newsletter
          //   .subscribe(null, body)
          //   .then((newsletterRes) => {
          //     if (leadTypes?.length) {
          //       leadTypes.forEach((l) => {
          //         if (equalString(l.consentType, 'termsCondition')) {
          //           leadsDetails[l.leadType] = newsletterRes.leadId ?? null;
          //         }
          //       });
          //     }
          //   })
          //   .catch();
          gtmFormSubmit(
            formType?.value,
            props.fields.emailForm.id,
            {
              email: state.email,
              mobileAreaCode: state.mobile.area.code,
              phone: state.mobile.number
            },
            formDetails,
            leadsDetails
          );
        }
        state.orderInfo = res;
        webStorage.set(S_ORDER_INFO, res);
        _qt.trackStep2Clk(true);
        state.step++;
        await nextTick();
        scrollToTop();
        const stripeHelper = await loadStripe(props.page, toast);
        if (!stripeHelper) return;
        const stripe = stripeHelper.initSdk(resP);
        state.publicKey = resP?.publicKey;
        confirmPayment = stripeHelper.initWidget(stripe, state.payWidgetRef, resP);
        _methods.gtmCheckout('3', `checkout`);
        qtUtils.trackPv('car_order_step3_pg', {
          order_number: res.orderNo,
          model_selection: state.configuration?.model
        });
        loading.hide();
      },
      onPay: debounce(async () => {
        loading.show();
        try {
          const addParams = {};
          if (!isNullOrEmpty(state.publicKey)) {
            addParams.pk = state.publicKey;
          }
          const redirectUrl = appendQuery(window.location.href, addParams, ['payment_intent', 'payment_intent_client_secret', 'redirect_status']);
          const error = await confirmPayment(redirectUrl);
          if (error) {
            loading.hide();
            _qt.trackPayClk(false);
          } else {
            _qt.trackPayClk(true);
          }
        } catch {
          loading.hide();
          _qt.trackPayClk(false);
        }
      }, 1000),
      async onShippingFormChange(key, value) {
        switch (key) {
          case 'country': {
            await _methods.updateProvinceList(value.code);
            break;
          }
          default:
            break;
        }
      },
      openPriceModal() {
        state.priceModalRef.open();
      }
    };
    if (canUseDOM()) {
      loading.show();
      document.documentElement.classList.add('p-build-to-order');
    }
    onMounted(async () => {
      state.alpha2Code = getPageAlpha2Code(props.page);
      [token, transferCode, filterId, clientSecret, redirectStatus, blindOrderId, benefitSet, vin, vid] = getQueryStrings(
        'access_token',
        'transfer_code',
        'filter_id',
        'payment_intent_client_secret',
        'redirect_status',
        'blind_order_id',
        'benefit_set',
        'vin',
        'vid'
      );
      const [detailLink] = getGlobalConfigs(props.page, 'btoDetailLink');
      detailHref = detailLink.value?.href;
      if (!detailHref) {
        await toast.showEx(null);
        return;
      }
      detailHref = getBetterUrl(detailHref, props.page.itemLanguage, true);
      if (clientSecret) {
        state.orderInfo = webStorage.get(S_ORDER_INFO);
        await _methods.checkPayment();
      } else {
        if (isAppBrowser()) {
          const tkObj = await $nativeCommon.getTokenAsync();
          if (isNullOrEmpty(tkObj?.token)) {
            $nativeCommon.login();
            return;
          }
          await _methods.loginByToken(tkObj?.token);
        } else if (!isNullOrEmpty(token)) {
          await _methods.loginByToken(token);
        }
        if (appStore?.hasLoggedIn) {
          await _methods.accountSignOut();
        }
        if (!isNullOrEmpty(vin)) {
          await _methods.loadStockDetail();
        } else {
          await _methods.loadConfiguration();
          await _methods.loadBenefits();
          await _methods.loadPrice();
        }
        await _methods.getOrderRules();
        if (!isNullOrEmpty(blindOrderId)) {
          if (!appStore?.hasLoggedIn) {
            redirectToLogin(props.page);
          }
          let [blindOrder, reservationDetailEx] = await api.order.detail(null, { id: blindOrderId });
          if (reservationDetailEx?.code === 54190013) {
            await toast.showError(props.fields.reservationNotBelongToAccountError?.value ?? 'The reservation order passed to the order page does not belong to the current account');
          }
          if (['200', 200].includes(blindOrder?.orderStatus)) {
            state.blindOrder = blindOrder;
          }
          if (!isNullOrEmpty(state.blindOrder)) {
            const [needRedirect, targetLang] = services.order.checkOrderLang(props.page, state.blindOrder.country);
            if (needRedirect) {
              await state.orderLocaleRedirectModalRef.open();
              redirectToLang(targetLang);
            }
            if (!appStore?.hasLoggedIn) {
              redirectToLogin(props.page, router);
              return;
            }
            state.contactFormCfg.mobile.visible = false;
            _methods.fillReservationToBto();
          }
        } else {
          if (appStore?.hasLoggedIn) {
            await _methods.checkReservation();
          }
        }
        if (appStore?.hasLoggedIn) {
          await _methods.checkBind();
          if (state.bound?.email) {
            state.email = state.blindOrder?.email ?? appStore?.loginInfo?.email;
            state.step = 2;
            state.contactFormCfg.email.visible = isNullOrEmpty(state.email);
            state.contactFormCfg.mobile.visible = true;
          }
        }
        _methods.updateProvinceList(state.alpha2Code).catch();
        state.storeFormCfg.store.controlProps.options = await services.agent.getSalesAgentOptions(props.page);
        // if (token) {
        //   const [res, ex] = await api.cidp.tokenLogin(null, {
        //     token
        //   });
        //   console.log('token login res', res, ex);
        // }
        // _methods.checkoutTracking({
        //   event: 'checkout',
        //   checkout_step: state.step
        // });
        _methods.gtmCheckout('1', 'checkout');
        qtUtils.trackPv('car_order_step1_pg');
        state.loaded = true;
        await nextTick();
        [getCaptcha, checkCaptcha] = getCaptchaExecutor(GET_CAPTCHA_SCENE.CHECKOUT, computes.bindRequired.value);
        state.geeCaptchaRef.init();
        gtmFormView(formType?.value, props.fields.emailForm.id);
        loading.hide();
      }
    });
    onBeforeUnmount(() => {
      document.documentElement.classList.remove('p-build-to-order');
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      deviceState,
      appStore,
      transitions
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
body {
  &.p-build-to-order {
    padding-bottom: 120px;
  }
  @include tablet-landscape {
    &.p-build-to-order {
      padding-bottom: 0;
    }
  }
}
.c-build-to-order {
  $c: &;
  position: relative;
  &__main {
    padding: $space-40 grid-width-m(1) 100px grid-width-m(1);
  }
  &__content {
    min-height: calc(100vh - 130px);
  }
  &__side {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background: $black;
    color: $white;
    padding-bottom: 100px;
    @include h10;
    &-content {
      padding: 0 grid-width-m(1) 40px grid-width-m(1);
    }
  }
  &__steps {
    display: flex;
    align-items: center;
    margin-bottom: $space-24;
  }
  &__step {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid $grey;
    line-height: 44px;
    text-align: center;
    flex-shrink: 0;
    color: $grey;
    &-line {
      flex-grow: 1;
      border-bottom: 1px solid $grey;
      &.finished {
        border-color: $black;
        border-width: 2px;
      }
    }
    &.finished {
      border-color: $black;
      color: $black;
    }
  }
  &__primary-title {
    padding-bottom: $space-10;
    border-bottom: 1px solid $grey-light;
  }
  &__important-kv {
    @include h8;
    color: $yellow;
  }
  &__phone {
    margin-bottom: 24px;
  }
  &__secondary-title {
    font-weight: 500;
  }
  &__vt {
    display: flex;
    align-items: center;
    cursor: pointer;
    .e-icon {
      margin-left: 16px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__address-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }
  &__finance-form {
    padding-bottom: 24px;
    border-bottom: 1px solid $grey-light;
    .s-dynamic-form {
      padding-bottom: 0;
    }
  }
  &__regulatory-img {
    width: 100%;
    height: auto;
  }
  &__toolbar {
    position: sticky;
    bottom: 0;
    z-index: 2;
    width: 100%;
    background: $white;
  }
  &__detail-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $grey-neutral;
    color: $yellow;
    padding: $space-16;
    .e-accordion-toggle {
      background: $yellow;
      color: $black;
      width: 18px;
      height: 18px;
      &__content {
        width: 18px;
        height: 18px;
        padding: 4px;
        &:before {
          width: calc(100% - 8px);
        }
        &:after {
          height: calc(100% - 8px);
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $space-20 $space-32;
    border-top: 1px solid $grey-light;
    .e-site-button {
      min-width: grid-width-m(3);
    }
    &.step_3 {
      justify-content: center;
    }
  }
  &__disclaimer {
    margin-top: 24px;
  }
  &__deposit-bar {
    display: none;
  }
  &__model-image {
    display: block;
    width: 100%;
    height: auto;
  }
  &__delivery-date {
    background: $yellow;
    text-align: center;
    padding: 8px;
    color: $black;
  }
  &__deposit-amount {
    color: $yellow;
  }
  &__model-title {
    padding: 32px 0;
    border-bottom: 1px solid $grey-neutral;
    font-size: 16px;
  }
  &__section-image {
    width: 120px;
    height: auto;
    display: block;
    margin-bottom: 8px;
  }
  &__config-section {
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      @include h9;
      padding: 16px 0;
    }
    &-description {
      font-family: lotusFontFamily('Overpass Thin'), sans-serif;
      text-align: justify;
      margin-right: 8px;
    }
    &-content {
      > .e-label-value {
        margin: 10px 8px 10px 0;
      }
    }
    &-subitem {
      font-family: lotusFontFamily('Overpass Thin'), sans-serif;
    }
    &-sub {
      background: rgba($grey-neutral, 0.5);
      padding: 12px;
      .e-label-value,
      #{$c}__config-section-description {
        + .e-label-value,
        + #{$c}__config-section-description {
          margin-top: 8px;
        }
      }
    }
    &-block {
      background: rgba($grey-neutral, 0.5);
      padding: 12px;
      .e-label-value,
      #{$c}__config-section-description {
        + .e-label-value,
        + #{$c}__config-section-description {
          margin-top: 16px;
        }
      }
      > .e-label-value {
        margin-right: 60px;
      }
    }
    &-edit {
      @include h11;
      color: $grey;
      cursor: pointer;
      text-decoration: underline;
    }
    + #{$c}__config-section {
      border-top: 1px solid rgba($white, 0.5);
      margin-top: 24px;
    }
  }
  &__vehicle-detail-content {
    //background: rgba($grey-neutral, 0.5);
    //padding: 12px 16px;
    margin-top: 8px;
  }
  &__vehicle-detail-item {
    .e-label-value {
      &__label {
        font-size: 13px;
        opacity: 0.9;
      }
    }
  }
  &__vehicle-detail-value {
    @include h11;
    opacity: 0.6;
    + #{$c}__vehicle-detail-item {
      margin-top: 8px;
    }
  }
  &__personal-info {
    margin-bottom: 16px;
  }
  &__confirm-modal {
    &.e-modal {
      align-items: flex-end;
    }
    .e-modal__content {
      max-height: 100vh;
      overflow-y: auto;
      background: $black;
      color: $white;
      padding: 16px 24px;
    }
    &-title {
      margin-bottom: 16px;
    }
    &-content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 16px;
      margin-top: 24px;
    }
    &-label {
      font-weight: bold;
      text-transform: capitalize;
    }
    &-text {
      margin-top: 8px;
      font-weight: 300;
      font-family: lotusFontFamily('Overpass Light'), sans-serif;
    }
    &-footer {
      display: flex;
      justify-content: center;
      margin-top: 36px;
      .e-site-button {
        width: auto;
      }
    }
    //&.convert {
    //  #{$c}__confirm-modal {
    //    &-footer {
    //      .e-site-button {
    //        width: 320px;
    //      }
    //    }
    //  }
    //}
  }
  &__failed {
    padding: 16px 24px;
    &-title {
      margin-top: 12px;
    }
    &-message {
      @include h11;
    }
    &-body {
      margin-top: 24px;
    }
    &-widget {
      margin-top: 12px;
      &:empty {
        display: none;
      }
    }
    &-bar {
      margin-top: 24px;
    }
  }
  @include mobile {
    &.detail-has-opened {
      #{$c}__toolbar {
        & {
          position: fixed;
          z-index: 2;
        }
      }
    }
    &.safari.scroll-to-top {
      #{$c}__toolbar {
        bottom: 100px;
      }
    }
  }
  @include tablet-landscape {
    padding-bottom: 0;
    &__content {
      @include grid-container;
    }
    &__main {
      @include grid-block(1, 12);
      padding: $space-40 0 100px 0;
    }
    &__side {
      position: static;
      height: auto;
      width: auto;
      z-index: 1;
      @include grid-block(13, 12);
      padding-bottom: 0;
      &-content {
        padding: 0 grid-width(1, false) 140px grid-width(1, false);
      }
    }
    &__steps {
      margin-bottom: $space-64;
      padding: 0 grid-width(2);
    }
    &__primary-title {
      padding-bottom: $space-32;
      margin-bottom: 12px;
    }
    &__regulatory-img {
      margin-top: 8px;
    }
    &__sections {
      padding: 0 grid-width(2);
    }
    &__toolbar {
      @include grid-container;
    }
    &__detail-toggle {
      display: none;
    }
    &__buttons {
      @include grid-block(1, 12);
      padding: $space-20 grid-width(2);
      .e-site-button {
        min-width: grid-width(2);
      }
    }
    &__deposit-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include grid-block(13, 12);
      padding: $space-20 grid-width(1, false);
      background: $grey-neutral;
      color: $white;
      &-left {
        width: 100%;
        //margin-right: 40px;
      }
      &-kv {
        @include h7;
        color: $yellow;
        margin-top: 2px !important;
      }
      .e-label-value {
        padding: 0;
        + .e-label-value,
        + #{$c}__deposit-bar-description {
          margin-top: 8px;
        }
      }
    }
    &__confirm-modal {
      &.e-modal {
        align-items: center;
      }
      .e-modal__content {
        overflow-y: hidden;
        padding: 40px 60px;
      }
      &-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 24px;
      }
    }
    &__failed {
      padding: 0 grid-width(2);
    }
    &__price-desc {
      color: $grey-neutral;
      @include h10;
      margin-top: 12px;
    }
    &__price-modal {
      &.e-modal {
        justify-content: flex-end;
        padding-top: desktop-header-height();
        .e-modal__content {
          position: relative;
          width: grid-width(12);
          @include height-except-header;
        }
      }
      &-title {
        height: 72px;
        border-bottom: 1px solid $grey-neutral;
        padding: 24px;
      }
      &-content {
        height: calc(100% - 212px);
        overflow-y: auto;
        padding: 24px;
      }
      &-toolbar {
        height: 140px;
        background: $yellow;
        padding: 36px 24px;
        &-money {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 400;
          @include h7;
        }
      }
      #{$c}__config-section-sub {
        background: $white;
      }
      #{$c}__config-section-block {
        background: rgba($grey-light, 0.5);
      }
    }
  }
}
</style>

<template>
  <overall-settings v-bind="$props">
    <section class="c-agenda__mainImage" v-if="pcpage === 'mb'">
      <adaptive-image :field="fields.image" />
    </section>

    <div class="c-agenda">
      <div class="c-agenda__content" :class="{ 'c-agenda__content--layoutRight': $settingValue(fields.layout) == 'Right' }">
        <section class="c-agenda__mainImage" v-if="pcpage === 'pc'">
          <adaptive-image :field="fields.image" />
        </section>

        <section class="c-agenda__base">
          <jss-rich-text :field="fields.title" tag="div" class="c-agenda__title" />

          <jss-rich-text :field="fields.remarks" tag="div" class="c-agenda__remarks" />

          <div class="c-agenda__list" v-for="(item, index) in fields.list" :key="'agendaList' + index">
            <div class="c-agenda__list__content">
              <jss-rich-text :field="item.fields.title" tag="div" class="c-agenda__list__title" />

              <jss-rich-text :field="item.fields.remarks" tag="div" class="c-agenda__list__remarks" />
            </div>

            <div class="c-agenda__list__control">
              <SiteButton v-bind="item.fields.button" :qt-data="{ cell_id: item.id, cell_name: item.fields.title.value }" />
            </div>
          </div>

          <div class="c-agenda__bottomContent">
            <SiteButton v-bind="fields.button" />
            <div></div>
            <SiteButton v-bind="fields.button2" />
          </div>
        </section>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, ref, nextTick } from 'vue';
import { isDesktop, isDesktopLarge, isTabletLandscape } from '@/utils/dom-utils';
import { debounce } from 'lodash';

/**
 * @typedef AgendaFields
 * @property {GlobalSettingEntry} theme
 * */

export default {
  name: 'Agenda',
  props: {
    /**@type AgendaFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      page_mainImage: '',
      pcpage: ''
    });

    const methods = {
      isDesktop: isDesktop,
      isDesktopLarge: isDesktopLarge,
      isTabletLandscape: isTabletLandscape,

      imageInit() {
        if (!isDesktop() && !isDesktopLarge() && !isTabletLandscape()) {
          state.pcpage = 'mb';
        } else {
          state.pcpage = 'pc';
        }
      },

      resizePageSet: debounce(async (e) => {
        methods.imageInit();
      }, 300)
    };

    onMounted(() => {
      methods.imageInit();

      window.addEventListener('resize', methods.resizePageSet);
      /*if (props.fields.image.fields) {
        state.page_mainImage = props.fields.image.fields.desktopImage.value.src;

        if ((isDesktop() || isDesktopLarge()) && props.fields.image.fields.desktopImage.value) {
          state.page_mainImage = props.fields.image.fields.desktopImage.value.src;
        }
        if (!isDesktop() && !isDesktopLarge() && !isTabletLandscape() && props.fields.image.fields.mobileImage.value) {
          state.page_mainImage = props.fields.image.fields.mobileImage.value.src;
        }
        if (isTabletLandscape() && props.fields.image.fields.tabletImage.value) {
          state.page_mainImage = props.fields.image.fields.tabletImage.value.src;
        }
      } else {
        state.page_mainImage = props.fields.image.value.src;
      }*/
    });

    onUnmounted(() => {
      window.removeEventListener('resize', methods.resizePageSet);
    });

    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-agenda {
  width: 100%;
  @include grid-container;
  overflow: hidden;
  color: #000;
}
.c-agenda__content {
  @include grid-block(2, 10);
}
.c-agenda__mainImage {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  .e-background-image {
    height: 100%;
  }
}
.c-agenda__base {
  width: 100%;
  padding-top: 70px;

  .c-agenda__title {
    width: 100%;
    font-size: 32px;
    line-height: 1.1;
    font-weight: normal;
  }

  .c-agenda__remarks {
    width: 100%;
    color: #4f4f4f;
    opacity: 0.8;
    padding: 24px 0 8px 0;
    font-size: 16px;
    line-height: 1.5;
  }
}

.c-agenda__list {
  width: 100%;
  border-bottom: 1px solid #ddd;

  .c-agenda__list__content {
    width: 100%;
  }
  .c-agenda__list__title {
    width: 100%;
    font-size: 18px;
    line-height: 1.3;
    padding: 32px 0 16px 0;
    font-weight: bold;
  }
  .c-agenda__list__remarks {
    width: 100%;
    font-size: 16px;
    color: #4f4f4f;
    opacity: 0.8;
    padding-bottom: 8px;
  }
  .c-agenda__list__control {
    display: flex;
    width: 100%;
    align-items: center;
    padding-bottom: 16px;

    .e-site-button {
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.c-agenda__bottomContent {
  width: 100%;
  padding: 40px 0;

  .e-site-button {
    flex-shrink: 0;
    font-size: 16px;
    font-weight: bold;
    padding: 0 24px;
    height: auto;
    margin-top: 40px;

    &.bordered {
      border: 2px solid #000;
      height: 56px;
      margin-top: 0;
    }
  }
}

@include tablet-landscape {
  .c-agenda__content {
    @include grid-block(3, 20);
    display: flex;
    gap: 60px;
    &.c-agenda__content--layoutRight {
      flex-direction: row-reverse;

      .c-agenda__base {
        padding-left: 0;
        padding-right: 100px;
      }
    }
  }

  .c-agenda__mainImage {
    width: 467px;
    height: 830px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .c-agenda__base {
    width: auto;
    flex-basis: 100%;

    .c-agenda__title {
      font-size: 56px;
    }

    .c-agenda__remarks {
      padding: 24px 0 8px 0;
    }
  }

  .c-agenda__list {
    display: flex;
    align-items: flex-end;

    .c-agenda__list__content {
      width: auto;
      flex-basis: 100%;
      padding: 0 20px 0 0;
    }
    .c-agenda__list__title {
      padding: 32px 0 16px 0;
    }
    .c-agenda__list__remarks {
      padding-bottom: 32px;
    }
    .c-agenda__list__control {
      width: auto;
      flex-shrink: 0;
      padding-bottom: 16px;
    }
  }

  .c-agenda__bottomContent {
    display: flex;
    gap: 32px;

    .e-site-button {
      flex-shrink: 0;
      margin-top: 0;
      height: 56px;
    }
  }
}
</style>

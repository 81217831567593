<template>
  <OverallSettings v-bind="$props">
    <adaptive-media class="c-countdown" :class="rootClasses" :field="media">
      <div class="c-countdown__content">
        <div class="c-countdown__ticks" v-if="!ended">
          <div class="c-countdown__tick">
            <div class="c-countdown__tick-value">
              <span v-for="d in days" :key="d">{{ d }}</span>
            </div>
            <jss-text class="c-countdown__tick-name" :field="fields.daysTitle" tag="div" />
          </div>
          <div class="c-countdown__tick">
            <div class="c-countdown__tick-value">
              <span v-for="h in hours" :key="h">{{ h }}</span>
            </div>
            <jss-text class="c-countdown__tick-name" :field="fields.hoursTitle" tag="div" />
          </div>
          <div class="c-countdown__tick">
            <div class="c-countdown__tick-value">
              <span v-for="m in minutes" :key="m">{{ m }}</span>
            </div>
            <jss-text class="c-countdown__tick-name" :field="fields.minutesTitle" tag="div" />
          </div>
          <div class="c-countdown__tick">
            <div class="c-countdown__tick-value">
              <span v-for="s in seconds" :key="s">{{ s }}</span>
            </div>
            <jss-text class="c-countdown__tick-name" :field="fields.secondsTitle" tag="div" />
          </div>
        </div>
        <heading class="c-countdown__title" :field="$adaptiveTextField(title, titleMobile)" :font-family="null" :type="fields.titleType" rich />
        <jss-rich-text class="c-countdown__body" :field="$adaptiveTextField(body, bodyMobile)" tag="div" />
        <div class="c-countdown__buttons" :class="[{ [`align-${$settingValue(buttonAlign)}`]: buttonAlign }]">
          <site-button class="c-countdown__button" v-bind="button" v-for="button in buttons" :key="button.id" />
        </div>
      </div>
    </adaptive-media>
  </OverallSettings>
</template>

<script>
/**
 * @typedef CountdownFields
 * @property {GlobalSettingEntry} theme
 * @property {SimpleField} title
 * @property {SimpleField} titleMobile
 * @property {GlobalSettingEntry} titleType
 * @property {SimpleField} body
 * @property {SimpleField} bodyMobile
 * @property {Date} eventDate
 * @property {GlobalSettingEntry} eventHour
 * @property {GlobalSettingEntry} eventMinute
 * @property {ImageField} image
 * @property {SimpleField} video
 * @property {SimpleField} daysTitle
 * @property {SimpleField} hoursTitle
 * @property {SimpleField} minutesTitle
 * @property {SimpleField} secondsTitle
 * @property {SimpleField} endTitle
 * @property {SimpleField} endBody
 * @property {SimpleField} endBody
 * @property {ImageField} endImage
 * @property {SimpleField} endVideo
 * @property {Array<ButtonField>} buttons
 * @property {GlobalSettingEntry} buttonAlign
 * */
import { computed, onMounted, reactive, toRefs } from 'vue';
import { settingValue } from '@/utils/site-utils';
import { padding } from '@/utils/obj-utils';
import { merge } from 'lodash';

export default {
  name: 'Countdown',
  props: {
    /**@type CountdownFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    let timeout;
    let missions = [...props.fields.missions];
    for (let mission of missions) {
      const { eventDate } = mission.fields || {};
      const [year, month, day, hours, minutes, seconds] = eventDate.value.split(/[:\-TZ]/g);
      mission.triggerDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
    }
    missions.sort((a, b) => {
      return a.triggerDate - b.triggerDate;
    });
    const _methods = {
      getActiveMission() {
        let now = new Date();
        let result = {
          theme: props.fields.theme,
          media: props.fields.media,
          title: props.fields.title,
          titleMobile: props.fields.titleMobile,
          body: props.fields.body,
          bodyMobile: props.fields.bodyMobile,
          buttons: props.fields.buttons,
          buttonAlign: props.fields.buttonAlign,
          gradientColor: props.fields.gradientColor,
          gradientDirection: props.fields.gradientDirection
        };
        for (let mission of missions) {
          const { theme, media, title, titleMobile, body, bodyMobile, buttons, buttonAlign, gradientColor, gradientDirection } = mission.fields || {};
          if (now < mission.triggerDate) {
            break;
          } else {
            result = merge(
              {},
              {
                theme,
                media,
                title,
                titleMobile,
                body,
                bodyMobile,
                buttons,
                buttonAlign,
                gradientColor,
                gradientDirection
              }
            );
          }
        }
        result.endDate = missions.length > 0 ? missions[missions.length - 1].triggerDate : null;
        return result;
      },
      countdown() {
        const activeMission = _methods.getActiveMission();
        state.theme = activeMission.theme;
        state.media = activeMission.media;
        state.title = activeMission.title;
        state.titleMobile = activeMission.titleMobile;
        state.body = activeMission.body;
        state.bodyMobile = activeMission.bodyMobile;
        state.buttons = activeMission.buttons;
        state.buttonAlign = activeMission.buttonAlign;
        state.gradientColor = activeMission.gradientColor;
        state.gradientDirection = activeMission.gradientDirection;
        const targetDate = activeMission.endDate;
        const now = new Date();
        const distance = Math.floor((targetDate - now) / 1000);
        if (distance >= 0) {
          state.seconds = padding(distance % 60);
          state.minutes = padding(Math.floor(distance / 60) % 60);
          state.hours = padding(Math.floor(distance / 60 / 60) % 24);
          state.days = padding(Math.floor(distance / 60 / 60 / 24));
          timeout = setTimeout(_methods.countdown, 1000);
        } else {
          state.ended = true;
          if (timeout) {
            clearTimeout(timeout);
          }
        }
      }
    };
    const activeMission = _methods.getActiveMission();
    const state = reactive({
      ended: false,
      seconds: '',
      hours: '',
      minutes: '',
      days: '',
      theme: activeMission.theme,
      media: activeMission.media,
      title: activeMission.title,
      titleMobile: activeMission.titleMobile,
      body: activeMission.body,
      bodyMobile: activeMission.bodyMobile,
      buttons: activeMission.buttons,
      buttonAlign: activeMission.buttonAlign,
      gradientColor: activeMission.gradientColor,
      gradientDirection: activeMission.gradientDirection
    });
    const computes = {
      rootClasses: computed(() => {
        const theme = settingValue(state.theme, 'black');
        const gradientColor = settingValue(state.gradientColor);
        const gradientDirection = settingValue(state.gradientDirection);
        return [
          `theme-${theme}`,
          {
            [`gradient-${gradientColor}`]: gradientColor,
            [`gradient-${gradientDirection}`]: gradientDirection
          }
        ];
      })
    };
    onMounted(() => {
      _methods.countdown();
    });
    return {
      ...toRefs(state),
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-countdown {
  $c: &;
  $media: #{'.e-adaptive-media'};
  @include component-themes;
  &.e-adaptive-media {
    height: 100vh;
    #{$media}__main,
    #{$media}__image {
      background-color: transparent;
    }
  }
  @each $color-name, $color-value in $colors {
    &.#{'gradient-' + $color-name} {
      position: relative;
      &.gradient-ltr,
      &.gradient-rtl {
        &.e-adaptive-media {
          height: 100vh;
          #{$media}__image {
            position: relative;
            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      &.gradient-ltr {
        &.e-adaptive-media {
          height: 100vh;
          #{$media}__image {
            position: relative;
            &:before {
              background-image: linear-gradient(to right, rgba($color-value, 0.5), transparent) !important;
            }
          }
        }
      }
      &.gradient-rtl {
        &.e-adaptive-media {
          height: 100vh;
          #{$media}__image {
            position: relative;
            &:before {
              background-image: linear-gradient(to left, rgba($color-value, 0.5), transparent) !important;
            }
          }
        }
      }
    }
  }
  &__content {
    position: relative;
    height: 100vh;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
  &__media {
    &.e-adaptive-media {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
    }
  }
  &__title {
    @include h5;
    margin-top: $space-8;
    word-break: break-all;
    margin-bottom: 24px;
  }
  &__ticks {
    width: 100%;
    display: flex;
    margin-bottom: $space-20;
  }
  &__tick {
    width: 68px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    + #{$c}__tick {
      margin-left: 24px;
      #{$c}__tick-value {
        position: relative;
        &:before {
          content: ':';
          position: absolute;
          top: calc(50% - 2px);
          left: -18px;
          line-height: 1;
          @include h5;
          transform: translateY(-50%);
        }
      }
    }
  }
  &__tick-value {
    @include h5;
    align-self: stretch;
    text-align: center;
    display: flex;
    > span {
      width: 30px;
      text-align: center;
      letter-spacing: 0;
    }
  }
  &__tick-name {
    font-size: 14px;
    letter-spacing: 2px;
  }
  &__body {
    width: grid-width-m(10);
    margin-bottom: $space-40;
    word-break: break-all;
  }
  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &.align {
      &-left {
        align-items: flex-start;
      }
      &-center {
        align-items: center;
      }
      &-right {
        align-items: flex-end;
      }
    }
  }
  &__button {
    width: grid-width-m(6);
    &.primary-link {
      width: auto;
      &.bordered {
        width: grid-width-m(6);
      }
    }
    + #{$c}__button {
      margin-top: $space-30;
    }
  }
  @include tablet-landscape {
    &__content {
      padding: grid-width(1) grid-width(2);
      justify-content: center;
      align-items: flex-start;
    }
    &__title {
      width: auto;
    }
    &__ticks {
      width: grid-width(8);
      margin-bottom: $space-80;
    }
    &__tick {
      height: 88px;
      + #{$c}__tick {
        margin-left: 60px;
        #{$c}__tick-value {
          > span {
            width: 40px;
          }
          &:before {
            top: calc(50% - 5px);
            left: -36px;
          }
        }
      }
    }
    &__body {
      width: auto;
    }
    &__buttons {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center !important;
    }
    &__button {
      width: auto;
      + #{$c}__button {
        margin-top: 0;
        margin-left: $space-40;
      }
    }
  }
}
</style>

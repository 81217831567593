<template>
  <OverallSettings v-bind="$props">
    <div class="c-comparisionUI" v-if="fields">
      <div class="c-comparisionUI__content">
        <JssRichText :field="fields.title" tag="div" class="c-comparisionUI__mainTitle" />
        <AdaptiveImage class="c-comparisionUI__mainImage" :field="fields.image" />

        <section class="c-comparisionUI__slideIconContent" v-if="slide_list && slide_list.length > 3 && largeThanTablet()">
          <Icon name="left" :class="{ disable: page_activeIndex == 0 }" @click="ms_slide($event, 'prev')" />
          <Icon name="right" :class="{ disable: page_activeIndex >= slide_list.length - 3 }" @click="ms_slide($event, 'next')" />
        </section>

        <template v-if="largeThanTablet()">
          <section class="c-comparisionUI__main" ref="swiperEl" v-if="slide_list && slide_list.length > 0">
            <div class="swiper-wrapper">
              <div class="swiper-slide c-comparisionUI__main-card" v-for="item in slide_list" :key="item.id">
                <section class="cmsss-slide__square">
                  <div class="cmsss-slide__top">
                    <JssRichText :field="item.fields.title" class="cmsss-slide__title" />
                    <JssRichText :field="item.fields.price" class="cmsss-slide__price" />
                  </div>

                  <div class="cmsss-slide__parameters">
                    <div class="cmsss-slide__parameters-item" v-for="des in item.fields.parameters" :key="des.id">
                      <JssRichText :field="des.fields.number" class="cmsss-slide__parameters-item-num" />
                      <JssRichText :field="des.fields.description" class="cmsss-slide__parameters-item-des" />
                    </div>
                  </div>

                  <div class="cmsss-slide__remarks">
                    <JssRichText :field="item.fields.remarks" />
                  </div>

                  <div class="cmsss-slide__remarksList" v-for="(item2, index) in item.fields.remarksList" :key="item2.id">
                    <div class="cmsss-slide__remarksList-title" @click="titleClick(item.fields.remarksList, index)">
                      <JssRichText :field="item2.fields.title" />
                      <Icon class="cmsss-slide__remarksList-icon" name="down" :class="{ active: item2.selected }" />
                    </div>

                    <transition @before-enter="onItemBeforeEnter" @enter="onItemTransEnter" @leave="onItemTransLeave">
                      <div class="cmsss-slide__remarksList-remarks" v-if="item2.selected">
                        <div class="remarks-item" v-for="item3 in item2.fields.remarks" :key="item3.id">
                          <JssRichText :field="item3.fields.remarks" />
                          <Icon class="remarks-item-icon" name="check" />
                        </div>
                      </div>
                    </transition>
                  </div>

                  <div class="cmsss-slide__btn">
                    <SiteButton v-bind="item.fields.button" />
                  </div>
                </section>
              </div>
            </div>
          </section>
        </template>
        <template v-else>
          <section class="c-comparisionUI__mobile">
            <div class="swiper-slide c-comparisionUI__main-card" v-for="item in slide_list" :key="item.id">
              <section class="cmsss-slide__square">
                <div class="cmsss-slide__top">
                  <JssRichText :field="item.fields.title" class="cmsss-slide__title" />
                  <JssRichText :field="item.fields.price" class="cmsss-slide__price" />
                </div>

                <div class="cmsss-slide__parameters">
                  <div class="cmsss-slide__parameters-item" v-for="des in item.fields.parameters" :key="des.id">
                    <JssRichText :field="des.fields.number" class="cmsss-slide__parameters-item-num" />
                    <JssRichText :field="des.fields.description" class="cmsss-slide__parameters-item-des" />
                  </div>
                </div>

                <div class="cmsss-slide__remarks">
                  <JssRichText :field="item.fields.remarks" />
                </div>

                <div class="cmsss-slide__remarksList" v-for="(item2, index) in item.fields.remarksList" :key="item2.id">
                  <div class="cmsss-slide__remarksList-title" @click="titleClick(item.fields.remarksList, index)">
                    <JssRichText :field="item2.fields.title" />
                    <Icon class="cmsss-slide__remarksList-icon" name="down" :class="{ active: item2.selected }" />
                  </div>

                  <transition @before-enter="onItemBeforeEnter" @enter="onItemTransEnter" @leave="onItemTransLeave">
                    <div class="cmsss-slide__remarksList-remarks" v-if="item2.selected">
                      <div class="remarks-item" v-for="item3 in item2.fields.remarks" :key="item3.id">
                        <JssRichText :field="item3.fields.remarks" />
                        <Icon class="remarks-item-icon" name="check" />
                      </div>
                    </div>
                  </transition>
                </div>
                <div class="cmsss-slide__btn">
                  <SiteButton v-bind="item.fields.button" />
                </div>
              </section>
            </div>
          </section>
        </template>
      </div>
    </div>
  </OverallSettings>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, nextTick } from 'vue';
import Swiper from 'swiper';
import { largeThanTablet } from '@/utils/dom-utils';
import { deepCopy } from '@/utils/obj-utils';
import gsap from 'gsap';
import { destroySwiper } from '@/utils/site-utils';

/**
 * @typedef comparisionUIFields
 * @property {GlobalSettingEntry} theme
 * */
export default {
  name: 'comparisionUI',
  props: {
    /**@type comparisionUIFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    console.log(props.fields);
    let swiper = null;
    const state = reactive({
      swiperEl: null,
      page_activeIndex: 0,
      page_slide_st: 0,
      slide_list: deepCopy(props.fields.slide_list)
    });
    const methods = {
      largeThanTablet,

      buildSwiper: () => {
        destroySwiper(swiper, true, true);
        swiper = new Swiper(state.swiperEl, {
          slidesPerView: 3,
          spaceBetween: 32,
          on: {
            slideChange: (swiper) => {
              state.page_activeIndex = swiper.activeIndex;
            }
          }
        });
      },

      titleClick(item, i) {
        item.forEach((value, index) => {
          if (index === i) {
            value.selected = !value.selected;
          }
        });
      },

      ms_slide(e, type) {
        e.stopPropagation();
        if (state.page_slide_st == 0) {
          state.page_slide_st = 1;
          if (type === 'prev') {
            swiper.slidePrev();
          } else {
            swiper.slideNext();
          }
        }
        setTimeout(() => {
          state.page_slide_st = 0;
        }, 300);
      },

      onItemBeforeEnter(el) {
        el.style.height = 0;
        el.style.paddingBottom = 0;
      },

      onItemTransEnter(el, done) {
        gsap.to(el, {
          height: el.scrollHeight + 20,
          paddingBottom: 20,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },

      onItemTransLeave(el, done) {
        gsap.to(el, {
          height: 0,
          paddingBottom: 0,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      }
    };

    onMounted(async () => {
      await nextTick();
      methods.buildSwiper();
    });

    onUnmounted(() => {
      destroySwiper(swiper, true, true);
    });

    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-comparisionUI {
  width: 100%;
  @include grid-container;
  overflow: hidden;
  &__content {
    width: 100%;
    @include grid-block(2, 10);
  }
  &__mainTitle {
    width: 100%;
    padding: 32px 0;
  }
  &__mainImage {
    display: block;
    width: 100%;
    > div {
      height: grid-width-m(math.div(10 * 9, 21));
    }
  }
  &__mobile {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  &__main {
    padding-top: 24px;
    &-card {
      border: 1px solid rgba(0, 0, 0, 0.1);
      .cmsss-slide {
        &__square {
          width: 100%;
          overflow: hidden;
        }
        &__top {
          display: flex;
          width: 100%;
          padding: 24px 16px;
          justify-content: space-between;
          align-items: center;
        }
        &__parameters {
          display: flex;
          flex-direction: row;
          gap: 32px;
          justify-content: space-around;
          background-color: $grey-light;
          padding: 24px 16px;
          text-align: center;
        }
        &__remarks {
          width: 100%;
          padding: 16px;
        }
        &__remarksList {
          max-width: 100%;
          margin: 0 16px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          transition: padding 0.2s linear 0s;

          &-title {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0;
            cursor: pointer;

            .e-icon {
              color: #000;
              &.active {
                transform: rotate(180deg);
              }
              > svg {
                height: 14px;
              }
            }
          }
          &-remarks {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 12px;
            overflow: hidden;

            .remarks-item {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              flex-grow: 1;
              gap: 16px;
              word-wrap: normal;
              word-break: normal;
              .remarks-item-icon {
                > svg {
                  width: 12px;
                  height: 22px;
                }
              }
            }
          }
        }
        &__btn {
          padding: 16px;
          text-align: center;
        }
      }
    }
  }

  @include tablet-landscape {
    &__content {
      @include grid-block(1, 24);
    }

    &__mainTitle {
      padding: grid-width(1);
    }

    &__mainImage > div {
      height: grid-width(math.div(24 * 9, 21));
    }

    &__slideIconContent {
      text-align: end;
      margin-right: grid-width(1);
      > .e-icon {
        cursor: pointer;
        & + .e-icon {
          margin-left: 20px;
        }
        &.disable {
          color: $grey;
        }
      }
    }

    &__main {
      padding: 32px;

      .cmsss-slide {
        &__top {
          padding: 32px;
        }
        &__parameters {
          padding: 24px 32px;
        }
        &__remarks {
          width: 100%;
          padding: 32px 32px 12px;
        }
        &__remarksList {
          margin: 0 32px;
          &-remarks {
            margin-top: 8px;
          }
        }
      }
    }
  }
}
</style>

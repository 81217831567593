<!--
-->
<template>
  <div class="app" id="root">
    <context-view :visible="contextViewVisible" />
    <!-- 'router-view' is a "global" component that is injected into the Vue component registry by vue-router. -->
    <router-view />
    <div v-if="languageIsChanging" class="alert alert-info">Language is changing...</div>
  </div>
</template>

<script>
import ContextView from './ContextView';
import { dictionaryServiceFactory } from './lib/dictionary-service-factory';
import { webStorage } from '@/utils/web-storage';
import { S_I18N } from '@/utils/web-storage-keys';
import { APP_CONST } from '@/utils/constants';

export default {
  name: 'AppRoot',
  data() {
    return {
      languageIsChanging: false,
      contextViewVisible: false
    };
  },
  methods: {
    async changeAppLanguage(language) {
      const i18n = this.$i18n;
      APP_CONST.lang = language;
      if (i18n.locale !== language) {
        // Changing languages is an async action, therefore the `languageIsChanging` property can be used
        // to show a loading/switching screen when language is being changed.
        this.languageIsChanging = true;
        const cache = webStorage.get(S_I18N) || {};
        if (!cache[language] || cache[language].length === 0) {
          // create an instance of the dictionary service
          const dictionaryServiceInstance = dictionaryServiceFactory.create();
          cache[language] = await dictionaryServiceInstance.fetchDictionaryData(language);
          webStorage.set(S_I18N, cache);
        }
        i18n.setLocaleMessage(language, cache[language]);
        i18n.locale = language;
        this.languageIsChanging = false;
      }
    },
    toggleContextView() {
      this.contextViewVisible = !this.contextViewVisible;
    }
  },
  provide() {
    // Use Vue's provide/inject capabilities to "provide" functions to
    // any descendant component that want to use/"inject" the functions.
    return {
      languageIsChanging: this.languageIsChanging,
      changeAppLanguage: this.changeAppLanguage,
      toggleContextView: this.toggleContextView
    };
  },
  components: {
    ContextView
  }
};
</script>

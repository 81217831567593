<script setup>
import { getCurrentInstance, inject } from 'vue';
import { canUseDOM } from '@/utils/dom-utils';

const { proxy } = getCurrentInstance();
const { $nativeCommon } = proxy;
const toast = inject('toast');
const buildButton = (text) => {
  return {
    text: {
      value: text
    },
    fields: {
      type: {
        fields: {
          phrase: {
            value: 'primary-button'
          }
        }
      },
      theme: {
        fields: {
          phrase: {
            value: 'black'
          }
        }
      }
    }
  };
};
const login = () => {
  $nativeCommon.login();
};
const getToken = async () => {
  const tkObj = await $nativeCommon.getTokenAsync();
  console.log('token', tkObj);
  await toast.show({
    message: tkObj?.token,
    centered: true,
    closable: true,
    showOk: true,
    okText: '确定'
  });
};
const goPage = (params) => {
  $nativeCommon.goPage(params);
};
if (canUseDOM()) {
  const VConsole = require('vconsole');
  new VConsole();
}
</script>

<template>
  <div class="c-app-test">
    <site-button :fields="buildButton('登录')" @click="login()" />
    <site-button :fields="buildButton('获取Token')" @click="getToken()" />
    <site-button :fields="buildButton('跳转收银台')" @click="goPage({ type: 1 })" />
  </div>
</template>

<style scoped lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-app-test {
  background: $black;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 80px 24px 24px 24px;
  @include tablet-landscape {
    flex-direction: row;
    flex-wrap: wrap;
    padding: grid-width(2);
  }
}
</style>

<template>
  <DashboardLayout :fields="fields?.dashboardHeader?.fields" active-menu="dashboard-account">
    <FormLayout :fields="fields">
      <div class="c-dashboard-account">
        <Heading class="c-dashboard-account__title" :field="fields.title" :type="fields.titleType" rich />
        <JssRichText class="c-dashboard-account__body" :field="fields.body" />
        <div class="c-dashboard-account__personal-info">
          <DynamicForm class="c-dashboard-account__personal-info-form" :form="fields.personalForm" :data="personalFormConfig" ref="personalFormRef" />
          <SiteButton class="c-dashboard-account__personal-info-btn" v-bind="fields.personalUpdateButton" @click="personalUpdate" />
        </div>
        <div class="c-dashboard-account__login-info">
          <JssRichText class="c-dashboard-account__login-info-title" :field="fields.loginTitle" />
          <div class="c-dashboard-account__login-info-email" v-show="showEmail">
            <DynamicForm class="c-dashboard-account__login-info-email-form" :form="fields.showEmailForm" :data="emailFormConfig" ref="emailFormRef" />
            <!-- <SiteButton class="c-dashboard-account__login-info-email-btn" v-bind="fields.updateEmailButton" @click="emailUpdate" v-if="!largeThanTablet()" /> -->
            <a class="c-dashboard-account__login-info-edit-btn" v-if="fields.editable?.value ?? false" @click="emailUpdate">{{ $t('Edit') }}</a>
          </div>
          <div class="c-dashboard-account__login-info-mobile" v-show="showMobile">
            <DynamicForm class="c-dashboard-account__login-info-mobile-form" :form="fields.showMobileForm" :data="mobileFormConfig" ref="mobileFormRef" />
            <!-- <SiteButton class="c-dashboard-account__login-info-mobile-btn" v-bind="fields.updateMobileButton" @click="mobileUpdate" v-if="!largeThanTablet()" /> -->
            <a class="c-dashboard-account__login-info-edit-btn" v-if="fields.editable?.value ?? false" @click="mobileUpdate">{{ $t('Edit') }}</a>
          </div>
        </div>
        <div class="c-dashboard-account__explains">
          <div class="c-dashboard-account__explains-item" v-for="item in fields.explains" :key="item.id">
            <Icon name="query" />
            <JssRichText :field="item.fields.text" />
          </div>
        </div>
      </div>
    </FormLayout>
    <GeeCaptcha ref="geeCaptchaRef" autoInit />
    <Modal ref="verifyOldEmailRef" class="c-dashboard-account__modal" closeLabel>
      <div class="c-dashboard-account__verify">
        <JssRichText class="c-dashboard-account__verify-title" :field="fields.verifyOldEmail.fields.title" />
        <JssRichText class="c-dashboard-account__verify-body" :field="fields.verifyOldEmail.fields.body" />
        <div class="c-dashboard-account__verify-data">{{ email }}</div>
        <DynamicForm :form="fields.verifyOldEmail.fields.form" ref="verifyOldEmailFormRef" />
        <div class="c-dashboard-account__verify-btn">
          <SiteButton v-bind="fields.verifyOldEmail.fields.changeVerifyButton" @click="verifyEmail(0)" />
          <SiteButton v-bind="fields.verifyOldEmail.fields.resendButton" @click="resendOldCode(0)" />
        </div>
      </div>
    </Modal>
    <Modal ref="changeEmailRef" class="c-dashboard-account__modal" closeLabel>
      <div class="c-dashboard-account__change">
        <JssRichText class="c-dashboard-account__change-title" :field="fields.enterNewEmail.fields.title" />
        <JssRichText class="c-dashboard-account__change-body" :field="fields.enterNewEmail.fields.body" />
        <DynamicForm :form="fields.enterNewEmail.fields.form" ref="changeEmailFormRef" />
        <div class="c-dashboard-account__change-btn">
          <SiteButton v-bind="fields.enterNewEmail.fields.changeVerifyButton" @click="changeEmail" />
        </div>
      </div>
    </Modal>
    <Modal ref="verifyNewEmailRef" class="c-dashboard-account__modal" closeLabel>
      <div class="c-dashboard-account__verify">
        <JssRichText class="c-dashboard-account__verify-title" :field="fields.verifyNewEmail.fields.title" />
        <JssRichText class="c-dashboard-account__verify-body" :field="fields.verifyNewEmail.fields.body" />
        <div class="c-dashboard-account__verify-data">{{ email }}</div>
        <DynamicForm :form="fields.verifyNewEmail.fields.form" ref="verifyNewEmailFormRef" />
        <div class="c-dashboard-account__verify-btn">
          <SiteButton v-bind="fields.verifyNewEmail.fields.changeVerifyButton" @click="verifyEmail(1)" />
          <SiteButton v-bind="fields.verifyNewEmail.fields.resendButton" @click="resendNewCode(0)" />
        </div>
      </div>
    </Modal>
    <Modal ref="verifyOldMobileRef" class="c-dashboard-account__modal" closeLabel>
      <div class="c-dashboard-account__verify">
        <JssRichText class="c-dashboard-account__verify-title" :field="fields.verifyOldMobile.fields.title" />
        <JssRichText class="c-dashboard-account__verify-body" :field="fields.verifyOldMobile.fields.body" />
        <div class="c-dashboard-account__verify-data">{{ `+${mobile.areaCode} ${mobile.phone}` }}</div>
        <DynamicForm :form="fields.verifyOldMobile.fields.form" ref="verifyOldMobileFormRef" />
        <div class="c-dashboard-account__verify-btn">
          <SiteButton v-bind="fields.verifyOldMobile.fields.changeVerifyButton" @click="verifyMobile(0)" />
          <SiteButton v-bind="fields.verifyOldMobile.fields.resendButton" @click="resendOldCode(1)" />
        </div>
      </div>
    </Modal>
    <Modal ref="changeMobileRef" class="c-dashboard-account__modal" closeLabel>
      <div class="c-dashboard-account__change">
        <JssRichText class="c-dashboard-account__change-title" :field="fields.enterNewMobile.fields.title" />
        <JssRichText class="c-dashboard-account__change-body" :field="fields.enterNewMobile.fields.body" />
        <DynamicForm :form="fields.enterNewMobile.fields.form" ref="changeMobileFormRef" />
        <div class="c-dashboard-account__change-btn">
          <SiteButton v-bind="fields.enterNewMobile.fields.changeVerifyButton" @click="changeMobile" />
        </div>
      </div>
    </Modal>
    <Modal ref="verifyNewMobileRef" class="c-dashboard-account__modal" closeLabel>
      <div class="c-dashboard-account__verify">
        <JssRichText class="c-dashboard-account__verify-title" :field="fields.verifyNewMobile.fields.title" />
        <JssRichText class="c-dashboard-account__verify-body" :field="fields.verifyNewMobile.fields.body" />
        <div class="c-dashboard-account__verify-data">{{ `+${mobile.areaCode} ${mobile.phone}` }}</div>
        <DynamicForm :form="fields.verifyNewMobile.fields.form" ref="verifyNewMobileFormRef" />
        <div class="c-dashboard-account__verify-btn">
          <SiteButton v-bind="fields.verifyNewMobile.fields.changeVerifyButton" @click="verifyMobile(1)" />
          <SiteButton v-bind="fields.verifyNewMobile.fields.resendButton" @click="resendNewCode(1)" />
        </div>
      </div>
    </Modal>
  </DashboardLayout>
</template>
<script>
import { reactive, toRefs, onMounted, inject, watchEffect } from 'vue';
import { merge, debounce } from 'lodash';
import api from '@/api';
import services from '@/services';
import useAppStore from '@/store/appStore';
import { CAPTCHA_TYPE, CAPTCHA_SCENE } from '@/utils/constants';
import { redirectToLogin } from '@/utils/uri-utils';
import { isNullOrWhitespace } from '@/utils/obj-utils';
import { getGlobalConfigs } from '@/utils/site-utils';
import { largeThanTablet } from '@/utils/dom-utils';
import { useRouter } from 'vue-router';
export default {
  name: 'DashboardAccount',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const appStore = useAppStore();
    const toast = inject('toast');
    const router = useRouter();
    const state = reactive({
      personalFormRef: null,
      personalFormConfig: {
        firstName: {
          controlProps: {
            value: ''
          }
        },
        surname: {
          controlProps: {
            value: ''
          }
        }
      },
      emailFormRef: null,
      emailFormConfig: {
        email: {
          controlProps: {
            value: '',
            disabled: true
          }
        }
      },
      mobileFormRef: null,
      mobileFormConfig: {
        mobile: {
          controlProps: {
            value: {
              area: {
                code: '',
                name: '',
                data: null
              },
              number: ''
            },
            disabled: true
          }
        }
      },
      email: '',
      showEmail: false,
      countries: [],
      mobile: {
        areaCode: '',
        phone: ''
      },
      showMobile: false,
      geeCaptchaRef: null,
      verifyOldEmailRef: null,
      verifyOldEmailFormRef: null,
      changeEmailRef: null,
      changeEmailFormRef: null,
      verifyNewEmailRef: null,
      verifyNewEmailFormRef: null,
      verifyOldMobileRef: null,
      verifyOldMobileFormRef: null,
      changeMobileRef: null,
      changeMobileFormRef: null,
      verifyNewMobileRef: null,
      verifyNewMobileFormRef: null,
      // 0:email 1:mobile
      changeType: 0
    });
    watchEffect(() => {
      state.personalFormConfig.firstName.controlProps.value = appStore.loginInfo.firstName;
      state.personalFormConfig.surname.controlProps.value = appStore.loginInfo.lastName;
      if (appStore.loginInfo.email) {
        state.showEmail = true;
        state.emailFormConfig.email.controlProps.value = appStore.loginInfo.email;
      }
      if (appStore.loginInfo.mobileAreaCode && appStore.loginInfo.phone) {
        state.showMobile = true;
        const country = state.countries.find((c) => c.countryPhoneCode === appStore.loginInfo.mobileAreaCode);
        state.mobileFormConfig.mobile.controlProps.value = {
          area: {
            code: appStore.loginInfo.mobileAreaCode,
            name: country?.countryName,
            data: country
          },
          number: appStore.loginInfo.phone
        };
      }
    });

    const methods = {
      largeThanTablet,
      personalUpdate: debounce(async () => {
        const [valid, formData] = await state.personalFormRef.validate();
        if (valid) {
          const [res, ex] = await api.cidp.details.updateName(null, {
            firstName: formData.firstName,
            lastName: formData.surname,
            SYSTEM_FLAG: '2'
          });
          if (ex) {
            await toast.showEx(ex);
            return;
          } else {
            await toast.showSuccess(res);
            appStore.updateLoginInfo({
              firstName: formData.firstName,
              lastName: formData.surname
            });
          }
        }
      }, 100),
      emailUpdate: debounce(async () => {
        state.changeType = 0;
        let geeResult = await state.geeCaptchaRef.validate();
        if (!isNullOrWhitespace(appStore?.loginInfo?.email)) {
          state.email = appStore?.loginInfo?.email;
          const [res, ex] = await api.cidp.v2.captcha.getAuth(
            null,
            merge(
              {
                captchaType: CAPTCHA_TYPE.email,
                captchaScene: CAPTCHA_SCENE.bindEmail
              },
              geeResult
            )
          );
          if (ex) {
            toast.showEx(ex);
            return;
          } else {
            state.verifyOldEmailRef.open();
          }
        } else {
          state.mobile.areaCode = appStore?.loginInfo?.mobileAreaCode;
          state.mobile.phone = appStore?.loginInfo?.phone;
          const [res, ex] = await api.cidp.v2.captcha.getAuth(
            null,
            merge(
              {
                captchaType: CAPTCHA_TYPE.phone,
                captchaScene: CAPTCHA_SCENE.bindPhone
              },
              geeResult
            )
          );
          if (ex) {
            toast.showEx(ex);
            return;
          } else {
            state.verifyOldMobileRef.open();
          }
        }
      }, 100),
      mobileUpdate: debounce(async () => {
        state.changeType = 1;
        let geeResult = await state.geeCaptchaRef.validate();
        if (!isNullOrWhitespace(appStore?.loginInfo?.phone)) {
          state.mobile.areaCode = appStore?.loginInfo?.mobileAreaCode;
          state.mobile.phone = appStore?.loginInfo?.phone;
          const [res, ex] = await api.cidp.v2.captcha.getAuth(
            null,
            merge(
              {
                captchaType: CAPTCHA_TYPE.phone,
                captchaScene: CAPTCHA_SCENE.bindPhone
              },
              geeResult
            )
          );
          if (ex) {
            toast.showEx(ex);
            return;
          } else {
            state.verifyOldMobileRef.open();
          }
        } else {
          state.email = appStore?.loginInfo?.email;
          const [res, ex] = await api.cidp.v2.captcha.getAuth(
            null,
            merge(
              {
                captchaType: CAPTCHA_TYPE.email,
                captchaScene: CAPTCHA_SCENE.bindEmail
              },
              geeResult
            )
          );
          if (ex) {
            toast.showEx(ex);
            return;
          } else {
            state.verifyOldEmailRef.open();
          }
        }
      }, 100),
      verifyEmail: debounce(async (flag) => {
        //flag 0:old 1:new
        if (flag) {
          const [valid, formData] = await state.verifyNewEmailFormRef.validate();
          if (valid) {
            const [res, ex] = await api.cidp.details.updatePhoneOrEmail(null, {
              captchaType: CAPTCHA_TYPE.email,
              account: state.email,
              captcha: formData.verifyNewEmailCode,
              SYSTEM_FLAG: '2'
            });
            if (ex) {
              await toast.showEx(ex);
              return;
            } else {
              await toast.showSuccess(res);
              const [res, ex] = await api.cidp.user.info();
              if (ex) {
                appStore.updateLoginInfo({ email: state.email });
              } else {
                if (Object.keys(res).includes('token')) {
                  delete res.token;
                }
                appStore.updateLoginInfo({
                  ...res,
                  checkTime: new Date().getTime()
                });
              }
              await state.verifyNewEmailRef.close().catch();
            }
          }
        } else {
          const [valid, formData] = await state.verifyOldEmailFormRef.validate();
          if (valid) {
            const [res, ex] = await api.cidp.captcha.checkAuth(null, {
              captchaType: CAPTCHA_TYPE.email,
              captchaScene: CAPTCHA_SCENE.bindEmail,
              captcha: formData.verifyOldEmailCode
            });
            if (ex) {
              await toast.showEx(ex);
              return;
            } else {
              await state.verifyOldEmailRef.close().catch();
              if (state.changeType) {
                state.changeMobileRef.open();
              } else {
                state.changeEmailRef.open();
              }
            }
          }
        }
      }, 100),
      verifyMobile: debounce(async (flag) => {
        //flag 0:old 1:new
        if (flag) {
          const [valid, formData] = await state.verifyNewMobileFormRef.validate();
          if (valid) {
            const [res, ex] = await api.cidp.details.updatePhoneOrEmail(null, {
              captchaType: CAPTCHA_TYPE.phone,
              account: state.mobile.phone,
              mobileAreaCode: state.mobile.areaCode,
              captcha: formData.verifyNewMobileCode,
              SYSTEM_FLAG: '2'
            });
            if (ex) {
              await toast.showEx(ex);
              return;
            } else {
              await toast.showSuccess(res);
              const [res, ex] = await api.cidp.user.info();
              if (ex) {
                appStore.updateLoginInfo({ phone: state.mobile.phone, mobileAreaCode: state.mobile.areaCode });
              } else {
                if (Object.keys(res).includes('token')) {
                  delete res.token;
                }
                appStore.updateLoginInfo({
                  ...res,
                  checkTime: new Date().getTime()
                });
              }
              await state.verifyNewMobileRef.close().catch();
            }
          }
        } else {
          const [valid, formData] = await state.verifyOldMobileFormRef.validate();
          if (valid) {
            const [res, ex] = await api.cidp.captcha.checkAuth(null, {
              captchaType: CAPTCHA_TYPE.phone,
              captchaScene: CAPTCHA_SCENE.bindPhone,
              captcha: formData.verifyOldMobileCode
            });
            if (ex) {
              await toast.showEx(ex);
              return;
            } else {
              await state.verifyOldMobileRef.close().catch();
              if (state.changeType) {
                state.changeMobileRef.open();
              } else {
                state.changeEmailRef.open();
              }
            }
          }
        }
      }, 100),
      changeEmail: debounce(async () => {
        const [valid, formData] = await state.changeEmailFormRef.validate();
        let geeResult = await state.geeCaptchaRef.validate();
        if (valid) {
          const [res, ex] = await api.cidp.v2.captcha.getBind(
            null,
            merge(
              {
                captchaType: CAPTCHA_TYPE.email,
                captchaScene: CAPTCHA_SCENE.bindEmail,
                email: formData.email
              },
              geeResult
            )
          );
          if (ex) {
            await toast.showEx(ex);
            return;
          } else {
            state.email = formData.email;
            await state.changeEmailRef.close().catch();
            state.verifyNewEmailRef.open();
          }
        }
      }, 100),
      changeMobile: debounce(async () => {
        const [valid, formData] = await state.changeMobileFormRef.validate();
        let geeResult = await state.geeCaptchaRef.validate();
        if (valid) {
          const [res, ex] = await api.cidp.v2.captcha.getBind(
            null,
            merge(
              {
                captchaType: CAPTCHA_TYPE.phone,
                captchaScene: CAPTCHA_SCENE.bindPhone,
                mobileAreaCode: formData.mobile.area.code,
                phone: formData.mobile.number
              },
              geeResult
            )
          );
          if (ex) {
            await toast.showEx(ex);
            return;
          } else {
            state.mobile = {
              areaCode: formData.mobile.area.code,
              phone: formData.mobile.number
            };
            state.changeMobileRef.close().catch();
            state.verifyNewMobileRef.open();
          }
        }
      }, 100),
      resendOldCode: debounce(async (flag) => {
        let body = null;
        let geeResult = await state.geeCaptchaRef.validate();
        //flag 0:email 1:mobile
        if (flag) {
          body = merge(
            {
              captchaType: CAPTCHA_TYPE.phone,
              captchaScene: CAPTCHA_SCENE.bindPhone
            },
            geeResult
          );
        } else {
          body = merge(
            {
              captchaType: CAPTCHA_TYPE.email,
              captchaScene: CAPTCHA_SCENE.bindEmail
            },
            geeResult
          );
        }
        const [res, ex] = await api.cidp.v2.captcha.getAuth(null, body);
        if (ex) {
          await toast.showEx(ex);
          return;
        }
      }, 100),
      resendNewCode: debounce(async (flag) => {
        let geeResult = await state.geeCaptchaRef.validate();
        let body = null;
        //flag 0:email 1:mobile
        if (flag) {
          body = merge(
            {
              captchaType: CAPTCHA_TYPE.phone,
              captchaScene: CAPTCHA_SCENE.bindPhone,
              mobileAreaCode: state.mobile.areaCode,
              phone: state.mobile.phone
            },
            geeResult
          );
        } else {
          body = merge(
            {
              captchaType: CAPTCHA_TYPE.email,
              captchaScene: CAPTCHA_SCENE.bindEmail,
              email: state.email
            },
            geeResult
          );
        }

        const [res, ex] = await api.cidp.v2.captcha.getBind(null, body);
        if (ex) {
          await toast.showEx(ex);
          return;
        }
      }, 100)
    };

    onMounted(async () => {
      if (!appStore?.hasLoggedIn) {
        redirectToLogin(props.page, router);
        return;
      }
      state.countries = await services.site.getCountries();
    });

    return {
      ...toRefs(state),
      ...methods,
      appStore
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-dashboard-account {
  &__body {
    margin-top: 32px;
  }
  &__personal-info {
    margin-top: 64px;
    &-btn {
      margin-top: 16px;
    }
  }
  &__login-info {
    margin-top: 80px;
    &-email {
      position: relative;
      margin-top: 24px;
    }
    &-mobile {
      position: relative;
      // margin-top: 24px;
    }
    &-edit-btn {
      position: absolute;
      top: 12px;
      right: 0;
      text-decoration: underline;
    }
  }
  &__explains {
    margin-top: 148px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    &-item {
      width: calc(50% - 10px);
      display: flex;
      flex-direction: row;
      gap: 10px;
      .e-icon {
        margin-top: 6px;
      }
    }
  }
  &__verify,
  &__change {
    width: calc(100vw - 32px);
    margin: 0 auto;
    padding: 48px 32px 32px;
    &-title {
      margin-bottom: 24px;
    }
    &-body {
      margin-bottom: 24px;
    }
    &-data {
      @include h7;
      margin-bottom: 16px;
    }
    &-btn {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  @include tablet-landscape {
    &__modal {
      .e-modal__content {
        width: fit-content;
      }
      .s-dynamic-form {
        .e-phone-input__country-options {
          max-height: 80px;
        }
      }
    }
    // &__login-info-mobile {
    //   margin: 0;
    // }
    &__verify,
    &__change {
      width: 600px;
      padding: 80px 80px 64px;
      &-title {
        margin-bottom: 24px;
      }
      &-body {
        margin-bottom: 24px;
      }
      &-data {
        border-bottom: 1px solid rgba(#2c2c2c, 0.2);
        padding-bottom: 32px;
        margin-bottom: 8px;
      }
      &-btn {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}
</style>

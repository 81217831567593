<template>
  <overall-settings v-bind="$props">
    <div class="c-contentBlockImage" ref="contentBLockImage" v-if="fields">
      <section class="c-contentBlockImage__grid--image" :style="pageStyle">
        <adaptive-media v-if="mediaStatus == 1" class="c-contentBlockImage__media" :field="fields.media" />
        <AdaptiveBackImage v-if="mediaStatus == 0" :pc-image="fields.media?.fields?.desktopImage" :mobile-image="fields.media?.fields?.mobileImage" class="c-contentBlockImage__imageOnly" />
      </section>

      <div class="c-contentBlockImage__grid">
        <section class="c-contentBlockImage__title" v-if="!$isNullOrEmpty(fields.title?.value)">
          <div
            class="c-contentBlockImage__title__word"
            :class="{
              'c-contentBlockTitle--left': fields.titlePosition && fields.titlePosition.fields.phrase.value === 'left',
              'c-contentBlockTitle--right': fields.titlePosition && fields.titlePosition.fields.phrase.value === 'right',
              'c-contentBlockTitle--center': fields.titlePosition && fields.titlePosition.fields.phrase.value === 'center'
            }"
          >
            <animated-content type="skew-in">
              <jss-rich-text :field="fields.title" tag="div" />
            </animated-content>
          </div>
        </section>

        <section class="c-contentBlockImage__content" v-if="!$isNullOrEmpty(fields.remarksTitle?.value) || !$isNullOrEmpty(fields.remarksWord?.value)">
          <section class="c-contentBlockImage__content__main">
            <div
              class="c-contentBlockImage__spTitle__content"
              :class="{
                'c-contentBlockImage--leftTop': fields.remarksWordPostion && fields.remarksWordPostion.name === 'LeftTop',
                'c-contentBlockImage--leftBottom': fields.remarksWordPostion && fields.remarksWordPostion.name === 'LeftBottom',
                'c-contentBlockImage--rightTop': fields.remarksWordPostion && fields.remarksWordPostion.name === 'RightTop',
                'c-contentBlockImage--rightBottom': fields.remarksWordPostion && fields.remarksWordPostion.name === 'RightBottom'
              }"
              ref="iconTitleContent"
            >
              <animated-content type="fade-in-top-left" v-if="fields.icon && fields.icon.value && fields.icon.value.svgCode">
                <div class="c-contentBlockImage__spTitle__icon">
                  <Icon :svg="fields.icon.value.svgCode" :style="fields.iconColor ? 'color:' + fields.iconColor.value : ''" />
                </div>
              </animated-content>

              <animated-content type="skew-in" :hide-content="false">
                <jss-rich-text v-if="!$isNullOrEmpty(fields.remarksTitle?.value)" class="c-contentBlockImage__spTitle" :field="fields.remarksTitle" tag="div" />
              </animated-content>

              <animated-content type="fade-in" :hide-content="false">
                <jss-rich-text v-if="!$isNullOrEmpty(fields.remarksWord?.value)" class="c-contentBlockImage__word--sp" :field="fields.remarksWord" tag="div" />
              </animated-content>
            </div>
          </section>
        </section>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, onMounted, watch } from 'vue';
import { isDesktop, isTabletLandscape, isDesktopLarge } from '@/utils/dom-utils';

/**
 * @typedef ContentBlockImageFields
 * @property {GlobalSettingEntry} theme
 * */
export default {
  name: 'ContentBlockImage',
  props: {
    /**@type ContentBlockImageFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      addStatus: 0,
      contentBLockImage: null,
      iconTitleContent: null,
      mediaStatus: 0,
      pageStyle: ''
    });

    watch(
      () => props.fields,
      (newVal) => {
        if (newVal && newVal.media) {
          if (newVal.media && newVal.media.fields) {
            if ((isDesktop() || isDesktopLarge() || isTabletLandscape()) && newVal.media.fields.desktopVideo.value) {
              state.mediaStatus = 1;
            }
            if (!isDesktop() && !isDesktopLarge() && !isTabletLandscape() && newVal.media.fields.mobileVideo.value) {
              state.mediaStatus = 1;
            }
            if (isTabletLandscape() && newVal.media.fields.tabletVideo.value) {
              state.mediaStatus = 1;
            }
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    const methods = {
      isDesktop: isDesktop,
      isDesktopLarge: isDesktopLarge,
      isTabletLandscape: isTabletLandscape
    };

    onMounted(() => {
      if (props.fields && props.fields.grid && props.fields.grid.fields.phrase.value) {
        state.pageStyle =
          !isDesktop() && !isDesktopLarge() && !isTabletLandscape()
            ? ''
            : 'grid-area: auto / ' +
              (parseInt(props.fields.grid.fields.phrase.value.split('-')[0]) + 1) +
              ' / span ' +
              (parseInt(props.fields.grid.fields.phrase.value.split('-')[0]) + 1) +
              ' / span ' +
              parseInt(props.fields.grid.fields.phrase.value.split('-')[1]) +
              ';';
      }
    });

    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-contentBlockImage__imageOnly {
  position: relative;
  width: 100%;
  height: 100%;
}
.clear-line {
  width: 100%;
  height: 1px;
  overflow: hidden;
  background: transparent;
}
.c-contentBlockImage {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  background-size: cover;
  height: 100vh;

  .c-contentBlockImage__grid {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .c-contentBlockImage__grid--image {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .c-contentBlockImage__media {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .c-contentBlockImage__title {
    position: relative;
    width: 100%;
    @include h3;
    z-index: 1;
    color: #fff;
    font-weight: normal;
    font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
    @include grid-container;

    .c-contentBlockImage__title__word {
      @include grid-block(2, 10);
      padding-top: 70px;
    }
  }
  .c-contentBlockImage__content {
    position: relative;
    width: 100%;
    flex-basis: 100%;
    z-index: 1;
    @include grid-container;
  }
  .c-contentBlockImage__content__main {
    position: relative;
    height: 100%;
    @include grid-block(2, 10);
    height: 100%;
  }

  .c-contentBlockImage__spTitle__icon {
    position: relative;
    width: 20px;
    height: 20px;

    .e-icon {
      color: #fff200;
      > svg {
        width: 100%;
        height: auto;
      }
    }
  }
  .c-contentBlockImage__spTitle__content {
    position: absolute;
    clear: both;
    width: 100%;
  }
  .c-contentBlockImage__spTitle {
    position: relative;
    width: 100%;
    font-size: 24px;
    line-height: 1.1;
    color: #fff200;
    padding: 12px 0 0 20px;
  }
  .c-contentBlockImage__word--sp {
    clear: both;
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    padding: 20px 0 0 20px;
    color: #fff;
  }

  .c-contentBlockImage__show {
    opacity: 1;
    transition: opacity 1.5s ease 0.3s;
  }
}
.c-contentBlockImage--leftTop {
  left: 0;
  top: 70px;
}

.c-contentBlockImage--leftBottom {
  left: 0;
  bottom: 70px;
}

.c-contentBlockImage--rightTop {
  right: 0;
  top: 70px;
}

.c-contentBlockImage--rightBottom {
  right: 0;
  bottom: 70px;
}

@include tablet-landscape {
  .c-contentBlockImage {
    @include grid-container;

    .c-contentBlockImage__grid {
      @include grid-block(1, 24);
    }

    .c-contentBlockImage__content__main {
      position: relative;
      @include grid-block(3, 20);
    }

    .c-contentBlockImage__spTitle__content {
      width: grid-width(6); //340px;
      position: absolute;
    }
    .c-contentBlockImage__spTitle {
      font-size: 32px;
    }

    .c-contentBlockImage__title {
      .c-contentBlockImage__title__word {
        @include grid-block(3, 20);

        &.c-contentBlockTitle--left {
          text-align: start;
        }
        &.c-contentBlockTitle--right {
          text-align: end;
        }
        &.c-contentBlockTitle--center {
          text-align: center;
        }
      }
    }
  }
}

html.rtl {
  .c-contentBlockImage--leftTop {
    right: 0;
  }

  .c-contentBlockImage--leftBottom {
    right: 0;
  }

  .c-contentBlockImage--rightTop {
    left: 0;
  }

  .c-contentBlockImage--rightBottom {
    left: 0;
  }
}
html[lang='ja-JP'] {
  .c-contentBlockImage {
    &__title {
      font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
    }
  }
}
html[lang='ko-KR'] {
  .c-contentBlockImage {
    &__title {
      font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
    }
  }
}
</style>

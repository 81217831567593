<template>
  <overall-settings v-bind="$props">
    <div class="c-comparison">
      <div class="c-comparison__content">
        <div class="c-comparison__title">
          <div>
            <jss-rich-text class="c-comparison__titleup" :field="fields.title" :type="fields.titleType" />
          </div>
          <div>
            <jss-rich-text class="c-comparison__titledown" :field="fields.title1" :type="fields.titleType" />
          </div>
        </div>
        <div v-if="fields.areatabs?.length > 1" class="c-comparison__subtitlewrap">
          <jss-rich-text class="c-comparison__subtitle" :field="fields.subtitle" />
          <div class="c-comparison__subtitlepick">
            <div
              v-for="(item, index) in fields.areatabs"
              :key="index"
              @click="pickClick(index)"
              :class="[fields.areatabs?.length == 2 && (isMobile() || isTablet()) ? 'c-comparison__pickleft2' : 'c-comparison__pickleft', index == selectedTab[0] ? 'c-comparison__pickselected' : '']"
            >
              {{ item.fields?.tabarea.value }}
            </div>
          </div>
        </div>
        <div
          v-if="selectItemsL || selectItemsR"
          :style="[selectItemsL ? 'left:0;' : 'right:0;', 'top:' + headerTop + 'px']"
          :class="selectItemsL ? `c-comparison__headertitlewrap1` : `c-comparison__headertitlewrap2`"
        >
          <div
            :class="(selectItemsL && selectedIndexL == index) || (selectItemsR && selectedIndexR == index) ? `c-comparison__selectedcolor` : ``"
            v-for="(item, index) in fields.areatabs[selectedTab[0]]?.fields?.headeritem"
            :key="index"
          >
            <jss-rich-text class="c-comparison__headertitle1" :field="item.fields.headertitle" @click="titleSelect(index)" />
          </div>
        </div>
        <div v-if="(isMobile() || isTablet()) && fields.areatabs?.length >= 1" ref="divScroll" class="c-comparison__headerwrap" :style="`height:` + headerHeight + 'px'">
          <div class="c-comparison__headeritem3">
            <div class="c-comparison__headertitlewrap" @click="openSelect(0)">
              <jss-rich-text class="c-comparison__headertitle" :field="fields.areatabs[selectedTab[0]]?.fields?.headeritem[selectedIndexL]?.fields?.headertitle" />
              <div><icon style="margin-right: 24px;" name="down"></icon></div>
            </div>
            <jss-rich-text class="c-comparison__headersubtitle" :field="fields.areatabs[selectedTab[0]]?.fields?.headeritem[selectedIndexL]?.fields?.headersubtitle" />
            <jss-rich-text class="c-comparison__headerbody" :field="fields.areatabs[selectedTab[0]]?.fields?.headeritem[selectedIndexL]?.fields?.headerbody" />
            <site-button
              v-if="fields.areatabs[selectedTab[0]]?.fields?.headeritem[selectedIndexL]?.fields?.headerbutton"
              class="c-comparison__headerbutton"
              v-bind="fields.areatabs[selectedTab[0]]?.fields?.headeritem[selectedIndexL]?.fields?.headerbutton"
            />
          </div>
          <div class="c-comparison__headeritem3" style="border-right:0;">
            <div class="c-comparison__headertitlewrap" @click="openSelect(1)">
              <jss-rich-text class="c-comparison__headertitle" :field="fields.areatabs[selectedTab[0]]?.fields?.headeritem[selectedIndexR]?.fields?.headertitle" />
              <div><icon style="margin-right: 24px;" name="down"></icon></div>
            </div>
            <jss-rich-text class="c-comparison__headersubtitle" :field="fields.areatabs[selectedTab[0]]?.fields?.headeritem[selectedIndexR]?.fields?.headersubtitle" />
            <jss-rich-text class="c-comparison__headerbody" :field="fields.areatabs[selectedTab[0]]?.fields?.headeritem[selectedIndexR]?.fields?.headerbody" />
            <site-button
              v-if="fields.areatabs[selectedTab[0]]?.fields?.headeritem[selectedIndexR]?.fields?.headerbutton"
              class="c-comparison__headerbutton"
              v-bind="fields.areatabs[selectedTab[0]]?.fields?.headeritem[selectedIndexR]?.fields?.headerbutton"
            />
          </div>
        </div>
        <div v-if="!(isMobile() || isTablet()) && fields.areatabs?.length >= 1" ref="divScroll" class="c-comparison__headerwrap" :style="`height:` + headerHeight + 'px'">
          <div
            :class="
              fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 5
                ? 'c-comparison__headeritem6'
                : fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 3
                ? 'c-comparison__headeritem4'
                : fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 2
                ? 'c-comparison__headeritem4s'
                : 'c-comparison__headeritem5'
            "
          ></div>
          <div
            :class="[
              index % 2 == 0 ? 'c-comparison__headeritem6odd' : '',
              fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 5
                ? 'c-comparison__headeritem6'
                : fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 4
                ? 'c-comparison__headeritems'
                : fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 3
                ? 'c-comparison__headeritem6s'
                : 'c-comparison__headeritem3'
            ]"
            v-for="(item, index) in fields.areatabs[selectedTab[0]]?.fields?.headeritem"
            :key="index"
          >
            <jss-rich-text class="c-comparison__headertitle" :field="item.fields.headertitle" />
            <jss-rich-text class="c-comparison__headersubtitle" :field="item.fields.headersubtitle" />
            <jss-rich-text class="c-comparison__headerbody" :field="item.fields.headerbody" />
            <site-button v-if="item.fields.headerbutton" class="c-comparison__headerbutton" v-bind="item.fields.headerbutton" />
          </div>
        </div>
        <div ref="panelScroll"></div>
        <div v-if="isMobile() || isTablet()">
          <div v-for="(item, index1) in fields.areatabs[selectedTab[0]]?.fields?.panelitem" :key="index1" v-show="showAll || openedIndexes.indexOf(index1) != -1">
            <div style="display: flex; flex-direction: row;" :class="openedIndexes.indexOf(index1) != -1 ? 'c-comparison__contentopen' : 'c-comparison__contentclose'" @click="openOrClose(index1)">
              <jss-rich-text :class="openedIndexes.indexOf(index1) != -1 ? `c-comparison__contentopentext` : `c-comparison__contentclosetext`" :field="item.fields.categorytitle" />
              <icon name="arrow-up" class="c-comparison__arrowup" v-if="openedIndexes.indexOf(index1) != -1" />
              <icon name="arrow-down" class="c-comparison__arrowdown" v-if="openedIndexes.indexOf(index1) == -1" />
            </div>
            <div class="c-comparison__contentdiv">
              <div v-if="openedIndexes.indexOf(index1) != -1" class="c-comparison__contentwrap">
                <div class="c-comparison__contentitem6" v-for="(item, index) in item.fields.categoryitems" :key="index">
                  <div style="flex-direction: row; display: flex;" class="c-comparison__rowtitle3">
                    <jss-rich-text :style="item.fields.icon?.value?.svgCode ? '' : 'width:100%'" class="c-comparison__rowtitleleft" :field="item.fields.rowtitle" />
                    <div v-if="item.fields.remark.value" class="c-comparison__rowtitleright">
                      <Tooltip :maxWidth="isMobile() ? 300 : 480">
                        <template #title>{{ item.fields.remark.value }}</template>
                        <Icon class="c-comparison__icon" :svg="item.fields.icon.value?.svgCode" />
                      </Tooltip>
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: row;">
                    <jss-rich-text
                      class="c-comparison__rowvalue3"
                      :field="
                        selectedIndexL == 0
                          ? item.fields?.value1?.value == ''
                            ? spaceValue
                            : item.fields.value1
                          : selectedIndexL == 1
                          ? item.fields?.value2?.value == ''
                            ? spaceValue
                            : item.fields.value2
                          : selectedIndexL == 2
                          ? item.fields?.value3?.value == ''
                            ? spaceValue
                            : item.fields.value3
                          : selectedIndexL == 3
                          ? item.fields?.value4?.value == ''
                            ? spaceValue
                            : item.fields.value4
                          : item.fields?.value5?.value == ''
                          ? spaceValue
                          : item.fields.value5
                      "
                    />
                    <jss-rich-text
                      class="c-comparison__rowvalue3"
                      :field="
                        selectedIndexR == 0
                          ? item.fields?.value1?.value == ''
                            ? spaceValue
                            : item.fields.value1
                          : selectedIndexR == 1
                          ? item.fields?.value2?.value == ''
                            ? spaceValue
                            : item.fields.value2
                          : selectedIndexR == 2
                          ? item.fields?.value3?.value == ''
                            ? spaceValue
                            : item.fields.value3
                          : selectedIndexR == 3
                          ? item.fields?.value4?.value == ''
                            ? spaceValue
                            : item.fields.value4
                          : item.fields?.value5?.value == ''
                          ? spaceValue
                          : item.fields.value5
                      "
                    />
                  </div>
                </div>
              </div>
              <div v-if="!showAll && index1 == 0" class="c-comparison__button">
                <site-button @click="showAllClick" class="c-comparison__showAllButton" v-bind="fields.button" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="!(isMobile() || isTablet())">
          <div v-for="(item, index1) in fields.areatabs[selectedTab[0]]?.fields?.panelitem" :key="index1" v-show="showAll || openedIndexes.indexOf(index1) != -1">
            <div style="display: flex; flex-direction: row;" :class="openedIndexes.indexOf(index1) != -1 ? 'c-comparison__contentopen' : 'c-comparison__contentclose'" @click="openOrClose(index1)">
              <jss-rich-text :field="item.fields.categorytitle" />
              <icon name="arrow-up" class="c-comparison__arrowup" v-if="openedIndexes.indexOf(index1) != -1" />
              <icon name="arrow-down" class="c-comparison__arrowdown" v-if="openedIndexes.indexOf(index1) == -1" />
            </div>
            <div class="c-comparison__contentdiv">
              <div v-if="openedIndexes.indexOf(index1) != -1" class="c-comparison__contentwrap">
                <div ref="contentDiv" class="c-comparison__contentitem9" v-for="(item, index) in item.fields.categoryitems" :key="index">
                  <div
                    :style="`height: ` + lineHeight[index] + `px`"
                    :class="[
                      fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 5
                        ? `c-comparison__rowtitle`
                        : fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 4
                        ? `c-comparison__rowtitle4`
                        : `c-comparison__rowtitle3`,
                      `c-comparison__rowStyle`
                    ]"
                  >
                    <jss-rich-text :style="item.fields.icon?.value?.svgCode ? '' : 'width:100%'" class="c-comparison__rowtitleleft" :field="item.fields.rowtitle" />
                    <div v-if="item.fields.remark?.value" class="c-comparison__rowtitleright">
                      <Tooltip :maxWidth="480">
                        <template #title>{{ item.fields.remark.value }}</template>
                        <Icon class="c-comparison__icon" :svg="item.fields.icon.value?.svgCode" />
                      </Tooltip>
                    </div>
                  </div>
                  <jss-rich-text
                    :class="[
                      fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 3 || fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 5
                        ? `c-comparison__rowvalue`
                        : fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 4
                        ? `c-comparison__rowvalue4`
                        : 'c-comparison__rowvalue3',
                      'c-comparison__contentitem6odd'
                    ]"
                    :field="item.fields?.value1?.value == '' ? spaceValue : item.fields.value1"
                  />
                  <jss-rich-text
                    :class="[
                      fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 3 || fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 5
                        ? `c-comparison__rowvalue`
                        : fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 4
                        ? `c-comparison__rowvalue4`
                        : 'c-comparison__rowvalue3'
                    ]"
                    :field="item.fields?.value2?.value == '' ? spaceValue : item.fields.value2"
                  />
                  <jss-rich-text
                    v-if="fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length >= 3"
                    :class="[fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 4 ? `c-comparison__rowvalue4` : 'c-comparison__rowvalue', 'c-comparison__contentitem6odd']"
                    :field="item.fields?.value3?.value == '' ? spaceValue : item.fields.value3"
                  />
                  <jss-rich-text
                    v-if="fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length >= 4"
                    :class="fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 4 ? `c-comparison__rowvalue4` : `c-comparison__rowvalue`"
                    :field="item.fields?.value4?.value == '' ? spaceValue : item.fields.value4"
                  />
                  <jss-rich-text
                    v-if="fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 5"
                    :class="[fields.areatabs[selectedTab[0]]?.fields?.headeritem?.length == 4 ? `c-comparison__rowvalue4` : 'c-comparison__rowvalue', 'c-comparison__contentitem6odd']"
                    :field="item.fields?.value5?.value == '' ? spaceValue : item.fields.value5"
                  />
                </div>
              </div>
              <div v-if="!showAll && index1 == 0" class="c-comparison__button">
                <site-button @click="showAllClick" class="c-comparison__showAllButton" v-bind="fields.button" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { onMounted, onUnmounted, reactive, toRefs, nextTick } from 'vue';
import { debounce } from 'lodash';
import { isMobile, isTablet, canUseDOM } from '@/utils/dom-utils';
import useDevice from '@/hooks/useDevice';

export default {
  name: 'Comparison',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    const { deviceState } = useDevice();
    const state = reactive({
      selectedTab: [0],
      showAll: false,
      divScroll: null,
      panelScroll: null,
      headerHeight: 227,
      openedIndexes: [0],
      lineHeight: [],
      selectItemsL: false,
      selectItemsR: false,
      headerTop: 448,
      selectedIndexL: 0,
      selectedIndexR: 1,
      contentDiv: null,
      spaceValue: { value: '<p style="text-align: center;">&nbsp;</p>' }
    });
    const methods = {
      isMobile,
      isTablet,
      pickClick: (value) => {
        if (value != state.selectedTab[0]) {
          state.selectedTab = [];
          state.selectedTab.push(value);
          state.selectItemsL = false;
          state.selectItemsR = false;
          state.selectedIndexL = 0;
          state.selectedIndexR = 1;
        }
        nextTick(() => {
          if (canUseDOM()) {
            state.lineHeight = [];
            const domNewObj = state.contentDiv;
            for (var i = 0; i < domNewObj?.length; i++) {
              state.lineHeight.push(domNewObj[i]?.offsetHeight);
            }
          }
        });
      },
      openSelect: debounce((flag) => {
        if (flag == 0) {
          state.selectItemsL = !state.selectItemsL;
          state.selectItemsR = false;
        } else {
          state.selectItemsR = !state.selectItemsR;
          state.selectItemsL = false;
        }
      }, 300),
      titleSelect: debounce((flag) => {
        if (state.selectItemsL) {
          if (flag == state.selectedIndexR) {
            state.selectedIndexR = state.selectedIndexL;
            state.selectedIndexL = flag;
          } else {
            state.selectedIndexL = flag;
          }
          state.selectItemsL = false;
        } else {
          if (flag == state.selectedIndexL) {
            state.selectedIndexL = state.selectedIndexR;
            state.selectedIndexR = flag;
          } else {
            state.selectedIndexR = flag;
          }
          state.selectItemsR = false;
        }
      }, 300),
      openOrClose: debounce((flag) => {
        if (state.openedIndexes.indexOf(flag) != -1) {
          let index = state.openedIndexes.indexOf(flag);
          if (state.showAll) {
            state.openedIndexes.splice(index, 1);
          }
        } else {
          state.openedIndexes.push(flag);
        }
      }, 300),
      showAllClick: () => {
        state.showAll = true;
      },
      onscroll: async () => {
        const offset = state.divScroll?.getBoundingClientRect();
        const offset1 = state.panelScroll?.getBoundingClientRect();
        state.headerTop = offset?.top + 56;
        if (offset?.top <= 0) {
          if (state.headerHeight <= 65 && !(methods.isMobile() || methods.isTablet())) {
            state.headerHeight = 65;
          } else if (state.headerHeight <= 58 && (methods.isMobile() || methods.isTablet())) {
            state.headerHeight = 58;
          } else if (state.headerHeight <= 227) {
            state.headerHeight = offset1?.top;
          } else {
            state.headerHeight = 227;
          }
        }
        if (offset1?.top >= 0) {
          if (offset1?.top <= 227 && offset1?.top > 65 && !(methods.isMobile() || methods.isTablet())) {
            state.headerHeight = offset1?.top;
          } else if (offset1?.top <= 227 && offset1?.top > 58 && (methods.isMobile() || methods.isTablet())) {
            state.headerHeight = offset1?.top;
          } else if (offset1?.top <= 65 && !(methods.isMobile() || methods.isTablet())) {
            state.headerHeight = 65;
          } else if (offset1?.top <= 58 && (methods.isMobile() || methods.isTablet())) {
            state.headerHeight = 58;
          } else {
            state.headerHeight = 227;
          }
        }
      }
    };
    onMounted(() => {
      state.lineHeight = [];
      const domObj = state.contentDiv;
      for (var i = 0; i < domObj?.length; i++) {
        state.lineHeight.push(domObj[i]?.offsetHeight);
      }
      document.addEventListener('scroll', methods.onscroll, true);
    });
    onUnmounted(() => {
      document.removeEventListener('scroll', methods.onscroll, true);
    });
    return {
      ...toRefs(state),
      deviceState,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-comparison {
  padding-bottom: $space-40;
  @include grid-block(1, 12);
  height: auto;
  @include grid-container;
  @include component-overall-settings;
  @include component-themes;
  &__content {
    @include grid-block(2, 10);
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__arrowup {
    width: 12px;
    height: 8px;
    color: #fff;
    position: absolute;
    right: 24px;
  }
  &__arrowdown {
    width: 12px;
    height: 8px;
    color: #000000;
    position: absolute;
    right: 24px;
  }
  &__title {
    width: grid-width-m(10);
    height: 64px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 80px;
  }
  &__titleup {
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
  }
  &__titledown {
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #b8b8b8;
  }
  &__subtitlewrap {
    width: grid-width-m(10);
    height: auto;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 24px;
  }
  &__subtitle {
    width: grid-width-m(10);
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
  }
  &__subtitlepick {
    width: grid-width-m(10);
    height: auto;
    min-height: 56px;
    position: relative;
    display: -webkit-box;
    flex-direction: row;
    margin-top: 24px;
    border: 0px solid #b8b8b8;
    border-right: 0.5px solid #b8b8b8;
    border-radius: 1px 0px 0px 1px;
    overflow: hidden;
    overflow-x: scroll;
  }
  &__subtitlepick::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }
  &__pickleft {
    width: calc(grid-width-m(10) / 3);
    height: auto;
    min-height: 56px;
    display: flex;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2f2f2f;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: 0.5px solid #b8b8b8;
    border-right: 0px;
    border-radius: 1px 0px 0px 1px;
    word-break: break-word;
    overflow: hidden;
  }
  &__pickleft2 {
    width: calc(grid-width-m(10) / 2);
    height: auto;
    min-height: 56px;
    display: flex;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2f2f2f;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: 0.5px solid #b8b8b8;
    border-right: 0px;
    border-radius: 1px 0px 0px 1px;
    word-break: break-word;
    overflow: hidden;
  }
  &__pickselected {
    background-color: #000000;
    color: #ffffff;
  }
  &__pickselected:hover {
    background-color: #000000 !important;
    color: #ffffff !important;
    cursor: default;
  }
  &__headerwrap {
    width: grid-width-m(12);
    height: 227px;
    overflow: hidden;
    margin-top: 60px;
    top: 0px;
    z-index: 1;
    background-color: #ffffff;
    position: sticky;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0px solid #e3e3e3;
    border-right: 0px;
    transition: all 0.3s linear 0;
  }
  &__headeritem3 {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #b8b8b8;
    border-left: 0;
    .e-site-button.secondary-button.theme-dark {
      color: #000;
      width: auto;
      margin: 16px 14px 11px 24px;
      border: 2px solid #000;
      height: 56px;
      .e-site-button__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
        text-transform: uppercase;
        padding: 18px 20px 14px 20px;
        width: auto;
        white-space: nowrap;
      }
      .e-icon {
        display: none;
      }
    }
    .e-site-button.secondary-button {
      padding: 0 16px 0 16px !important;
    }
    // .e-site-button.primary-link.ani-link-swipe.bordered {
    //   padding-left: 48px;
    //   padding-right: 48px;
    //   -webkit-tap-highlight-color: transparent;
    // }
  }
  &__headertitle1 {
    width: auto;
    height: 32px;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #2f2f2f;
    margin: 12px auto 12px 20px;
  }
  &__headertitle {
    width: auto;
    height: 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #2f2f2f;
    margin: 16px auto 16px 24px;
  }
  &__headertitlewrap {
    display: flex;
    flex-direction: row;
    position: relative;
    background: #ffffff;
    border: 1px solid #b8b8b8;
    border-left: 0;
    border-right: 0;
    align-items: center;
  }
  &__headertitlewrap1 {
    display: flex;
    width: grid-width-m(5.88);
    position: fixed;
    flex-direction: column;
    background: #fff;
    border: 1px solid #b8b8b8;
    border-top: 0;
    border-left: 0;
    border-right: 1;
    z-index: 6;
    font-size: 18px;
    line-height: 32px;
  }
  &__headertitlewrap2 {
    display: flex;
    width: grid-width-m(5.9);
    position: fixed;
    flex-direction: column;
    background: #fff;
    border: 1px solid #b8b8b8;
    border-top: 0;
    border-left: 1;
    border-right: 0;
    z-index: 6;
    font-size: 18px;
    line-height: 32px;
  }
  &__selectedcolor {
    background-color: #f2f1f0;
  }
  &__headersubtitle {
    width: auto;
    height: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #757575;
    margin: 20px auto 8px 24px;
  }
  &__headerbody {
    width: auto;
    height: 60px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
    margin: 0px auto 0px 24px;
  }
  &__headerbutton {
    width: auto;
    height: 48px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    margin: 0px auto 8px 20px;
  }
  &__contentopentext {
    width: 96px;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  &__contentopen {
    top: 58px;
    // top: 0;
    // z-index: 0;
    z-index: 1;
    position: sticky;
    // position: relative;
    overflow: hidden;
    width: grid-width-m(12);
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    gap: 134px;
    background: #000000;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  &__contentclosetext {
    width: 96px;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
  }
  &__contentclose {
    // top: 58px;
    top: 0;
    z-index: 0;
    // position: sticky;
    position: relative;
    overflow: hidden;
    width: grid-width-m(12);
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    gap: 134px;
    background: #f2f1f0;
    margin-top: 4px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  &__contentwrap {
    overflow: hidden;
    z-index: 1;
    width: grid-width-m(12);
    height: auto;
    display: flex;
    align-items: center;
    border-right: 0px;
    border-bottom: 0px;
    flex-direction: column;
  }
  &__contentitem6 {
    width: grid-width-m(12);
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &__rowtitle3 {
    width: grid-width-m(12);
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    padding: 24px 24px 0px 24px;
    text-align: start;
  }
  &__rowvalue3 {
    width: grid-width-m(6);
    position: relative;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f2f1f0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4f4f4f;
    padding: 16px 24px;
    align-items: center;
    justify-content: center;
    text-align: start;
  }
  &__showAllButton {
    margin-top: 24px;
    width: 300px;
  }
  &__button {
    width: grid-width-m(12);
    margin-bottom: 120px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    .e-site-button {
      height: 60px;
      font-size: 16px !important;
      font-weight: 700;
      line-height: 24px;
      width: grid-width-m(10);
    }
    .e-site-button__icon {
      display: none !important;
    }
    .e-site-button__text {
      line-height: 24px;
      height: 20px;
      width: 211px;
      font-size: 16px !important;
      font-weight: 700;
      line-height: 24px;
      color: #000000;
      white-space: nowrap;
      margin-right: 40px;
    }
  }
  &__rowtitleleft {
    width: auto;
    max-width: 90%;
    overflow: hidden;
    word-break: break-word;
  }
  &__rowtitleright {
    width: 10%;
    display: flex;
    // justify-content: center;
    align-items: center;
    padding-left: 4px;
  }
  &__icon {
    width: 18px;
    height: 18px;
    color: black;
  }
  &__contentdiv {
    position: relative;
    overflow: hidden;
  }
  @include tablet-landscape {
    @include grid-block(1, 24);
    height: auto;
    @include grid-container;
    @include component-overall-settings;
    @include component-themes;
    &__content {
      @include grid-block(2, 22);
      height: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__title {
      width: grid-width(8);
      height: 110px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 160px;
    }
    &__titleup {
      font-weight: 400;
      font-size: 56px;
      line-height: 56px;
      text-align: center;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #000000;
    }
    &__titledown {
      font-weight: 400;
      font-size: 56px;
      line-height: 56px;
      text-align: center;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #b8b8b8;
    }
    &__subtitlewrap {
      width: grid-width(6);
      // height: 120px;
      height: auto;
      max-height: 320px;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 32px;
    }
    &__subtitle {
      width: grid-width(6);
      height: 32px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 26px;
      line-height: 32px;
      color: #000000;
    }
    &__subtitlepick {
      width: auto;
      min-height: 56px;
      height: auto;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 32px;
      border: 0px solid #b8b8b8;
      border-right: 0.5px solid #b8b8b8;
      border-radius: 1px 0px 0px 1px;
    }
    &__pickleft {
      width: grid-width(2);
      height: auto;
      min-height: 56px;
      display: flex;
      font-weight: 400;
      font-size: 16px;
      color: #2f2f2f;
      justify-content: center;
      padding: 10px 20px;
      align-items: center;
      border: 0.5px solid #b8b8b8;
      border-right: 0px;
      border-radius: 1px 0px 0px 1px;
      word-break: break-word;
      overflow: hidden;
    }
    &__pickleft:hover {
      background-color: #fff200;
      cursor: pointer;
    }
    &__pickselected {
      background-color: #000000;
      color: #ffffff;
    }
    &__headerwrap {
      width: grid-width(22);
      height: 227px;
      overflow: hidden;
      margin-top: 60px;
      top: 0px;
      z-index: 1;
      background-color: #ffffff;
      position: sticky;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #e3e3e3;
      border-right: 0px;
      transition: all 0.3s linear 0;
      .e-site-button {
        height: 48px;
        width: 78%;
        font-size: 16px !important;
        border: 1px solid;
      }
    }
    &__headeritem6 {
      width: grid-width(math.div(22, 6.005));
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #e3e3e3;
      .e-site-button.secondary-button.theme-dark {
        color: #000;
        width: auto;
        margin: 16px 24px 20px 20px;
        border: 2px solid #000;
        height: 56px;
        .e-site-button__icon {
          display: none;
        }
        .e-site-button__text {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          text-transform: uppercase;
          padding: 18px 48px 14px 48px;
          width: auto;
          white-space: nowrap;
          margin-right: 40px;
        }
      }
    }
    &__headeritem6s {
      width: grid-width(math.div(11.49, 3));
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #e3e3e3;
      .e-site-button.secondary-button.theme-dark {
        color: #000;
        width: auto;
        margin: 16px 24px 20px 20px;
        border: 2px solid #000;
        height: 56px;
        .e-site-button__icon {
          display: none;
        }
        .e-site-button__text {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          text-transform: uppercase;
          padding: 18px 48px 14px 48px;
          width: auto;
          white-space: nowrap;
          margin-right: 40px;
        }
      }
    }
    &__headeritem5 {
      width: grid-width(6);
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #e3e3e3;
    }
    &__headeritem4 {
      width: grid-width(10.51);
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #e3e3e3;
    }
    &__headeritem4s {
      width: grid-width(11);
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #e3e3e3;
    }
    &__headeritem3 {
      width: grid-width(math.div(11, 2));
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #e3e3e3;
    }
    &__headeritems {
      width: grid-width(4);
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #e3e3e3;
      .e-site-button.secondary-button.theme-dark {
        color: #000;
        width: auto;
        margin: 16px 24px 20px 20px;
        border: 2px solid #000;
        height: 56px;
        .e-site-button__icon {
          display: none;
        }
        .e-site-button__text {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          text-transform: uppercase;
          padding: 18px 48px 14px 48px;
          width: auto;
          white-space: nowrap;
          margin-right: 40px;
        }
      }
    }
    &__headeritem6odd {
      background: rgba(227, 227, 227, 0.3);
    }
    &__headertitle {
      width: auto;
      height: 32px;
      font-weight: 500;
      font-size: 26px;
      line-height: 32px;
      color: #000000;
      margin: 20px auto 8px 20px;
    }
    &__headersubtitle {
      width: auto;
      height: 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #757575;
      margin: 0px auto 8px 20px;
    }
    &__headerbody {
      width: auto;
      height: 60px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #757575;
      margin: 0px auto 8px 20px;
    }
    &__headerbutton {
      width: auto;
      height: 48px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #000000;
      margin: 0px auto 8px 20px;
    }
    // .e-site-button {
    //   height: 48px;
    //   width: 78%;
    //   font-size: 16px !important;
    //   border: 2px solid;
    // }
    &__contentopen {
      top: 65px;
      z-index: 1;
      position: sticky;
      // top: 0px;
      // z-index: 0;
      // position: relative;
      overflow: hidden;
      width: grid-width(22);
      height: 52px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 24px;
      gap: 134px;
      background: #000000;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #ffffff;
      cursor: pointer;
    }
    &__contentwrap {
      overflow: hidden;
      z-index: 1;
      width: grid-width(22);
      height: auto;
      display: flex;
      align-items: center;
      border: 1px solid #e3e3e3;
      // border-right: 0px;
      border-bottom: 0px;
      flex-direction: column;
    }
    &__contentitem6 {
      width: grid-width(22);
      height: auto;
      position: relative;
      display: flex;
      flex-direction: row;
      border-right: 1px solid #e3e3e3;
    }
    &__rowStyle {
      flex-direction: row !important;
      display: flex !important;
    }
    &__contentitem9 {
      width: grid-width(22);
      height: auto;
      position: relative;
      display: flex;
      flex-direction: row;
      border-right: 1px solid #e3e3e3;
    }
    &__contentitem6odd {
      background: rgba(227, 227, 227, 0.3);
    }
    &__rowtitle {
      width: calc(100vw / 6);
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      // border-right: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #4f4f4f;
      padding: 24px;
      text-align: start;
    }
    &__rowtitle3 {
      width: grid-width(11);
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      // border-right: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #4f4f4f;
      padding: 24px;
      text-align: start;
    }
    &__rowtitle4 {
      width: grid-width(6);
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      // border-right: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #4f4f4f;
      padding: 24px;
      text-align: start;
    }
    &__rowvalue {
      width: calc(100vw / 6);
      position: relative;
      display: flex;
      flex-direction: column;
      border-left: 0.5px solid #e3e3e3;
      // border-right: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #4f4f4f;
      padding: 24px;
      align-items: center;
      justify-content: center;
      text-align: start;
    }
    &__rowvalue3 {
      width: grid-width(math.div(11, 2));
      position: relative;
      display: flex;
      flex-direction: column;
      border-left: 0.5px solid #e3e3e3;
      // border-right: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #4f4f4f;
      padding: 24px;
      align-items: center;
      justify-content: center;
      text-align: start;
    }
    &__rowvalue4 {
      width: grid-width(4);
      position: relative;
      display: flex;
      flex-direction: column;
      border-left: 0.5px solid #e3e3e3;
      // border-right: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #4f4f4f;
      padding: 24px;
      align-items: center;
      justify-content: center;
      text-align: start;
    }
    &__contentclose {
      top: 0px;
      z-index: 0;
      // position: sticky;
      position: relative;
      overflow: hidden;
      width: grid-width(22);
      height: 52px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 24px;
      gap: 134px;
      background: #f2f1f0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      margin-top: 4px;
      color: #000000;
      cursor: pointer;
    }
    &__showAllButton {
      margin-top: 24px;
      width: 300px;
    }
    &__button {
      width: grid-width(22);
      margin-bottom: 180px;
      margin-top: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      .e-site-button {
        height: 60px;
        width: 290px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
      .e-site-button__icon {
        display: none !important;
      }
      .e-site-button__text {
        line-height: 24px;
        height: 20px;
        width: 211px;
        font-size: 16px !important;
        font-weight: 700;
        line-height: 24px;
        color: #000000;
        white-space: nowrap;
        margin-right: 40px;
      }
    }
    &__rowtitleleft {
      width: auto;
      max-width: 80%;
      overflow: hidden;
      align-items: center;
      display: flex;
      word-break: break-word;
    }
    &__rowtitleright {
      // width: 20%;
      display: flex;
      // justify-content: center;
      align-items: center;
      padding-left: 18px;
    }
    &__icon {
      width: 20px;
      height: 20px;
      color: black;
    }
    &__contentdiv {
      position: relative;
      overflow: hidden;
    }
  }
}
</style>

<template>
  <OverallSettings v-bind="$props">
    <div class="c-cta-heading" v-if="fields">
      <div class="c-cta-heading__content" :class="contentClasses" v-grid-layout="fields.gridLayout">
        <heading class="c-cta-heading__content-title" :field="fields.title" :type="fields.titleType" rich />
        <div class="c-cta-heading__content-btn">
          <SiteButton v-bind="fields.button" />
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { computed } from 'vue';
export default {
  name: 'CTAHeading',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const computes = {
      contentClasses: computed(() => [props.fields.bottomLine.value ? 'bottom-line' : null, props.fields.topLine.value ? 'top-line' : null])
    };
    return {
      ...computes
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
@import '../styles/rte/fontSize.scss';
.c-cta-heading {
  @include grid-container;
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: $space-40;
    padding-bottom: $space-20;
    &.top-line {
      border-top: 5px solid #000;
      border-bottom: 2px solid #000;
    }
    &.bottom-line {
      border-bottom: 5px solid #000;
    }
    &-title {
      flex-grow: 1;
      word-break: break-all;
    }
    &-btn {
      flex-shrink: 0;
      margin-left: $space-40;
    }
  }
}
</style>

<template>
  <overall-settings v-bind="$props" ref="overallRef">
    <form-layout class="c-create-account__layout" :fields="succeed ? fields.successLayout.fields : fields.layout.fields">
      <div class="c-create-account">
        <div class="c-create-account__success" v-if="isLeaseDriver && succeed">
          <jss-rich-text class="c-create-account__title" :field="fields.successLdTitle" tag="div" />
          <jss-rich-text class="c-create-account__subtitle" :field="fields.successLdSubtitle" tag="div" />
          <jss-rich-text class="c-create-account__body" :field="fields.successLdBody" tag="div" />
          <site-button v-bind="fields.viewVehicleButton" />
        </div>
        <template v-else>
          <div class="c-create-account__section" v-show="step === 1">
            <div class="c-create-account__header" v-if="!isLeaseDriver">
              <back-button v-bind="fields.loginButton" />
            </div>
            <heading class="c-create-account__title" :field="fields.registerTitle" :type="fields.titleType" :font-family="null" rich />
            <div
              class="c-create-account__body"
              v-html="$formatString(fields.registerLdBody.value, { email: leaseDriver?.email })"
              v-if="isLeaseDriver && !$isNullOrEmpty(fields.registerLdBody?.value) && !keyHasExpired"
            />
            <jss-rich-text class="c-create-account__body" :field="fields.registerBody" v-if="!isLeaseDriver" />
            <dynamic-form :form="fields.registerForm" ref="registerFormRef" @change="onFormChange" />
            <div class="c-create-account__buttons">
              <site-button v-bind="fields.continueButton" :disabled="$isNullOrEmpty(leaseDriver?.email)" @click="onLeaseDriverRegister" v-if="isLeaseDriver" />
              <site-button v-bind="fields.createButton" @click="onRegister" v-else />
            </div>
          </div>
          <div class="c-create-account__section" v-show="step === 2">
            <div class="c-create-account__back" v-if="!$isNullOrEmpty(fields.backText?.value)" @click="onBack">
              <icon name="left" />
              <jss-rich-text :field="fields.backText" />
            </div>
            <jss-rich-text class="c-create-account__title" :field="fields.verifyTitle" tag="div" />
            <div
              class="c-create-account__body"
              v-html="$formatString(fields.verifyBody?.value, { email: registerFormData?.email, seconds: seconds })"
              v-if="!$isNullOrEmpty(fields.verifyBody?.value) && seconds >= 0"
            />
            <captcha-input
              class="c-create-account__captcha"
              v-model:value="captcha"
              :label="fields.captchaInput?.fields?.label?.value"
              :description="fields.captchaInput?.fields?.description?.value"
              ref="captchaInputRef"
            />
            <div class="c-create-account__buttons">
              <site-button v-bind="fields.verifyButton" :disabled="captcha?.length !== 6 || verifying" @click="onVerify" />
            </div>
            <div class="c-create-account__resend" v-if="codeHasSent && seconds === 0">
              <jss-rich-text :field="fields.resendTextPrefix" tag="span" />
              <jss-rich-text class="hand" :field="fields.resendText" @click="onResend" tag="span" />
            </div>
          </div>
          <help-content class="mg-t-64" v-bind="fields.helpContent" />
        </template>
        <gee-captcha captcha-name="web-login" ref="geeCaptchaRef" />
      </div>
    </form-layout>
  </overall-settings>
</template>

<script>
/**
 * @typedef CreateAccountFields
 * @property {SimpleField} registerTitle
 * @property {GlobalSettingEntry} titleType
 * @property {FormFields} registerForm
 * @property {SimpleField} verifyTitle
 * @property {SimpleField} verifyBody
 * @property {FormFields} verifyForm
 * @property {ButtonField} registerButton
 * @property {ButtonField} submitButton
 * @property {ButtonField} cancelButton
 * @property {SimpleField} registerSuccessMessage
 * */
import { inject, nextTick, onMounted, reactive, toRefs, watch } from 'vue';
import api from '@/api';
import { CAPTCHA_SCENE, captchaTypes } from '@/utils/constants';
import { equalString, formatString, ifEmpty } from '@/utils/string-utils';
import { useI18n } from 'vue-i18n';
import { getBetterUrl, getQueryStrings, isAbsoluteUri } from '@/utils/uri-utils';
import { isNullOrEmpty, isNullOrWhitespace } from '@/utils/obj-utils';
import { useRoute, useRouter } from 'vue-router';
import useAppStore from '@/store/appStore';
import { transitions } from '@/utils/transitions';
import { merge, debounce, difference } from 'lodash';
import { getGlobalConfigs, getPageAlpha2Code, isOnApp, scrollElToTop, scrollToTop } from '@/utils/site-utils';
import { countryNeedDoubleOptin } from '@/services/siteService';
import { formatDate } from '@/utils/date-utils';
import services from '@/services';
import { gtmPush, gtmFormView, gtmFormStart, gtmFormStep, gtmFormSubmit } from '@/utils/gtm-utils';

export default {
  name: 'CreateAccount',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    /**@type AppStore*/
    const appStore = useAppStore();
    const route = useRoute();
    const router = useRouter();
    const toast = inject('toast');
    const loading = inject('loading');
    const { t } = useI18n();
    const { formType } = props.page.fields;
    let codeTimeout = null;
    const _methods = {
      startCodeCountdown(seconds) {
        const text = t('Please wait ##seconds## seconds before requesting a new code');
        const fn = (seconds) => {
          state.seconds = seconds;
          if (seconds > 0) {
            codeTimeout = setTimeout(() => {
              fn(--seconds);
            }, 1000);
          }
        };
        fn(seconds);
      },
      alertThenRedirect() {
        const successMsg = props.fields.captchaInput?.fields?.successMsg?.value ?? 'Verification successful';
        let myOrderUrl;
        let [redirectUrl] = getQueryStrings('redirect_url');
        if (isNullOrWhitespace(redirectUrl)) {
          const [myOrderLink] = getGlobalConfigs(props.page, 'myOrderLink');
          redirectUrl = myOrderUrl = getBetterUrl(ifEmpty(myOrderLink?.value?.href, '/my-lotus'), props.page.itemLanguage, true, false);
        }
        if (!isAbsoluteUri(redirectUrl)) {
          redirectUrl = getBetterUrl(redirectUrl, props.page.itemLanguage, true, false);
        }

        state.captchaInputRef.setSuccessMsg(successMsg);
        const successMessage = props.fields.registerSuccessMessage?.value;
        if (!isNullOrEmpty(successMessage)) {
          toast.showSuccess(
            formatString(successMessage, {
              name: `${state.registerFormData.firstName} ${state.registerFormData.lastName}`
            }),
            {
              okText: t(equalString(myOrderUrl, redirectUrl) ? 'My account' : 'Redirect'),
              onClosed() {
                window.location = redirectUrl;
              }
            }
          );
        } else {
          window.location = redirectUrl;
        }
      },
      async callCrm(formData) {
        const alpha2Code = getPageAlpha2Code(props.page);
        const allConsents = state.registerFormRef.getVerifiedConsents();
        // const newsletterConsents = formData.termsCondition ? state.registerFormRef.getItemConsents('termsCondition') : [];
        // const bindConsents = difference(allConsents, newsletterConsents);
        const email = formData.email ?? state.leaseDriver?.email;
        const leadTypes = state.registerFormRef.getLeadTypes();
        let leadsDetails = {};
        try {
          const [bindRes, bindEx] = await services.site.bindConsents(allConsents, {
            countryRegion: formData.mobile?.area?.data?.alpha2Code ?? '',
            phone: formData.mobile?.number ?? '',
            firstName: formData.firstName,
            lastName: formData.lastName,
            email
          });
          if (leadTypes?.length) {
            leadTypes.forEach((l) => {
              leadsDetails[l.leadType] = bindRes ?? null;
            });
          }
        } catch (ex) {
          console.log('ex', ex);
        }
        if (formData?.CPTC) {
          const cptcBody = merge(
            {
              firstName: formData.firstName,
              middleName: formData.middleName ?? '',
              lastName: formData.lastName,
              email: formData.email,
              countryRegion: alpha2Code,
              channel: 'Official Website'
            },
            formData?.externalData
          );
          if (!isNullOrEmpty(formData.isAboveTwenty)) {
            merge(cptcBody, { isAboveTwenty: formData.isAboveTwenty });
          }
          const [newsletterRes] = await api.lcms.crm.cptc.create(null, cptcBody);
          // const needDoubleOptin = await countryNeedDoubleOptin(alpha2Code);
          // const [newsletterRes, newsletterEx] = await api.crm.newsletter.subscribe(
          //   null,
          //   merge(
          //     {
          //       vehicleModelOfInterest: '',
          //       countryRegion: formData.country.data.alpha2Code,
          //       language: formData.lang?.code ?? props.page.itemLanguage,
          //       needDoubleOptin,
          //       termsAndConditions: newsletterConsents.map((revisionNumber) => ({
          //         revisionNumber: revisionNumber,
          //         title: window.document.title,
          //         formUrl: window.location.href,
          //         effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
          //       })),
          //       phone: formData.mobile ? `${formData.mobile.area.code}${formData.mobile.number}` : '',
          //       firstName: formData.firstName,
          //       middleName: '',
          //       lastName: formData.lastName,
          //       email,
          //       channel: isOnApp(route) ? 'APP' : 'Official Website',
          //       company: formData?.companyName ?? '',
          //       title: formData?.title ?? '',
          //       gRecaptchaToken: formData.gRecaptchaToken ?? null
          //     },
          //     formData?.externalData ?? {}
          //   )
          // );
          if (leadTypes?.length) {
            leadTypes.forEach((l) => {
              if (equalString(l.consentType, 'CPTC')) {
                leadsDetails[l.leadType] = newsletterRes?.leadId ?? null;
              }
            });
          }
        }

        gtmFormSubmit(
          formType?.value,
          props.fields.verifySection?.fields?.accountForm?.id,
          {
            email: formData.email ?? '',
            mobileAreaCode: formData.mobile?.area?.code ?? '',
            phone: formData.mobile?.number ?? ''
          },
          {
            selected_model: null,
            selected_location_name: null,
            selected_location_type: null,
            selected_location_date: null,
            selected_location_time: null,
            customer_type: null,
            dealer_name: null
          },
          leadsDetails
        );
      },
      callGtm(event) {
        let { leadFormName } = state.registerFormRef.getExternalData();
        const { id, name } = props.fields.registerForm || {};
        if (isNullOrEmpty(leadFormName)) leadFormName = name;
        gtmPush({
          event,
          form_type: 'driver_registration',
          form_id: id,
          form_name: leadFormName
        });
      }
    };
    const methods = {
      onLeaseDriverRegister: debounce(async () => {
        const [valid, formData] = await state.registerFormRef.validate();
        if (!valid) return;
        loading.show();
        _methods.callGtm('form_submit');
        const { firstName, lastName, mobile, country, dateOfBirth } = formData;
        const email = formData.email ?? state.leaseDriver?.email;
        const body = {
          validateToken: state.validateToken,
          validateKey: state.validateKey,
          email,
          firstName,
          lastName,
          country: country.code,
          phone: mobile?.number ?? '',
          mobileAreaCode: mobile?.area?.code ?? '',
          birthday: formatDate(dateOfBirth, 'yyyy-MM-dd'),
          source: 202,
          lang: formData.lang?.code ?? props.page.itemLanguage
        };
        const [res, ex] = await api.magicLink.login(null, body);
        if (!res) {
          toast.showEx(ex);
          loading.hide();
          return;
        }
        await appStore.setLoginInfo(res);
        state.succeed = true;
        await _methods.callCrm(formData);
        scrollElToTop(state.overallRef.rootEl);
        loading.hide();
      }, 200),
      onRegister: debounce(async () => {
        const [valid, formData] = await state.registerFormRef.validate();
        if (!valid) return;
        loading.show();
        const body = merge(
          {
            firstName: formData.firstName,
            lastName: formData.lastName,
            emailAddress: formData.email ?? '',
            country: formData.country.code,
            phone: formData.mobile?.number ?? '',
            mobileAreaCode: formData.mobile?.area?.code ?? ''
            // password: '123$Rty'
          },
          formData.externalData
        );
        const [res, ex] = await api.cidp.email.validate(null, body);
        if (ex) {
          await toast.showEx(ex);
          loading.hide();
          return;
        }
        state.registerFormData = formData;
        const sent = await methods.sendCaptcha();
        loading.hide();
        if (sent) {
          state.step = 2;
          gtmFormStep(
            formType?.value,
            props.fields.registerForm.id,
            {
              email: formData.email ?? '',
              mobileAreaCode: formData.mobile?.area?.code ?? '',
              phone: formData.mobile?.number ?? ''
            },
            2,
            'register-verify',
            null
          );
          await nextTick();
          scrollElToTop(state.overallRef.rootEl);
        }
      }, 200),
      onVerify: debounce(async () => {
        state.verifying = true;
        loading.show();
        const { email, firstName, middleName, lastName, title, mobile, country } = state.registerFormData;
        const errorMsg = props.fields.captchaInput?.fields?.errorMsg?.value ?? 'The code you entered was incorrect or incomplete';
        const [, verifyEx] = await api.cidp.captcha.check(null, {
          captchaType: captchaTypes.email,
          captcha: state.captcha,
          email: email,
          captchaScene: CAPTCHA_SCENE.registerLogin
        });
        if (verifyEx) {
          loading.hide();
          await toast.showEx(ex);
          state.verifying = false;
          state.captchaInputRef.setErrorMsg(errorMsg);
          return;
        }
        const [res, ex] = await api.cidp.bto.emailRegisterLogin(null, {
          email,
          firstName,
          middleName: middleName ?? '',
          lastName,
          mobileAreaCode: mobile?.area.code ?? '',
          phone: mobile?.number ?? '',
          terms: true,
          source: isOnApp(route) ? '102' : '202',
          password: '',
          title: title ?? '',
          country: country.code,
          preferLanguage: props.page.itemLanguage,
          communicatePreference: 0,
          // companyName: state.registerFormData?.companyName ?? '',
          // vatNumber: state.registerFormData?.vatNumber ?? '',
          // companyRegistrationNumber: state.registerFormData?.companyRegistrationNumber ?? '',
          accountType: 1
        });
        // const [res, ex] = await api.cidp.email.register(null, {
        //   registerSource: 202,
        //   email: state.registerFormData.email,
        //   captcha: state.captcha,
        //   source: 202
        // });
        if (ex) {
          await toast.showEx(ex);
          return;
        }
        await appStore.setLoginInfo(res);
        await _methods.callCrm(state.registerFormData);
        loading.hide();
        state.verifying = false;
        _methods.alertThenRedirect();
      }, 200),
      onResend: debounce(() => {
        methods.sendCaptcha();
      }, 200),
      async onBack() {
        if (state.verifying) return;
        const [valid, formData] = await state.registerFormRef.validate();
        state.step = 1;
        gtmFormStep(
          formType?.value,
          props.fields.registerForm.id,
          {
            email: formData.email ?? '',
            mobileAreaCode: formData.mobile?.area?.code ?? '',
            phone: formData.mobile?.number ?? ''
          },
          1,
          'register-details',
          null
        );
        state.captcha = null;
        state.seconds = 60;
        await nextTick();
        scrollToTop();
      },
      async sendCaptcha() {
        loading.show();
        const geeResult = await state.geeCaptchaRef.validate();
        const body = merge(
          {
            captchaType: captchaTypes.email,
            captchaScene: CAPTCHA_SCENE.registerLogin,
            email: state.registerFormData?.email
          },
          geeResult
        );
        const [, ex] = await api.cidp.v2.captcha.get(null, body);
        if (ex) {
          await toast.showEx(ex);
          loading.hide();
          return false;
        }
        state.codeHasSent = true;
        loading.hide();
        _methods.startCodeCountdown(60);
        return true;
      },
      onFormChange(key, value) {
        if (!state.formChanged) {
          state.formChanged = true;
          gtmFormStart(formType?.value, props.fields.registerForm.id);
        }
      }
    };
    const state = reactive({
      step: 1,
      validateToken: null,
      validateKey: null,
      isLeaseDriver: false,
      keyHasExpired: false,
      leaseDriver: null,
      overallRef: null,
      /**@type DynamicForm*/
      registerFormRef: null,
      registerFormData: null,
      /**@type CaptchaInputRef*/
      captchaInputRef: null,
      /**@type GeeCaptchaRef*/
      geeCaptchaRef: null,
      codeHasSent: true,
      captcha: null,
      verifying: false,
      succeed: false,
      seconds: 60,
      formChanged: false
    });
    watch(
      () => state.captcha,
      (_captcha) => {
        if (_captcha?.length === 6) {
          methods.onVerify();
        }
      }
    );
    onMounted(async () => {
      const [validateToken, validateKey] = getQueryStrings('validateToken', 'validateKey');
      state.validateToken = validateToken;
      state.validateKey = validateKey;
      const langOptions = await services.site.getMarketLangOptions();
      state.registerFormRef.updateItem('lang', {
        controlProps: {
          options: langOptions
        }
      });
      if (!isNullOrEmpty(validateToken) && !isNullOrEmpty(validateKey)) {
        loading.show();
        state.isLeaseDriver = true;
        _methods.callGtm('form_start');
        // state.registerFormRef.updateItem('mobile', {
        //   visible: false
        // });
        const [res, ex] = await api.magicLink.get({ validateToken, validateKey });
        if (ex) {
          state.keyHasExpired = true;
          toast.showEx(ex);
        }
        state.leaseDriver = res;
        state.registerFormRef.updateItem('email', {
          controlProps: {
            value: res?.email ?? '',
            disabled: true
          }
        });
        loading.hide();
      } else {
        // state.registerFormRef.updateItem('lang', {
        //   visible: false
        // });
      }
      state.geeCaptchaRef.init();
      gtmFormView(formType?.value, props.fields.registerForm.id);
      gtmFormStep(formType?.value, props.fields.registerForm.id, appStore?.loginInfo, 1, 'register-details', null);
    });
    return {
      ...toRefs(state),
      ...methods,
      transitions
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-create-account {
  height: 100%;
  position: relative;
  padding-bottom: 100px;
  $l: '.s-form-layout';
  &__layout {
    #{$l}__side-image {
      display: block;
    }
  }
  &__header {
    margin-bottom: 24px;
    .e-site-button {
      &__text {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
  &__title {
    @include h5;
    font-weight: 300;
    line-height: 1;
  }
  &__subtitle {
    color: $grey-night;
  }
  &__body {
    color: $grey-next;
  }
  &__description {
    margin-top: 12px;
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
  }
  &__captcha {
    align-self: stretch;
  }
  &__buttons {
    margin-top: 8px;
    width: 100%;
    .e-site-button {
      width: 100%;
      + .e-site-button {
        margin-top: 24px;
      }
    }
  }
  &__back {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 24px;
    .e-icon {
      svg {
        height: 12px;
      }
    }
  }
  &__section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  &__success {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
  @include tablet-landscape {
    &__header {
      margin-bottom: 56px;
    }
    &__buttons {
      display: flex;
      justify-content: center;
      .e-site-button {
        + .e-site-button {
          margin-top: 0;
          margin-left: 24px;
        }
      }
    }
    &__success {
      gap: 40px;
    }
  }
}
</style>

<template>
  <OverallSettings v-bind="$props">
    <div class="c-exhibition-stand-pro" :class="[{ 'image-full-height': fields?.imageFullHeight?.value }]" v-if="fields">
      <AdaptiveMedia class="c-exhibition-stand-pro__background" :field="fields.backgroundImage" />
      <div class="c-exhibition-stand-pro__content">
        <div class="c-exhibition-stand-pro__main swiper" ref="swiperEl">
          <div class="swiper-wrapper">
            <div class="swiper-slide" :data-slide-id="item.id" v-for="item in fields.items" :key="item.id">
              <div class="c-exhibition-stand-pro__cars">
                <AdaptiveImage :field="item.fields.AdaptiveCarImage" :isBackground="false" :lazy-load="false" />
              </div>
              <div class="c-exhibition-stand-pro__details">
                <div class="c-exhibition-stand-pro__details-title">
                  <AnimatedContent type="skew-in" :duration="300" :delay="100">
                    <JssRichText :field="item.fields.title" />
                  </AnimatedContent>
                </div>
                <div class="c-exhibition-stand-pro__details-body">
                  <AnimatedContent type="fade-in" :duration="300" :delay="200">
                    <JssRichText :field="item.fields.des" />
                  </AnimatedContent>
                </div>
                <div class="c-exhibition-stand-pro__btn">
                  <AnimatedContent type="bottom-fade-in" :duration="300" :delay="300">
                    <SiteButton v-bind="item.fields.button" />
                  </AnimatedContent>
                </div>
                <div class="c-exhibition-stand-pro__buttons" :class="item.fields.buttonsLayout?.fields.phrase.value ?? 'vertical'">
                  <animated-content type="bottom-fade-in" :delay="index * 200" v-for="(btn, index) in item.fields.buttons" :key="btn.id" :turn-off="fields.turnOffTextAnimation?.value">
                    <SiteButton v-bind="btn" />
                  </animated-content>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-exhibition-stand-pro__pagination">
          <div
            class="swiper-pagination"
            :class="[{ [`__c-${paginationBulletColor}`]: !$isNullOrEmpty(paginationBulletColor) }]"
            ref="paginationEl"
            v-show="fields.items?.length > 1"
            v-if="!$equalString(paginationBulletColor, 'transparent')"
          />
        </div>
        <div class="c-exhibition-stand-pro__nav" v-show="fields.items?.length > 1" v-if="!$deviceComputes.isMobileOrTablet.value && !$equalString(arrowColor, 'transparent')">
          <div class="c-exhibition-stand-pro__nav-prev" :class="[`__c-${arrowColor}`, { [`bd-${squareColor}`]: !$isNullOrEmpty(squareColor) }]" ref="prevEl">
            <Icon :svg="fields.prevIcon?.value.svgCode" />
          </div>
          <div class="c-exhibition-stand-pro__nav-next" :class="[`__c-${arrowColor}`, { [`bd-${squareColor}`]: !$isNullOrEmpty(squareColor) }]" ref="nextEl">
            <Icon :svg="fields.nextIcon?.value.svgCode" />
          </div>
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { toRefs, onMounted, reactive, onBeforeUnmount, nextTick, computed } from 'vue';
import { largeThanTablet } from '@/utils/dom-utils';
import Swiper from 'swiper';
import { settingValue } from '@/utils/site-utils';
export default {
  name: 'ExhibitionStandPro',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let swiper;
    const state = reactive({
      swiperEl: null,
      paginationEl: null,
      prevEl: null,
      nextEl: null
    });
    const computes = {
      arrowColor: computed(() => settingValue(props.fields?.arrowColor, 'yellow')),
      squareColor: computed(() => settingValue(props.fields?.squareColor, 'yellow')),
      paginationBulletColor: computed(() => settingValue(props.fields?.paginationBulletColor, 'yellow'))
    };
    const methods = {
      largeThanTablet,
      buildSwiper() {
        swiper && swiper.destroy(true, true);
        swiper = new Swiper(state.swiperEl, {
          resizeObserver: true,
          slidesPerView: 1,
          loop: true,
          touchAngle: 10,
          pagination: {
            el: state.paginationEl,
            bulletElement: 'div'
          },
          breakpoints: {
            992: {
              navigation: {
                prevEl: state.prevEl,
                nextEl: state.nextEl
              }
            }
          }
        });
      }
    };
    onMounted(() => {
      nextTick(() => {
        methods.buildSwiper();
      });
    });
    onBeforeUnmount(() => {
      swiper && swiper.destroy(true, true);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-exhibition-stand-pro {
  $c: &;
  height: calc(100vh - #{mobile-header-height()});
  position: relative;
  &__background {
    height: 100%;
  }
  &__buttons {
    position: absolute;
    left: 0;
    bottom: $space-56;
    width: grid-width-m(12);
    padding: 0 grid-width-m(1);
    display: flex;
    flex-direction: column;
    gap: 16px;
    .e-animated-content {
      width: 100%;
    }
    .e-site-button {
      display: flex;
      width: 100%;
    }
  }
  &__content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 2;
  }
  &__main {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    .swiper {
      &-slide {
        position: relative;
        width: grid-width-m(12);
        padding: 0 grid-width-m(1);
        .animate {
          display: none;
        }
        &-active {
          .animate {
            display: block;
            animation: displayShow 0.5s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }
  &__details {
    margin: $space-80 0 0;
    &-icon {
      color: #b8b8b8;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    &-title {
      margin: $space-16 $space-20;
    }
    &-body {
      margin: $space-16 $space-20;
    }
  }
  &__cars {
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    img {
      width: 100%;
      height: auto;
    }
  }
  &__image {
    height: 100%;
    .e-background-image {
      position: unset;
    }
  }
  &__btn {
    position: absolute;
    left: 0;
    bottom: $space-56;
    width: grid-width-m(12);
    padding: 0 grid-width-m(1);
    .e-site-button {
      width: 100%;
    }
  }
  &__pagination {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 5;
    bottom: 200px;
    .swiper-pagination {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 12px;
      &-bullet {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        opacity: 0.4;
        background: currentColor;
        &-active {
          opacity: 1;
        }
      }
    }
  }
  &.image-full-height {
    #{$c}__cars {
      height: 100%;
      .e-background-image {
        height: 100%;
        &__img {
          height: 100%;
          img {
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }
  @include tablet-landscape {
    &__main {
      .swiper-slide {
        position: unset;
        width: grid-width(24);
        padding: 0 grid-width(2);
      }
    }
    &__buttons {
      position: unset;
      width: fit-content;
      z-index: 3;
      padding: 0;
      margin: $space-16 $space-20;
      &.horizontal {
        flex-direction: row;
        align-items: center;
        > div + div {
          margin-top: 0;
          margin-left: 0;
        }
      }
      &.vertical {
        flex-direction: column;
        justify-content: center;
        > div + div {
          margin-top: 0;
          margin-left: 0;
        }
      }
    }
    &__details {
      position: absolute;
      margin: $space-60 0 0;
      max-width: 500px;
    }
    &__btn {
      position: unset;
      width: fit-content;
      z-index: 3;
      padding: 0;
      margin: $space-16 $space-20;
      .e-site-button {
        width: 100%;
      }
    }
    &__nav {
      > div {
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        z-index: 5;
        width: 60px;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 2px solid transparent;
        cursor: pointer;
        .e-icon {
          svg {
            width: 40px;
            height: 40px;
          }
        }
        &.bd {
          &-black {
            border-color: $black;
          }
          &-yellow {
            border-color: $yellow;
          }
          &-white {
            border-color: $white;
          }
        }
        &.__c {
          &-black {
            .e-icon {
              svg {
                fill: $black !important;
                path {
                  fill: $black !important;
                }
              }
            }
          }
          &-yellow {
            .e-icon {
              svg {
                fill: $yellow !important;
                path {
                  fill: $yellow !important;
                }
              }
            }
          }
          &-white {
            .e-icon {
              svg {
                fill: $white !important;
                path {
                  fill: $white !important;
                }
              }
            }
          }
        }
      }
      &-prev {
        left: grid-width(1);
      }
      &-next {
        right: grid-width(1);
      }
    }
  }
  @include desktop {
    &__buttons {
      font-size: 1rem;
      align-items: flex-start;
      flex-wrap: wrap;
      padding-left: 0 !important;
      padding-right: 0 !important;
      &.horizontal {
        flex-direction: row;
        gap: 1em;
        align-items: center;
        > div + div {
          margin-top: 0;
          margin-left: 0;
        }
      }
      &.vertical {
        flex-direction: column;
        justify-content: center;
        > div + div {
          margin-top: 0;
          margin-left: 0;
        }
      }
    }
  }
  @keyframes displayShow {
    0% {
      display: none;
    }
    100% {
      display: block;
    }
  }
}
</style>
